<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <div class="main_contents_text boxs">
        <div class="bh4 mt_35 fc_y fw_m">{{
          $t('pages.Policy.JudgingApplicationCryptoAssets.title1')
        }}</div>
        <div class="policy_inner">
          <p>{{ $t('pages.Policy.JudgingApplicationCryptoAssets.msg1') }}</p>
        </div>
        <div class="bh4 mt_35 fc_y fw_m">{{
          $t('pages.Policy.JudgingApplicationCryptoAssets.title2')
        }}</div>
        <div class="policy_inner">
          <p>{{ $t('pages.Policy.JudgingApplicationCryptoAssets.msg2') }}</p>
          <dl class="dl_table">
            <dt>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.th1')
            }}</dt>
            <dd>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.td1')
            }}</dd>
            <dt>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.th2')
            }}</dt>
            <dd>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.td2')
            }}</dd>
            <dt>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.th3')
            }}</dt>
            <dd>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.td3')
            }}</dd>
            <dt>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.th4')
            }}</dt>
            <dd>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.td4')
            }}</dd>
            <dt>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.th5')
            }}</dt>
            <dd>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.td5')
            }}</dd>
            <dt>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.th6')
            }}</dt>
            <dd>{{
              $t('pages.Policy.JudgingApplicationCryptoAssets.form2.td6')
            }}</dd>
          </dl>
        </div>
        <div class="bh4 mt_35 fc_y fw_m">{{
          $t('pages.Policy.JudgingApplicationCryptoAssets.title3')
        }}</div>
        <div class="policy_inner">
          <p>{{ $t('pages.Policy.JudgingApplicationCryptoAssets.msg3') }}</p>
          <ul class="policy_list">
            <li>
              <span>１）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title3_1') }}
              <p
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg3_1')
                "
              ></p>
            </li>
            <li>
              <span>２）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title3_2') }}
              <p
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg3_2')
                "
              ></p>
            </li>
            <li
              ><span>３）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title3_3') }}
              <p
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg3_3')
                "
              ></p>
            </li>
            <li>
              <span>４）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title3_4') }}
              <p
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg3_4_1')
                "
              ></p>
              <p
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg3_4_2')
                "
              ></p>
              <p
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg3_4_3')
                "
              ></p>
            </li>
            <li>
              <span>５）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title3_5') }}
              <p
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg3_5')
                "
              ></p>
            </li>
          </ul>
        </div>
        <div class="bh4 mt_35 fc_y fw_m">{{
          $t('pages.Policy.JudgingApplicationCryptoAssets.title4')
        }}</div>
        <div class="policy_inner">
          <p>{{ $t('pages.Policy.JudgingApplicationCryptoAssets.msg4') }}</p>
          <ul class="policy_list">
            <li>
              <span>１）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title4_1') }}
              <p
                class="no-indent"
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg4_1')
                "
              ></p>
            </li>
            <li>
              <span>２）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title4_2') }}
              <p
                class="no-indent"
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg4_2')
                "
              ></p>
            </li>
            <li
              ><span>３）</span
              >{{ $t('pages.Policy.JudgingApplicationCryptoAssets.title4_3') }}
              <p
                class="no-indent"
                v-html="
                  $t('pages.Policy.JudgingApplicationCryptoAssets.msg4_3')
                "
              ></p>
            </li>
          </ul>
        </div>
        <p style="text-align: right">{{
          $t('pages.Policy.JudgingApplicationCryptoAssets.end')
        }}</p>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<style lang="scss" scoped>
  .policy_list p {
    margin: 10px 0 30px 0;
    line-height: 2.5rem;
    &.no-indent {
      text-indent: 0;
    }
  }

  .policy_list li span {
    padding-right: 0;
  }
</style>
