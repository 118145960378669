<template>
  <!-- article start -->
  <article id="contents" class="boxs loginart">
    <div class="main_contents boxs">
      <div class="main_contents_text boxs">
        <h1 class="err_head">{{ $t('pages.Maintenance.title') }}</h1>

        <div class="boxss">
          <p>&nbsp;</p>
            <p class="p_name"><span>{{ $t('pages.Error.subtitle') }}</span></p
            ><p>&nbsp;</p
            ><p class="p_text"
              >{{ $t('pages.Error.msg1') }}<br /><br />

              <b class="err_title">{{ $t('pages.Maintenance.msg1') }}</b
              ><br /><br />
              {{ $t('pages.Maintenance.msg2') }}
              <br /><br />
              {{ $t('pages.Error.msg2') }}</p
            >
            <br /><br />
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
  .err_head {
    margin: 4rem 0 0;
    text-align: center;
    font-size: 2.6rem;
    color: #febd09;
  }
  .err_title {
    color: #f0516e;
    margin: 3rem 0 1.25rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .is-center {
    text-align: center;
  }
</style>
