<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.DepositConveni.title') }}</h1>

        <template v-if="Object.keys(state.formJson).length > 0">
          <div class="formbox">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <div v-else>
              <the-message v-if="state.successMessage" type="primary">{{
                state.successMessage
              }}</the-message>
            </div>
          </div>

          <div class="tbl_border">
            <div class="tbl_form">
              <p>{{ $t('pages.DepositConveni.msg1') }}</p>
              <p v-html="$t('pages.DepositConveni.msg2')"></p>
              <p>{{ $t('pages.DepositConveni.msg3') }}</p>
              <p class="indent-1">&nbsp;</p>
              <p class="tbl_title">{{
                $t('pages.DepositConveni.msg4', {
                  button_name:
                    state.deposit?.inqno == null
                      ? $t('pages.DepositConveni.button_create')
                      : $t('pages.DepositConveni.button_update')
                })
              }}</p>

              <SchemaFormWithValidation
                :schema="state.schema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
              />

              <div class="uk-form-controls uk-text-right">
                <label class="uk-margin-top"
                  >{{ $t('pages.DepositConveni.form.fee') }} :
                  <span class="text-bold">{{
                    state.fee.toLocaleString()
                  }}</span>
                  {{ $t('pages.DepositConveni.unit') }}
                </label>
              </div>
              <div class="uk-form-controls uk-text-right">
                <label class="uk-margin-top"
                  >{{ $t('pages.DepositConveni.form.total') }} :
                  <span class="text-bold">{{
                    state.total.toLocaleString()
                  }}</span>
                  {{ $t('pages.DepositConveni.unit') }}
                </label>
              </div>

              <template v-if="state.twostep_flg == '0'">
                <router-link
                  :to="{ name: 'TwoStepStatus' }"
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                  >{{ $t('pages.DepositConveni.button_twostep') }}
                </router-link>
              </template>
              <template v-else>
                <button
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary uk-margin-top"
                  v-debounce="{
                    func: confirm
                  }"
                  type="button"
                >
                  <template v-if="state.deposit?.inqno == null">{{
                    $t('pages.DepositConveni.button_create')
                  }}</template>
                  <template v-else>{{
                    $t('pages.DepositConveni.button_update')
                  }}</template>
                </button>
              </template>

              <div
                class="uk-margin-large-top"
                v-if="state.deposit?.inqno != null"
              >
                <p class="tbl_title">{{
                  $t('pages.DepositConveni.deposit.title')
                }}</p>
                <div class="fee_table_wrap">
                  <table class="fee_table">
                    <tbody>
                      <tr>
                        <td class="fee_th">{{
                          $t('pages.DepositConveni.deposit.item1')
                        }}</td>
                        <td class="fee_td">{{ state.deposit?.inqno }}</td>
                      </tr>
                      <tr>
                        <td class="fee_th">{{
                          $t('pages.DepositConveni.deposit.item2')
                        }}</td>
                        <td class="fee_td">{{ state.deposit?.limit_date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="uk-form-controls uk-text-right error">
                  <button
                    v-debounce="{
                      func: cancel
                    }"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.DepositConveni.button_cancel')
                    }"
                    type="button"
                    >{{ $t('pages.DepositConveni.button_cancel') }}</button
                  >
                </div>

                <a
                  :href="state.deposit?.barcode"
                  v-if="env == 'local' || env == 'it'"
                  target="_blank"
                  >{{ state.deposit?.barcode }}</a
                >
              </div>
            </div>
          </div>

          <h1 class="history-title">{{
            $t('pages.DepositConveni.title_history')
          }}</h1>

          <history-item
            :detail="state.history"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>
          <pagination
            :total="state.history.total_pages"
            :page="state.page"
            @onchange="changePage"
            v-if="state.history && state.history?.results?.length > 0"
          ></pagination>

          <the-modal
            v-if="state.modal"
            id="modal-confirm"
            hasClose
            @close="state.modal = false"
          >
            <section class="modal-inner">
              <h1 v-html="$t('pages.DepositConveni.modal_title')"></h1>

              <div class="corporation-confirm">
                <dl>
                  <dt>{{ $t('pages.DepositConveni.form.amount') }}</dt>
                  <dd
                    >{{ parseInt(state.formJson?.amount).toLocaleString() }}
                    {{ $t('pages.DepositConveni.unit') }}</dd
                  >
                </dl>
                <dl>
                  <dt>{{ $t('pages.DepositConveni.form.fee') }}</dt>
                  <dd
                    >{{ state.fee.toLocaleString() }}
                    {{ $t('pages.DepositConveni.unit') }}</dd
                  >
                </dl>
                <dl>
                  <dt>{{ $t('pages.DepositConveni.form.total') }}</dt>
                  <dd
                    >{{ state.total.toLocaleString() }}
                    {{ $t('pages.DepositConveni.unit') }}</dd
                  >
                </dl>
              </div>

              <div id="div_id_twostep_code">
                <label
                  for="id_twostep_code"
                  class="col-form-label requiredField"
                >
                  <span class="font_size">{{
                    $t('pages.TwoStepCheck.form.issueLabel')
                  }}</span>
                  <button
                    class="uk-button uk-button-primary"
                    style="margin: 0 auto; display: block"
                    v-if="state.twostep_flg !== '3'"
                    v-debounce="{
                      func: issueCode
                    }"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.TwoStepCheck.form.issue')
                    }"
                    type="button"
                    >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                  >
                  <div v-else>&nbsp;</div>
                </label>
              </div>

              <SchemaFormWithValidation
                :schema="state.schema2"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
              />

              <button
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                v-debounce="{
                  func: submit
                }"
                v-loading="{
                  loading: state.loading,
                  value:
                    state.deposit?.inqno == null
                      ? $t('pages.DepositConveni.button_create')
                      : $t('pages.DepositConveni.button_update')
                }"
                type="button"
              >
                <template v-if="state.deposit?.inqno == null">{{
                  $t('pages.DepositConveni.button_create')
                }}</template>
                <template v-else>{{
                  $t('pages.DepositConveni.button_update')
                }}</template>
              </button>
            </section>
          </the-modal>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import TheModal from '@/components/TheModal.vue'
  import {
    getDepositConveni,
    getWalletFiatHistory,
    twoStepIssue,
    setDepositConveni,
    cancelDepositConveni
  } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'

  const router = useRouter()
  const store = useMainStore()
  const { env } = storeToRefs(store)
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const { t } = useI18n()
  const state: {
    title: any[]
    total: number
    fee: number
    code_mst: any[]
    history:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    formJson: any
    deposit: any
    schema: any
    schema2: any
    loading: boolean
    modal: boolean
    twostep_flg: string
    isIssued: boolean
    errorMessage: string
    successMessage: string
  } = reactive({
    title: [],
    total: 0,
    fee: 330,
    code_mst: [],
    history: undefined,
    page: 1,
    formJson: {},
    deposit: {},
    schema: {
      amount: {
        component: 'Text',
        label: t('pages.DepositConveni.form.amount'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.DepositConveni.form.amount'),
            type: t('validation.type.input')
          })
        )
      }
    },
    schema2: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    loading: true,
    modal: false,
    twostep_flg: '',
    isIssued: false,
    errorMessage: '',
    successMessage: ''
  })

  const confirm = async () => {
    state.successMessage = ''
    state.errorMessage = ''

    const valid = form.value?.validate()

    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        if (state.formJson.amount < 1) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_limit_amount')
          return
        }
        if (299670 < state.formJson.amount) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_conveni_max')
          return
        }

        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const issueCode = async () => {
    state.loading = true
    await twoStepIssue('db_check', null)
    state.isIssued = true
    state.loading = false
  }

  const submit = async () => {
    state.successMessage = ''
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
        state.loading = true
        try {
          const { contents } = await setDepositConveni({
            contents: {
              ...state.formJson
            }
          })
          state.loading = false
          state.modal = false
          state.successMessage = t('error.success_deposit_conveni')
          init()
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          state.loading = false
          state.modal = false
        } finally {
          state.formJson.passcode = ''
        }
      }
    }, 0)
  }

  const cancel = async () => {
    state.successMessage = ''
    state.errorMessage = ''

    setTimeout(async () => {
      state.loading = true
      try {
        const { contents } = await cancelDepositConveni({
          contents: {
            inqno: state.deposit?.inqno
          }
        })
        state.loading = false
        state.successMessage = t('error.success_cancel_conveni')
        init()
      } catch (error: any) {
        state.errorMessage = error.error_translations || error.message_detail
        document.documentElement.scrollTop = 0
        state.loading = false
      }
    }, 0)
  }

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getWalletFiatHistory(state.page, '4', '')
    state.history = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  const init = async () => {
    try {
      const { contents } = await getDepositConveni()
      if (!contents?.agreement) {
        router.push({ name: 'DepositConveniAgreement' })
      }
      state.twostep_flg = contents?.twostep_flg
      state.deposit = contents?.deposit
      state.formJson.amount = contents?.deposit?.amount
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.DepositConveni.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  onMounted(async () => {
    init()

    state.title = [
      { key: 'upd_date', name: t(`pages.DepositConveni.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.DepositConveni.item.title2`),
        type: 'code'
      },
      {
        key: 'amount',
        name: t(`pages.DepositConveni.item.title3`),
        type: 'number'
      },
      {
        key: 'fee',
        name: t(`pages.DepositConveni.item.title4`),
        type: 'number'
      },
      {
        key: 'balance',
        name: t(`pages.DepositConveni.item.title5`),
        type: 'number'
      }
    ]
    state.code_mst = options.deposit_fiat
    await showHistory()
  })

  watch(
    () => state.formJson.amount,
    async (value: string, oldValue: string) => {
      if (value == null) {
        state.total = state.fee
      } else {
        state.total = parseInt(value) + state.fee
      }
    }
  )
</script>
<style lang="scss" scoped>
  .uk-margin-top {
    margin-top: 20px !important;
  }
  .uk-margin-large-top {
    margin-top: 50px !important;
  }
  .text-bold {
    font-weight: bold !important;
  }
  .fee_table_wrap {
    margin-bottom: unset !important;
  }
</style>
