<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.PointBalance.title') }}</h1>

        <template v-if="Object.keys(state.formJson).length > 0">
          <div class="boxs">
            <div class="account-info">
              <h5>{{ $t('pages.PointBalance.sub_title1') }}</h5>
              <ul>
                <li class="account-title area1"></li>
                <li class="account-total"
                  ><span class="column_04"></span
                  ><span class="column_05"
                    ><span class="total">{{
                      state.formJson.point.toLocaleString()
                    }}</span>
                    {{ $t('pages.PointBalance.unit') }}</span
                  ></li
                >
              </ul>
            </div>

            <div class="account-info">
              <h5>{{ $t('pages.PointBalance.sub_title2') }}</h5>
              <p
                class="text-red text-center"
                v-html="$t('pages.PointBalance.alert_msg')"
              ></p>
              <ul>
                <li class="account-title"
                  ><span class="column_04">{{
                    $t('pages.PointBalance.item.title1')
                  }}</span
                  ><span class="column_05">{{
                    $t('pages.PointBalance.item.title2')
                  }}</span></li
                >
                <template v-for="obj in state.point_list" :key="obj.limit_date">
                  <li
                    ><span
                      class="column_04"
                      v-bind:class="[obj.alert ? 'text-red' : '']"
                      >{{ obj.limit_date }}</span
                    ><span
                      class="column_05"
                      v-bind:class="[obj.alert ? 'text-red' : '']"
                      >{{ obj.amount.toLocaleString() }}
                      {{ $t('pages.PointBalance.unit') }}</span
                    ></li
                  >
                </template>
              </ul>
            </div>

            <div style="margin-top: 30px">
              <p>
                <strong v-html="$t('pages.PointBalance.msg')"></strong>
                ≪
                <span style="color: #febd09">
                  <router-link :to="{ name: 'Inquiry' }">{{
                    $t('menu.user.support.inquiry')
                  }}</router-link>
                </span>
                ≫
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import { getPointBalance } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const { t } = useI18n()
  const state: {
    title: any[]
    point_list: any[]
    formJson: any
  } = reactive({
    title: [],
    point_list: [],
    formJson: {}
  })

  const init = async () => {
    try {
      const { contents } = await getPointBalance()
      state.point_list = contents?.balance_list
      state.formJson.point = contents?.balance
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.PointBalance.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  onMounted(async () => {
    init()
  })
</script>
<style lang="scss" scoped>
  .area1 {
    min-height: unset !important;
  }
  .text-red {
    color: #df4c5b;
  }
  .text-center {
    text-align: center;
  }
  .total {
    color: #febd09;
    font-size: 25px;
  }
</style>
