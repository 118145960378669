<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="bh4 fw_m fc_y">{{ $t('pages.Service.tool.title1') }}</div>
      <div class="btxt ta_c">{{ $t('pages.Service.tool.description1') }}</div>

      <img
        :src="`${s3Url}img/page/tool_sample_new.png?v=4`"
        style="
          display: block;
          width: 75%;
          border-radius: 12px;
          margin: 4rem auto;
        "
        alt="tool_sample"
      />
      <div class="box_set" v-if="language === 'ja'">
        <a
          :href="`${s3Url}resource/trade_manual.pdf?v=3`"
          class="step_pdf btn_link_box"
          target="blank"
          ><i class="far fa-file-pdf"></i> PC版取引システムの操作マニュアル</a
        >
      </div>

      <div class="bh4 fw_m fc_y">{{ $t('pages.Service.tool.title2') }}</div>
      <div class="btxt ta_c mb_40">{{
        $t('pages.Service.tool.description2')
      }}</div>

      <div class="bh4 fw_m fc_y">{{ $t('pages.Service.tool.title3') }}</div>
      <div class="btxt ta_c">{{ $t('pages.Service.tool.description3') }}</div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)
</script>
