<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li class="is-current">３{{ $t('pages.Corporation.title.step3') }}</li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <h1>{{ $t('pages.Corporation.title.step3Confirm') }}</h1>
      <step3 :data="state.formJson"></step3>

      <button
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Corporation.form.next')
        }"
        type="button"
        >{{ $t('pages.Corporation.form.next') }}</button
      >
      <button
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
        v-debounce="{
          func: rollback
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Corporation.form.return')
        }"
        type="button"
        >{{ $t('pages.Corporation.form.return') }}</button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { getTradeHead, setCorporationStatus } from '@/api'
  import Step3 from '@/components/corporation/Step3.vue'

  const router = useRouter()

  const state: {
    init: boolean
    errorMessage: string
    formJson: any
  } = reactive({
    init: false,
    errorMessage: '',
    formJson: {}
  })

  const loading = ref(false)

  const submit = async () => {
    loading.value = true
    try {
      await setCorporationStatus('03', '1')
      router.push({ name: 'CorporationStep4Select' })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    }
    loading.value = false
  }

  const rollback = async () => {
    console.log('roll back')
    router.push({ name: 'CorporationStep3Input' })
  }

  onMounted(async () => {
    try {
      const { contents } = await getTradeHead()
      state.formJson = Object.assign(state.formJson, contents)
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>
