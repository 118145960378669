<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.LoginHistory.title') }}</h1>

        <history-item
          :detail="state.history"
          :title="state.title"
        ></history-item>
      </div>

      <pagination
        :total="state.history.total_pages"
        :page="state.page"
        @onchange="changePage"
        v-if="state.history && state.history?.results?.length > 0"
      ></pagination>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import { getLoginHistory } from '@/api'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  const state: {
    title: any[]
    history:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    loading: boolean
  } = reactive({
    title: [],
    history: undefined,
    page: 1,
    loading: true
  })

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getLoginHistory(state.page)
    state.history = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  onMounted(async () => {
    state.title = [
      { key: 'reg_date', name: t(`pages.LoginHistory.item.title1`) },
      { key: 'ip', name: t(`pages.LoginHistory.item.title2`) },
      { key: 'os', name: t(`pages.LoginHistory.item.title3`) },
      { key: 'browser', name: t(`pages.LoginHistory.item.title4`) },
      { key: 'user_agent', name: t(`pages.LoginHistory.item.title5`) }
    ]
    await showHistory()
  })
</script>
<style lang="scss" scoped></style>
