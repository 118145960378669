<template>
  <!-- <light-trading-view-chart :type="props.type"></light-trading-view-chart> -->
  <trading-view-chart v-if="typeFlag" :type="props.type"></trading-view-chart>
</template>

<script lang="ts" setup>
  import TradingViewChart from '@/components/TradingViewChart.vue'
  import LightTradingViewChart from '@/components/LightTradingViewChart.vue'

  const typeFlag = import.meta.env.VITE_APP_NEW_TRADE_TOOL_FLG === 'true'
  const props = defineProps({
    type: String
  })
</script>
