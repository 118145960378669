<template>
  <!-- article start -->
  <article id="contents">
    <h1 class="contents_title">{{
      $router.currentRoute?.value?.meta?.title
    }}</h1>

    <div class="main_contents boxm">
      <column-links></column-links>
      <div class="main_contents">
        <the-loading v-if="state.loading" color="#434343"></the-loading>

        <div class="main_contents_list">
          <div class="column_list" v-if="state.articles.length > 0">
            <h3>新着用語集・コラム</h3>
            <div class="column_lists">
              <article-preview
                v-for="item in state.articles"
                :key="item.idx"
                :item="item"
              ></article-preview>
            </div>
          </div>
          <div class="column_list beginners" v-if="state.beginner.length > 0">
            <h3>
              <i class="fas fa-star beginners"></i>&nbsp;初心者向け<router-link
                :to="{ name: 'Beginners' }"
                ><i class="fas fa-caret-right"></i>&nbsp;<span>初心者向け </span
                >一覧を見る</router-link
              >
            </h3>
            <div class="column_lists">
              <article-preview
                v-for="item in state.beginner"
                :key="item.idx"
                :item="item"
              ></article-preview>
            </div>
          </div>
          <div class="column_list advanced" v-if="state.advanced.length > 0">
            <h3>
              <i class="fas fa-graduation-cap advanced"></i
              >&nbsp;中・上級者向け<router-link :to="{ name: 'Advanced' }"
                ><i class="fas fa-caret-right"></i>&nbsp;<span
                  >中・上級者向け </span
                >一覧を見る</router-link
              >
            </h3>
            <div class="column_lists">
              <article-preview
                v-for="item in state.advanced"
                :key="item.idx"
                :item="item"
              ></article-preview>
            </div>
          </div>
          <div
            class="column_list trend_commentary"
            v-if="state.trending.length > 0"
          >
            <h3>
              <i class="fas fa-chart-pie trend_commentary"></i
              >&nbsp;動向解説<router-link :to="{ name: 'TrendCommentary' }"
                ><i class="fas fa-caret-right"></i>&nbsp;<span>動向解説 </span
                >一覧を見る</router-link
              >
            </h3>
            <div class="column_lists">
              <article-preview
                v-for="item in state.trending"
                :key="item.idx"
                :item="item"
              ></article-preview>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tag-list></tag-list>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import TagList from '@/components/TagList.vue'
  import ColumnLinks from '@/components/ColumnLinks.vue'
  import ArticlePreview from '@/components/ArticlePreview.vue'
  import {
    getArticles,
    getBeginner,
    getAdvanced,
    getTrending
  } from '@/api/index'
  import { onMounted, reactive } from 'vue'

  const state: {
    pageSize: number
    beginner: {
      idx: number
    }[]
    advanced: {
      idx: number
    }[]
    trending: {
      idx: number
    }[]
    articles: {
      idx: number
    }[]
    loading: boolean
  } = reactive({
    pageSize: 3,
    beginner: [],
    advanced: [],
    trending: [],
    articles: [],
    loading: true
  })

  const showArticles = async () => {
    const { contents } = await getArticles({ page_size: state.pageSize })
    state.articles = contents.results
  }

  const showBeginner = async () => {
    const { contents } = await getBeginner({ page_size: state.pageSize })
    state.beginner = contents.results
  }

  const showAdvanced = async () => {
    const { contents } = await getAdvanced({ page_size: state.pageSize })
    state.advanced = contents.results
  }

  const showTrending = async () => {
    const { contents } = await getTrending({ page_size: state.pageSize })
    state.trending = contents.results
  }

  onMounted(async () => {
    await Promise.any([
      showArticles(),
      showBeginner(),
      showAdvanced(),
      showTrending()
    ])
    state.loading = false
  })
</script>

<style lang="scss" scoped>
  .main_contents .main_contents {
    background: transparent;
    padding-bottom: 10px;
  }
</style>
