<template>
  <div class="tstep">
    <img
      :src="`${s3Url}img/pc_step3.${language}.png?v=1`"
      class="sp_no"
      alt="step"
    />
    <img
      :src="`${s3Url}img/sp_step3.${language}.png?v=1`"
      class="sp"
      alt="step"
    />
  </div>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="individual">
    <div class="individual-wrapper">
      <ol class="step mb30">
        <li><span style="padding-left: 1em">１</span></li>
        <li class="is-current br-tl-6 br-bl-6"
          >２{{ $t('pages.Individual.title.step2') }}</li
        >
        <li><span style="padding-left: 1em">３</span></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Individual.title.step4')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Individual.title.step2') }}</h1>

        <div class="wrapper">
          <p style="font-size: 1.75rem; margin: 30px 0 15px 0">
            {{ $t('pages.Individual.step2.qrcode.msg1') }}
          </p>
          <p>{{ $t('pages.Individual.step2.qrcode.msg2') }}</p>

          <the-loading color="#333" v-if="loading"></the-loading>
          <template v-else>
            <QRCodeVue3
              :value="state.qrcode"
              :width="256"
              :height="256"
              :qrOptions="{
                typeNumber: '0',
                mode: 'Byte',
                errorCorrectionLevel: 'Q'
              }"
              :imageOptions="{
                hideBackgroundDots: true,
                imageSize: 0.5
              }"
              imgclass="img-qr"
              :dotsOptions="{ type: 'square', color: '#000' }"
              :cornersSquareOptions="{ type: 'square', color: '#000' }"
            />

            <a :href="state.qrcode" target=".">{{ state.qrcode }}</a>
          </template>
        </div>
      </form>
    </div>
  </div>
  <!-- ここから Crib Notesユニバーサルタグのコード -->
  <component :is="'script'" v-if="env === 'prod' && state.clib_counter_flg">
    (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/" + a
    + "/atm.js"; e.id = d; e.async = true;
    b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
    "script", "__cribnotesTagMgrCmd", "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
    "https://tag.cribnotes.jp/container_manager");
  </component>
  <!-- ここまで Crib Notesユニバーサルタグのコード -->
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, onBeforeMount } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import QRCodeVue3 from 'qrcode-vue3'
  import { getIndividualQRCode, checkEKYCIsExist } from '@/api'
  import { useRouter, onBeforeRouteLeave } from 'vue-router'

  const loading = ref(true)
  const router = useRouter()
  const store = useMainStore()
  const { s3Url, language, env } = storeToRefs(store)

  const state: {
    clib_counter_flg: boolean
    qrcode: string
    errorMessage: string
    timer: any
  } = reactive({
    clib_counter_flg: false,
    qrcode: '',
    errorMessage: '',
    timer: 0
  })

  const getResult = async () => {
    const { contents } = await checkEKYCIsExist()
    state.clib_counter_flg = contents?.clib_counter_flg

    if (contents?.is_exist) {
      clearInterval(state.timer)
      router.push({
        name: 'IndividualStep3'
      })
    }
  }

  onBeforeMount(() => {
    state.timer = 0
    clearInterval(state.timer)
  })

  onMounted(async () => {
    try {
      const { contents } = await getIndividualQRCode()
      if (contents?.mobile) {
        window.location.replace(contents?.kyc_url)
        return
      }
      state.qrcode = contents?.kyc_url
      loading.value = false
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }

    state.timer = setInterval(() => {
      getResult()
    }, 4000)
  })

  onBeforeRouteLeave((to, from, next) => {
    clearInterval(state.timer)
    next()
  })
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 65%;
    margin: 20px auto;

    a {
      text-decoration: underline;
    }

    a,
    p {
      text-align: center;
      display: block;
    }
  }

  @media (max-width: 800px) {
    .wrapper {
      width: 80%;
    }
  }

  @media (max-width: 600px) {
    .wrapper {
      width: 100%;
    }
  }
</style>

<style lang="scss">
  .img-qr {
    display: block;
    margin: 30px auto;
  }
</style>
