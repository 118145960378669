<template>
  <li :class="liClass" v-show="show">
    <label :for="uuid?.toString()">
      {{ label }}
      <template v-if="required">
        <span v-if="requiredStyle === 'form'" class="required-notice">{{
          $t('form.required')
        }}</span>
        <span v-else class="required">*</span>
      </template>
    </label>
    <p
      style="font-size: 0.9rem; margin-top: 5px; margin-bottom: 0"
      v-if="additionalNotesShow && additionalNotesPosition === 'top'"
      v-html="additionalNotes"
    ></p>

    <select
      :id="uuid?.toString()"
      class="uk-select color-1e valid"
      :value="modelValue"
      @input="(event) => $emit('update:modelValue', (event.target as HTMLInputElement)?.value)"
      @blur="validate"
    >
      <option v-if="placeholders" disabled selected value hidden>{{
        placeholders
      }}</option>
      <option
        v-for="option in options"
        :key="option.key"
        :value="option.key"
        :selected="option.key === modelValue"
        >{{ option.value }}</option
      >
    </select>
    <label
      :id="uuid?.toString()"
      class="error"
      :for="uuid?.toString()"
      v-if="validation?.errorMessage"
      >{{ validation.errorMessage }}</label
    >
    <p
      style="font-size: 0.9rem; margin-top: 5px"
      v-if="additionalNotesShow && additionalNotesPosition === 'bottom'"
      v-html="additionalNotes"
    ></p>
  </li>
</template>

<script lang="ts" setup>
  import { inject, ref, watch } from 'vue'

  interface Props {
    modelValue?: any
    show?: boolean
    required?: boolean
    requiredStyle?: string
    uuid?: number
    label?: string
    options?: { key: string; value: string }[]
    placeholders?: string
    validation?: any
    validations?: any
    additionalNotes?: string
    additionalNotesShow?: boolean
    additionalNotesPosition?: string
    liClass?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    show: true,
    additionalNotesPosition: 'bottom'
  })

  const needValidation = ref(inject('needValidation'))
  const validate = (): void => {
    props.validation.validate()
  }

  watch(needValidation, () => {
    props.validation.validate()
  })

  defineExpose({
    validate
  })
</script>
