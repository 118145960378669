<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <form id="landingPageForm">
        <the-message v-if="state.errorMessage">{{
          state.errorMessage
        }}</the-message>

        <div style="display: flex; justify-content: center">
          <div class="div_opt" :class="{ radio_choice: state.type === '1' }">
            <div class="pretty p-default p-round">
              <input
                type="radio"
                checked
                name="user_type"
                autocomplete="off"
                value="1"
                v-model="state.type"
                required
                id="lb_opt1"
              />
              <div class="state">
                <label class="lb_choice" for="lb_opt1">{{
                  $t('pages.NewAccount.type.individual')
                }}</label>
              </div>
            </div>
          </div>
          <div class="div_opt" :class="{ radio_choice: state.type === '2' }">
            <div class="pretty p-default p-round">
              <input
                type="radio"
                name="user_type"
                autocomplete="off"
                value="2"
                v-model="state.type"
                required
                id="lb_opt2"
              />
              <div class="state">
                <label class="lb_choice" for="lb_opt2">{{
                  $t('pages.NewAccount.type.corporate')
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <p
          style="color: red; text-align: center; margin-top: 10px"
          v-if="state.type === '1'"
          >{{ $t('pages.NewAccount.warning_in') }}</p
        >
        <p style="color: red; text-align: center; margin-top: 10px" v-else>{{
          $t('pages.NewAccount.warning_co')
        }}</p>

        <SchemaFormWithValidation
          :schema="state.inquirySchema"
          :model="state.formJson"
          useCustomFormWrapper
          ref="form"
        />

        <p
          style="
            color: red;
            font-size: 0.82rem;
            padding: 0 0.5rem;
            margin: 5px 0;
          "
          v-if="state.type === '2'"
          >{{ $t('pages.NewAccount.tips.pc') }}</p
        >

        <!-- UM00-01-01-2 START -->
        <div class="newAccount-heads-up" style="margin: 5px 0">{{
          $t('pages.NewAccount.tips.email')
        }}</div>
        <!-- UM00-01-01-2 END -->

        <div>
          <button
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom bank_info_submit u-regist-btn"
            id="submitBtn"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.NewAccount.form.submit')
            }"
            type="button"
            >{{ $t('pages.NewAccount.form.submit') }}</button
          >
        </div>
      </form>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { newAccount } from '@/api'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'
  import { useReCaptcha } from 'vue-recaptcha-v3'

  const recaptchaInstance = useReCaptcha()
  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { language, isRecaptchaRequired } = storeToRefs(store)

  const state: {
    googleToken: string
    inquirySchema: any
    formJson: any
    errorMessage: string
    type: string
  } = reactive({
    googleToken: '',
    inquirySchema: {
      email: {
        component: 'Text',
        label: t('pages.NewAccount.form.email'),
        required: true,
        type: 'email',
        inputClass: 'form-control',
        placeholder: t('pages.NewAccount.placeholder.email'),
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.email'),
              type: t('validation.type.input')
            })
          )
          .email(
            t('validation.input_error', {
              content: t('validation.item.email')
            })
          )
      }
    },
    formJson: {},
    errorMessage: '',
    type: '1'
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha('signup')
    state.googleToken = token || ''
    if (!state.googleToken && isRecaptchaRequired.value) {
      state.errorMessage = t('validation.reCAPTCHA')
      return
    }

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true

        const data = {
          ...state.formJson,
          user_type: state.type,
          language: language.value,
          'g-recaptcha-response': state.googleToken
        }
        if (router.currentRoute.value.params?.invite_account) {
          Object.assign(data, {
            invite_account: router.currentRoute.value.params?.invite_account
          })
        }

        try {
          const { contents } = await newAccount({
            contents: data
          })
          router.push({
            name: 'NewAccountCreateSuccess',
            params: { email_encode: contents.encrypt_email }
          })
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      }
    }, 0)
  }

  onMounted(() => {
    const breadcrumb: SiteMapType = {
      route: router.currentRoute.value.name as string,
      name: router.currentRoute.value.meta.title
    }
    store.setBreadcrumb(breadcrumb)
  })
</script>

<style lang="scss">
  #landingPageForm {
    .error {
      margin-top: -0.5rem;
    }
    .madd_title {
      display: block;
    }
  }

  .is-warning {
    width: 90%;
    max-width: 420px;
    margin: -3.5em auto 4em auto;
  }

  div.div_opt {
    border-radius: 30px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    line-height: 2rem;
    padding: 0 1rem;
  }

  div.div_opt * {
    display: inline-block;
    color: #fff;
    font-size: 0.92rem;
    label {
      margin-left: 3px;
    }
  }
</style>
