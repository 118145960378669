import i18n from '@/locale'
import CDMST from '@/enum/cd_mst.json'

const countries = Object.keys(CDMST.contents['10001'].results)
const country = countries.reduce((prev: any[], cur: string) => {
  prev.push({
    key: cur,
    value: i18n.global.te(`country.${cur}`)
      ? i18n.global.t(`country.${cur}`)
      : (CDMST.contents['10001'].results as any)[cur].val1
  })
  return prev
}, [])

export const numberSymbols: Record<string, string> = {
  '1': '①',
  '2': '②',
  '3': '③',
  '4': '④',
  '5': '⑤'
}

export const shareHolders: Record<string, string> = {
  a: '1',
  b: '3',
  c: '5',
  d: '1',
  e: '1',
  f: '3',
  g: '5',
  h: '1'
}

export const validator: Record<
  string,
  {
    max?: number
    maxLength?: number
    min?: number
    regex?: RegExp
    errorMsg?: string
  }
> = {
  name: {
    maxLength: 50,
    regex: /^[^\x01-\x7E\uFF61-\uFF9F]+$/
  },
  name_kanji: {
    maxLength: 50,
    regex: /^[^\x01-\x7E\uFF61-\uFF9F１２３４５６７８９０]+$/
  },
  name_katakana: {
    maxLength: 50,
    regex: /^[ァ-ヶー]+$/
  },
  name_sei_katakana: {
    maxLength: 20,
    regex: /^[ァ-ヶー]+$/
  },
  name_mei_katakana: {
    maxLength: 20,
    regex: /^[ァ-ヶー]+$/
  },
  account_kana: {
    maxLength: 50,
    regex: /^[ァ-ヶーＡ-Ｚａ-ｚ]+$/
  },
  co_number: {
    regex: /^\d{13}$/,
    maxLength: 13
  },
  post_no: {
    regex: /^\d{7}$/,
    maxLength: 7
  },
  addr1: {
    maxLength: 50,
    regex: /^[^\x01-\x7E\uFF61-\uFF9F]+$/
  },
  addr2: {
    maxLength: 50,
    regex: /^[^\x01-\x7E\uFF61-\uFF9F]+$/
  },
  addr3: {
    maxLength: 50,
    regex: /^[^\x01-\x7E\uFF61-\uFF9F]+$/
  },
  rep_phone_no: {
    regex: /^(\d{10}|\d{11})$/,
    maxLength: 11
  },
  phone_no: {
    regex: /^\d{10}$/,
    maxLength: 10
  },
  mobile_no: {
    regex: /^\d{11}$/,
    maxLength: 11
  },
  account_no: {
    regex: /^\d*$/,
    maxLength: 7
  },
  pass_code: {
    regex: /^\d{6}$/
  },
  capital: {
    max: 999999999999,
    maxLength: 12
  },
  industry: {
    maxLength: 64
  },
  final_settlement_date: {
    maxLength: 20,
    regex:
      /^(?!0230)(?!0231)(?!0431)(?!0631)(?!0931)(?!1131)(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])*$/
  },
  business_contents: {
    maxLength: 128
  },
  nature_funds_other: {
    maxLength: 256
  },
  overseas_bases_name: {
    maxLength: 256
  },
  crypto_held_text: {
    maxLength: 256
  },
  homepage: {
    maxLength: 64
  },
  position: {
    maxLength: 64
  },
  rep_position: {
    maxLength: 124
  },
  email: {
    maxLength: 64
  },
  department: {
    maxLength: 64
  },
  relationship: {
    maxLength: 64
  },
  peps_text: {
    maxLength: 128
  },
  rep_peps_text: {
    maxLength: 128
  },
  motivation_user_other: {
    maxLength: 64
  },
  motivation_user_introducer: {
    maxLength: 64
  },
  transaction_objective_other: {
    maxLength: 64
  },
  transaction_objective_abroad: {
    maxLength: 64
  },
  address_confirm_code: {
    regex: /^\d{6}$/
  },
  profession_other: {
    maxLength: 64
  },
  address: {
    maxLength: 100
  },
  addr_tag: {
    maxLength: 64
  },
  exp_other_text: {
    maxLength: 256
  },
  dest_other: {
    maxLength: 256
  },
  dest_name: {
    maxLength: 256
  },
  dest_exchange_other: {
    maxLength: 256
  },
  dest_addr_other: {
    maxLength: 256
  },
  dest_trob_other: {
    maxLength: 256
  }
}

export const options: Record<
  string,
  {
    key: string
    value: string
  }[]
> = {
  yesOrNo: [
    { key: '0', value: i18n.global.t('form.options.no') },
    { key: '1', value: i18n.global.t('form.options.yes') }
  ],
  withOrWithout: [
    { key: '0', value: i18n.global.t('form.options.without') },
    { key: '1', value: i18n.global.t('form.options.with') }
  ],
  experience: [
    {
      key: '010',
      value: i18n.global.t('form.options.experience_010')
    },
    {
      key: '020',
      value: i18n.global.t('form.options.experience_020')
    },
    {
      key: '030',
      value: i18n.global.t('form.options.experience_030')
    },
    {
      key: '040',
      value: i18n.global.t('form.options.experience_040')
    },
    {
      key: '050',
      value: i18n.global.t('form.options.experience_050')
    },
    {
      key: '060',
      value: i18n.global.t('form.options.experience_060')
    },
    {
      key: '070',
      value: i18n.global.t('form.options.experience_070')
    }
  ],
  tax_type: [
    { key: '0', value: i18n.global.t('form.options.tax_type_0') },
    { key: '1', value: i18n.global.t('form.options.tax_type_1') }
  ],
  nature_funds: [
    { key: '01', value: i18n.global.t('form.options.nature_funds_01') },
    { key: '02', value: i18n.global.t('form.options.nature_funds_02') },
    { key: '99', value: i18n.global.t('form.options.others') }
  ],
  individual_transaction_objective: [
    {
      key: '01',
      value: i18n.global.t('form.options.individual_transaction_objective_01')
    },
    {
      key: '02',
      value: i18n.global.t('form.options.individual_transaction_objective_02')
    },
    {
      key: '03',
      value: i18n.global.t('form.options.individual_transaction_objective_03')
    },
    {
      key: '04',
      value: i18n.global.t('form.options.individual_transaction_objective_04')
    },
    { key: '99', value: i18n.global.t('form.options.others') }
  ],
  transaction_objective: [
    {
      key: '01',
      value: i18n.global.t('form.options.transaction_objective_01')
    },
    {
      key: '02',
      value: i18n.global.t('form.options.transaction_objective_02')
    },
    {
      key: '03',
      value: i18n.global.t('form.options.transaction_objective_03')
    },
    {
      key: '04',
      value: i18n.global.t('form.options.transaction_objective_04')
    },
    {
      key: '05',
      value: i18n.global.t('form.options.transaction_objective_05')
    },
    { key: '99', value: i18n.global.t('form.options.others') }
  ],
  motivation_user: [
    {
      key: '01',
      value: i18n.global.t('form.options.motivation_user_01')
    },
    {
      key: '02',
      value: i18n.global.t('form.options.motivation_user_02')
    },
    {
      key: '03',
      value: i18n.global.t('form.options.motivation_user_03')
    },
    {
      key: '04',
      value: i18n.global.t('form.options.motivation_user_04')
    },
    {
      key: '05',
      value: i18n.global.t('form.options.motivation_user_05')
    },
    {
      key: '06',
      value: i18n.global.t('form.options.motivation_user_06')
    },
    { key: '99', value: i18n.global.t('form.options.others') }
  ],
  sex: [
    {
      key: '1',
      value: i18n.global.t('form.options.male')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.female')
    }
  ],
  priority_phone: [
    {
      key: '1',
      value: i18n.global.t('form.options.priority_phone_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.priority_phone_2')
    }
  ],
  preferred_phone: [
    {
      key: '1',
      value: i18n.global.t('form.options.preferred_phone_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.preferred_phone_2')
    }
  ],
  account_type: [
    {
      key: '1',
      value: i18n.global.t('form.options.account_type_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.account_type_2')
    }
  ],
  financial_asset: [
    {
      key: '100',
      value: i18n.global.t('form.options.financial_asset_100')
    },
    {
      key: '200',
      value: i18n.global.t('form.options.financial_asset_200')
    },
    {
      key: '300',
      value: i18n.global.t('form.options.financial_asset_300')
    },
    {
      key: '500',
      value: i18n.global.t('form.options.financial_asset_500')
    },
    {
      key: '1000',
      value: i18n.global.t('form.options.financial_asset_1000')
    },
    {
      key: '2000',
      value: i18n.global.t('form.options.financial_asset_2000')
    },
    {
      key: '3000',
      value: i18n.global.t('form.options.financial_asset_3000')
    },
    {
      key: '5000',
      value: i18n.global.t('form.options.financial_asset_5000')
    },
    {
      key: '9999',
      value: i18n.global.t('form.options.financial_asset_9999')
    },
    {
      key: '10000',
      value: i18n.global.t('form.options.financial_asset_10000')
    },
    {
      key: '50000',
      value: i18n.global.t('form.options.financial_asset_50000')
    }
  ],
  annual_income: [
    {
      key: '100',
      value: i18n.global.t('form.options.annual_income_100')
    },
    {
      key: '200',
      value: i18n.global.t('form.options.annual_income_200')
    },
    {
      key: '300',
      value: i18n.global.t('form.options.annual_income_300')
    },
    {
      key: '500',
      value: i18n.global.t('form.options.annual_income_500')
    },
    {
      key: '1000',
      value: i18n.global.t('form.options.annual_income_1000')
    },
    {
      key: '2000',
      value: i18n.global.t('form.options.annual_income_2000')
    },
    {
      key: '3000',
      value: i18n.global.t('form.options.annual_income_3000')
    },
    {
      key: '5000',
      value: i18n.global.t('form.options.annual_income_5000')
    },
    {
      key: '9999',
      value: i18n.global.t('form.options.annual_income_9999')
    },
    {
      key: '10000',
      value: i18n.global.t('form.options.annual_income_10000')
    }
  ],
  peps: [
    {
      key: '1',
      value: i18n.global.t('form.options.peps_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.peps_2')
    }
  ],
  peps_kbn: [
    {
      key: '1',
      value: i18n.global.t('form.options.peps_kbn')
    }
  ],
  ability_act: [
    {
      key: '1',
      value: i18n.global.t('form.options.ability_act')
    }
  ],
  country: country,
  profession: [
    {
      key: '01',
      value: i18n.global.t('form.options.profession_01')
    },
    {
      key: '02',
      value: i18n.global.t('form.options.profession_02')
    },
    {
      key: '03',
      value: i18n.global.t('form.options.profession_03')
    },
    {
      key: '04',
      value: i18n.global.t('form.options.profession_04')
    },
    {
      key: '05',
      value: i18n.global.t('form.options.profession_05')
    },
    {
      key: '06',
      value: i18n.global.t('form.options.profession_06')
    },
    {
      key: '07',
      value: i18n.global.t('form.options.profession_07')
    },
    {
      key: '08',
      value: i18n.global.t('form.options.profession_08')
    },
    {
      key: '99',
      value: i18n.global.t('form.options.profession_99')
    }
  ],
  liabilities_asset: [
    {
      key: '010',
      value: i18n.global.t('form.options.liabilities_asset_010')
    },
    {
      key: '020',
      value: i18n.global.t('form.options.liabilities_asset_020')
    },
    {
      key: '030',
      value: i18n.global.t('form.options.liabilities_asset_030')
    },
    {
      key: '040',
      value: i18n.global.t('form.options.liabilities_asset_040')
    },
    {
      key: '050',
      value: i18n.global.t('form.options.liabilities_asset_050')
    },
    {
      key: '060',
      value: i18n.global.t('form.options.liabilities_asset_060')
    }
  ],
  annual_sales: [
    {
      key: '010',
      value: i18n.global.t('form.options.annual_sales_010')
    },
    {
      key: '020',
      value: i18n.global.t('form.options.annual_sales_020')
    },
    {
      key: '030',
      value: i18n.global.t('form.options.annual_sales_030')
    },
    {
      key: '040',
      value: i18n.global.t('form.options.annual_sales_040')
    },
    {
      key: '050',
      value: i18n.global.t('form.options.annual_sales_050')
    },
    {
      key: '060',
      value: i18n.global.t('form.options.annual_sales_060')
    }
  ],
  financial_asset_corporation: [
    {
      key: '010',
      value: i18n.global.t('form.options.financial_asset_corporation_010')
    },
    {
      key: '020',
      value: i18n.global.t('form.options.financial_asset_corporation_020')
    },
    {
      key: '030',
      value: i18n.global.t('form.options.financial_asset_corporation_030')
    },
    {
      key: '040',
      value: i18n.global.t('form.options.financial_asset_corporation_040')
    },
    {
      key: '050',
      value: i18n.global.t('form.options.financial_asset_corporation_050')
    },
    {
      key: '060',
      value: i18n.global.t('form.options.financial_asset_corporation_060')
    }
  ],
  two_step_setting: [
    {
      key: '01',
      value: i18n.global.t('form.options.two_step_setting_01')
    },
    {
      key: '02',
      value: i18n.global.t('form.options.two_step_setting_02')
    },
    {
      key: '08',
      value: i18n.global.t('form.options.two_step_setting_08')
    },
    {
      key: '09',
      value: i18n.global.t('form.options.two_step_setting_09')
    },
    {
      key: '03',
      value: i18n.global.t('form.options.two_step_setting_03')
    },
    {
      key: '05',
      value: i18n.global.t('form.options.two_step_setting_05')
    },
    {
      key: '06',
      value: i18n.global.t('form.options.two_step_setting_06')
    },
    {
      key: '07',
      value: i18n.global.t('form.options.two_step_setting_07')
    }
  ],
  two_step_method: [
    {
      key: '1',
      value: i18n.global.t('form.options.two_step_method_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.two_step_method_2')
    },
    {
      key: '3',
      value: i18n.global.t('form.options.two_step_method_3')
    }
  ],
  notification_login: [
    {
      key: '01',
      value: i18n.global.t('form.options.notification_setting_01')
    }
  ],
  notification_trade: [
    {
      key: '02',
      value: i18n.global.t('form.options.notification_setting_02')
    },
    {
      key: '03',
      value: i18n.global.t('form.options.notification_setting_03')
    },
    {
      key: '04',
      value: i18n.global.t('form.options.notification_setting_04')
    },
    {
      key: '05',
      value: i18n.global.t('form.options.notification_setting_05')
    },
    {
      key: '06',
      value: i18n.global.t('form.options.notification_setting_06')
    },
    {
      key: '07',
      value: i18n.global.t('form.options.notification_setting_07')
    },
  ],
  notification_transfer: [
    {
      key: '08',
      value: i18n.global.t('form.options.notification_setting_08')
    }
  ],
  deposit_fiat: [
    {
      key: '1',
      value: i18n.global.t('form.options.deposit_fiat_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.deposit_fiat_2')
    },
    {
      key: '3',
      value: i18n.global.t('form.options.deposit_fiat_3')
    },
    {
      key: '4',
      value: i18n.global.t('form.options.deposit_fiat_4')
    }
  ],
  deposit_crypto: [
    {
      key: '1',
      value: i18n.global.t('form.options.deposit_crypto_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.deposit_crypto_2')
    }
  ],
  transfer_type: [
    {
      key: '1',
      value: i18n.global.t('form.options.transfer_type_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.transfer_type_2')
    }
  ],
  point_trade_type: [
    {
      key: '1',
      value: i18n.global.t('form.options.point_trade_type_1')
    },
    {
      key: '2',
      value: i18n.global.t('form.options.point_trade_type_2')
    }
  ],
  dest_self: [
    {
      key: '01',
      value: i18n.global.t('form.options.dest_self_01')
    },
    {
      key: '99',
      value: i18n.global.t('form.options.dest_self_99')
    }
  ],
  dest_target: [
    {
      key: '02',
      value: i18n.global.t('form.options.dest_target_02')
    },
    {
      key: '01',
      value: i18n.global.t('form.options.dest_target_01')
    },
    {
      key: '99',
      value: i18n.global.t('form.options.dest_target_99')
    }
  ],
  dest_addr: [
    {key: '01',value: i18n.global.t('form.options.dest_addr_01')},
    {key: '02',value: i18n.global.t('form.options.dest_addr_02')},
    {key: '03',value: i18n.global.t('form.options.dest_addr_03')},
    {key: '04',value: i18n.global.t('form.options.dest_addr_04')},
    {key: '05',value: i18n.global.t('form.options.dest_addr_05')},
    {key: '06',value: i18n.global.t('form.options.dest_addr_06')},
    {key: '07',value: i18n.global.t('form.options.dest_addr_07')},
    {key: '08',value: i18n.global.t('form.options.dest_addr_08')},
    {key: '09',value: i18n.global.t('form.options.dest_addr_09')},
    {key: '10',value: i18n.global.t('form.options.dest_addr_10')},
    {key: '11',value: i18n.global.t('form.options.dest_addr_11')},
    {key: '12',value: i18n.global.t('form.options.dest_addr_12')},
    {key: '13',value: i18n.global.t('form.options.dest_addr_13')},
    {key: '14',value: i18n.global.t('form.options.dest_addr_14')},
    {key: '15',value: i18n.global.t('form.options.dest_addr_15')},
    {key: '16',value: i18n.global.t('form.options.dest_addr_16')},
    {key: '17',value: i18n.global.t('form.options.dest_addr_17')},
    {key: '18',value: i18n.global.t('form.options.dest_addr_18')},
    {key: '19',value: i18n.global.t('form.options.dest_addr_19')},
    {key: '20',value: i18n.global.t('form.options.dest_addr_20')},
    {key: '21',value: i18n.global.t('form.options.dest_addr_21')},
    {key: '22',value: i18n.global.t('form.options.dest_addr_22')},
    {key: '23',value: i18n.global.t('form.options.dest_addr_23')},
    {key: '24',value: i18n.global.t('form.options.dest_addr_24')},
    {key: '25',value: i18n.global.t('form.options.dest_addr_25')},
    {key: '26',value: i18n.global.t('form.options.dest_addr_26')},
    {key: '27',value: i18n.global.t('form.options.dest_addr_27')},
    {key: '28',value: i18n.global.t('form.options.dest_addr_28')},
    {key: '29',value: i18n.global.t('form.options.dest_addr_29')},
    {key: '30',value: i18n.global.t('form.options.dest_addr_30')},
    {key: '31',value: i18n.global.t('form.options.dest_addr_31')},
    {key: '32',value: i18n.global.t('form.options.dest_addr_32')},
    {key: '33',value: i18n.global.t('form.options.dest_addr_33')},
    {key: '34',value: i18n.global.t('form.options.dest_addr_34')},
    {key: '35',value: i18n.global.t('form.options.dest_addr_35')},
    {key: '36',value: i18n.global.t('form.options.dest_addr_36')},
    {key: '37',value: i18n.global.t('form.options.dest_addr_37')},
    {key: '38',value: i18n.global.t('form.options.dest_addr_38')},
    {key: '39',value: i18n.global.t('form.options.dest_addr_39')},
    {key: '40',value: i18n.global.t('form.options.dest_addr_40')},
    {key: '41',value: i18n.global.t('form.options.dest_addr_41')},
    {key: '42',value: i18n.global.t('form.options.dest_addr_42')},
    {key: '43',value: i18n.global.t('form.options.dest_addr_43')},
    {key: '44',value: i18n.global.t('form.options.dest_addr_44')},
    {key: '45',value: i18n.global.t('form.options.dest_addr_45')},
    {key: '46',value: i18n.global.t('form.options.dest_addr_46')},
    {key: '47',value: i18n.global.t('form.options.dest_addr_47')}
  ],
  dest_trob: [
    {key: '01',value: i18n.global.t('form.options.dest_trob_01')},
    {key: '02',value: i18n.global.t('form.options.dest_trob_02')},
    {key: '03',value: i18n.global.t('form.options.dest_trob_03')},
    {key: '99',value: i18n.global.t('form.options.dest_trob_99')}
  ],
  dest_exchange: [
    // {key: '01',value: i18n.global.t('form.options.dest_exchange_01')},
    {key: '02',value: i18n.global.t('form.options.dest_exchange_02')},
    {key: '03',value: i18n.global.t('form.options.dest_exchange_03')},
    {key: '04',value: i18n.global.t('form.options.dest_exchange_04')},
    {key: '06',value: i18n.global.t('form.options.dest_exchange_06')},
    // {key: '07',value: i18n.global.t('form.options.dest_exchange_07')},
    // {key: '08',value: i18n.global.t('form.options.dest_exchange_08')},
    // {key: '09',value: i18n.global.t('form.options.dest_exchange_09')},
    {key: '99',value: i18n.global.t('form.options.dest_exchange_99')}
  ],
  addr_type: [
    {key: '01',value: i18n.global.t('form.options.addr_type_01')},
    {key: '02',value: i18n.global.t('form.options.addr_type_02')},
    {key: '03',value: i18n.global.t('form.options.addr_type_03')},
  ]
}

export const agreement = [
  {
    key: 'xVTIadfkebKdsA',
    value: i18n.global.t('pages.Agreement.xVTIadfkebKdsA')
  },
  {
    key: 'vMAvPRG3Yps3S3',
    value: i18n.global.t('pages.Agreement.vMAvPRG3Yps3S3')
  },
  {
    key: '08U55Lz88LVqQH',
    value: i18n.global.t('pages.Agreement.08U55Lz88LVqQH')
  },
  {
    key: '08U55Lz88HbMma',
    value: i18n.global.t('pages.Agreement.08U55Lz88HbMma')
  },
  {
    key: '08U55Lg485LLvM',
    value: i18n.global.t('pages.Agreement.08U55Lg485LLvM')
  },
  {
    key: '08U55Lz885LLy4',
    value: i18n.global.t('pages.Agreement.08U55Lz885LLy4')
  },
  {
    key: 'pGckPtjPnmkdCn',
    value: i18n.global.t('pages.Agreement.pGckPtjPnmkdCn')
  },
  {
    key: 'xEjdisafj8hnA3',
    value: i18n.global.t('pages.Agreement.xEjdisafj8hnA3')
  }
]
