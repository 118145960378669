import { options, validator } from '@/enum/enums'
import { routesArr } from '@/router/routes'
import router from '@/router'
import { useMainStore } from '@/store/pinia'

const transFormat = (value: number) => {
  return ('0' + value).slice(-2)
}

export const isNotificationNew = (time: string) => {
  const now = new Date().getTime()
  const last = new Date(time).getTime()
  return now - last < 1000 * 60 * 60 * 24 * 14
}

export const unwarp = (obj: any) => obj && (obj.__v_raw || obj.valueOf() || obj)

export function formatDate(date: Date) {
  const originTime = new Date(date)
  const year = originTime.getFullYear()
  const month = originTime.getMonth() + 1
  const days = originTime.getDate()

  return year + '/' + transFormat(month) + '/' + transFormat(days)
}

export function formatTime(date: Date) {
  const originTime = new Date(date)
  const year = originTime.getFullYear()
  const month = originTime.getMonth() + 1
  const days = originTime.getDate()
  const hours = originTime.getHours()
  const minutes = originTime.getMinutes()

  const week = ['日', '月', '火', '水', '木', '金', '土'][originTime.getDay()]

  return (
    year +
    '年' +
    transFormat(month) +
    '月' +
    transFormat(days) +
    '日' +
    '（' +
    week +
    '）' +
    transFormat(hours) +
    '時' +
    transFormat(minutes) +
    '分'
  )
}

export function addFormStyle() {
  setTimeout(() => {
    const needAddStyle = document.getElementsByClassName('is-half')
    for (const element of needAddStyle) {
      const parent = element.parentElement as HTMLElement
      parent.classList.add('flex-1-1')
    }
  }, 0)
}

export function checkDate(val?: string) {
  if (val) {
    const newValue = val.includes('-')
      ? val
      : insertStr(insertStr(val, 4, '-'), 7, '-')
    if (newValue.length !== 10) return false
    const pattern =
      /^(19|20)\d{2}-((0?[1-9])|(1[0-2]))-((0?[1-9])|([1-2]\d)|3[01])$/
    if (pattern.test(newValue)) {
      const date = new Date(newValue)
      if (date < new Date('1900-12-31') || date > new Date()) {
        return false
      }
      const month = newValue.substring(
        newValue.indexOf('-') + 1,
        newValue.lastIndexOf('-')
      )
      return date && date.getMonth() + 1 == parseInt(month)
    }
    return false
  }
  return true
}

export function insertStr(source: string, start: number, newStr: string) {
  if (!source) return ''
  return source.slice(0, start) + newStr + source.slice(start)
}

export function assignForm(
  schema: Record<string, any>,
  data: Record<string, any>
) {
  const keys = Object.keys(schema)
  const result: Record<string, any> = {}
  keys.forEach((key: string) => {
    if (data[key]) {
      key === 'establishment_date' || key === 'financial_status_date'
        ? (result[key] = data[key].replaceAll('-', ''))
        : (result[key] = data[key])
    }
  })
  return result
}

export const findOption = (keyword: string, value: string | string[]) => {
  if (value instanceof Array) {
    const result: string[] = []
    value.forEach((key) => {
      result.push(options[keyword].find((i) => i.key === key)?.value as string)
    })
    return result.join('\n')
  } else {
    return options[keyword].find((i) => i.key === value)?.value
  }
}

export const watchOptions = (
  value: string,
  criteria: string,
  key: string,
  schema: any,
  form: any,
  yup: any,
  t: any,
  name?: string
) => {
  if (value === criteria) {
    schema[key].show = true
    schema[key].required = true
    if (validator[key]?.maxLength) {
      schema[key].validations = yup
        .string()
        .required(
          t('validation.required', {
            content: t(`pages.${name || 'Corporation'}.form.${key}`),
            type: t('validation.type.input')
          })
        )
        .max(
          validator[key].maxLength as number,
          t('validation.maxLength', {
            maxLength: validator[key].maxLength
          })
        )
    } else {
      schema[key].validations = yup.string().required(
        t('validation.required', {
          content: t(`pages.${name || 'Corporation'}.form.${key}`),
          type: t('validation.type.input')
        })
      )
    }
  } else {
    schema[key].show = false
    schema[key].required = false
    schema[key].validations = undefined
    form[key] = ''
  }
}

export function hasCallbackUrl(callback_url: string) {
  let isRedirect = false
  routesArr.some((route) => {
    if (route.path.includes(callback_url)) {
      router.push({
        name: route.name
      })
      isRedirect = true
    }
  })
  if (!isRedirect)
    window.location.href = import.meta.env.VITE_APP_DJANGO_DOMAIN + callback_url
}

let timer = 0

export async function getUserCheckInformation() {
  const store = useMainStore()
  const contents = await store.getHome()
  return contents
}

export function loopUserInformation() {
  if (timer) return
  timer = setInterval(() => {
    getUserCheckInformation()
  }, 10 * 60 * 1000)
}

export function qty_plus(qty: string, plus_qty: string) {
  if (plus_qty == '0') {
    return plus_qty
  }
  let amount = parseFloat(qty)
  if (isNaN(amount)) {
    amount = parseFloat('0')
  }
  amount = amount + Number(plus_qty)
  amount = amount * 100000
  amount = Math.round(amount)
  amount = amount / 100000
  return amount.toString()
}

interface msgParam {
  [key: string]: string
}

export function make_massage(message_data: string, t: any) {
  /*
  エラーメッセージを作成
  */

  const err = message_data.split('|')
  if (0 < err.length) {
    const param: msgParam = {}
    err.forEach((data, i) => {
      if (i != 0) {
        param['p' + i] = data
      }
    })
    const keyword = 'error.'
    return t(keyword + err[0], param).replace(keyword, '')
  }
  return ''
}
