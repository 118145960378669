<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <div class="main_contents_text boxs">
        <div class="policy_inner">
          <section class="main-container-inner">
            <div class="main-contents">
              <h2
                class="tc"
                v-html="$t('pages.Policy.ResponsePolicy.msg1')"
              ></h2>

              <ul class="re_po">
                <li> ①&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg1_1') }}</li>
                <li> ②&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg1_2') }} </li>
                <li> ③&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg1_3') }} </li>
                <li> ④&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg1_4') }} </li>
                <li> ⑤&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg1_5') }} </li>
              </ul>

              <h2
                class="tc"
                v-html="$t('pages.Policy.ResponsePolicy.msg2')"
              ></h2>
              <ul class="re_po">
                <li>
                  ①&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg2_1') }}
                  <ul class="anti-list">
                    <li>
                      {{ $t('pages.Policy.ResponsePolicy.msg2_1_1') }}
                    </li>
                    <li>
                      {{ $t('pages.Policy.ResponsePolicy.msg2_1_2') }}
                    </li>
                    <li>
                      {{ $t('pages.Policy.ResponsePolicy.msg2_1_3') }}
                    </li>
                    <li>
                      {{ $t('pages.Policy.ResponsePolicy.msg2_1_4') }}
                    </li>
                    <li>{{ $t('pages.Policy.ResponsePolicy.msg2_1_5') }}</li>
                  </ul>
                </li>
                <li> ②&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg2_2') }} </li>
                <li> ③&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg2_3') }} </li>
                <li> ④&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg2_4') }} </li>
                <li> ⑤&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg2_5') }} </li>
              </ul>
              <h2
                class="tc"
                v-html="$t('pages.Policy.ResponsePolicy.msg3')"
              ></h2>
              <ul class="re_po">
                <li> ①&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg3_1') }} </li>
                <li> ②&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg3_2') }} </li>
                <li> ③&nbsp;{{ $t('pages.Policy.ResponsePolicy.msg3_3') }} </li>
              </ul>

              <p style="color: #f44" v-if="language !== 'ja'"
                ><br />{{ $t('language_warning') }}</p
              >

              <div class="pp_set">
                <p class="m_right">{{
                  $t('pages.Policy.ResponsePolicy.end')
                }}</p>
              </div>
              <p
                v-html="$t('pages.Policy.ResponsePolicy.history')"
                style="text-align: right"
              ></p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
