<template>
  <user>
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1 class="contents_title">{{
          $router.currentRoute?.value?.meta?.title
        }}</h1>

        <div class="form-box">
          <div class="main_contents_text boxs">
            <the-message v-if="state.successMessage" type="primary">{{
              state.successMessage
            }}</the-message>

            <div class="main_contents_head_sp text-center">{{
              $t('pages.MailSetting.msg1')
            }}</div>
            <form class="mailstopform">
              <p style="text-align: center; margin: 20px 0"
                >{{ $t('pages.MailSetting.status.title')
                }}{{
                  state.send_mail_flg === '1'
                    ? $t('pages.MailSetting.status.delivering')
                    : $t('pages.MailSetting.status.suspend')
                }}<span v-if="language === 'cn'">{{
                  $t('pages.MailSetting.status.status')
                }}</span></p
              >
              <label class="container">
                <input type="checkbox" v-model="state.checkbox" />
                <span class="checkmark"></span>
                {{
                  $t('pages.MailSetting.msg2', {
                    operator:
                      state.send_mail_flg === '1'
                        ? $t('pages.MailSetting.status.suspend')
                        : $t('pages.MailSetting.status.deliver')
                  })
                }}
              </label>

              <div class="main_contents_text boxz" style="margin-bottom: 10px">
                <input
                  v-if="state.send_mail_flg === '1'"
                  :disabled="!state.checkbox"
                  type="button"
                  :value="$t('pages.MailSetting.submit')"
                  @click="mail_delivery_reject"
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
                  :class="{
                    'uk-button-primary': state.checkbox,
                    'uk-button-default': !state.checkbox
                  }"
                />
                <input
                  v-else
                  :disabled="!state.checkbox"
                  type="button"
                  :value="$t('pages.MailSetting.submit')"
                  @click="mail_delivery_request"
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
                  :class="{
                    'uk-button-primary': state.checkbox,
                    'uk-button-default': !state.checkbox
                  }"
                />
              </div>
            </form>
            <div class="main_contents_head_sp"></div>
          </div>
        </div>
      </div>
    </article>
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import User from '@/components/User.vue'
  import {
    getMailSettingStatus,
    setMailDeliveryReject,
    setMailDeliveryRequest
  } from '@/api'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { language } = storeToRefs(store)

  const state = reactive({
    successMessage: '',
    send_mail_flg: undefined,
    checkbox: true
  })

  const mail_delivery_reject = async () => {
    if (state.send_mail_flg !== '0') {
      const { contents } = await setMailDeliveryReject()
      if (!contents.login) {
        router.push({ name: 'Login' })
      } else {
        state.successMessage = t('error.success_modify')
        router.push({ name: 'MailSetting' })
        init()
      }
    } else {
      router.push({ name: 'Home' })
    }
  }

  const mail_delivery_request = async () => {
    if (state.send_mail_flg !== '1') {
      const { contents } = await setMailDeliveryRequest()
      if (!contents.login) {
        router.push({ name: 'Login' })
      } else {
        state.successMessage = t('error.success_modify')
        router.push({ name: 'MailSetting' })
        init()
      }
    } else {
      router.push({ name: 'Home' })
    }
  }
  const init = async () => {
    const { contents } = await getMailSettingStatus()
    if (!contents.login) {
      router.push({ name: 'Login' })
    }
    state.send_mail_flg = contents.send_email_flg
  }

  onMounted(async () => {
    init()
  })
</script>

<style lang="scss">
  .container {
    display: flex;
    position: relative;
    margin: 50px 0;
    cursor: pointer;
    line-height: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    justify-content: center;
    align-items: center;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
  }

  .checkmark {
    position: relative;
    height: 22px;
    width: 22px;
    border-radius: 5px;
    background-color: #ccc;
    margin-right: 10px;
  }

  .container input:checked ~ .checkmark {
    background-color: #febd09;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input[type='button']:hover {
    border-color: #ccc;
    background: unset;
  }

  .text-center {
    text-align: center;
  }

  .form-box {
    width: calc(100% - 2rem);
    max-width: 720px !important;
    margin: 0 auto;
  }
</style>
