<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <dl class="dl_table">
        <dt>{{ $t('pages.Company.profile.table.dt1') }}</dt>
        <dd>{{ $t('pages.Company.profile.table.dd1') }}</dd>
        <dt>{{ $t('pages.Company.profile.table.dt2') }}</dt>
        <dd>{{ $t('pages.Company.profile.table.dd2') }}</dd>
        <dt>{{ $t('pages.Company.profile.table.dt3') }}</dt>
        <dd>{{ $t('pages.Company.profile.table.dd3') }}</dd>
        <dt>{{ $t('pages.Company.profile.table.dt4') }}</dt>
        <dd>{{ state.capital }}</dd>
        <dt>{{ $t('pages.Company.profile.table.dt5') }}</dt>
        <dd>{{ $t('pages.Company.profile.table.dd5') }}</dd>
        <dt>{{ $t('pages.Company.profile.table.dt6') }}</dt>
        <dd>{{ $t('pages.Company.profile.table.dd6') }}</dd>
        <dt>{{ $t('pages.Company.profile.table.dt7') }}</dt>
        <dd>{{ $t('pages.Company.profile.table.dd7') }}</dd>
      </dl>

      <div class="bh4 fw_m mt_40">{{
        $t('pages.Company.profile.subTitle')
      }}</div>
      <div class="btn_mb">
        <a
          :href="`${s3Url}resource/financial_statement_202402.pdf`"
          target="_blank"
          class="btn_link"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Company.profile.link4') }}</a
        >
        <a
          :href="`${s3Url}resource/financial_statement_202302.pdf`"
          target="_blank"
          class="btn_link"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Company.profile.link5') }}</a
        >
        <a
          :href="`${s3Url}resource/financial_statement_202202.pdf`"
          target="_blank"
          class="btn_link"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Company.profile.link1') }}</a
        >
        <a
          :href="`${s3Url}resource/financial_statement_202102.pdf`"
          target="_blank"
          class="btn_link"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Company.profile.link2') }}</a
        >
        <a
          :href="`${s3Url}resource/financial_statement_202002.pdf`"
          target="_blank"
          class="btn_link"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Company.profile.link3') }}</a
        >
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { getCapital } from '@/api'
  import { reactive, onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  const store = useMainStore()
  const { s3Url } = storeToRefs(store)

  const state: {
    capital: string
    loading: boolean
  } = reactive({
    capital: '',
    loading: true
  })

  onMounted(async () => {
    const { contents } = await getCapital()
    state.loading = false

    const capital = contents?.body
    const amount = capital.split('|')[0]
    const ymd = capital.split('|')[1]
    const ymd_date = new Date(ymd)

    state.capital = t('pages.Company.profile.table.dd4', {
      amount: amount,
      ymd: ymd_date.toLocaleDateString('ja-jp', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }),
      ymd_eng: ymd_date.toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    })
  })
</script>
