<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="bh4 fw_m fc_y mt_40">{{
        $t('pages.Beginner.required_documents.area1.title')
      }}</div>

      <dl class="certificate">
        <dd class="fdblock boxss mb_25 ta_c">
          <div class="bh4 fw_m fc_y"
            >{{ $t('pages.Beginner.required_documents.area1.msg1') }}
          </div>
          <div
            ><img
              :src="`${s3Url}img/group_AA.${language}.png?v=1`"
              alt="顔写真付きのご本人確認書類"
              style="border: 5px solid rgb(152, 152, 152)"
          /></div> </dd
        ><dd class="fdblock boxss mb_25 ta_c">
          <div class="bh4 fw_m fc_y"
            >{{ $t('pages.Beginner.required_documents.area1.msg2') }}
          </div>
          <div
            ><img
              :src="`${s3Url}img/group_AB.${language}.png?v=1`"
              alt="顔写真付きのご本人確認書類"
              style="border: 5px solid rgb(152, 152, 152)"
          /></div>
        </dd>
      </dl>

      <div class="main_contents_text boxs">
        <div class="bh4 fw_m fc_y mt_40">{{
          $t('pages.Beginner.required_documents.area2.title')
        }}</div>
        <dl class="certificate">
          <dd class="fdblock boxss mb_25 ta_c">
            <div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg1')
            }}</div>
            <div>
              <img
                src="@static/img/menkyo_img.png?v=0.1"
                alt="Driver's license"
            /></div>
            <p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip1')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c">
            <div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg2')
            }}</div>
            <div>
              <img
                src="@static/img/shoumei_img.png"
                alt="Proof of driving history"
            /></div>
            <p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip2')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c">
            <div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg3')
            }}</div>
            <div>
              <img
                src="@static/img/mynumber_img.png"
                alt="Personal Number Card"
            /></div>
            <p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip3')
            }}</p>
            <p class="cat_red">{{
              $t('pages.Beginner.required_documents.area2.warning3')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c"
            ><div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg5')
            }}</div
            ><div
              ><img
                src="@static/img/jyuki_img.png"
                alt="Basic Resident Registration Card" /></div
            ><p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip5')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c"
            ><div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg6')
            }}</div
            ><div
              ><img
                src="@static/img/zairyu_img.png"
                alt="Residence card / Special permanent resident certificate" /></div
            ><p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip6')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c"
            ><div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg7')
            }}</div
            ><div
              ><img
                src="@static/img/eijyu_img.png"
                alt="Special Permanent Resident Certificate" /></div
            ><p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip7')
            }}</p
            ><p class="cat_red">{{
              $t('pages.Beginner.required_documents.area2.warning7')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c"
            ><div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg8')
            }}</div
            ><div
              ><img
                src="@static/img/hoken_img.png"
                alt="Certificate of health insurance, etc." /></div
            ><p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip8')
            }}</p
            ><p class="cat_red">{{
              $t('pages.Beginner.required_documents.area2.warning8')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c"
            ><div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg4')
            }}</div
            ><div><img src="@static/img/passport_img.png" alt="Passport" /></div
            ><p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip4')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c">
            <div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg13')
            }}</div>
            <p class="ctf_t mb_15">{{
              $t('pages.Beginner.required_documents.area2.tip12')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c">
            <div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg11')
            }}</div>
            <p class="ctf_t mb_15">{{
              $t('pages.Beginner.required_documents.area2.tip11')
            }}</p>
          </dd>
          <dd class="fdblock boxss mb_25 ta_c">
            <div class="bh4 fw_m">{{
              $t('pages.Beginner.required_documents.area2.msg10')
            }}</div>
            <p class="ctf_t">{{
              $t('pages.Beginner.required_documents.area2.tip10')
            }}</p>
          </dd>
        </dl>
        <div class="main_contents_text boxs"
          ><div class="bh4 fw_m fc_y mt_40">{{
            $t('pages.Beginner.required_documents.area3.title')
          }}</div
          ><dl class="certificate"
            ><dd class="fdblock boxss mb_25 ta_c"
              ><div class="bh4 fw_m">{{
                $t('pages.Beginner.required_documents.area3.msg1')
              }}</div
              ><div
                ><img
                  src="@static/img/denki_img.png"
                  alt="Receipt of electricity bill" /></div
              ><p class="ctf_t">{{
                $t('pages.Beginner.required_documents.area3.tip1')
              }}</p></dd
            ><dd class="fdblock boxss mb_25 ta_c"
              ><div class="bh4 fw_m mb_0">{{
                $t('pages.Beginner.required_documents.area3.msg2')
              }}</div></dd
            ><dd class="fdblock boxss mb_25 ta_c"
              ><div class="bh4 fw_m mb_0">{{
                $t('pages.Beginner.required_documents.area3.msg3')
              }}</div></dd
            ></dl
          ></div
        ></div
      >
      <div class="main_contents_text boxs" v-if="language == 'ja'">
        <div class="bh3 fw_m fc_y mt_40">&lt;注意事項&gt;</div>
        <div class="bh4 fw_m fc_y mt_40">【A群】の書類について</div
        ><dl class="">
          <dd class="fdblock boxss mb_25 ta_l"
            ><p
              >・お客さまの「氏名」「生年月日」「現住所」の3点が確認できる書類をお送りください。</p
            ><p
              >・裏面がある本人確認書類につきましては、本人情報の有無にかかわらず裏面もご提出ください。(個人番号カードを除く)</p
            ><p>・有効期限のある場合は期限内の日付があるものに限ります。</p
            ><p
              >・写真付き本人確認書類（各種）のコピーは、顔写真部分を塗潰さずにご提出ください。</p
            ><p
              >・「個人番号カードの裏面」および
              「通知カード」（写真なし）は本人確認書類として利用できませんので、送付しないでください。</p
            ><p
              >本人確認書類として「個人番号カードの裏面」および「通知カード」を送付された場合は、廃棄いたしますので、あらかじめご了承ください。</p
            ><p
              >※廃棄するにあたり、当社よりお客さまへご連絡はいたしません。</p
            ></dd
          ></dl
        >
        <div class="bh4 fw_m fc_y mt_40">【B群】の書類について</div
        ><dl class="">
          <dd class="fdblock boxss mb_25 ta_l"
            ><p>・現住所が記載されているものに限ります。</p
            ><p
              >・「領収日付の押印又は発行年月日」、「氏名」、「現住所」が記載されているもので、</p
            ><p>その日が弊社が送付を受ける日の前6ヶ月以内のものに限ります。</p
            ><p>・請求書や通知書は受付できません。</p></dd
          ></dl
        ></div
      >
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)
</script>
