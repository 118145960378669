<template>
  <ul class=""
    ><li class="wal_list"
      ><span class="box-right">{{ props.type?.toUpperCase() }}</span
      ><span class="box-left">{{ $t('pages.Trade.balance.item1') }}</span
      ><span class="box-right">JPY</span></li
    ><li class="wal_list"
      ><span class="box-right">{{
        props.data?.usable_balance?.balance_currency.toLocaleString(undefined, {
          maximumFractionDigits: [8]
        }) || 0
      }}</span
      ><span class="box-left">{{ $t('pages.Trade.balance.item2') }}</span
      ><span class="box-right"
        >￥{{ props.data?.usable_balance?.jpy.toLocaleString() || 0 }}</span
      ></li
    ><li class="wal_list"
      ><span class="box-right">{{
        props.data?.usable_balance?.allocated_currency.toLocaleString(
          undefined,
          { maximumFractionDigits: [8] }
        ) || 0
      }}</span
      ><span class="box-left">{{ $t('pages.Trade.balance.item3') }}</span
      ><span class="box-right"
        >￥{{
          props.data?.usable_balance?.allocated_jpy.toLocaleString() || 0
        }}</span
      ></li
    ><li class="wal_list"
      ><span class="box-right">{{
        props.data?.usable_balance?.usable_currency.toLocaleString(undefined, {
          maximumFractionDigits: [8]
        }) || 0
      }}</span
      ><span class="box-left">{{ $t('pages.Trade.balance.item4') }}</span
      ><span class="box-right"
        >￥{{
          props.data?.usable_balance?.usable_jpy.toLocaleString() || 0
        }}</span
      ></li
    ><li class="wal_list"
      ><span class="box-right"
        >￥{{
          parseFloat(
            props.data?.usable_balance?.balance_currency *
              (store.ws.crypto &&
                store.ws.crypto[props.type?.toLocaleLowerCase()]?.bid) || 0
          ).toLocaleString(undefined, {
            maximumFractionDigits: [props.decimal_unit]
          }) || 0
        }}</span
      ><span class="box-left">{{ $t('pages.Trade.balance.item5') }}</span
      ><span class="box-right"
        >￥{{ props.data?.usable_balance?.jpy.toLocaleString() || 0 }}</span
      ></li
    ></ul
  >
</template>

<script lang="ts" setup>
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()

  const props = defineProps({
    type: String,
    data: Object,
    decimal_unit: Number
  })
</script>

<style lang="scss" scoped>
  @use '@/static/styles/trade-list.scss';
  .price_left {
    width: 50%;
  }
  .price_right {
    width: 50%;
    border-left: 1px solid #febd09 !important;
  }

  .wal_list {
    padding: unset !important;
  }
</style>
