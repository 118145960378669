import axios from '@/api/axios'
import { PageType, ArticleFilterType } from '@/@types'

export const getCareer = () => {
  return axios.get(`/crm/commons/career/`)
}

export const getCampaign = () => {
  return axios.get(`/crm/commons/campaign/`)
}
export const getCapital = () => {
  return axios.get(`/crm/commons/capital/`)
}

export const getGlossary = () => {
  return axios.get(`/crm/columns/glossary/`)
}

export const getGlossaryArticle = (id: string) => {
  return axios.get(`/crm/columns/articles/${id}/`)
}

export const getTags = () => {
  return axios.get(`/crm/columns/tags/`)
}

export const getArticles = (page?: PageType, filters?: ArticleFilterType) => {
  return axios.get(`/crm/columns/articles/`, {
    params: {
      ...page,
      ...filters
    }
  })
}

export const getBeginner = (page?: PageType) => {
  return axios.get(`/crm/columns/beginner/`, {
    params: {
      ...page
    }
  })
}

export const getAdvanced = (page?: PageType) => {
  return axios.get(`/crm/columns/advanced/`, {
    params: {
      ...page
    }
  })
}

export const getTrending = (page?: PageType) => {
  return axios.get(`/crm/columns/trending/`, {
    params: {
      ...page
    }
  })
}

export const getNotificationList = (page: number) => {
  return axios.get(`/crm/notifications/pub/?page=${page}`)
}

export const getNotificationDetail = (id: string) => {
  return axios.get(`/crm/notifications/pub/${id}/`)
}

export const getNotificationPrivList = (page: number) => {
  return axios.get(`/crm/notifications/priv/?page=${page}`)
}

export const getNotificationPrivDetail = (id: string) => {
  return axios.get(`/crm/notifications/priv/${id}/`)
}

export const getMailSettingStatus = () => {
  return axios.post(`/crm/mail_delivery/status/`)
}

export const setMailDeliveryRequest = () => {
  return axios.post(`/crm/mail_delivery/request/`)
}

export const setMailDeliveryReject = () => {
  return axios.post(`/crm/mail_delivery/reject/`)
}

export const createInquiry = (inquiry: any) => {
  const data = new FormData()
  Object.keys(inquiry.contents).forEach((key) => {
    !key.includes('file') && data.append(key, inquiry.contents[key])
  })
  const array = ['', '2', '3', '4']
  array.forEach((name) => {
    if (inquiry.contents[`file${name}`]) {
      data.append(`file${name}`, inquiry.contents[`file${name}`][0])
    }
  })
  return axios.post(`/crm/inquiry/create/`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const getCoinRate = (type: string) => {
  return axios.get(`/rate/${type}/`)
}

export const getCoinRateHistory = (type: string, interval?: string, trade_type?: string) => {
  if (trade_type == null){
    return axios.get(`/rate/${type}/${interval || '1d'}/`)
  }else {
    return axios.get(`/rate/${type}/${interval || '1d'}/${trade_type || 'ask'}/`)
  }
}

export const checkLogin = () => {
  return axios.get(`/system/vue/login_check/`)
}

export const login = (data: any) => {
  return axios.post(`/auth/login/`, data)
}

export const newAccount = (data: any) => {
  return axios.post(`/auth/signup/`, data)
}

export const pwModify = (
  data: any,
  param_key: string,
  email_encode: string
) => {
  return axios.post(`/auth/pw_modify/${param_key}/${email_encode}/`, data)
}

export const getPwModifyAfter = () => {
  return axios.get(`/auth/pw_modify/after/`)
}

export const setPwModifyAfter = (data: any) => {
  return axios.post(`/auth/pw_modify/after/`, data)
}

export const getNotificationSetting = () => {
  return axios.get(`/crm/notification_settings/`)
}

export const setNotificationSetting = (data: any) => {
  return axios.post(`/crm/notification_settings/`, data)
}

export const pwReset = (data: any) => {
  return axios.post(`/auth/pw_reset/`, data)
}

export const decodeEmail = (email_encode: string) => {
  return axios.post(`/auth/check/${email_encode}/`)
}

export const getCampaigns = (page?: number) => {
  return axios.get(`/crm/campaign/?page=${page || 1}`)
}

export const getCampaignDetail = (id: string) => {
  return axios.get(`/crm/campaign/${id}`)
}

export const getCorporationBasic = (uncheck_flg?: string) => {
  return axios.get(
    `/kyc/corporation/basic/${
      uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''
    }`
  )
}

export const setCorporationBasic = (data: any) => {
  return axios.post(`/kyc/corporation/basic/create/`, data)
}

export const getCorporationExp = (uncheck_flg?: string) => {
  return axios.get(
    `/kyc/corporation/exp/${
      uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''
    }`
  )
}

export const setCorporationExp = (data: any) => {
  return axios.post(`/kyc/corporation/exp/create/`, data)
}

export const setCorporationStatus = (status: string, idx?: string) => {
  return axios.post(`/kyc/judging/status/`, {
    contents: {
      status: status,
      idx: idx || '0'
    }
  })
}

export const getTradeHead = (uncheck_flg?: string) => {
  return axios.get(
    `/kyc/trade_head/${uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''}`
  )
}

export const setTradeHead = (data: any) => {
  return axios.post(`/kyc/trade_head/create/`, data)
}

export const getBankInfo = (uncheck_flg?: string) => {
  return axios.get(
    `/kyc/bank_account/${uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''}`
  )
}

export const getBankList = () => {
  return axios.get(`/kyc/bank_account/bank_list/`)
}

export const getMajorBankList = (get_type: string) => {
  if (get_type != null) {
    return axios.get(`/kyc/bank_account/major_bank_list/${get_type}/`)
  } else {
    return axios.get(`/kyc/bank_account/major_bank_list/`)
  }
}

export const getBankByName = (name: string) => {
  return axios.get(`/kyc/bank_account/bank_list/${name}/`)
}

export const getQuickBankByName = (name: string) => {
  return axios.get(`/kyc/bank_account/quick_bank_list/${name}/`)
}

export const getBranchList = (code: string, name: string) => {
  return axios.get(`/kyc/bank_account/branch_list/${code}/${name}/`)
}

export const setBankAccount = (data: any) => {
  return axios.post(`/kyc/bank_account/create/`, data)
}

export const selectShareHolder = (
  share_holder_type?: string,
  share_holder_company?: string
) => {
  return axios.post(`/kyc/share_holder/choice/`, {
    contents: {
      share_holder_type: share_holder_type || '',
      share_holder_company: share_holder_company || ''
    }
  })
}

export const getShareHolderType = (uncheck_flg?: string) => {
  return axios.get(
    `/kyc/corporation/share_holder_type/${
      uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''
    }`
  )
}

export const getShareHolder = (index: string, uncheck_flg?: string) => {
  return axios.get(
    `/kyc/share_holder/${index}/${
      uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''
    }`
  )
}

export const setShareHolder = (index: string, data: any) => {
  return axios.post(`/kyc/share_holder/${index}/create/`, data)
}

export const corporationFileUpload = (file: any) => {
  const data = new FormData()
  Object.keys(file.contents).forEach((key) => {
    !key.includes('file') && data.append(key, file.contents[key])
  })
  const array = ['1', '2', '3', '4', '5']
  array.forEach((name) => {
    if (file.contents[`file${name}`]) {
      data.append(`file${name}`, file.contents[`file${name}`][0])
    }
  })
  return axios.post(`/kyc/fileupload/update/`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const getFileUpload = (uncheck_flg?: string) => {
  return axios.get(
    `/kyc/fileupload/${uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''}`
  )
}

export const twoStepIssue = (type: string, two_step_method: string) => {
  return axios.post(`/kyc/twostep/regist/`, {
    contents: {
      two_step_regist: {
        auth_type: type,
        two_step_method: two_step_method,
      }
    }
  })
}

export const twoStepVerify = (type: string, code: string, method: string) => {
  return axios.post(`/kyc/twostep/check/`, {
    contents: {
      two_step_check: {
        two_step_method: method,
        auth_type: type,
        passcode: code
      }
    }
  })
}

export const twoStepUpdate = (
  code: string,
  twostep_auth_list: any[],
  two_step_auth_flg: string
) => {
  return axios.post(`/kyc/twostep/update/`, {
    contents: {
      passcode: code,
      twostep_auth_list: twostep_auth_list,
      two_step_auth_flg: two_step_auth_flg
    }
  })
}

export const getTwoStep = (uncheck_flg?: string, auth_type?: string) => {
  return axios.get(
    `/kyc/twostep/${uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''}${
      uncheck_flg && auth_type ? '&' : ''
    }${auth_type ? '?auth_type=' + auth_type : ''}`
  )
}

export const getTwoStepStatus = () => {
  return axios.get(`/kyc/twostep/status/`)
}

export const getTwoStepSetting = () => {
  return axios.get(`/kyc/twostep/setting/`)
}

export const getTwoStepGoogleQrCode = () => {
  return axios.get(`/kyc/twostep/google_qr_code/`)
}

export const getInvite = () => {
  return axios.get(`/crm/invite/`)
}

export const getCorporationConfirm = () => {
  return axios.get(`/kyc/confirm/`)
}

export const getFinished = () => {
  return axios.get(`/kyc/finish/`)
}

export const getAddrByPostCode = (post_no: string) => {
  return axios.get(`/kyc/post_code/?post_no=${post_no}`)
}

export const getIndividual = (uncheck_flg?: string) => {
  return axios.get(
    `/kyc/individual/${uncheck_flg === 'uncheck' ? '?uncheck_flg=1' : ''}`
  )
}

export const setIndividual = (data: any) => {
  return axios.post(`/kyc/individual/create/`, data)
}

export const getIndividualStep2 = () => {
  return axios.get(`/kyc/fileupload/choice/`)
}

export const getIndividualQRCode = () => {
  return axios.get(`/kyc/ekyc/`)
}

export const getAgreement = () => {
  return axios.get(`/kyc/agreement/`)
}

export const setAgreement = (data: string[]) => {
  return axios.post(`/kyc/agreement/`, {
    contents: {
      agreement_check_list: data
    }
  })
}

export const getImportant = () => {
  return axios.get(`/kyc/agreement/important/`)
}

export const setImportant = () => {
  return axios.post(`/kyc/agreement/important/`, {
    contents: {}
  })
}

export const getHome = () => {
  return axios.get(`/kyc/home/`)
}

export const getAddressConfirm = () => {
  return axios.get(`/kyc/address_confirm/`)
}

export const setAddressConfirm = (data: any) => {
  return axios.post(`/kyc/address_confirm/`, {
    contents: data
  })
}

export const checkEKYCIsExist = () => {
  return axios.get(`/kyc/ekyc/is_exist/`)
}

export const getConveniAgreement = () => {
  return axios.get(`/kyc/agreement/conveni/`)
}

export const setConveniAgreement = () => {
  return axios.post(`/kyc/agreement/conveni/`, {})
}

export const getUserCheckInfo = () => {
  return axios.get(`/crm/customer/status/`)
}

export const updateUserCheckInfo = (data: {
  contents: { req_id: string; value: string }
}) => {
  return axios.post(`/crm/customer/update_info/`, data)
}

export const changeLanguage = (language: string) => {
  return axios.post(`/system/vue/language/`, {
    contents: { language: language }
  })
}

export const isTwoStepSuccess = (id: string, query?: any) => {
  return axios.get(`/kyc/ekyc/receive/${id}/`, {
    params: query
  })
}

export const getLoginHistory = (page: number) => {
  return axios.get(`/history/login/?page=${page}`)
}

export const getWalletFiatHistory = (
  page: number,
  transfer_type: string,
  complete_flg: string
) => {
  return axios.get(
    `/history/wallet/fiat/?page=${page}${
      transfer_type === '' ? '' : '&transfer_type=' + transfer_type
    }${complete_flg === 'incomplete' ? '&complete_flg=0' : ''}`
  )
}

export const getWalletCryptoHistory = (
  crypto: string,
  page: number,
  transfer_type: string,
  complete_flg: string
) => {
  return axios.get(
    `/history/wallet/${crypto}/?page=${page}${
      transfer_type === '' ? '' : '&transfer_type=' + transfer_type
    }${complete_flg === 'incomplete' ? '&complete_flg=0' : ''}`
  )
}

export const getTransferHistory = (page: number) => {
  return axios.get(`/history/transfer/transfer/?page=${page}`)
}

export const getTradeHistory = (crypto: string, page: number) => {
  return axios.get(`/history/trade/${crypto}/?page=${page}`)
}

export const getBuyPointHistory = (page: number) => {
  return axios.get(`/history/point/point/?page=${page}`)
}

export const getPointBuyCryptoHistory = (crypto: string, page: number) => {
  return axios.get(`/history/point/${crypto}/?page=${page}`)
}

export const getBalanceHistory = (target_date: string) => {
  return axios.get(`/history/balance/${target_date}/`)
}

export const getReportBalanceQuarter = () => {
  return axios.get(`/report/balance/quarter/`)
}

export const getReportBalanceYear = () => {
  return axios.get(`/report/balance/year/`)
}

export const getTransfer = () => {
  return axios.get(`/wallet/transfer/`)
}

export const setTransfer = (data: any) => {
  return axios.post(`/wallet/transfer/`, data)
}

export const getDeposit = (deposit_type: string) => {
  return axios.get(`/wallet/deposit/bank/${deposit_type}/`)
}

export const getDepositQuick = () => {
  return axios.get(`/wallet/deposit/quick/`)
}

export const setDepositQuick = (data: any) => {
  return axios.post(`/wallet/deposit/quick/`, data)
}

export const getDepositConveni = () => {
  return axios.get(`/wallet/deposit/conveni/`)
}

export const setDepositConveni = (data: any) => {
  return axios.post(`/wallet/deposit/conveni/`, data)
}

export const cancelDepositConveni = (data: any) => {
  return axios.post(`/wallet/deposit/conveni/cancel/`, data)
}

export const getDepositCrypto = (crypto: string) => {
  return axios.get(`/wallet/deposit/crypto/${crypto}/`)
}

export const setDepositCrypto = (crypto: string) => {
  return axios.post(`/wallet/deposit/crypto/${crypto}/`, {})
}

export const getWithdrawal = () => {
  return axios.get(`/wallet/withdrawal/bank/`)
}

export const setWithdrawal = (data: any) => {
  return axios.post(`/wallet/withdrawal/bank/`, data)
}

export const getWithdrawalCrypto = (crypto: string) => {
  return axios.get(`/wallet/withdrawal/crypto/${crypto}/`)
}

export const setWithdrawalCrypto = (crypto: string, data: any) => {
  return axios.post(`/wallet/withdrawal/crypto/${crypto}/`, data)
}

export const getWithdrawalFee = (crypto: string, data: any) => {
  return axios.post(`/wallet/withdrawal/fee/${crypto}/`, data)
}

export const getPointBalance = () => {
  return axios.get(`/wallet/point/balance/`)
}

export const getPointCrypto = (crypto: string) => {
  return axios.get(`/wallet/point/crypto/${crypto}/`)
}

export const setPointCrypto = (crypto: string, qty: string) => {
  return axios.post(`/wallet/point/crypto/${crypto}/`, {
    contents: {
      qty: qty
    }
  })
}

export const getHyperWalletPayment = (p: string, k: string) => {
  return axios.get(`/wallet/hyper/payment/?p=${p}&k=${k}`)
}

export const setHyperWalletPayment = (p: string, k: string, data: any) => {
  return axios.post(`/wallet/hyper/payment/?p=${p}&k=${k}`, data)
}

export const getTradeInit = (crypto: string) => {
  return axios.get(`/trade/otc/${crypto}/`)
}
export const getTradeLatest = (crypto: string) => {
  return axios.get(`/trade/otc/${crypto}/latest/`)
}
export const setTradeConfirmFlg = (crypto: string, data: any) => {
  return axios.post(`/trade/otc/${crypto}/update/confirm_flg/`, data)
}
export const orderMarket = (crypto: string, data: any) => {
  return axios.post(`/trade/otc/${crypto}/order/market/`, data)
}
export const orderLimit = (crypto: string, data: any) => {
  return axios.post(`/trade/otc/${crypto}/order/limit/`, data)
}
export const orderOco = (crypto: string, data: any) => {
  return axios.post(`/trade/otc/${crypto}/order/oco/`, data)
}
export const updateOrder = (crypto: string, data: any) => {
  return axios.post(`/trade/otc/${crypto}/update/`, data)
}
export const deleteOrder = (crypto: string, data: any) => {
  return axios.post(`/trade/otc/${crypto}/delete/`, data)
}
