<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m fc_y">{{
        $router.currentRoute?.value?.meta?.title
      }}</div>
      <div class="bh5 fw_m mb_40">
        {{ $t('pages.Service.application_crypto_assets.msg1') }}
      </div>
      <div class="bh4 fw_m fc_y">{{
        $t('pages.Service.application_crypto_assets.title1')
      }}</div>
      <div class="boxs">
        <img
          class="acaflow__img"
          :src="`${s3Url}img/page/acaflow.${language}.png?v=2`"
          :alt="$t('pages.Service.application_crypto_assets.title1')"
        />
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)
</script>
