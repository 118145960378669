<template>
  <!-- article start -->
  <article id="contents">
    <the-loading v-if="state.loading" color="#434343"></the-loading>

    <template v-if="state.article">
      <template v-if="state.category === 'glossary'">
        <h1 class="glossary_title boxm" v-if="state.article?.title">{{
          state.article?.title
        }}</h1>
      </template>
      <template v-else>
        <header id="column">
          <figure><img src="@static/img/l_img.jpg" alt="column" /></figure>
          <h1 class="column_title boxm" v-if="state.article?.title">{{
            state.article?.title
          }}</h1>
        </header>
      </template>

      <nav class="column_nav boxm">
        <div class="time"
          ><i class="far fa-clock"></i>
          {{ formatDate(state.article?.reg_date) }}</div
        >
      </nav>

      <div
        id="column_body"
        class="boxm"
        v-if="state.article?.body"
        v-html="state.article?.body"
      >
      </div>

      <nav class="column_nav boxm"></nav>

      <nav class="column_tagarea boxm">
        <router-link :to="{ name: state.categoryBreadcrumb?.route }" class="cat"
          ><i class="fas fa-folder-open"></i>&nbsp;{{
            state.categoryBreadcrumb?.name
          }}</router-link
        >
        <router-link
          :to="{ name: 'Tag', params: { name: tag } }"
          class="tag"
          v-for="tag in state.article?.tags"
          :key="tag"
          ><i class="fas fa-tag"></i>&nbsp;{{ tag }}</router-link
        >
      </nav>
    </template>

    <glossary :silent="true" v-if="state.category === 'glossary'"></glossary>
    <div
      class="main_contents boxm"
      v-if="state.category !== 'glossary' && state.relatedList.length > 0"
    >
      <div class="main_contents_list">
        <div class="column_list">
          <h3>関連コラム</h3>
          <div class="column_lists">
            <article-preview
              v-for="item in state.relatedList"
              :key="item.idx"
              :item="item"
            ></article-preview>
          </div>
        </div>
      </div>
    </div>

    <column-links type="2"></column-links>
    <tag-list></tag-list>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive, onMounted, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { getGlossaryArticle, getArticles } from '@/api/index'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType, CDMSTType } from '@/@types'
  import CDMST from '@/enum/cd_mst.json'
  import { siteMap } from '@/enum/siteMap'
  import Glossary from '@/components/Glossary.vue'
  import TagList from '@/components/TagList.vue'
  import ColumnLinks from '@/components/ColumnLinks.vue'
  import ArticlePreview from '@/components/ArticlePreview.vue'
  import { formatDate } from '@/utils/tool'
  import { useI18n } from 'vue-i18n'

  const TheENUM = CDMST as CDMSTType

  const state: {
    article:
      | {
          title: string
          body: string
          reg_date: Date
          tags: string[]
          description: string
          category: string
        }
      | undefined
    category: string | undefined
    categoryBreadcrumb: SiteMapType | undefined
    loading: boolean
    relatedList: {
      idx: number
      title: string
      url: string
      reg_date: string
      tags: string[]
    }[]
  } = reactive({
    article: undefined,
    category: undefined,
    categoryBreadcrumb: undefined,
    loading: true,
    relatedList: []
  })

  const route = useRoute()
  const store = useMainStore()
  const { t } = useI18n()

  const showGlossaryArticle = async (id: string) => {
    state.loading = true
    state.article = undefined

    const { contents } = await getGlossaryArticle(id)
    state.article = contents

    state.loading = false

    const list = siteMap.find((item) => item.route === 'Column')
    state.category =
      state.article?.category !== undefined
        ? TheENUM.contents['50038'].results[state.article?.category].val2
        : undefined

    list && store.setBreadcrumb(list)

    state.categoryBreadcrumb = list?.children?.find(
      (item) => item.route.toLowerCase() === state.category
    )
    state.categoryBreadcrumb && store.setBreadcrumb(state.categoryBreadcrumb)

    const breadcrumb: SiteMapType = {
      route: route?.name as string,
      name: contents.title
    }

    store.setBreadcrumb(breadcrumb)
    // eslint-disable-next-line
    document!.title = (state.article?.title as string) + ' | Coin Estate'

    const metaOption = [
      {
        name: 'description',
        content: state.article?.description || t('meta.description')
      },
      {
        name: 'twitter:description',
        content: state.article?.description || t('meta.twitterDescription')
      },
      {
        property: 'og:title',
        content: state.article?.title || t('meta.ogTitle')
      },
      {
        property: 'og:description',
        content: state.article?.description || t('meta.ogDescription')
      }
    ]
    metaOption.map((item: any) => {
      const metaData: [
        { key: string; value: string },
        { key: string; value: string }
      ] = [] as any
      Object.keys(item).forEach((key) => {
        metaData.push({ key, value: item[key] })
      })
      document
        ?.querySelector(`meta[${metaData[0].key}='${metaData[0].value}']`)
        ?.setAttribute(metaData[1].key, metaData[1].value)
    })
  }

  const showArticles = async () => {
    if (!state.article?.category) return
    const { contents } = await getArticles(
      { page_size: 6, page: 1 },
      { category: state.article?.category }
    )
    state.relatedList = contents.results
  }

  onMounted(async () => {
    await showGlossaryArticle(route?.params?.id as string)
    await showArticles()
  })

  watch(route, async () => {
    await showGlossaryArticle(route?.params?.id as string)
    await showArticles()
  })
</script>
