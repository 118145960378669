<template>
  <div class="corporation-confirm" v-if="props.part1">
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.rep_name_sei') }}</dt>
      <dd>{{
        props.data?.rep_name_sei || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.rep_name_mei') }}</dt>
      <dd>{{
        props.data?.rep_name_mei || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.rep_name_sei_kana') }}</dt>
      <dd>{{
        props.data?.rep_name_sei_kana || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.rep_name_mei_kana') }}</dt>
      <dd>{{
        props.data?.rep_name_mei_kana || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.rep_birthday') }}</dt>
      <dd>{{
        props.data?.rep_birthday || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.rep_country') }}</dt>
      <dd>{{
        findOption('country', props.data?.rep_country) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.rep_position') }}</dt>
      <dd>{{
        props.data?.rep_position || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.rep_peps') }}</dt>
      <dd>{{
        findOption('peps', props.data?.rep_peps) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl v-if="props.data?.rep_peps === '2'">
      <dt>{{ $t('pages.Corporation.form.rep_peps_text') }}</dt>
      <dd>{{
        props.data?.rep_peps_text || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
  </div>

  <div class="corporation-confirm" v-else>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_stock') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_stock) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_stock_credit') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_stock_credit) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_public_bonds') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_public_bonds) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_investment_trust') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_investment_trust) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_crypto_spot') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_crypto_spot) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_crypto_leverage') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_crypto_leverage) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.transaction_objective') }}</dt>
      <dd>{{
        findOption(
          'transaction_objective',
          props.data?.transaction_objective
        ) || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl v-if="props.data?.transaction_objective?.includes('05')">
      <dt>{{ $t('pages.Corporation.form.transaction_objective_abroad') }}</dt>
      <dd>{{
        props.data?.transaction_objective_abroad ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl v-if="props.data?.transaction_objective?.includes('99')">
      <dt>{{ $t('pages.Corporation.form.transaction_objective_other') }}</dt>
      <dd>{{
        props.data?.transaction_objective_other ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.motivation_user') }}</dt>
      <dd>{{
        findOption('motivation_user', props.data?.motivation_user) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl v-if="props.data?.motivation_user === '05'">
      <dt>{{ $t('pages.Corporation.form.motivation_user_introducer') }}</dt>
      <dd>{{
        props.data?.motivation_user_introducer ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl v-if="props.data?.motivation_user === '99'">
      <dt>{{ $t('pages.Corporation.form.motivation_user_other') }}</dt>
      <dd>{{
        props.data?.motivation_user_other || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
  </div>
</template>

<script setup lang="ts">
  import { findOption } from '@/utils/tool'
  const props = defineProps({
    data: Object,
    part1: Boolean,
    part2: Boolean
  })
</script>
