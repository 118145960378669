<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <template
          v-if="
            store.home &&
            store.home?.account_status !== '500' &&
            store.home?.account_status !== '000'
          "
        >
          <h1>{{ $t('pages.Home.account.title') }}</h1>
          <p style="text-align: center">{{ $t('pages.Home.account.msg1') }}</p>
          <div
            class="boxss account-status"
            v-if="store.home?.user_type === '2'"
          >
            <dl>
              <dt>{{ $t('pages.Home.account.corporation.step1') }} *</dt>
              <dd>{{ $t('pages.Home.account.corporation.status_review') }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.corporation.step2') }} *</dt>
              <dd>{{ $t('pages.Home.account.corporation.status_review') }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.corporation.step3') }} *</dt>
              <dd>{{ $t('pages.Home.account.corporation.status_review') }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.corporation.step4') }} *</dt>
              <dd>{{ $t('pages.Home.account.corporation.status_review') }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.corporation.step5') }} *</dt>
              <dd>{{ $t('pages.Home.account.corporation.status_review') }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.corporation.step6') }} *</dt>
              <dd>{{ $t('pages.Home.account.corporation.status_review') }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.corporation.step7') }} *</dt>
              <dd>{{ $t('pages.Home.account.corporation.status_review') }}</dd>
            </dl>
            <dl>
              <dt
                ><router-link :to="{ name: 'CorporationStep8' }">{{
                  $t('pages.Home.account.corporation.step8')
                }}</router-link></dt
              >
              <dd></dd>
            </dl>
            <dl class="unconfirmed">
              <template v-if="store.home?.account_status === '045'">
                <dt
                  ><router-link :to="{ name: 'AddressConfirm' }"
                    >{{
                      $t('pages.Home.account.corporation.step99')
                    }}
                    *</router-link
                  ></dt
                >
              </template>
              <template v-else>
                <dt>{{ $t('pages.Home.account.corporation.step99') }} *</dt>
              </template>
              <dd>{{
                $t('pages.Home.account.corporation.status_unConfirm')
              }}</dd>
            </dl>
          </div>
          <div class="boxss account-status" v-else>
            <dl>
              <dt>{{ $t('pages.Home.account.individual.step1') }} *</dt>
              <dd>{{
                $t('pages.Home.account.individual.status_registered')
              }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.individual.step2') }} *</dt>
              <dd>{{ $t('pages.Home.account.individual.status_uploaded') }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('pages.Home.account.individual.step3') }} *</dt>
              <dd>{{
                $t('pages.Home.account.individual.status_authorized')
              }}</dd>
            </dl>
            <dl class="unconfirmed">
              <template v-if="store.home?.account_status === '045'">
                <dt
                  ><router-link :to="{ name: 'AddressConfirm' }"
                    >{{
                      $t('pages.Home.account.individual.step99')
                    }}
                    *</router-link
                  ></dt
                >
              </template>
              <template v-else>
                <dt>{{ $t('pages.Home.account.individual.step99') }} *</dt>
              </template>
              <dd>{{
                $t('pages.Home.account.individual.status_unConfirm')
              }}</dd>
            </dl>
          </div>
          <p class="boxss" style="margin-bottom: 5rem; font-size: 0.875rem">{{
            $t('pages.Home.account.msg2')
          }}</p>
        </template>

        <template v-if="store.home && store.home?.account_status === '500'">
          <h1>{{ $t('pages.Home.summary.title') }}</h1>
          <div class="boxss">
            {{ $t('pages.Home.summary.msg1')
            }}<a :href="`${state.domain}/wallet/transfer/`" class="is-link">{{
              $t('pages.Home.summary.msg2')
            }}</a
            >{{ $t('pages.Home.summary.msg3') }}

            <dl class="total-assets">
              <dt>{{ $t('pages.Home.summary.total') }}</dt>
              <dd
                >{{ store.home?.summary_objs?.total_assets || '0'
                }}<span>JPY</span></dd
              >
            </dl>
          </div>

          <div class="boxss">
            <div class="account-info">
              <h5>{{ $t('pages.Home.summary.holding.title') }}</h5>
              <ul>
                <li class="account-title"
                  ><span class="column_01"> </span
                  ><span class="column_02">{{
                    $t('pages.Home.summary.holding.amount')
                  }}</span
                  ><span class="column_03">{{
                    $t('pages.Home.summary.holding.toJPY')
                  }}</span></li
                >
                <li
                  ><span class="column_01">JPY</span
                  ><span class="column_02">{{
                    store.home?.summary_objs?.wallet_balance?.qty_jpy.toLocaleString(
                      undefined,
                      { maximumFractionDigits: [8] }
                    ) || '0'
                  }}</span
                  ><span class="column_03">{{
                    store.home?.summary_objs?.wallet_balance?.qty_jpy.toLocaleString(
                      undefined,
                      { maximumFractionDigits: [8] }
                    ) || '0'
                  }}</span></li
                >
                <li v-for="coin in state.coins" :key="coin"
                  ><span class="column_01">{{ coin }}</span
                  ><span class="column_02">{{
                    store.home?.summary_objs?.wallet_balance?.[
                      `qty_${coin.toLocaleLowerCase()}`
                    ].toLocaleString(undefined, {
                      maximumFractionDigits: [8]
                    }) || '0'
                  }}</span
                  ><span class="column_03">{{
                    store.home?.summary_objs?.wallet_jpy?.[
                      coin.toLocaleLowerCase()
                    ] || '0'
                  }}</span></li
                >
                <li
                  ><span class="column_01">{{ $t('coins.point') }}</span
                  ><span class="column_02">{{
                    store.home?.summary_objs?.wallet_balance?.bitgate?.toLocaleString(
                      undefined,
                      { maximumFractionDigits: [8] }
                    ) || '0'
                  }}</span
                  ><span class="column_03">{{
                    store.home?.summary_objs?.wallet_balance?.bitgate?.toLocaleString(
                      undefined,
                      { maximumFractionDigits: [8] }
                    ) || '0'
                  }}</span></li
                >
                <li class="account-total"
                  ><span class="column_01">{{
                    $t('pages.Home.summary.holding.total')
                  }}</span
                  ><span class="column_02"> </span
                  ><span class="column_03">{{
                    store.home?.summary_objs?.wallet_total || '0'
                  }}</span></li
                >
              </ul>
            </div>

            <div class="account-info">
              <h5>{{ $t('pages.Home.summary.trading.title') }}</h5>
              <ul>
                <li class="account-title"
                  ><span class="column_01"> </span
                  ><span class="column_02">{{
                    $t('pages.Home.summary.trading.amount')
                  }}</span
                  ><span class="column_03">{{
                    $t('pages.Home.summary.trading.toJPY')
                  }}</span></li
                >
                <li
                  ><span class="column_01">JPY</span
                  ><span class="column_02">{{
                    store.home?.summary_objs?.trade_balance?.jpy.toLocaleString() ||
                    0
                  }}</span
                  ><span class="column_03">{{
                    store.home?.summary_objs?.trade_balance?.jpy.toLocaleString() ||
                    0
                  }}</span></li
                >
                <li v-for="coin in state.coins" :key="coin"
                  ><span class="column_01">{{ coin }}</span
                  ><span class="column_02">{{
                    store.home?.summary_objs?.trade_balance?.[
                      coin.toLocaleLowerCase()
                    ]?.toLocaleString(undefined, {
                      maximumFractionDigits: [8]
                    }) || '0'
                  }}</span
                  ><span class="column_03">{{
                    store.home?.summary_objs?.trade_jpy?.[
                      coin.toLocaleLowerCase()
                    ]?.toLocaleString() || '0'
                  }}</span></li
                >
                <li class="account-total"
                  ><span class="column_01">{{
                    $t('pages.Home.summary.trading.total')
                  }}</span
                  ><span class="column_02"> </span
                  ><span class="column_03">{{
                    store.home?.summary_objs?.trade_total || '0'
                  }}</span></li
                >
              </ul>
            </div>

            <div class="price_attention">{{
              $t('pages.Home.summary.msg4')
            }}</div>
          </div>
        </template>

        <h1>{{ $t('pages.Home.rate.title') }}</h1>
        <div class="main_contents boxs">
          <div class="coin_r_wrap">
            <div class="coin_r_set" v-for="coin in state.coins" :key="coin">
              <div class="crs_icon"
                ><img
                  :src="`${
                    store.s3Url
                  }img/icon_${coin.toLocaleLowerCase()}.png`"
                  :alt="coin.toLocaleLowerCase()"
              /></div>
              <div class="crs_info">
                <div>{{ coin }}/JPY{{ $t('pages.Home.rate.latestPrice') }}</div>
                <div class="display_price">
                  <div class="display_price1">
                    <span id="id_btc_average" class="price_active">{{
                      (
                        (store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]?.average) ||
                        0
                      ).toLocaleString()
                    }}</span
                    ><span class="pt19 pl4 fw-bold">JPY</span>
                  </div>
                  <div
                    class="display_price2"
                    :class="{
                      text_red:
                        ((store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]
                            ?.day_before_ratio) ||
                          0) < 0,
                      text_green:
                        ((store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]
                            ?.day_before_ratio) ||
                          0) >= 0
                    }"
                  >
                    <span id="id_btc_day_before_ratio_img">{{
                      ((store.ws.crypto &&
                        store.ws.crypto[coin.toLocaleLowerCase()]
                          ?.day_before_ratio) ||
                        0) >= 0
                        ? '▲'
                        : '▼'
                    }}</span>
                    <span id="id_btc_previous_day_price">{{
                      countDayBefore(
                        (store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]
                            ?.daily_base_rate) ||
                          0,
                        (store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]?.average) ||
                          0
                      ).toLocaleString()
                    }}</span>
                    <span>JPY</span> <span>(</span
                    ><span id="id_btc_day_before_ratio"
                      >{{
                        ((store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]
                            ?.day_before_ratio) ||
                          0) >= 0
                          ? '+'
                          : ''
                      }}{{
                        (
                          (store.ws.crypto &&
                            store.ws.crypto[coin.toLocaleLowerCase()]
                              ?.day_before_ratio) ||
                          0
                        ).toLocaleString()
                      }}</span
                    ><span>%)</span>
                  </div>
                </div>
                <ul class="price_hl">
                  <li
                    >HIGH:<span id="id_btc_daily_max_rate">{{
                      (
                        (store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]
                            ?.daily_max_rate) ||
                        0
                      ).toLocaleString()
                    }}</span
                    ><span class="price_y">JPY</span></li
                  >
                  <li
                    >LOW:<span id="id_btc_daily_min_rate">{{
                      (
                        (store.ws.crypto &&
                          store.ws.crypto[coin.toLocaleLowerCase()]
                            ?.daily_min_rate) ||
                        0
                      ).toLocaleString()
                    }}</span
                    ><span class="price_y">JPY</span></li
                  >
                </ul>
              </div>
            </div>
          </div>

          <div class="price_attention">{{ $t('pages.Home.rate.msg1') }}</div>
          <div class="price_attention">{{ $t('pages.Home.rate.msg2') }}</div>
          <div class="price_attention">{{ $t('pages.Home.rate.msg3') }}</div>
        </div>
      </div>
    </article>
    <!-- article end -->
    <!-- ここから Crib Notesユニバーサルタグのコード -->
    <component
      :is="'script'"
      v-if="
        store.home?.account_status !== '500' &&
        store.home?.account_status !== '000' &&
        store.env === 'prod' &&
        store.home?.clib_counter_flg
      "
    >
      (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/" +
      a + "/atm.js"; e.id = d; e.async = true;
      b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
      "script", "__cribnotesTagMgrCmd", "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
      "https://tag.cribnotes.jp/container_manager");
    </component>
    <!-- ここまで Crib Notesユニバーサルタグのコード -->

    <!-- ここから Crib Notesユニバーサルタグのコード -->
    <template
      v-if="
        store.home?.account_status === '500' &&
        store.env === 'prod' &&
        store.home?.clib_counter_flg
      "
    >
      <component
        :is="'script'"
        src="https://tag.cribnotes.jp/support/ntm.js"
      ></component>
      <component :is="'script'">
        window.crib?.setItem('transaction_id',
        {{ store.home?.transaction_id || '' }});
        window.crib?.setItem('thanks_id', {{ store.home?.thanks_id || '' }});
      </component>
      <component :is="'script'">
        (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/"
        + a + "/atm.js"; e.id = d; e.async = true;
        b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
        "script", "__cribnotesTagMgrCmd",
        "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
        "https://tag.cribnotes.jp/container_manager");
      </component>
    </template>
    <!-- ここまで Crib Notesユニバーサルタグのコード -->

    <!-- ここから TCSアフィリエイトのコード -->
    <img
      v-if="
        store.home?.account_status === '500' &&
        store.env === 'prod' &&
        store.home?.tcs_counter_flg
      "
      :src="`https://www.tcs-asp.net/aresult?LC=BGT1&NK=${
        store.home?.transaction_id || ''
      }&AKEY=${store.home?.akey || ''}`"
      width="1"
      height="1"
      alt="tcs-asp"
    />
    <!-- ここまで TCSアフィリエイトのコード -->
  </user>

  <router-link
    id="scroll_bnr"
    :to="{ name: 'Trade', params: { crypto: 'btc' } }"
    ><i class="fas fa-chart-line"></i>
    {{ $t('menu.user.trade.tool') }}</router-link
  >
</template>

<script lang="ts" setup>
  import { reactive, onMounted, onBeforeMount, watch } from 'vue'
  import User from '@/components/User.vue'
  import { useMainStore } from '@/store/pinia'
  import { onBeforeRouteLeave, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { omit } from 'lodash'
  import { getUserCheckInfo } from '@/api'

  const coinFlag =
    import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG &&
    JSON.parse(import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG)

  const store = useMainStore()
  const router = useRouter()
  const { t } = useI18n()

  const state: {
    timer: any
    coins: string[]
    user: any
    domain: string
    choice_flg: string
  } = reactive({
    timer: 0,
    coins: Object.keys(coinFlag).filter((i) => coinFlag[i] === 'true'),
    user: undefined,
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN as string,
    choice_flg: ''
  })

  const countDayBefore = (base: number, average: number) => {
    return Math.abs(base - average)
  }

  onBeforeMount(() => {
    state.timer = 0
    clearInterval(state.timer)
  })

  onMounted(async () => {
    if (router.currentRoute.value.query?.success) {
      store.openMessage({
        type: 'success',
        text: t(
          `pages.${router.currentRoute.value.query?.success}.form.success`
        ),
        position: 'user'
      })
      const newQuery = omit(router.currentRoute.value.query, 'success')
      router.replace({ query: newQuery })
    }

    try {
      const { contents } = await getUserCheckInfo()
      state.choice_flg = contents?.choice_flg
      if (contents?.choice_flg && store.home?.account_status === '500') {
        router.push({ name: 'InfoConfirm' })
        return
      }
    } catch (error: any) {
      console.log(error)
    }
  })

  watch(
    () => store.home?.account_status,
    async (status: string) => {
      if (state.choice_flg && status === '500') {
        router.push({ name: 'InfoConfirm' })
        return
      }
    },
    { immediate: true, deep: true }
  )

  onBeforeRouteLeave((to, from, next) => {
    clearInterval(state.timer)
    next()
  })
</script>

<style lang="scss" scoped>
  $green: #00bd00;
  $red: #ff2525;

  .is-link {
    display: inline;
    color: #febd09;
    &::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f0c1';
      font-weight: 900;
      font-size: 0.7rem;
      padding: 0 0.2rem;
      opacity: 0.75;
    }
  }
  .account-status {
    margin: 2rem auto 1rem auto;
    border: 2px solid #febd09;
    background: #081828;
    padding: 1rem;
    font-size: 1rem;
    dl {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      max-width: 100%;
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
      &.unconfirmed {
        dd {
          color: $red;
        }
      }
      dt {
        width: 80%;
        text-align: left;
        font-weight: normal;
        a {
          color: #febd09;
          text-decoration: underline;
        }
      }
      dd {
        width: 20%;
        text-align: right;
        color: $green;
        font-weight: bold;
      }
    }
  }
</style>
<style lang="scss">
  #scroll_bnr {
    display: block;
  }
  @media (max-width: 1024px) {
    #scroll_bnr {
      left: calc(50% - 8.5rem);
    }
  }
</style>
