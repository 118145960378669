import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useMainStore } from '@/store/pinia'
import { siteMap } from '@/enum/siteMap'
import { SiteMapType } from '@/@types'
import { routesArr } from '@/router/routes'

const routes: Array<RouteRecordRaw> = routesArr

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              el: to.hash,
              behavior: 'smooth'
            }),
          100
        )
      })
    } else {
      if (savedPosition) {
        return savedPosition
      } else {
        return new Promise((resolve) => {
          setTimeout(
            () =>
              resolve({
                left: 0,
                top: 0,
                behavior: 'smooth'
              }),
            100
          )
        })
      }
    }
  }
})

const countSiteMap = (
  route: SiteMapType,
  user: {
    career?: string
    column_article?: string
    glossary?: string
  }
) => {
  const actions = () => {
    return new Map([
      [
        /^Career.*$/,
        () => {
          route.ja_hide = user.career === '0'
        }
      ],
      [
        /^(Beginners|Advanced|TrendCommentary).*$/,
        () => {
          route.ja_hide = user.column_article === '0'
        }
      ],
      [
        /^Glossary.*$/,
        () => {
          route.ja_hide = user.glossary === '0'
        }
      ],
      [
        /^Column.*$/,
        () => {
          route.ja_hide = user.glossary === '0' && user.column_article === '0'
          route.route = user.column_article === '0' ? 'Glossary' : 'Column'
        }
      ]
    ])
  }
  const action = [...actions()].filter(([key, value]) => key.test(route.route))
  action.forEach(([key, value]) => value.call(this))
}

router.beforeEach(async (to, from, next) => {
  const store = useMainStore()
  const actions = () => {
    return new Map([
      [
        /^Regulation.*$/,
        () => {
          window.location.href = `${
            import.meta.env.VITE_APP_S3_URL
          }resource/crypto_assets_spot_trading_service_terms_and_conditions_${
            store.language
          }.pdf?v=${import.meta.env.VITE_APP_PDF_VERSION}`
        }
      ],
      [
        /^Contract.*$/,
        () => {
          window.location.href = `${
            import.meta.env.VITE_APP_S3_URL
          }resource/service_manual_of_crypto_assets_spot_trading_document_delivered_prior_to_conclusion_of_a_contract_${
            store.language
          }.pdf?v=${import.meta.env.VITE_APP_PDF_VERSION}`
        }
      ],
      [
        /^BPTermsOfService.*$/,
        () => {
          window.location.href = `${
            import.meta.env.VITE_APP_S3_URL
          }resource/himapoi_service_terms_of_use_${store.language}.pdf?v=${
            import.meta.env.VITE_APP_PDF_VERSION
          }`
        }
      ],
      [
        /^PrivacyPolicy.*$/,
        () => {
          window.location.href = `${
            import.meta.env.VITE_APP_S3_URL
          }resource/personal_information_protection_policy_${
            store.language
          }.pdf?v=${import.meta.env.VITE_APP_PDF_VERSION}`
        }
      ],
      [
        /^Claim.*$/,
        () => {
          window.location.href = `${
            import.meta.env.VITE_APP_S3_URL
          }resource/our_business_operation_system_for_complaint_handling_and_dispute_resolution_${
            store.language
          }.pdf?v=${import.meta.env.VITE_APP_PDF_VERSION}`
        }
      ],
      [
        /^SecurityPolicy.*$/,
        () => {
          window.location.href = `${
            import.meta.env.VITE_APP_S3_URL
          }resource/calling_attention_to_fraud_prevention_measures_for_online_crimes_${
            store.language
          }.pdf?v=${import.meta.env.VITE_APP_PDF_VERSION}`
        }
      ]
    ])
  }
  const action = [...actions()].filter(([key, value]) =>
    key.test(to.name as string)
  )
  action.forEach(([key, value]) => value.call(this))

  const limitArr = [
    'Career',
    'Beginners',
    'Advanced',
    'TrendCommentary',
    'Glossary',
    'Column'
  ]
  !limitArr.includes(to.name as string) && next()

  if (store.siteMap.length === 0) {
    store.setSiteMap(siteMap)
  }
  if (Object.keys(store.userInfo).length === 0) {
    await store.checkLogin()
    const rawSiteMap = store.siteMap
    rawSiteMap.forEach((parent) => {
      countSiteMap(parent, store.userInfo)
      parent.children?.map((child) => {
        countSiteMap(child, store.userInfo)
      })
    })
    store.setSiteMap(rawSiteMap)
  }
  if (store.language !== 'ja' && to.meta.lang === false) {
    next({ name: '404' })
    return
  }

  limitArr.includes(to.name as string) && next()
})

router.afterEach((to) => {
  // eslint-disable-next-line
  document!.title = to?.meta?.title + ' | Coin Estate'
})

export default router
