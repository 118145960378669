<template>
  <div id="tv_chart_container" style="width: 99%; height: 100%"></div>
</template>

<script lang="ts" setup>
  import { onMounted, reactive, watch } from 'vue'
  import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
  import { getCoinRateHistory } from '@/api'
  import { useRouter } from 'vue-router'

  declare const TradingView: any

  const router = useRouter()
  const notIntegerScale = ['xlm', 'xrp']
  const props = defineProps({
    type: String,
    reflashtoken: String
  })

  const emit = defineEmits<{
    (e: 'onchange'): void
    (e: 'businessTime', flg: boolean): void
    (e: 'tokenClose'): void
  }>()

  const state: {
    raw_data: any
    isLink: boolean
    pricescale: number
    bars: {
      time: number
      open: number
      high: number
      low: number
      close: number
      volume: number
    }[]
    selected_trade_type: string
    selected_interval: string
    resolution: string
    crypto: string
    on_mounted: boolean
  } = reactive({
    raw_data: undefined as any,
    isLink: true,
    pricescale: 1,
    bars: [],
    selected_trade_type: 'ask',
    selected_interval: '1m',
    resolution: '1',
    crypto: '',
    on_mounted: false
  })

  let socket: any = null

  const connectWebsocket = () => {
    const interval = getIntervalType(state.resolution)
    const wsUrl = `${import.meta.env.VITE_APP_WS_RATE_URL}?id=${
      props.reflashtoken
    }&server=${state.crypto}&interval=${interval}`

    try {
      socket = new WebSocket(wsUrl)
      socket.onerror = function () {
        // onerror
        socket.close()
      }
      socket.onclose = function () {
        // onclose
        if (state.isLink) {
          connectWebsocket()
        } else {
          clearInterval(timer)
        }
      }

      const timer = setInterval(() => {
        if (socket.readyState === socket.OPEN) {
          const param = `{"action": "check","method": "reflash", "token": "${props.reflashtoken}"}`
          socket.send(param)
        }
      }, 4000)
    } catch (error) {
      console.error(error)
    }
  }

  const sortTime = (value: string, resolution: string) => {
    const newValue = value.replace(/-/g, '/')
    return resolution.toLowerCase() === '1d'
      ? new Date(newValue).getTime() + 1000 * 60 * 60 * 9
      : new Date(newValue).getTime()
  }

  const getIntervalType = (resolution: string) => {
    switch (resolution) {
      case '1':
        return '1m'
      case '1h':
        return '1h'
      case 'D':
        return '1d'
      case '1D':
        return '1d'
      case '60':
        return '1h'
      default:
        return '1m'
    }
  }

  const initTradingView = () => {
    const DataFeed = {
      /* mandatory methods for realtime chart */
      onReady: (cb: any) => {
        setTimeout(
          () =>
            cb({
              supported_resolutions: ['1', '60', 'D']
            }),
          0
        )
      },
      resolveSymbol: (
        symbolName: any,
        onSymbolResolvedCallback: any,
        onResolveErrorCallback: any
      ) => {
        const symbol_stub = {
          name: state.crypto?.toUpperCase(),
          description: '',
          type: 'crypto',
          session: '24x7',
          timezone: 'Asia/Tokyo',
          ticker: state.crypto?.toUpperCase(),
          minmov: state.pricescale,
          has_intraday: true,
          pricescale: 1 / state.pricescale,
          intraday_multipliers: ['1', '60', 'D'],
          supported_resolution: ['1', '60', 'D'],
          data_status: 'streaming'
        }
        setTimeout(function () {
          onSymbolResolvedCallback(symbol_stub)
        }, 0)
      },
      getBars: async (
        symbolInfo: any,
        resolution: string,
        periodParams: any,
        onHistoryCallback: any,
        onErrorCallback: any
      ) => {
        // console.log("=========getBars", symbolInfo, resolution, periodParams)
        window.tvWidget.activeChart().setResolution(state.resolution)
        if (!periodParams.firstDataRequest) {
          onHistoryCallback([], {
            noData: true
          })
        } else {
          if (resolution == state.resolution) {
            // console.log('=========getBars', symbolInfo, resolution, periodParams)

            const { contents } = await getCoinRateHistory(
              state.crypto,
              getIntervalType(state.resolution),
              state.selected_trade_type
            )
            const rawBars = contents.interval
            const bars = rawBars.map(
              (bar: [string, number, number, number, number]) => {
                return {
                  time: sortTime(bar[0], resolution),
                  open: bar[1],
                  high: bar[4],
                  low: bar[3],
                  close: bar[2],
                  volume: 0
                }
              }
            )

            if (bars.length > 0) {
              state.bars = bars
              onHistoryCallback(bars, {
                noData: false
              })
            } else {
              onHistoryCallback([], {
                noData: true
              })
            }
          }
        }
      },
      subscribeBars: function (
        symbolInfo: any,
        resolution: string,
        onRealtimeCallback: any,
        subscriberUID: any,
        onResetCacheNeededCallback: any
      ) {
        window.tvWidget
          .activeChart()
          .onIntervalChanged()
          .subscribe(null, (interval: string) => {
            state.resolution = interval
            if (state.selected_interval === getIntervalType(interval)) return
            state.selected_interval = getIntervalType(interval)
            // socket.close(1000, 'interval')
            // socket = undefined
            closeWebSocket()
            connectWebsocket()
            initTVOnReady()
          })

        // console.log('=========subscribeBars', symbolInfo, resolution)
        if (socket == null) {
          return
        }

        socket.onmessage = function (msg: any) {
          // onmessage
          const ws_data = JSON.parse(msg.data)
          const rawBars = ws_data[state.selected_trade_type]
          const crypto = state.crypto?.toLowerCase()
          if (rawBars) {
            onResetCacheNeededCallback()
            window.tvWidget.activeChart().resetData()
          } else if (ws_data[crypto]) {
            const rawBar = ws_data[crypto]
            const lastBar = state.bars[state.bars.length - 1]

            const test = new Date(rawBar.timestamp)
            const bar = {
              time: Date.parse(new Date(rawBar.timestamp).toString()),
              open: lastBar.open,
              close: rawBar[state.selected_trade_type],
              low: lastBar.low,
              high: lastBar.high,
              volume: 0
            }

            if (bar && state.bars[state.bars.length - 1].time < bar.time) {
              onRealtimeCallback(bar)
            }
          } else if (ws_data['contents']) {
            emit('businessTime', ws_data.contents?.business_time)

            if (ws_data.contents?.reflash) {
              emit('onchange')
            }
            if (!ws_data.contents?.access) {
              emit('tokenClose')
              closeWebSocket()
            }
          }
        }
      },
      unsubscribeBars: function (subscriberUID: any) {
        // console.log("=========unsubscribeBars", subscriberUID)
      }
    }

    const initTVOnReady = () => {
      let askButton: any = null
      let bidButton: any = null

      const widgetOptions = {
        debug: false,
        symbol: state.crypto?.toUpperCase(),
        datafeed: DataFeed,
        interval: '1',
        container: 'tv_chart_container',
        library_path: `${
          import.meta.env.VITE_APP_STATIC_BASE_URL
        }charting_library/`,
        timezone: 'Asia/Tokyo',
        locale: 'en',
        disabled_features: [
          'header_symbol_search',
          'header_compare',
          'header_screenshot',
          'timeframes_toolbar',
          'use_localstorage_for_settings'
          //'header_resolutions'
        ],
        enabled_features: [],
        client_id: 'test',
        user_id: 'public_user_id',
        fullscreen: false,
        autosize: true,
        theme: 'Dark'
      }

      Object.assign(window, { tvWidget: new TradingView.widget(widgetOptions) })

      const timer = setInterval(() => {
        if (window.tvWidget._ready) {
          if (window.tvWidget.activeChart().getAllStudies().length > 1) {
            clearInterval(timer)
            return
          }

          const clickButton = (button: any, type: string) => {
            if (button.classList.contains('is-active')) return
            state.selected_trade_type = type
            initTVOnReady()
          }

          window.tvWidget.headerReady().then(function () {
            if (bidButton) return

            bidButton = window.tvWidget.createButton()
            if (state.selected_trade_type === 'bid') {
              bidButton.style.color = 'rgb(255, 255, 255)'
              bidButton.style.fontWeight = 'bold'
              bidButton.classList.add('is-active')
            }

            bidButton.addEventListener('click', function () {
              clickButton(bidButton, 'bid')
            })
            bidButton.textContent = 'BID'
          })

          window.tvWidget.headerReady().then(function () {
            if (askButton) return
            askButton = window.tvWidget.createButton()
            if (state.selected_trade_type === 'ask') {
              askButton.style.color = 'rgb(255, 255, 255)'
              askButton.style.fontWeight = 'bold'
              askButton.classList.add('is-active')
            }
            askButton.addEventListener('click', function () {
              clickButton(askButton, 'ask')
            })
            askButton.textContent = 'ASK'
          })

          window.tvWidget.activeChart().removeAllStudies()

          window.tvWidget
            .activeChart()
            .createStudy('Moving Average', false, false, [5], null)
          window.tvWidget
            .activeChart()
            .createStudy('Moving Average', false, false, [15], null)
          window.tvWidget
            .activeChart()
            .createStudy('Moving Average', false, false, [30], null)
          // window.tvWidget
          //   .activeChart()
          //   .createStudy('MACD', false, false, [14, 30, 'close', 9])
          clearInterval(timer)
        }
      }, 500)
    }

    initTVOnReady()
  }

  const closeWebSocket = () => {
    state.isLink = false
    socket?.close()
  }

  onBeforeRouteUpdate((to, from) => {
    closeWebSocket()
  })

  onBeforeRouteLeave((to, from) => {
    closeWebSocket()
  })

  onMounted(async () => {
    state.crypto = router.currentRoute.value.params.crypto as string
    initTradingView()
    if (props.reflashtoken) {
      initTradingView()
      connectWebsocket()
    }
    state.on_mounted = true
  })

  watch(
    () => props.type,
    (newValue, oldValue) => {
      if (newValue) {
        state.crypto = newValue
        state.pricescale = notIntegerScale.indexOf(newValue) >= 0 ? 0.001 : 1
      }
    },
    { immediate: true }
  )

  watch(
    () => props.reflashtoken,
    (newValue, oldValue) => {
      if (state.on_mounted) {
        initTradingView()
        connectWebsocket()
      }
    },
    { immediate: true }
  )
</script>
