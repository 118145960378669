<template>
  <div class="corporation-confirm">
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_sei') }}</dt>
      <dd>{{ props.data?.name_sei || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_mei') }}</dt>
      <dd>{{ props.data?.name_mei || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_sei_kana') }}</dt>
      <dd>{{
        props.data?.name_sei_kana || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_mei_kana') }}</dt>
      <dd>{{
        props.data?.name_mei_kana || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.birthday') }}</dt>
      <dd>{{ props.data?.birthday || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.sex') }}</dt>
      <dd>{{
        findOption('sex', props.data?.sex) || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.post_no') }}</dt>
      <dd>{{ props.data?.post_no || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.addr1') }}</dt>
      <dd>{{ props.data?.addr1 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.addr2') }}</dt>
      <dd>{{ props.data?.addr2 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.addr3') }}</dt>
      <dd>{{ props.data?.addr3 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.country') }}</dt>
      <dd>{{
        findOption('country', props.data?.country) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.relationship') }}</dt>
      <dd>{{
        props.data?.relationship || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.peps') }}</dt>
      <dd>{{
        findOption('peps', props.data?.peps) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl v-if="props.data?.peps === '2'">
      <dt>{{ $t('pages.Corporation.form.peps_text') }}</dt>
      <dd>{{ props.data?.peps_text || $t('pages.Corporation.form.none') }}</dd>
    </dl>
  </div>
</template>

<script setup lang="ts">
  import { findOption } from '@/utils/tool'
  const props = defineProps({
    data: Object
  })
</script>
