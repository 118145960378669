<template>
  <div class="corporation-confirm">
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.bank_code') }}</dt>
      <dd>{{ props.data?.bank_name || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <!-- <dl>
      <dt>{{ $t('pages.Corporation.form.bank_other') }}</dt>
      <dd>{{ props.data?.bank_other || $t('pages.Corporation.form.none') }}</dd>
    </dl> -->
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.branch_code') }}</dt>
      <dd>{{
        props.data?.branch_name || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <!-- <dl>
      <dt>{{ $t('pages.Corporation.form.branch_other') }}</dt>
      <dd>{{ props.data?.branch_other || $t('pages.Corporation.form.none') }}</dd>
    </dl> -->
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.account_type') }}</dt>
      <dd>{{
        findOption('account_type', props.data?.account_type) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.account_no') }}</dt>
      <dd>{{ props.data?.account_no || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.account_holder_kana') }}</dt>
      <dd>{{
        props.data?.account_holder_kana || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
  </div>
</template>

<script setup lang="ts">
  import { findOption } from '@/utils/tool'
  const props = defineProps({
    data: Object
  })
</script>
