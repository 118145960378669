<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.BankSetting.title') }}</h1>

        <div class="form-box">
          <the-message v-if="state.errorMessage">{{
            state.errorMessage
          }}</the-message>
          <div v-else>
            <the-message v-if="state.successMessage" type="primary">{{
              state.successMessage
            }}</the-message>
          </div>

          <form>
            <ul class="form-normal">
              <li style="padding-top: 40px; padding-bottom: 0">
                <span class="search-operation">
                  <div class="search-operation-left">
                    <label class="madd_title"
                      >{{ t('pages.Corporation.form.bank_code') }}
                      <span class="required-notice">{{
                        $t('form.required')
                      }}</span>
                    </label>
                    <search-bank @bank_name="selectedBank"> </search-bank>
                  </div>

                  <span class="search-operation-right"
                    >{{ $t('pages.Corporation.step5.msg1') }}
                  </span>
                </span>
                <input
                  type="text"
                  required
                  readonly
                  disabled
                  :value="state.formJson.bank_name"
                  class="form-control valid"
                />

                <label
                  class="error"
                  style="margin-top: -10px"
                  v-if="state.validationError.bank"
                  >{{
                    $t('validation.required', {
                      content: t('pages.Corporation.form.bank_code'),
                      type: t('validation.type.select')
                    })
                  }}</label
                >
              </li>
              <li style="padding-bottom: 0">
                <span>
                  <label class="madd_title"
                    >{{ t('pages.Corporation.form.branch_code') }}
                    <span class="required-notice">{{
                      $t('form.required')
                    }}</span>
                  </label>
                </span>
                <input
                  type="text"
                  required
                  readonly
                  disabled
                  :value="state.formJson.branch_name"
                  class="form-control valid"
                />
                <label
                  class="error"
                  style="margin-top: -10px"
                  v-if="state.validationError.branch"
                  >{{
                    $t('validation.required', {
                      content: t('pages.Corporation.form.branch_code'),
                      type: t('validation.type.select')
                    })
                  }}</label
                >
              </li>
              <SchemaFormWithValidation
                :schema="state.schema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
                class="corporation-form"
                style="padding-top: 0"
              />
            </ul>

            <div id="div_id_twostep_code" v-if="state.twostep_check != '0'">
              <label for="id_twostep_code" class="col-form-label requiredField">
                <span class="font_size">{{
                  $t('pages.TwoStepCheck.form.issueLabel')
                }}</span>
                <button
                  class="uk-button uk-button-primary"
                  style="margin: 0 auto; display: block"
                  v-if="state.twostep_check !== '3'"
                  v-debounce="{
                    func: issueCode
                  }"
                  v-loading="{
                    loading: loading,
                    value: $t('pages.TwoStepCheck.form.issue')
                  }"
                  type="button"
                  >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                >
                <div v-else>&nbsp;</div>
              </label>

              <SchemaFormWithValidation
                :schema="state.schema2"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
                class="corporation-form"
              />
            </div>

            <button
              class="uk-button uk-button-primary uk-width-1-1 submit-button"
              v-debounce="{ func: submit }"
              v-loading="{
                loading: loading,
                value: $t('pages.PasswordSetting.button')
              }"
              id="register"
              type="button"
              >{{ $t('pages.PasswordSetting.button') }}</button
            >
          </form>
        </div>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import SearchBank from '@/components/SearchBank.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'
  import { twoStepIssue, getBankInfo, setBankAccount } from '@/api'
  import { addFormStyle, assignForm } from '@/utils/tool'

  const { t } = useI18n()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)

  const state: {
    init: boolean
    schema: any
    schema2: any
    formJson: any
    errorMessage: string
    successMessage: string
    validationError: {
      bank: boolean
      branch: boolean
    }
    twostep_check: string
  } = reactive({
    init: false,
    schema: {
      account_type: {
        component: 'Select',
        label: t('pages.Corporation.form.account_type'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.account_type,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.account_type'),
            type: t('validation.type.select')
          })
        )
      },
      account_no: {
        component: 'Text',
        label: t('pages.Corporation.form.account_no'),
        required: true,
        requiredStyle: 'form',
        type: 'number',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.account_no.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.account_no.maxLength
            })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.account_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.account_no.regex as RegExp,
            t('validation.input_error', {
              content: t('pages.Corporation.form.account_no')
            })
          )
      },
      account_holder_kana: {
        component: 'Text',
        label: t('pages.Corporation.form.account_holder_kana'),
        required: false,
        readonly: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid text-readonly',
        additionalNotesShow: true,
        // additionalNotes:
        //  '※金融機関にご登録されている口座名義カナをご入力ください。',
        validations: yup
          .string()
          .trim()
          .max(
            validator.account_kana.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.account_kana.maxLength
            })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.account_holder_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.account_kana.regex as RegExp,
            t('validation.katakana_error')
          )
      }
    },
    schema2: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    formJson: {},
    errorMessage: '',
    successMessage: '',
    validationError: {
      bank: false,
      branch: false
    },
    twostep_check: '0'
  })

  const issueCode = async () => {
    loading.value = true
    await twoStepIssue('db_check', null)
    loading.value = false
  }

  const selectedBank = (e: any) => {
    state.formJson.bank_name = e.bank?.name
    state.formJson.bank_code = e.bank?.code
    state.formJson.branch_name = e.branch?.name
    state.formJson.branch_code = e.branch?.code
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        (valid?.meta?.valid ||
          document.getElementsByClassName('error').length === 0) &&
        state.formJson.bank_code &&
        state.formJson.branch_code
      ) {
        loading.value = true
        try {
          await setBankAccount({
            contents: {
              ...state.formJson
            }
          })
          state.successMessage = t('error.success_modify')
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
        } finally {
          state.formJson.passcode = ''
          document.documentElement.scrollTop = 0
        }

        loading.value = false
      } else {
        state.validationError.bank = !state.formJson.bank_code
        state.validationError.branch = !state.formJson.branch_code
        document.documentElement.scrollTop =
          document.getElementsByTagName('form')[0].offsetTop
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const data = await getBankInfo('uncheck')
      state.formJson = Object.assign(
        state.formJson,
        assignForm(
          {
            ...state.schema,
            ...{
              bank_code: undefined,
              bank_name: undefined,
              branch_code: undefined,
              branch_name: undefined
            }
          },
          data.contents
        )
      )
      state.twostep_check = data.contents?.two_step_flg
      console.log(data.contents?.two_step_flg)
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped>
  .form-box {
    width: calc(100% - 2rem);
    max-width: 720px !important;
    margin: 0 auto;
  }
  .submit-button {
    margin-top: 0.5rem;
  }
</style>
