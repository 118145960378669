<template>
  <!-- article start -->
  <article id="contents">
    <div class="main_contents boxm">
      <div class="main_contents_text boxs">
        <div class="main_contents_head_sp"></div>
        <div class="btn_set" v-if="state.links">
          <template v-for="link in state.links.children" :key="link.route">
            <template
              v-if="
                (language === 'ja' && !link.ja_hide) ||
                (language === 'en' && !link.en_hide) ||
                (language === 'cn' && !link.cn_hide)
              "
            >
              <router-link
                :to="{ name: link.route, params: link.params }"
                class="btn_link"
                v-if="!link.link"
              >
                <i class="fas fa-chevron-right"></i>&nbsp;{{ link.name }}
              </router-link>
              <a :href="link.link" v-else class="btn_link">
                <i class="fas fa-chevron-right"></i>&nbsp;{{ link.name }}
              </a>
            </template>
          </template>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'

  const props = defineProps({
    route: String
  })

  const store = useMainStore()
  const { language, siteMap } = storeToRefs(store)

  const state: {
    links:
      | (SiteMapType & {
          children: {
            route: string
            params?: any
            name: string
            ja_hide?: boolean | null
            en_hide?: boolean | null
            cn_hide?: boolean | null
            link?: string
          }[]
        })
      | undefined
  } = reactive({
    links: undefined
  })

  watch(
    () => siteMap.value,
    (siteMap) => {
      const found = siteMap.find((i: SiteMapType) => i.route === props.route)
      if (found) {
        state.links = found as SiteMapType & {
          children: {
            route: string
            params?: any
            name: string
            ja_hide?: boolean | null
            en_hide?: boolean | null
            cn_hide?: boolean | null
            link?: string
          }[]
        }
      }
    },
    { immediate: true, deep: true }
  )
</script>
