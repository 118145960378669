<template>
  <transition name="the-message">
    <div class="modal-message" v-if="state.message">
      <div
        class="uk-alert the-message"
        v-if="!isClosed"
        :class="{
          'uk-alert-danger': state.type === 'error',
          'uk-alert-success': state.type === 'success',
          'uk-alert-primary': state.type === 'primary'
        }"
      >
        <p id="p_message">{{ state.message }}</p>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
  import { ref, reactive, watch } from 'vue'
  const isClosed = ref(false)

  interface Props {
    message?: any
  }

  const props = withDefaults(defineProps<Props>(), {
    message: {}
  })

  const state: {
    type: string
    message: string
  } = reactive({
    type: '',
    message: ''
  })

  watch(
    () => props.message as any,
    async (value: any, oldValue: any) => {
      if (value?.success != null) {
        state.type = 'success'
        state.message = value?.success
      }
      else if (value?.error != null) {
        state.type = 'error'
        state.message = value?.error
      }

      setTimeout(function () {
        state.message = ''
      }, 3000)
    },
    { deep: true }
  )
</script>

<style lang="scss" scoped>
  .uk-alert-success {
    border: 1px solid #0000ff !important;
    background: #101273 !important;
    color: white;
  }
  .uk-alert-danger {
    border: 1px solid #f0516e !important;
    background: #45152c !important;
    color: white;
  }

  .modal-message {
    width: 100%;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
  }

  .the-message {
    width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    overflow: auto;
    position: relative;
    text-align: center;
    top: 5rem;
    max-width: 500px;
  }
  @media screen and (max-width: 600px) {
    .the-message {
      width:unset;
      max-width: unset;
      margin: 0px 20px;
    }
  }
</style>

