<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.Invite.title') }}</h1>

        <div class="text-center">
          <p class="indent-1">{{ $t('pages.Invite.msg') }}</p>
        </div>

        <p class="uk-flex uk-flex-center">
          <QRCodeVue3
            :value="state.formJson.qrcode"
            :width="256"
            :height="256"
            :qrOptions="{
              typeNumber: '0',
              mode: 'Byte',
              errorCorrectionLevel: 'Q'
            }"
            :imageOptions="{
              hideBackgroundDots: true,
              imageSize: 0.5
            }"
            imgclass="img-qr"
            :dotsOptions="{ type: 'square', color: '#000' }"
            :cornersSquareOptions="{ type: 'square', color: '#000' }"
          />
        </p>

        <div class="text-center">
          <p id="id_invite_url" style="word-break: break-all">{{
            state.formJson.qrcode
          }}</p>
        </div>
        <div class="text-center">
          <button
            type="button"
            v-if="state.formJson.qrcode"
            v-debounce="{
              func: copy
            }"
            v-on:mouseover="hover"
            class="uk-button-primary"
            >{{ $t('pages.Invite.copy_url') }}</button
          >
          <p
            id="copySuccessMsg"
            style="color: forestgreen"
            v-if="state.formJson.clipboard"
            >{{ $t('pages.Invite.copy_url_msg') }}</p
          >
        </div>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import QRCodeVue3 from 'qrcode-vue3'
  import { getInvite } from '@/api'

  const state: {
    formJson: any
  } = reactive({
    formJson: {}
  })

  const hover = async () => {
    state.formJson.clipboard = ''
  }

  const copy = async () => {
    state.formJson.clipboard = state.formJson.qrcode
    navigator.clipboard.writeText(state.formJson.clipboard)
  }

  onMounted(async () => {
    try {
      const { contents } = await getInvite()
      state.formJson.qrcode = contents?.invite_url
    } catch (error: any) {
      state.formJson.errorMessage =
        error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped>
  .text-center {
    text-align: center;
  }
</style>
