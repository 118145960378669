<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="is-current br-tr-6 br-br-6"
          >９{{ $t('pages.Corporation.title.step9') }}</li
        >
      </ol>

      <form>
        <h1>{{ $t('pages.Corporation.step9.title') }}</h1>
        <p style="text-align: center">{{
          $t('pages.Corporation.step9.msg')
        }}</p>
        <router-link
          :to="{ name: 'Home' }"
          style="margin-top: 50px"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
          >{{ $t('pages.Corporation.step9.link') }}</router-link
        >
      </form>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  import { getFinished } from '@/api'

  const state = reactive({
    init: false,
    errorMessage: ''
  })

  onMounted(async () => {
    try {
      await getFinished()
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>
