<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxm">
        <div class="main_contents_text boxs">
          <h1 class="contents_title" v-if="state.article?.title">
            {{ state.article?.title }}
          </h1>

          <div class="formbox">
            <the-loading v-if="state.loading"></the-loading>

            <template v-if="state.article?.body">
              <div id="column_body" class="boxm" v-html="state.article?.body">
              </div>
              <div class="btn_set">
                <a @click="router.go(-1)" class="btn_link"
                  ><i class="fas fa-chevron-right"></i>&nbsp;{{
                    $t('pages.Notification.back')
                  }}</a
                ></div
              >
            </template>
          </div>
        </div>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { getNotificationPrivDetail } from '@/api/index'
  import User from '@/components/User.vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'

  interface Article {
    title: string
    body: string
    notification_unread: boolean
  }

  const state = reactive({
    article: undefined as Article | undefined,
    loading: true
  })

  const route = useRoute()
  const router = useRouter()
  const store = useMainStore()
  const { userInfo } = storeToRefs(store)

  const showNotificationDetail = async () => {
    const { contents } = await getNotificationPrivDetail(
      route?.params?.id as string
    )
    state.article = contents

    userInfo.value.notification_unread = contents?.notification_unread

    const breadcrumb: SiteMapType = {
      route: route?.name as string,
      name: contents.title
    }

    store.setBreadcrumb(breadcrumb)
    // eslint-disable-next-line
    document!.title = (state.article?.title as string) + ' | Coin Estate'
    state.loading = false
  }

  onMounted(async () => {
    await showNotificationDetail()
  })
</script>
<style lang="scss" scoped>
  .btn_set {
    margin: 4rem 15rem;
  }
</style>
