<template>
  <div class="main_contents_text boxs">
    <div class="bh4 mt_35 fc_y fw_m">{{
      $t('pages.Support.privacy_policy.title')
    }}</div>
    <div class="policy_inner">
      <p>{{ $t('pages.Support.privacy_policy.msg1') }}</p>
      <ul class="policy_list">
        <li>
          <span>1.</span>{{ $t('pages.Support.privacy_policy.msg2_1') }}
        </li>
        <li>
          <span>2.</span>{{ $t('pages.Support.privacy_policy.msg2_2') }}
        </li>
        <li>
          <span>3.</span>{{ $t('pages.Support.privacy_policy.msg2_3') }}
        </li>
        <li>
          <span>4.</span>{{ $t('pages.Support.privacy_policy.msg2_4') }}
        </li>
        <li>
          <span>5.</span>{{ $t('pages.Support.privacy_policy.msg2_5') }}
        </li>
      </ul>
      <p>
        {{ $t('pages.Support.privacy_policy.msg3') }}
      </p>
      <dl class="dl_table">
        <dt>{{ $t('pages.Support.privacy_policy.table.th1') }}</dt>
        <dd>{{ $t('pages.Support.privacy_policy.table.td1') }}</dd>

        <dt>{{ $t('pages.Support.privacy_policy.table.th2') }}</dt>
        <dd>{{ $t('pages.Support.privacy_policy.table.td2') }}</dd>

        <dt>{{ $t('pages.Support.privacy_policy.table.th3') }}</dt>
        <dd>{{ $t('pages.Support.privacy_policy.table.td3') }}</dd>

        <dt>{{ $t('pages.Support.privacy_policy.table.th4') }}</dt>
        <dd>{{ $t('pages.Support.privacy_policy.table.td4') }}</dd>

        <dt>{{ $t('pages.Support.privacy_policy.table.th5') }}</dt>
        <dd>
          {{
            $t('pages.Support.privacy_policy.table.email')
          }}&nbsp;&nbsp;&nbsp;&nbsp;cs@coinestate.co.jp<br />
          <router-link
            :to="{
              name: 'Inquiry',
              params: {
                inquiry_type_id: '15'
              }
            }"
            >{{ $t('pages.Support.privacy_policy.table.inquiry') }}</router-link
          >
          <br />
        </dd>
      </dl>
      <p>
        {{ $t('pages.Support.privacy_policy.msg4') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents1.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents1.msg1') }}<br />
        {{ $t('pages.Support.privacy_policy.subContents1.msg2') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents2.title')
      }}</div>
      <ul class="policy_list">
        <li>
          <span>1.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_1') }}
        </li>
        <li>
          <span>2.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_2') }}
        </li>
        <li
          ><span>3.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_3') }}</li
        >
        <li>
          <span>4.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_4') }}
        </li>
        <li>
          <span>5.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_5') }}
        </li>
        <li>
          <span>6.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_6') }}
        </li>
        <li>
          <span>7.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_7') }}
        </li>
        <li>
          <span>8.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_8') }}
        </li>
        <li>
          <span>9.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_9') }}
        </li>
        <li>
          <span>10.</span
          >{{ $t('pages.Support.privacy_policy.subContents2.msg1_10') }}
        </li>
      </ul>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents2.msg2') }}<br />
        {{ $t('pages.Support.privacy_policy.subContents2.msg3') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents3.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents3.msg1') }}<br />
        {{ $t('pages.Support.privacy_policy.subContents3.msg2') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents4.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents4.msg1') }}
      </p>
      <ul class="policy_list">
        <li
          ><span>1.</span
          >{{ $t('pages.Support.privacy_policy.subContents4.msg2_1') }}</li
        >
        <li>
          <span>2.</span
          >{{ $t('pages.Support.privacy_policy.subContents4.msg2_2') }}
        </li>
        <li>
          <span>3.</span
          >{{ $t('pages.Support.privacy_policy.subContents4.msg2_3') }}
        </li>
        <li>
          <span>4.</span
          >{{ $t('pages.Support.privacy_policy.subContents4.msg2_4') }}
        </li>
      </ul>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents5.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents5.msg1') }}<br />
        {{ $t('pages.Support.privacy_policy.subContents5.msg2') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents6.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents6.msg1') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents7.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents7.msg1') }}
      </p>
      <ul class="policy_list">
        <li>
          <span>1.</span
          >{{ $t('pages.Support.privacy_policy.subContents7.msg2_1') }}
        </li>
        <li>
          <span>2.</span
          >{{ $t('pages.Support.privacy_policy.subContents7.msg2_2') }}
        </li>
      </ul>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents8.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents8.msg1') }}
      </p>
      <ul class="policy_list">
        <li>
          <span>1.</span
          >{{ $t('pages.Support.privacy_policy.subContents8.msg2_1') }}
        </li>
        <li>
          <span>2.</span
          >{{ $t('pages.Support.privacy_policy.subContents8.msg2_2') }}
        </li>
        <li>
          <span>3.</span
          >{{ $t('pages.Support.privacy_policy.subContents8.msg2_3') }}</li
        >
      </ul>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents8.msg3') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents9.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents9.msg1') }}
      </p>
      <hr />

      <div class="bh4 mt_35 fc_y fw_m">{{
        $t('pages.Support.privacy_policy.subContents10.title')
      }}</div>
      <p>
        {{ $t('pages.Support.privacy_policy.subContents10.msg1') }}
      </p>
      <p style="color: #f44" v-if="language !== 'ja'"
        ><br />{{ $t('language_warning') }}</p
      >
      <hr />
      <div
        class="pp_set"
        v-html="$t('pages.Support.privacy_policy.history')"
      ></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
