<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.TwoStepSetting.title') }}</h1>
        <div class="two-factor_center1">
          <form class="w60 mr20p ml20p tbw-w100 tbw-mr0p tbw-ml0p formbox">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>

            <ul class="tbw-pl8" style="margin-top: 0">
              <li v-for="option in options.two_step_setting" :key="option.key">
                <label :for="option.value">
                  <span class="id_code_wrap">
                    <input
                      type="checkbox"
                      v-model="two_step_setting"
                      name="transaction_objective"
                      :value="option.key"
                      :id="option.value"
                      class="uk-checkbox"
                      :disabled="readonlySettings.includes(option.key)"
                      :readonly="readonlySettings.includes(option.key)"
                    />
                    <span class="code_span"
                      >{{ option.value
                      }}<span
                        v-if="readonlySettings.includes(option.key)"
                        class="required-notice"
                        >{{ $t('form.required') }}</span
                      ></span
                    >
                  </span>
                </label>
              </li>
              <li>
                <label class="two_step_auth_way mb20"
                  ><span class="font_size">{{
                    $t('pages.TwoStepSetting.form.method')
                  }}</span></label
                >
              </li>
              <li v-for="option in options.two_step_method" :key="option.key">
                <label :for="`two_step_auth_flg_${option.key}`">
                  <input
                    type="radio"
                    :id="`two_step_auth_flg_${option.key}`"
                    name="two_step_auth_flg"
                    :value="option.key"
                    class="uk-radio"
                    v-model="two_step_auth_flg"
                  /><span>{{ option.value }}</span>
                </label>
              </li>

              <div class="two-factor_left">
                <p class="notice addition">{{
                  $t('pages.TwoStepSetting.warning')
                }}</p>
                <button
                  class="uk-button uk-button-primary btn_size"
                  style="margin: 0 auto; display: block; max-width: 200px"
                  v-debounce="{
                    func: confirm
                  }"
                  v-loading="{
                    loading: loading,
                    value: $t('pages.TwoStepSetting.form.submit')
                  }"
                  type="button"
                  >{{ $t('pages.TwoStepSetting.form.submit') }}</button
                >
              </div>
            </ul>
          </form>
        </div>
      </div>

      <the-modal
        v-if="state.modal"
        id="modal-twostep"
        hasClose
        @close="state.modal = false"
      >
        <section class="modal-inner">
          <h1 v-html="$t('pages.TwoStepSetting.confirm.title')"></h1>

          <dl class="modal_dl">
            <dt id="div_id_inquiry_title">
              <label for="id_inquiry_title" class="col-form-label">
                {{ $t('pages.TwoStepSetting.confirm.method') }}
              </label>
            </dt>
            <dd>
              {{ state.two_step_auth_flg_disp }}
            </dd>
            <dt
              id="div_id_inquiry_title"
              v-if="state.formJson?.two_step_auth_flg !== '3'"
            >
              <label for="id_inquiry_title" class="col-form-label">
                {{ $t('pages.TwoStepSetting.confirm.recipient') }}
              </label>
            </dt>
            <dd v-if="state.formJson?.two_step_auth_flg === '1'">
              {{ state.email }}
            </dd>
            <dd v-if="state.formJson?.two_step_auth_flg === '2'">
              {{ state.mobile }}
            </dd>
          </dl>

          <div
            id="div_id_twostep_code"
            v-if="state.formJson?.two_step_auth_flg === '3'"
          >
            <QRCodeVue3
              :value="state.qrcode"
              :width="256"
              :height="256"
              :qrOptions="{
                typeNumber: '0',
                mode: 'Byte',
                errorCorrectionLevel: 'Q'
              }"
              :imageOptions="{
                hideBackgroundDots: true,
                imageSize: 0.5
              }"
              imgclass="img-qr"
              :dotsOptions="{ type: 'square', color: '#000' }"
              :cornersSquareOptions="{ type: 'square', color: '#000' }"
            />
          </div>

          <div
            id="div_id_twostep_code"
            v-if="state.formJson?.two_step_auth_flg !== '3'"
          >
            <label for="id_twostep_code" class="col-form-label requiredField">
              <span class="font_size">{{
                $t('pages.TwoStepCheck.form.issueLabel')
              }}</span>
              <button
                class="uk-button uk-button-primary"
                style="margin: 0 auto; display: block"
                v-debounce="{
                  func: issueCode
                }"
                v-loading="{
                  loading: loading,
                  value: $t('pages.TwoStepCheck.form.issue')
                }"
                type="button"
                >{{ $t('pages.TwoStepCheck.form.issue') }}</button
              >
            </label>
          </div>
          <SchemaFormWithValidation
            :schema="state.schema"
            :model="state.formJson"
            useCustomFormWrapper
            ref="form"
          />

          <button
            class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.TwoStepStatus.form.submit')
            }"
            type="button"
            >{{ $t('pages.TwoStepStatus.form.submit') }}</button
          >
        </section>
      </the-modal>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import QRCodeVue3 from 'qrcode-vue3'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'
  import { useRouter } from 'vue-router'
  import TheModal from '@/components/TheModal.vue'
  import {
    getTwoStepSetting,
    twoStepIssue,
    twoStepVerify,
    twoStepUpdate,
    getTwoStepGoogleQrCode
  } from '@/api'

  const { t } = useI18n()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)
  const router = useRouter()

  const readonlySettings = ['01', '02', '08', '09']
  const postSettings = ['03', '05', '06', '07']
  const two_step_setting = ref<string[]>([])
  const two_step_auth_flg = ref<string>('1')
  const two_step_recipient = ref('')

  const state: {
    formJson: any
    schema: any
    email: string
    mobile: string
    qrcode: string
    modal: boolean
    two_step_auth_flg_disp: string
    isIssued: boolean
    errorMessage: string
  } = reactive({
    formJson: {},
    schema: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    email: '',
    mobile: '',
    qrcode: '',
    modal: false,
    two_step_auth_flg_disp: '',
    isIssued: false,
    errorMessage: ''
  })

  const confirm = async () => {
    const valid = form.value?.validate()
    const selected_two_step_auth_flg = two_step_auth_flg.value

    state.formJson.two_step_auth_flg = selected_two_step_auth_flg
    state.two_step_auth_flg_disp = options.two_step_method.find(
      (i) => i.key === selected_two_step_auth_flg
    ).value

    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const issueCode = async () => {
      loading.value = true
      await twoStepIssue('setting', state.formJson?.two_step_auth_flg)
      state.isIssued = true
      loading.value = false
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
        loading.value = true

        const twostep_auth_list = []
        postSettings.forEach((code) => {
          const value = two_step_setting.value.includes(code) ? '1' : '0'
          twostep_auth_list.push({ code: code, value: value })
        })

        try {
          const { contents } = await twoStepUpdate(
            state.formJson.passcode,
            twostep_auth_list,
            two_step_auth_flg.value
          )
          loading.value = false
          router.push({
            name: 'TwoStepStatus',
            query: {
              success: contents?.message
            }
          })
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          loading.value = false
          state.modal = false
        }
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getTwoStepSetting()
      two_step_setting.value = contents?.two_step_setting

      if (contents?.two_step_auth_flg === '0') {
        two_step_auth_flg.value = '1'
      } else {
        two_step_auth_flg.value = contents?.two_step_auth_flg
      }

      state.email = contents?.email
      state.mobile = contents?.mobile
    } catch (error: any) {
      console.log(error?.message)
      if (error?.message === 'error_modification_validity_check') {
        router.push({
          name: 'TwoStepStatus',
          query: {
            error: error?.message
          }
        })
      }

      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => two_step_auth_flg.value,
    async (value: string, oldValue: string) => {
      if (value === '3') {
        const { contents } = await getTwoStepGoogleQrCode()
        state.qrcode = contents?.qr_code
      }
    }
  )
</script>
<style lang="scss" scoped>
  @use '@/static/styles/user.scss';
</style>
