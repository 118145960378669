<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <div class="main_contents_text boxs lh_175">
        <div class="faq_inner">
          <ul class="faq_inner_menu">
            <li v-for="tag in tags" :key="tag.name">
              <router-link :to="`#${tag.hash}`"
                ><i class="fas fa-chevron-right"></i>
                {{ tag.name }}</router-link
              >
            </li>
          </ul>
          <div class="bh4 fw_m fc_y mt_35" id="open">口座開設について</div>
          <dl class="wrap">
            <faq-item question="口座開設はどのように行えばいいですか？">
              個人口座開設方法につきましては、<router-link
                :to="{ name: 'AccountOpening' }"
                >口座開設の流れ</router-link
              >をご確認ください。<br />
              法人口座開設方法につきましては、<a
                :href="`${s3Url}resource/flow_of_opening_a_corporate_account.pdf?v=3`"
                >法人口座開設の流れ</a
              >をご確認ください。
            </faq-item>
            <faq-item
              question="口座開設（個人口座・法人口座）を行うのに費用はかかりますか？"
              >当社の口座は無料で開設することができます。</faq-item
            >
            <faq-item
              question="口座開設時に「このメールアドレスは既に登録されています」と表示されます"
              >既にお申込み済みのメールアドレスになります。<br />
              同じメールアドレスを使用しての複数回口座開設申し込みはできません。<br />
              ※本口座はお一人様１口座となります。<br />
              &nbsp;<br />
              パスワードを失念されている場合は、「<router-link
                :to="{ name: 'PasswordReset' }"
                >パスワードを忘れた方はこちら</router-link
              >」から再設定をお願いいたします。</faq-item
            >
            <faq-item
              question="口座開設をするにあたって、本人確認書類は何が使用できますか？"
              >ご使用いただける本人確認書類の詳細につきましては、<router-link
                :to="{ name: 'RequiredDocuments' }"
                >本人確認書類について</router-link
              >‌をご確認ください。</faq-item
            >
            <faq-item question="口座開設に年齢制限はありますか？"
              >口座開設は、満20歳以上75歳未満の方とさせていただきます。</faq-item
            >
            <faq-item question="海外に在住している場合、口座開設はできますか？"
              >日本国籍の方でも居住地（住所）が海外の場合、口座開設をすることはできません。</faq-item
            >
            <faq-item
              question="現在の住所が本人確認書類の住所と異なる場合、口座開設はできますか？"
              >お客様が登録された住所と本人確認書類に記載された住所が異なる場合は、口座開設はできません。</faq-item
            >
            <faq-item question="証券外務員に取引制限はありますか？"
              >現在、暗号資産は、金融商品取引法上の「有価証券」に該当しないとされており、また「協会員の従業員に関する規則」の規制対象外ですので、証券外務員による取引が可能です。(2022年8月現在)<br />
              &nbsp;<br />
              但し、将来にわたり暗号資産取引が可能であることを保証するものではありません。<br />
              今後適用される法規制及び自主規制を、ご確認いただきますようお願いいたします。<br />
              また、規定はお勤め先によって異なりますので、お勤め先のコンプライアンス部門等へご確認ください。</faq-item
            >
            <faq-item question="口座開設の申込み受付確認メールが届きません"
              >確認メールが届かない場合は、迷惑メールのフィルタリングをご利用で無い場合でも、迷惑メールフォルダに振り分けられている可能性がございます。<br />
              また、迷惑メール対策等でURL付メールの受信拒否設定・ドメイン指定受信等を設定されている場合は設定を解除していただくか、「info@coinestate.co.jp」からの受信を有効にしていただきますようお願いいたします。<br />
              &nbsp;<br />
              携帯電話のメールアドレスをご利用の場合、メール受信許可設定方法につきましては、キャリア（通信事業者）毎に異なります。各キャリアへお問い合わせくださいますようお願いいたします。
            </faq-item>
            <faq-item
              question="申込画面で本人確認書類上の漢字の入力ができない場合はどうすればよいですか？"
              >お申込画面のお名前をご入力する際、漢字の字体が異体字(旧字等)で、入力ができない場合は、ご入力可能な範囲の漢字で代用の上、ご登録ください。<br />
              &nbsp;<br />
              なお、上記の理由により本人確認書類とご入力いただいたお名前の漢字表記が異なる場合は、弊社より確認のご連絡をさせていただくことがございます。
            </faq-item>
            <faq-item question="本人確認書類がアップロードできません"
              >アップロードいただける画像のファイル形式は下記の通りです。<br />
              &nbsp;<br />
              .bmp<br />.gif<br />.jpg<br />.jpeg<br />.png<br />.pdf<br />
              &nbsp;<br />

              アップロードファイル名につきましては、『
              .（ドット）』は使用しないようお願いいたします。<br />
              &nbsp;<br />

              また、画像ファイルの容量は最大5MBまでです。<br />
              &nbsp;<br />
              なお、iPhoneで撮影された画像が、形式「.jfif」や「.heic」になり、アップロード時にエラーになる場合がございます。<br />
              形式を「.jpeg」にしていただくか、画像ファイルの容量を2MB以下にしたうえでアップロードをお願いいたします。<br />
              <br />
              問題が解決しない場合は、お手数ではございますが、<router-link
                :to="{ name: 'Inquiry' }"
                >お問い合わせページ</router-link
              >より下記の内容をご記載のうえ、ご連絡くださいますようお願いいたします。<br />
              &nbsp;<br />
              ・ご利用環境（PC、スマートフォン等）<br />
              ・ご利用ブラウザ（Chrome、Safari、Microsoft Edge等）<br />
              ・ご利用OS（Windows 10、Mac OS X等）<br />
              ・画像について（アップロードファイルの形式および容量）<br />
              ・具体的な症状（表示されるエラーメッセージ等の画面スクリーンショットと合わせてお送りいただけると幸いです。）
            </faq-item>
            <faq-item
              question="携帯電話にSMS（ショートメッセージ）認証メッセージが届きません"
              >以下の可能性が考えられます。<br />
              &nbsp;<br />
              ・電話番号が間違っている<br />ご登録いただいた電話番号が間違っていますとSMSが届きませんのでご確認ください。<br />
              間違った電話番号を登録してしまった場合は、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >より登録情報変更のご連絡をください。<br />
              &nbsp;<br />
              ・SMSの受信ができない設定になっている<br />※一度、お客様自身で登録いただいた電話番号へSMSの送受信をお試しください。届かない場合は、設定やSIMカードの契約内容を再確認してください。詳細につきましては、各キャリア（通信事業者）へお問い合わせください。<br />
              &nbsp;<br />
              ・受信拒否設定にしている<br />SMSの受信拒否設定をされている場合がございます。<br />キャリア（通信事業者）の迷惑メール対策機能または、ご利用中の携帯電話自体でSMSを受信しない設定になっていないかご確認ください。<br />※SMSの受信拒否設定はお客様がご利用のキャリアや機種によって異なります。受信拒否設定については各キャリアへお問い合わせいただくか、ご利用の機種の説明書等をご確認ください。<br />
              &nbsp;<br />
              ・機内モードにしている<br />キャリアの電話回線を使ったものであるため、機内モードがオンになっていないかご確認ください。<br />
              &nbsp;<br />
              ・Wi-Fiを使っている<br />キャリアの電話回線を使ったものであるため、Wi-Fiをご利用中の場合は電話が利用可能な状況かご確認ください。<br />
              &nbsp;<br />
              ・SMSに対応していない<br />キャリアの電話回線を使ったものであるため、ご利用のキャリアがSMSに対応していない場合、SMS認証はご利用いただけません。また、海外携帯電話やPHSをご利用の場合は、SMSに対応していないことがありますので、製造メーカーまたは各キャリアにお問い合わせくださいますようお願いいたします。<br />
              &nbsp;<br />
              ・モバイルデータ通信をオフにしている<br />キャリアの電話回線を使ったものであるためモバイルデータ通信がオンである必要がございます。<br />
              &nbsp;<br />
              ・携帯電話の容量不足<br />携帯電話の空き容量が不足しているとSMSを受信できません。<br />不要なデータを削除し、携帯電話の容量を空けて再度SMS認証を行って下さい。<br />
              &nbsp;<br />
              ・携帯電話の電波状況<br />電波が不安定な場所であったり、一時的に電波が悪くなってしまっている場合、SMSは受信できないことがございます。<br />電波が弱い場合、場所を移動していただくか、しばらく経ってからお試しください。<br />
              &nbsp;<br />
              ・ネットワーク上の問題により送信が遅れている<br />ネットワーク上の混雑状況によってSMSを送信するまでに時間がかかる場合がございます。時間を空けてお試しください。<br />
              &nbsp;<br />
              上記をお試し頂いたうえでSMSが届かない場合は、端末を再起動していただくと受信できる場合がございます。<br />
              &nbsp;<br />
              問題が解決しない場合は、お手数ですが<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりご連絡ください。
            </faq-item>
            <faq-item question="携帯認証ができません"
              >「入力されたコードが正しくありません」と表示される場合、発行した認証コードの有効期限が切れている場合があります。<br />
              &nbsp;<br />
              再度「認証コード発行」ボタンを押して、新しい二段階認証コードをご入力ください。
            </faq-item>
            <faq-item
              question="「取引時確認コード郵送」メールを受け取ってからどのくらいの日数で届きますか？"
              >口座開設審査完了後、ご登録の住所宛に取引時確認コードを郵送にて送付いたします。<br />
              発送手続きが完了次第「取引時確認コード郵送」のメールをお送りいたします。<br />
              &nbsp;<br />
              お届け目安は「取引時確認コード郵送」メール送信日の2～4日後です。<br />
              ※一部離島や遠距離の場合お届けに時間を要する場合がございます。
            </faq-item>
            <faq-item
              question="口座開設申込時の住所と異なる住所に取引時確認コードを郵送してもらうことはできますか？"
              >郵便物の郵送は本人確認を伴っておりますので、転送を含めて、口座開設申し込み時にご入力いただいた住所と異なる住所に郵送することはできません。</faq-item
            >
            <faq-item
              question="郵送される取引時確認コードの受取日時指定はできますか？"
              >お受取日時の指定はできません。</faq-item
            >
            <faq-item
              question="取引時確認コードを受取れなかったのですがどうすればいいですか？"
              >取引時確認コードのお届け時に不在であった場合は、一定期間最寄りの郵便局にて保管されます。不在の際にポストに投函される「ご不在連絡票」をご確認の上、再配達等でご対応頂きますようお願いいたします。<br />
              なお、転送はできませんのでご注意下さい。<br />
              &nbsp;<br />
              また、郵便局の保管期限を経過してしまった場合は、お手数ではございますが、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりご連絡ください。
            </faq-item>
            <faq-item
              question="郵送で取引時確認コードが届いたのですがどうすればいいのですか？"
              >1.<router-link :to="{ name: 'Login' }">マイページ</router-link
              >にログインしてください。<br />
              &nbsp;<br />
              2.「取引時確認コードのご確認」をクリックしてください。<br />
              &nbsp;<br />
              3.郵送で届いた取引時確認コードを入力して登録をクリックしてください。<br />
              &nbsp;<br />
              4.取引時確認コードの入力が完了しますと、口座開設手続きが完了します。
            </faq-item>
          </dl>

          <div class="bh4 fw_m fc_y mt_35" id="login">ログインについて</div>
          <dl class="wrap">
            <faq-item
              question="「パスワード変更に失敗しました。もう一度実行してください。」と表示されました。"
              subQuestion="どうすれば良いですか？"
              >下記のようなケースが考えられます。ご参照いただき、再度お試しください。<br />
              &nbsp;<br />
              1. パスワードに余分なスペースが入っているケース<br />
              コピー＆ペーストをした場合、前後に余分なスペースが入ることがあります。<br />
              スペースが入らないようにご注意の上、コピー＆ペーストしていただくか、キーボードを利用して正確にご入力ください。<br />
              &nbsp;<br />
              2.キーボード入力する際、文字自体が誤っているケース<br />
              &nbsp;<br />
              間違えやすい例<br />
              ・o(オー)と0(ぜろ)<br />
              ・l(エル)と1(いち)<br />
              &nbsp;<br />
              3. アルファベットの大文字と小文字を誤っているケース<br />
              大文字(ABCDE…)と小文字(abcde…)はパスワードとして使用する場合、別の文字として認識されます。<br />
              大文字と小文字が異なると同じパスワードと認識されませんご注意下さい。
            </faq-item>
            <faq-item
              question="取引画面にログインするのに、間違って入力してしまいロックがかかってしまいました"
              >当社にてログインのロック解除を行いますので、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >からご連絡ください。</faq-item
            >
            <faq-item question="ログインパスワードを忘れてしまいました"
              >パスワードの初期化、および再設定のお手続きは「<router-link
                :to="{ name: 'PasswordReset' }"
                >パスワードを忘れた方はこちら</router-link
              >」からお願いいたします。</faq-item
            >
            <faq-item
              question="ログインパスワードを設定する際に、文字数や使用できる文字の制約はありますか？"
              >文字数は14桁以上128桁以下で設定してください。文字は通常の英数字（半角）をご使用ください。<br />
              &nbsp;<br />
              また、セキュリティの観点から、数字と英字の大文字と小文字を組み合わせて設定してください。
            </faq-item>
            <faq-item question="reCAPTCHAの画像認証がうまくできません"
              >reCAPTCHAとは、悪質なプログラムによるサービスへの侵入を防ぐ仕組みです。<br />
              ログイン時にreCAPTCHA画面が表示される場合は、指示された画像をご選択いただく必要があります。<br />
              &nbsp;<br />
              reCAPTCHA画面例<br />
              <img
                :src="`${s3Url}img/img_faq_recaptcha01.${language}.png?v=1`"
                alt="reCAPTCHA画像サンプル"
              /><br />
              &nbsp;<br />
              reCAPTCHAの画像認証に失敗してしまう場合、以下のいずれかの方法をお試しください。(Windowsの場合)<br />
              ※macOSをご使用の場合推奨ブラウザはSafariのみとなりますのでご注意ください。<br />
              &nbsp;<br />
              1.ご利用のブラウザ内でGoogleアカウントにサインインしていない場合、ご自身のGoogleアカウントにサインインしてログインをお試しください。<br />
              &nbsp;<br />
              2.ご利用のブラウザ内でGoogleアカウントにサインインしている場合、一度ログアウトしていただき、別のGoogleアカウントでサインインしてログインをお試しください。<br />
              &nbsp;<br />
              3.Windows10をご使用の場合はMicrosoft EdgeまたはGoogle
              Chromeにブラウザを切り替えてお試しください。
            </faq-item>
            <faq-item question="二段階認証とは何ですか？"
              >ID・パスワードによる認証に加え、別の方法により本人確認を行う認証方法です。<br />
              &nbsp;<br />
              二段階認証を利用することで、第三者がなりすましでサービスを利用することを防ぐことができます。<br />
              &nbsp;<br />
              二段階認証の方法は「メール」「SMS（ショートメッセージサービス）」「スマホアプリ（Google
              Authenticator）」の3つからご選択いただけます。<br />
              &nbsp;<br />
              初期設定は「メール」での二段階認証となっております。
            </faq-item>
            <faq-item question="二段階認証の方法を変更したい"
              >二段階認証設定方法<br />
              &nbsp;<br />
              1.マイページにログイン<br />
              2.メニューの「設定」→「二段階認証」をクリック<br />
              3.二段階認証設定変更の為、「コード発行」ボタンをクリック<br />
              4.届いた「認証コード」を入力して「設定変更」ボタンをクリック<br />
              5.「メール」「SMS（ショートメッセージサービス）」<br />
              「スマホアプリ（Google
              Authenticator）」から任意の二段階認証の方法を選択<br />
              6.「確認画面へ」ボタンをクリック<br />
              7.画面指示に従って任意の方法で二段階認証を行う<br />
              &nbsp;<br />
              設定が完了すると「二段階認証を正常に設定しました」のメッセージが画面上部に表示されます。<br />
              &nbsp;<br />
              「スマホアプリ（Google
              Authenticator）」で二段階認証を行う場合は、予めご使用のスマートフォンにGoogle
              Authenticatorをインストールしておいてください。<br />
              &nbsp;<br />
              <a
                href="https://itunes.apple.com/jp/app/google-authenticator/id388497605?mt=8"
                ><img
                  src="@static/img/badge_app_store_s.png"
                  alt="app store"
                  height="24px" /></a
              >&nbsp;
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=ja&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                ><img
                  alt="Google Play で手に入れよう"
                  src="@static/img/badge_google_play_s.png"
                  height="24px" /></a
            ></faq-item>
            <faq-item
              question="二段階認証をスマホアプリにしたいのですがどうすればいいですか？"
              >スマホアプリ（Google
              Authenticator）を用いた二段階認証は以下の手順で設定いただけます。<br />
              &nbsp;<br />
              1.Google AuthenticatorをApp StoreもしくはGoogle
              Playからダウンロードする<br />
              &nbsp;<br />
              <a
                href="https://itunes.apple.com/jp/app/google-authenticator/id388497605?mt=8"
                ><img
                  src="@static/img/badge_app_store_s.png"
                  alt="app store"
                  height="24px" /></a
              >&nbsp;
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=ja&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                ><img
                  alt="Google Play で手に入れよう"
                  src="@static/img/badge_google_play_s.png"
                  height="24px" /></a
              ><br />
              &nbsp;<br />
              2.Google
              Authenticatorを起動し「設定を開始」から「バーコードをスキャン」を選択し、カメラへのアクセスを許可する
              <!--（説明画像）--><br />
              &nbsp;<br />
              3.二段階認証設定画面においてスマホアプリを選択後、表示されているQRコードを読み取る<br />
              &nbsp;<br />
              4.アプリ上に表示された6桁の認証コードを入力する<br />
              &nbsp;<br />
              以上、4ステップでGoogle
              Authenticatorを用いた二段階認証設定は完了です。<br />
              &nbsp;<br />
              二段階認証でログインの際はGoogle
              Authenticatorを起動していただき、Coin
              Estateとして表示された6桁の認証コードを入力していただくことで、サービスをご利用いただくことができます。
            </faq-item>
            <faq-item
              question="アプリ(Google Authenticator)での二段階認証がよくわかりません"
              >QRコードをアプリにて読み取っていただき、表示されている6桁のコードを入力していただく認証方法です。<br />
              確認コードは30秒ごとに変化しますので二段階認証の都度ご確認の上、ご入力ください。
            </faq-item>
            <faq-item
              question="Google Authenticatorで二段階認証を設定しています。スマートフォンの機種変更の際、事前に行うことはありますか？"
              >ログイン時に二段階認証を設定されている場合は、二段階認証の方法を「メールで二段階認証を行う」または「SMSで二段階認証を行う※」に二段階認証の方法を変更してください。<br />
              &nbsp;<br />
              ※機種変更時に電話番号の変更がある場合は「SMSで二段階認証を行う」ではなく「メールで二段階認証を行う」に設定変更してください。<br />
              &nbsp;<br />
              なお、Google
              Authenticatorを用いて二段階認証を設定する際に表示されるQRコード画像のバックアップを取得されている場合は、上記手順は不要です。<br />
              機種変更後にGoogle
              Authenticatorを起動して、バックアップしたQRコード画像を読み込んでください。
            </faq-item>
            <faq-item
              question="ログインするときに二段階認証を設定していたのですが、機種変更をしたらログインできなくなりました"
              >お手数ですが、二段階認証の初期化をいたしますので、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >からご連絡いただく必要があります。
            </faq-item>
          </dl>

          <div class="bh4 fw_m fc_y mt_35" id="transfer"
            >入出金･振替について</div
          >
          <dl class="wrap">
            <faq-item question="銀行振込で入金したい"
              >銀行振込によるご入金方法は、以下の手順の通りです。<br />
              &nbsp;<br />
              1.マイページのメニューより「入金・入庫」から「日本円銀行振込」をクリック<br />
              （スマートフォンをご利用の場合は画面上部右のメニューボタン「≡」のマークのアイコンをタップして選択）<br />
              &nbsp;<br />
              2.日本円銀行振込ページに記載されている銀行口座に入金を行う<br />
              ※お客様の登録情報と振込人名義が同一である必要がございますので、ご確認の上ご入金ください
            </faq-item>
            <faq-item
              question="日本円銀行振込の際に、「振込口座確認出来ず」と表示され送金することができません"
              >下記のいずれかの場合がございます。ご確認ください。<br />
              &nbsp;<br />
              ・入力いただいた銀行情報に間違いがある可能性が考えられます<br />
              支店名、預金科目（普通預金）、口座番号、口座名義を再度ご確認ください<br />
              &nbsp;<br />
              【振込先銀行口座の確認方法】<br />
              1. 当社のホームページから、マイページにログインする<br />
              2. メニューの「入金・入庫」をクリックする<br />
              （スマートフォンをご利用の場合は画面上部右のメニューボタン「≡」のマークのアイコンをタップして選択）<br />
              3.「日本円銀行振込」をクリックし振込先口座を確認する<br />
              ※振込先銀行口座番号はお客様毎に異なります。<br />
              &nbsp;<br />
              ・ご指定の金融機関が口座名義人名の確認ができない金融機関である可能性が考えられます<br />
              例
              信託銀行（SMBC信託銀行を除く）、農業協同組合、全国キャッシュサービス（MICS）非提携金融機関（外国銀行など）<br />
              &nbsp;<br />
              ・ご指定の金融機関で口座名義人名の確認ができない時間帯である可能性が考えられます<br />
              &nbsp;<br />
              問題が解決しない場合は、お手数ですが<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりご連絡ください。
            </faq-item>
            <!-- 
            <faq-item question="クイック入金で入金したい"
              >クイック入金によるご入金方法は、以下の手順の通りです。<br />
              &nbsp;<br />
              1.マイページのメニューより「入金・入庫」から「日本円クイック入金」をクリック<br />
              （スマートフォンをご利用の場合は画面上部右のメニューボタン「≡」のマークのアイコンをタップして選択）<br />
              &nbsp;<br />
              2.金融機関を「検索」をクリックして選択<br />
              &nbsp;<br />
              3.入金金額を設定し、「入金する」をクリック<br />
              &nbsp;<br />
              4.申請内容の確認画面になり、間違いがなければ「金融機関にログインする」をクリック<br />
              &nbsp;<br />
              5.設定された金融機関のページへ遷移しますので、画面指示に従いお振込み手続きを進めてください<br />
            </faq-item>
            -->
            <faq-item question="入金が反映されるまでどれくらいかかりますか？"
              >お振込みいただいた日本円の口座への反映は、かかるお振込みを当社にて確認次第順次行います。<br />
              &nbsp;<br />
              なお、該当するお振込みを当社で確認出来てからのお客様口座への反映となりますので、銀行の営業日・営業時間によっては翌営業日以降となる場合がございます。<br />
              &nbsp;<br />
              ・銀行振込入金の反映スケジュール<br />
              &nbsp;<br />
              <img
                alt="入金反映イメージ図"
                :src="`${s3Url}img/table_nyukin_schedule.${language}.png?v=1`"
              />
              <!-- 
              &nbsp;<br />
              ※クイック入金の場合は、自動的にマイページ内の保管口座に反映されます。
               -->
            </faq-item>
            <faq-item question="クイック入金で入金したい"
              >2024年2月1日より、当社ではクイック入金サービスを廃止しております。
            </faq-item>
            <!-- 
            <faq-item question="クイック入金とは何ですか？"
              >クイック入金とは、当社と提携している約100行以上の金融機関でお客様がインターネットバンキング口座をお持ちであれば、お客様がお持ちの預金口座内の預金を、24時間いつでも※即座に当社のお客様取引口座内へご入金いただけるサービスです。<br />
              &nbsp;<br />
              ※当社メンテナンス時間を除く<br />
            </faq-item>
            <faq-item
              question="クイック入金と銀行振り込み入金の違いを教えてください"
              >クイック入金は24時間365日対応可能な入金サービスとなっております。<br />
              (週次・臨時メンテナンス時間を除く)<br />
              銀行振込による入金は、かかるお振込みを当社で確認した後の反映となります。<br />
              入金方法により手数料が変わりますので、ご確認の上ご利用ください。<br />
              &nbsp;<br />
              ・手数料<br />
              銀行振込：銀行振込手数料はお客様負担<br />
              クイック入金：440円(税込)
            </faq-item>
            <faq-item
              question="クイック入金をしたいのですが、使っている銀行が表示されません"
              >クイック入金は当社と提携している銀行のみ対応しています。<br />
              &nbsp;<br />
              日本円クイック入金画面の金融機関検索画面にて、ご利用の金融機関が表示されない場合は、クイック入金はご利用いただけません。<br />
              &nbsp;<br />
              クイック入金に対応していない金融機関からのお振込みをご希望の場合は、銀行振込をご利用ください。
            </faq-item>
            <faq-item question="クイック入金で100万円入金したいのですが"
              >クイック入金のお手続き1回あたりの上限金額は999,999円までです。</faq-item
            >
             -->
            <faq-item question="家族名義の口座から入金することはできますか？"
              >お客様ご本人名義以外からの入金はできません。<br />
              お客様ご本人以外の名義からのお振込みが確認された場合は組み戻しをしていただきます。
            </faq-item>
            <faq-item
              question="クレジットカードで暗号資産を購入することはできますか？"
              >当社ではクレジットカードでの決済は取り扱っておりません。<br />
              入金の際は銀行振込をご利用いただきますようお願いいたします。
            </faq-item>
            <faq-item question="外貨の入金はできますか？"
              >当社では外貨の入金は取り扱っておりません。<br />
              法定通貨の入金は日本円のみ取り扱っております。</faq-item
            >
            <faq-item question="暗号資産を入庫したい"
              >暗号資産の入庫方法は、以下の手順の通りです。<br />
              ※例）BTCの場合 &nbsp;<br />
              1.マイページのメニューより「入金・入庫」から「BTC入庫」をクリック<br />
              （スマートフォンをご利用の場合は画面上部右のメニューボタン「≡」のマークのアイコンをタップして選択）<br />
              &nbsp;<br />
              2.BTC入庫ページに記載されているアドレスまたはQRコードを読み取って送金する<br />
            </faq-item>
            <faq-item
              question="暗号資産の入庫手続きをしたのですが、反映されるまでどれくらいかかりますか？"
              >入庫していただいた暗号資産の口座への反映は、お客様の入庫を当社にて確認でき次第、順次行います。<br />
              ネットワークの混雑状況や手数料等により、口座への反映にお時間をいただく場合がございます。<br />
              &nbsp;<br />
              未承認のトランザクションにつきましては、一定回数の承認が完了した後にお客様の口座への反映手続きを行います。<br />
              ※トランザクションの承認状況は、マイページにログイン後、「入金・入庫」メニューから、ご確認いただけます。<br />
            </faq-item>
            <faq-item question="出金をしたい"
              >日本円の出金方法は、以下の手順の通りです。<br />
              &nbsp;<br />
              1.マイページのメニューより「出金・出庫」から「日本円出金」をクリック<br />
              （スマートフォンをご利用の場合は画面上部右のメニューボタン「≡」のマークのアイコンをタップして選択）<br />
              &nbsp;<br />
              2.出金額を設定し、「出金依頼の確認」をクリックし、二段階認証をして「確定」<br />
              &nbsp;<br />
              ※出金の際には二段階認証が必要ですので、未設定の場合はメニューの設定から二段階認証の設定をお願いいたします。<br />
              &nbsp;<br />
              ※出金の際には出金先の銀行口座の情報を設定していただく必要がございます。メニューの「設定」＞「銀行口座のご入力」より<br />
              ・金融機関名<br />
              ・支店名<br />
              ・口座種別<br />
              ・口座番号<br />
              ・口座名義<br />
              以上5点のご設定をお願いいたします。<br />
            </faq-item>
            <faq-item
              question="出金が確認できるまでどれくらいの時間がかかりますか？"
              >17時（土日祝日・年末年始を除く）までに出金手続きを行っていただければ、銀行営業日の2営業日以内に出金処理を行います。<br />
              &nbsp;<br />
              ※当社での出金処理後、お客様の口座で確認できるタイミングは、ご利用の金融機関により前後します。
            </faq-item>
            <faq-item question="家族名義の口座へ出金することはできますか？"
              >お客様ご本人様名義以外の口座への出金はできません。</faq-item
            >
            <faq-item question="暗号資産を出庫したい"
              >暗号資産の出庫方法は、以下の手順の通りです。<br />
              ※例）BTCの場合 &nbsp;<br />
              1.マイページのメニューより「出金・出庫」から「BTC出庫」をクリック<br />
              （スマートフォンをご利用の場合は画面上部右のメニューボタン「≡」のマークのアイコンをタップして選択）<br />
              &nbsp;<br />
              2.下記の項目を記載の上「出庫依頼の確認」をクリックし、二段階認証をして「確定」<br />
              ・出庫先アドレス<br />
              ・出庫数（単位:BTC）<br />
              ・手数料※<br />
              ※マイナーへの手数料です。<br />
              手数料の詳細は、<router-link :to="{ name: 'Fee' }"
                >手数料等</router-link
              >のページをご覧ください。
            </faq-item>
            <faq-item
              question="暗号資産の出庫依頼をしたのですが、こちらで確認できるまでどれくらいの時間がかかりますか？"
              >当日17時までに出庫依頼を行っていただければ、土日祝日および年末年始を除き2営業日以内に出庫処理を行います。<br />
              &nbsp;<br />
              ※当社での出庫処理後、出庫先で確認できるまでにかかる時間は、ネットワークの混雑状況や出庫先の承認回数等により異なります。
            </faq-item>
            <faq-item
              question="保管口座にある暗号資産を出庫したいのですが、出庫先アドレスの入力ができません"
              >暗号資産の出庫を行う際は、二段階認証の設定が必要となります。<br />
              二段階認証の設定を行っていない場合は、出庫先アドレスの入力ができません。<br />
              &nbsp;<br />
              下記の手順に従って、二段階認証の設定をお願いいたします。<br />
              &nbsp;<br />
              1.マイページにログイン<br />
              2.メニューの「設定」→「二段階認証」をクリック<br />
              3.二段階認証設定変更の為、「コード発行」ボタンをクリック<br />
              4.届いた「認証コード」を入力して「設定変更」ボタンをクリック<br />
              5.「メール」「SMS（ショートメッセージサービス）」「スマホアプリ（Google
              Authenticator）」から任意の二段階認証の方法を選択<br />
              6.「確認画面へ」ボタンをクリック<br />
              7.画面指示に従って任意の方法で二段階認証を行う&nbsp;<br />
              &nbsp;<br />
              「二段階認証を正常に設定しました」のメッセージが、画面上部に表示されたら、設定完了です。<br />
              &nbsp;<br />
              二段階認証の設定後に、改めて暗号資産の出庫手続をお願いします。<br />
              &nbsp;<br />
              なお、二段階認証の設定を行っても、暗号資産の出庫手続ができない場合は、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >からご連絡ください。<br />
            </faq-item>
            <faq-item
              question="保管口座と取引口座とは何ですか？また違いはなんですか？"
              >以下の点が異なります。<br />
              ・保管口座<br />
              お客様のご資金を保管する口座です。<br />
              円貨のご入金・ご出金・取引口座への振替と、暗号資産の入庫・出庫・取引口座への振替ができます。<br />
              &nbsp;<br />
              取引システムを通じて暗号資産の売買を行うには、保管口座の資金を取引口座に振り替える必要があります。<br />
              &nbsp;<br />
              ・取引口座<br />
              お客様がお取引に利用するご資金を保管する口座です。<br />
              取引口座内のご資金を利用して暗号資産の売買を行うことができ、売買の結果、取引口座内のご資金が増減します。<br />
              また、円貨や暗号資産の保管口座への振替ができます。<br />
              取引システムを通じたお取引を行う際は、保管口座から取引口座に資金を振り替える必要があります。<br />
              &nbsp;<br />
              資金を出金・出庫される際には、取引口座の資金を保管口座に振り替える必要があります。<br />
              &nbsp;<br />
              保管口座と取引口座のイメージ図は下記の通りです。<br />
              <img
                alt="保管口座と取引口座のイメージ図"
                :src="`${s3Url}img/img_faq_kouza01.${language}.png?v=1`"
              />
            </faq-item>
            <faq-item question="入金したのに取引ができません"
              >取引システムを通じて暗号資産の取引を行うには、保管口座の資金を取引口座に振り替えていただく必要があります。</faq-item
            >
            <faq-item
              question="出金・出庫の際、口座に残高があるはずなのに「残高以内の金額で申請してください。」というメッセージが表示されます"
              >日本円（JPY）の出金及び暗号資産の出庫は「保管口座」内で保有している範囲内での出金・出庫が可能です。<br />
              &nbsp;<br />
              「残高以内の金額で申請してください。」とメッセージが表示された場合は、マイページの「口座状況サマリー」にて、「取引口座」内の日本円（JPY）と暗号資産の残高をご確認ください。<br />
              &nbsp;<br />
              日本円（JPY）及び暗号資産が「取引口座」にある場合は、口座振替にて「保管口座」へお振替の後、出金または出庫手続きを行ってください。<br />
              &nbsp;<br />
              なお、保有されている暗号資産を、日本円（JPY）として出金することはできません。<br />
              暗号資産のまま出庫していただくか、「取引口座」にお振替の上、現物取引で暗号資産を売却し、再び「保管口座」に振替えることで、日本円（JPY）での出金が可能になります。
            </faq-item>
          </dl>

          <div class="bh4 fw_m fc_y mt_35" id="transaction">お取引について</div>
          <dl class="wrap">
            <faq-item question="取引できる暗号資産は何がありますか？"
              >下記の暗号資産の現物取引が行えます。<br />
              &nbsp;<br />
              <template v-for="crypto in cryptoList" :key="crypto">
                ・{{ $t(`coins.${crypto.toLocaleLowerCase()}`) }}（{{
                  crypto
                }}）<br />
              </template>
              こちらの<router-link :to="{ name: 'Tool' }">取引画面</router-link
              >を用いてお取引を行っていただきます。
            </faq-item>
            <faq-item question="取引できる時間について教えてください"
              ><p>・取引時間は9:00～17:59(土・日・祝を除く平日)です。</p>
              <p
                >・営業時間外（メンテナンス時間を除く）は、ストリーミング注文はできませんが、指値注文・OCO注文の発注と変更は行うことができます。</p
              >
              <p
                >・毎週水曜日18:00～20：00はメンテナンス時間のため取引できません。<br />
                （メンテナンス時間は、前後する場合がございます。）
              </p>
              <p
                >・臨時にメンテナンスを実施する場合も取引はできません。</p
              ></faq-item
            >
            <faq-item question="暗号資産の最小取引単位を教えてください"
              >暗号資産の最小取引単位は、<router-link :to="{ name: 'Fee' }"
                >手数料等</router-link
              >のページをご覧ください。</faq-item
            >
            <faq-item question="暗号資産に保有上限はありますか？"
              >暗号資産の保有上限は、<router-link :to="{ name: 'Fee' }"
                >手数料等</router-link
              >のページをご覧ください。</faq-item
            >
            <faq-item question="一度に取引できる最大数量を教えてください"
              >暗号資産のお取引最大発注数量は、<router-link
                :to="{ name: 'Fee' }"
                >手数料等</router-link
              >のページをご覧ください。
            </faq-item>
            <faq-item question="スプレッドとは何ですか？"
              >暗号資産の価格は購入価格(Ask)と売却価格(Bid)の二つがあります。<br />
              AskレートとBidレートの差のことをスプレッドといいます。
            </faq-item>
          </dl>

          <div class="bh4 fw_m fc_y mt_35" id="order">ご注文について</div>
          <dl class="wrap">
            <faq-item question="どのような注文ができますか？"
              >現物取引にて下記の注文方法がご利用いただけます。<br />
              &nbsp;<br />
              ・ストリーミング注文<br />
              ・指値注文<br />
              ・逆指値注文<br />
              ・OCO注文<br
            /></faq-item>
            <faq-item question="ストリーミング注文とは何ですか？"
              >ストリーミング注文は、価格を指定しない注文方法です。<br />
              当社が連続して配信する価格を見てお客様が任意のタイミングで発注することにより注文できます。<br />
              許容するスリッページ幅を設定でき、スリッページ幅を超えるときは、当該注文は約定しません。
            </faq-item>
            <faq-item question="指値注文とはどのような注文ですか？"
              >指値注文は、価格と数量を指定する注文方法です。<br />
              具体的には、下記のように現在の価格より有利な注文価格を指定し発注します。<br />
              &nbsp;<br />
              ・買い注文は現在価格の買値以下の価格を指定して注文<br />
              ・売り注文は現在価格の売値以上の価格を指定して注文<br />
              &nbsp;<br />
              ※指値注文は原則指定した価格で約定いたします。
            </faq-item>
            <faq-item question="逆指値注文とはどのような注文ですか？"
              >逆指値注文は、価格と数量を指定する注文方法です。<br />
              &nbsp;<br />
              具体的には、下記のように現在の価格より不利な注文価格を指定し発注します。<br />
              &nbsp;<br />
              ・買い注文は現在価格の買値以上の価格を指定して注文<br />
              ・売り注文は現在価格の売値以下の価格を指定して注文<br />
              &nbsp;<br />
              ※逆指値注文は指定した価格を超えたときの配信価格で約定いたしますので、相場の急変時や流動性が不十分な時は、指定した価格と約定した価格のかい離が大きくなることがあります。
            </faq-item>
            <faq-item
              question="指値注文(逆指値注文)をすると「○○円より高い(低い)注文レートを指定してください。」というメッセージが表示され注文できません"
              >指値注文(逆指値注文)は現在の価格を参照して、現在の価格よりも高い(低い)価格を指定して注文を発注するものです。<br />
              従いまして、指値注文・逆指値注文を発注する際に「○○円より高い(低い)注文レートを指定してください。」というメッセージが表示される場合は、現在価格をご確認の上、再度発注をお願いいたします。
            </faq-item>
            <faq-item question="OCO注文とは何ですか？"
              >OCO注文（One Cancels the
              Other）は注文種類の異なる二つの注文(指値注文と逆指値注文・売買の別)を同時発注して、どちらか片方が成立すると、もう一方が自動的にキャンセルされる注文方法です。<br />
            </faq-item>
            <faq-item question="注文画面のスリッページとは何ですか？"
              >お客様が注文を発注される際、発注した時点の価格と当社のシステムで実際に約定する価格に差が生じることがあり、この差をスリッページといいます。<br />
              スリッページはお客様にとっては有利になることもあれば、不利になる場合もございます。<br />
              &nbsp;<br />
              注文画面にてスリッページ幅を設定していただくと、注文時と約定時の価格が設定幅を超えてかい離することがあった場合は当該注文は約定せずに失効いたします。<br />
              &nbsp;<br />
              またスリッページをオフもしくは0に設定していただくことで、注文時と約定時の価格が同一でなければ約定しないようにすることも可能です。
            </faq-item>
            <faq-item question="注文の有効期限はいつまでですか？"
              >取引が成立する、またはお客様が取り消しをされるまで無期限で有効です。</faq-item
            >
          </dl>

          <div class="bh4 fw_m fc_y mt_35" id="charge">手数料について</div>
          <dl class="wrap">
            <faq-item question="取引手数料はいくらですか？"
              >お取引手数料は、<router-link :to="{ name: 'Fee' }"
                >手数料等</router-link
              >のページをご覧ください。</faq-item
            >
            <faq-item question="入金または入庫の際、手数料はかかりますか？"
              >入金または入庫の手数料は、<router-link :to="{ name: 'Fee' }"
                >手数料等</router-link
              >のページをご覧ください。</faq-item
            >
            <faq-item question="出金または出庫の際、手数料はかかりますか？"
              >出金または出庫の手数料は、<router-link :to="{ name: 'Fee' }"
                >手数料等</router-link
              >のページをご覧ください。</faq-item
            >
          </dl>

          <div class="bh4 fw_m fc_y mt_35" id="tax">税金について</div>
          <dl class="wrap">
            <faq-item question="税金について教えてください"
              >現在、暗号資産の利益分の税金については、総合課税の雑所得扱いとなっております。(2022年8月現在)<br />
              &nbsp;<br />
              ※税制が変更となる場合がございますので、国税庁のホームページで最新の情報をご確認くださいますようお願いいたします。<br />
              &nbsp;<br />
              <a href="http://www.nta.go.jp/" target="_"
                >国税庁｜ホームページ</a
              >
              &nbsp;<br />
              &nbsp;<br />
              なお、暗号資産取引に係る所得税の確定申告の詳細につきましては、下記の国税庁ホームページをご覧ください。<br />
              &nbsp;<br />
              <a
                href="https://www.nta.go.jp/publication/pamph/shotoku/kakuteishinkokukankei/kasoutuka/index.htm"
                target="_"
                >国税庁｜暗号資産に関する税務上の取扱い及び計算書について（令和3年12月）</a
              ><br />
              <a
                href="https://www.nta.go.jp/publication/pamph/pdf/virtual_currency_faq_03.pdf"
                target="_"
                >国税庁｜暗号資産に関する税務上の取扱いについて（PDF）</a
              ><br />
            </faq-item>
            <faq-item question="FXや株式の税金と合算することは可能ですか？"
              >現在のところは税区分が違うため、合算することは不可となっています。（2022年8月現在）</faq-item
            >
            <faq-item
              question="暗号資産の売買で利益を得た場合、確定申告は必要ですか？"
              >暗号資産の売買で得た利益は日本の所得税法上の課税対象となっています。<br />年間の取引約定ベースによる確定した損益で利益が出た場合は、一般に総合課税の雑所得（事業所得等に該当する場合を除く）として確定申告が必要です。<br />詳しくは、国税庁、税務署、または税理士等にご確認ください。<br />
              &nbsp;<br />
              ※法令等の改正により内容が変更される可能性があります。最新の情報をご確認くださいますようお願いいたします。
            </faq-item>
            <faq-item question="相続手続きに関して教えてください"
              >相続手続きは下記の通りになります。<br />
              &nbsp;<br />
              1.（相続人等関係人から）被相続人が亡くなったことを、相続の期限までに当社へご連絡ください<br />
              &nbsp;<br />
              相続手続きに関するお問い合わせ窓口<br />
              <router-link :to="{ name: 'Inquiry' }">
                お問い合わせフォーム</router-link
              ><br />
              &nbsp;<br />
              2.当社にて口座を確認し、相続人等関係人の指定先住所に相続手続きに必要な書類を郵送します。必要書類にご記入の上、下記書類と合わせて当社にご郵送ください。<br />
              &nbsp;<br />
              ・被相続人が亡くなったことが分かる除籍謄本または全部事項証明（除籍）<br />
              ・相続人であることが確認できる戸籍謄本<br />
              ・相続人等関係人の印鑑証明書<br />
              ・相続人以外（遺産整理受任者、遺言執行者、代理人等）が手続きする場合は、委任関係が分かる書類<br />
              &nbsp;<br />
              3.当社にて確認のうえ、必要手続きを実施いたします。
            </faq-item>
          </dl>

          <div class="bh4 fw_m fc_y mt_35" id="other">その他</div>
          <dl class="wrap">
            <faq-item
              question="スマートフォン・タブレットで取引する事は可能ですか？"
              >パソコンとスマートフォンでお取引は可能です。タブレットでのお取引はできません。
            </faq-item>
            <faq-item
              question="スマートフォン・タブレット・パソコンのアプリケーションはありますか？"
              >現在のところ当社ではアプリケーションの提供は行っておりません。<br />スマートフォン、パソコンのブラウザ機能で取引可能となっております。
            </faq-item>
            <faq-item question="電話でのお問い合わせの受付は行っていますか？"
              >大変申し訳ございませんが、現在のところ当社では電話でのサポートは行っておりません。<br />
              &nbsp;<br />
              <router-link :to="{ name: 'Inquiry' }">
                お問い合わせフォーム</router-link
              >よりご連絡をくださいますようお願いいたします。
            </faq-item>
            <faq-item question="サポートの受付時間について教えてください"
              >サポートの受付時間は下記の通りです。<br />
              &nbsp;<br />
              サポート受付時間：月～金曜日
              9:00～17:00【祝日（振替休日を含む）および年末年始を除く】<br />
              &nbsp;<br />
              サポート受付時間外にご連絡いただいたお問い合わせのご返信は、翌サポート受付時間以降となります。<br />
              ご了承くださいますようお願い申し上げます。
            </faq-item>
            <faq-item question="苦情相談窓口について教えてください"
              >当社はお客様からの苦情を下記の窓口で受け付けております。<br />
              &nbsp;<br />
              ・当社の窓口へのご相談<br />
              Coin Estate株式会社 業務管理チーム<br />
              所在地 :〒110-0016 東京都台東区台東一丁目24番9号<br />
              &nbsp;<br />
              メール : css@coinestate.co.jp（24時間）<br />
              ※お電話でのご相談は受け付けておりません。<br />
              &nbsp;<br />
              窓口対応時間：お問い合わせは24時間受け付けておりますが、担当者による対応は【土日祝祭日・年末年始を除く、平日
              9：00～17：00】とさせていただきます。<br />
              &nbsp;<br />
              ・協会へのご相談<br />
              一般社団法人日本暗号資産等取引業協会（JVCEA）<br />
              所在地 : 〒102-0082 東京都千代田区一番町18番地
              川喜多メモリアルビル4階<br />
              電話 : 03-3222-1061<br />
              &nbsp;<br />
              苦情受付フォーム : https://jvcea.or.jp/contact/form-contact/<br />
              受付時間：
              9時30分～17時30分（土・日・祝･休日、年末・年始を除きます。）
            </faq-item>
            <faq-item question="登録住所を変更したい"
              >お引越しなどにより登録住所を変更されたい場合は、マイページにログインの上、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりご連絡ください。<br />
              &nbsp;<br />
              なお、マイページにログインができない場合は、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりその旨を記載の上、ご連絡をお願いいたします。<br />本人確認ができましたら手続きをいたします。<br />
              &nbsp;<br />
              また、ご変更手続きの際に本人確認書類が必要になりますので、ご用意いただきますようお願いいたします。<br />本人確認書類につきましては<router-link
                :to="{ name: 'RequiredDocuments' }"
                >本人確認書類について</router-link
              >をご参照ください。<br />
            </faq-item>
            <faq-item question="登録情報を変更したい"
              >電話番号や婚姻に伴うお名前の変更など、ご登録情報を変更されたい場合は、マイページにログインの上、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >より変更事項を併せてご連絡ください。<br />
              &nbsp;<br />
              なお、マイページにログインができない場合は、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりその旨を記載の上、ご連絡をお願いいたします。<br />本人確認ができましたら手続きをいたします。
            </faq-item>
            <faq-item question="メールアドレスを変更したい"
              >大変申し訳ございませんが、メールアドレスの変更は承っておりません。</faq-item
            >
            <faq-item question="パスワードを変更したい"
              >パスワードの変更は、マイページのメニュー「設定」の「パスワード変更」より行っていただけます。</faq-item
            >
            <faq-item question="アカウントIDを確認したい"
              >アカウントIDはログイン後、マイページ左上部※のメールアドレスの下に記載されております10桁の数字ですので、ログインが可能な場合はそちらをご参照ください。<br />
              &nbsp;<br />
              ログインができない場合につきましては、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりご連絡ください。<br />
              本人確認が完了次第、こちらからご連絡差し上げます。<br />
              &nbsp;<br />
              (スマートフォンの場合は画面右上のメニューボタンをタップするとご確認いただけます。)
            </faq-item>
            <faq-item
              question="「取引残高報告書 兼 取引報告書」はどこで確認できますか？"
              >取引残高報告書 兼
              取引報告書（PDFファイル）は、下記の手順にてご確認ください。<br />
              &nbsp;<br />
              1.マイページにログイン<br />
              2.マイページのメニュー「取引報告書」をクリック<br />
              3.期間別の「取引残高報告書 兼
              取引報告書」一覧から閲覧したいファイル（PDF）をクリック
            </faq-item>
            <faq-item question="解約したい"
              >解約をご希望の場合は、マイページにログインの上、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >から「口座解約について」を選択の上、ご連絡ください。<br />
              &nbsp;<br />
              マイページにログインができない場合は、<router-link
                :to="{ name: 'Inquiry' }"
              >
                お問い合わせフォーム</router-link
              >よりその旨を記載の上、ご連絡をお願いいたします。<br />本人確認ができましたら手続きをいたします。<br />
              &nbsp;<br />
              なお、ご解約につきましては、口座内に「日本円」「暗号資産」および「ひまぽい」残高が無い状態であることが必要となりますので、ご確認の程よろしくお願いいたします。
            </faq-item>
          </dl>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import FaqItem from '@/components/FaqItem.vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { reactive } from 'vue'
  import { useHead } from '@vueuse/head'
  import faq from '@/enum/faq.json'
  const store = useMainStore()
  const { s3Url, language, tokenDeploy } = storeToRefs(store)

  const tags = [
    {
      name: '口座開設について',
      hash: 'open'
    },
    {
      name: 'ログインについて',
      hash: 'login'
    },
    {
      name: '入出金･振替について',
      hash: 'transfer'
    },
    {
      name: 'お取引について',
      hash: 'transaction'
    },
    {
      name: 'ご注文について',
      hash: 'order'
    },
    {
      name: '手数料について',
      hash: 'charge'
    },
    {
      name: '税金について',
      hash: 'tax'
    },
    {
      name: 'その他',
      hash: 'other'
    }
  ]

  const state: {
    crypto: string[]
  } = reactive({
    crypto: []
  })

  Object.keys(tokenDeploy.value).map((key: string) => {
    tokenDeploy.value[key] === 'true' ? state.crypto.push(key) : null
  })

  const cryptoList = state.crypto

  useHead({
    script: [
      ...(import.meta.env.VITE_APP_SETTINGS_NAME !== 'local'
        ? [
            {
              type: 'application/ld+json',
              innerHTML: JSON.stringify(faq)
            }
          ]
        : [])
    ]
  })
</script>

<style lang="scss" scope>
  dd.info a {
    color: #39f;
  }
</style>
