<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.TradeHistory.title') }}</h1>

        <crypto-nav :link_name="state.link" :crypto="state.crypto.toString()">
        </crypto-nav>

        <history-item
          :detail="state.history"
          :title="state.title"
        ></history-item>
      </div>

      <pagination
        :total="state.history.total_pages"
        :page="state.page"
        @onchange="changePage"
        v-if="state.history && state.history?.results?.length > 0"
      ></pagination>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import CryptoNav from '@/components/CryptoNav.vue'
  import { getTradeHistory } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const { t } = useI18n()
  const state: {
    title: any[]
    history:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    loading: boolean
    crypto: string | string[]
    link: string
  } = reactive({
    title: [],
    history: undefined,
    page: 1,
    loading: true,
    crypto: '',
    link: ''
  })

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getTradeHistory(
      state.crypto?.toString(),
      state.page
    )
    state.history = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  onMounted(async () => {
    state.link = 'TradeHistory'
    state.crypto = router.currentRoute.value.params.crypto
    state.title = [
      { key: 'trade_date', name: t(`pages.TradeHistory.item.title1`) },
      { key: 'brand_name', name: t(`pages.TradeHistory.item.title2`) },
      { key: 'trading', name: t(`pages.TradeHistory.item.title3`) },
      {
        key: 'qty',
        name:
          t(`pages.TradeHistory.item.title4`) +
          '(' +
          state.crypto.toString() +
          ')',
        type: 'number'
      },
      {
        key: 'rate',
        name: t(`pages.TradeHistory.item.title5`),
        type: 'number'
      },
      {
        key: 'amount',
        name: t(`pages.TradeHistory.item.title6`),
        type: 'number'
      }
    ]
    await showHistory()
  })
</script>
<style lang="scss" scoped></style>
