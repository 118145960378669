<template>
  <div v-if="!state.type">
    <the-loading style="margin-top: 100px; margin-bottom: 100px"></the-loading>
  </div>
  <div v-else>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.TwoStepCheck.title') }}</h1>
        <div class="two-factor_center1">
          <the-message v-if="state.errorMessage" class="form-error">{{
            state.errorMessage
          }}</the-message>

          <form class="w60 mr20p ml20p tbw-w100 tbw-mr0p tbw-ml0p formbox">
            <ul class="tbw-pl8" style="margin-top: 0">
              <div
                style="width: 100%; margin-bottom: 50px"
                v-if="state.type !== '3'"
              >
                <button
                  class="uk-button uk-button-primary btn_size"
                  style="margin: 0 auto; display: block; max-width: 200px"
                  v-debounce="{
                    func: issueCode
                  }"
                  v-loading="{
                    loading: loading,
                    value: $t('pages.TwoStepCheck.form.issue')
                  }"
                  type="button"
                  >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                >
              </div>

              <SchemaFormWithValidation
                :schema="state.schema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
                class="width-50"
              />

              <div class="two-factor_left" style="margin-bottom: 50px">
                <button
                  class="uk-button uk-button-primary btn_size"
                  style="margin: 0 auto; display: block; max-width: 310px"
                  v-debounce="{
                    func: submit
                  }"
                  v-loading="{
                    loading: loading,
                    value: $t('pages.TwoStepCheck.form.submit')
                  }"
                  type="button"
                  >{{ $t('pages.TwoStepCheck.form.submit') }}</button
                >
              </div>
            </ul>
          </form>
        </div>
      </div>
    </article>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { twoStepIssue, twoStepVerify, getTwoStep } from '@/api'
  import { validator } from '@/enum/enums'

  const { t } = useI18n()
  const router = useRouter()

  const state: {
    schema: any
    formJson: any
    errorMessage: string
    isIssued: boolean
    init: boolean
    type: string | undefined
  } = reactive({
    schema: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    formJson: {},
    errorMessage: '',
    isIssued: false,
    init: false,
    type: undefined
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const issueCode = async () => {
    loading.value = true
    await twoStepIssue('login', null)
    state.isIssued = true
    loading.value = false
  }

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await twoStepVerify(
            'login',
            state.formJson.passcode,
            state.type || ''
          )
          router.push({ name: 'Home' })
        } catch (error: any) {
          state.errorMessage = t('pages.Individual.step3.error')
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getTwoStep('', 'login')
      state.type = contents.two_step_auth_flg
      state.schema.passcode.placeholder =
        t(`pages.TwoStepCheck.form.placeholder${state.type}`) || '1'
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped>
  .uk-button-default {
    font-weight: bold;
    font-size: 1.1rem;
    border-radius: 3px !important;
  }

  .width-50 {
    width: 50%;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    .width-50 {
      width: 100%;
    }
  }
</style>
