<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.DepositConveniAgreement.title') }}</h1>

        <div class="formbox">
          <p>{{ $t('pages.DepositConveniAgreement.msg1') }}</p>
          <p class="indent-1">{{ $t('pages.DepositConveniAgreement.msg2') }}</p>
          <div class="tbl_border">
            <div class="tbl_form">
              <p class="tbl_title">{{
                $t('pages.DepositConveniAgreement.conveni.title')
              }}</p>
              <ul>
                <li>{{
                  $t('pages.DepositConveniAgreement.conveni.store1')
                }}</li>
                <li>{{
                  $t('pages.DepositConveniAgreement.conveni.store2')
                }}</li>
                <li>{{
                  $t('pages.DepositConveniAgreement.conveni.store3')
                }}</li>
                <li>{{
                  $t('pages.DepositConveniAgreement.conveni.store4')
                }}</li>
                <li>{{
                  $t('pages.DepositConveniAgreement.conveni.store5')
                }}</li>
              </ul>
            </div>
          </div>

          <div>
            <p class="conv_caution">{{
              $t('pages.DepositConveniAgreement.msg3')
            }}</p>
          </div>
          <div class="tbl_border">
            <div class="tbl_form">
              <div class="conv_clist">
                <p class="tbl_title">{{
                  $t('pages.DepositConveniAgreement.important.title')
                }}</p>
                <ul>
                  <li>{{
                    $t('pages.DepositConveniAgreement.important.msg1')
                  }}</li>
                  <li>{{
                    $t('pages.DepositConveniAgreement.important.msg2')
                  }}</li>
                  <li>{{
                    $t('pages.DepositConveniAgreement.important.msg3')
                  }}</li>
                </ul>
              </div>
            </div>
          </div>
          <p>
            {{ $t('pages.DepositConveniAgreement.link_msg1')
            }}<a
              :href="`${s3Url}resource/deposit_conveni_doc.pdf?v=1.04`"
              target="_blank"
              >{{ $t('pages.DepositConveniAgreement.link') }}</a
            >{{ $t('pages.DepositConveniAgreement.link_msg2') }}
          </p>
          <p class="indent-1">&nbsp;</p>
          <p class="tbl_title uk-margin-top">{{
            $t('pages.DepositConveniAgreement.msg4')
          }}</p>
          <button
            class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: state.loading,
              value: $t('pages.DepositConveniAgreement.button')
            }"
            type="button"
            >{{ $t('pages.DepositConveniAgreement.button') }}</button
          >
        </div>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import TheModal from '@/components/TheModal.vue'
  import { getConveniAgreement, setConveniAgreement } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'

  const router = useRouter()
  const store = useMainStore()
  const { s3Url } = storeToRefs(store)
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const { t } = useI18n()

  const state: {
    loading: boolean
    errorMessage: string
  } = reactive({
    loading: false,
    errorMessage: ''
  })

  const submit = async () => {
    state.errorMessage = ''

    setTimeout(async () => {
      state.loading = true
      try {
        const { contents } = await setConveniAgreement()
        state.loading = false
        router.push({ name: 'DepositConveni' })
      } catch (error: any) {
        state.errorMessage = error.error_translations || error.message_detail
        document.documentElement.scrollTop = 0
        state.loading = false
      }
    }, 0)
  }

  onMounted(async () => {
    const { contents } = await getConveniAgreement()
    if (contents?.agreement) {
      router.push({ name: 'DepositConveni' })
    }
  })
</script>
<style lang="scss" scoped>
  .uk-margin-top {
    margin-top: 20px !important;
  }
  .text-bold {
    font-weight: bold !important;
  }
  .tbl_border {
    padding: 1rem 0rem !important;
    width: 100% !important;
    margin: 2rem auto 2rem !important;
  }
  p > a {
    display: inline;
    color: #febd09;
  }
  ul {
    list-style: unset !important;
  }
</style>
