<template>
  <li>
    <span>
      <label :for="uuid"
        >{{ label }}
        <template v-if="required">
          <span v-if="requiredStyle === 'form'" class="required-notice">{{
            $t('form.required')
          }}</span>
          <span v-else class="required">*</span>
        </template>
      </label>
    </span>
    <textarea
      :id="uuid"
      :required="required"
      class="valid"
      aria-invalid="false"
      :disabled="disabled"
      :value="modelValue"
      :col="col"
      :rows="rows"
      @input="update($event.target.value)"
      @blur="validate"
    ></textarea>
    <br v-if="validation?.errorMessage" />
    <label
      :id="uuid"
      class="error"
      :for="uuid"
      v-if="validation?.errorMessage"
      >{{ validation.errorMessage }}</label
    >
  </li>
</template>

<script>
  import { inject, ref, watch } from 'vue'

  export default {
    setup(props) {
      const needValidation = ref(inject('needValidation'))

      watch(needValidation, () => {
        props.validation.validate()
      })
    },
    props: {
      modelValue: { required: true },
      required: {
        type: Boolean,
        default: false
      },
      requiredStyle: {
        type: String
      },
      col: {
        type: Number,
        default: 40
      },
      rows: {
        type: Number,
        default: 10
      },
      label: {
        type: String,
        required: true
      },
      config: {
        type: Object,
        default: () => ({})
      },
      disabled: {
        type: Boolean,
        default: false
      },
      uuid: {
        type: Number,
        default: 0
      },
      validation: {
        type: Object,
        default: () => ({})
      },
      validations: {
        type: Object,
        default: () => ({})
      }
    },
    methods: {
      update(value) {
        this.$emit('update:modelValue', value)
      },
      validate() {
        this.validation.validate()
      }
    }
  }
</script>
