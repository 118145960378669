import { LanguagesType, i18nType } from '@/@types'

export const languages: LanguagesType[] = [
  {
    id: 'en' as i18nType,
    name: 'English(GB)',
    abbreviation: 'EN'
  },
  {
    id: 'ja' as i18nType,
    name: '日本語',
    abbreviation: 'JA'
  },
  {
    id: 'cn' as i18nType,
    name: '中文',
    abbreviation: 'CN'
  }
]
