<template>
  <div id="tv_chart_container" :style="{ width: '100%', height: '100%' }"></div>
</template>

<script lang="ts" setup>
  import { onMounted, reactive, watch } from 'vue'
  import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
  import { getCoinRateHistory } from '@/api'
  import { make_token } from '@/utils/helper'

  declare const TradingView: any

  const notIntegerScale = ['xlm', 'xrp']
  const props = defineProps({
    type: String
  })
  const state: {
    raw_data: any
    isLink: boolean
    pricescale: number
    bars: {
      time: number
      open: number
      high: number
      low: number
      close: number
      volume: number
    }[]
    selected_trade_type: string
    resolution: string
  } = reactive({
    raw_data: undefined as any,
    isLink: true,
    pricescale: 1,
    bars: [],
    selected_trade_type: 'ask',
    resolution: '1D'
  })

  let socket: any = null
  let askButton: any = null
  let bidButton: any = null

  const connectWebsocket = () => {
    const crypto = props.type || 'btc'
    const interval = getIntervalType(state.resolution)
    const token = make_token(128)
    const wsUrl = `${
      import.meta.env.VITE_APP_WS_RATE_URL
    }?id=${token}&server=${crypto}&interval=${interval}`

    try {
      socket = new WebSocket(wsUrl)
      socket.onerror = function () {
        // onerror
        socket.close()
      }
      socket.onclose = function () {
        // onclose
        state.isLink && connectWebsocket()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const sortTime = (value: string, resolution: string) => {
    const newValue = value.replace(/-/g, '/')
    return resolution.toLowerCase() === '1d'
      ? new Date(newValue).getTime() + 1000 * 60 * 60 * 9
      : new Date(newValue).getTime()
  }

  const getIntervalType = (resolution: string) => {
    switch (resolution) {
      case '1':
        return '1m'
      case '1h':
        return '1h'
      case 'D':
        return '1d'
      case '1D':
        return '1d'
      case '60':
        return '1h'
      default:
        return '1m'
    }
  }

  const initTradingView = () => {
    const DataFeed = {
      /* mandatory methods for realtime chart */
      onReady: (cb: any) => {
        setTimeout(
          () =>
            cb({
              supported_resolutions: ['1', '60', 'D']
            }),
          0
        )
      },
      resolveSymbol: (
        symbolName: any,
        onSymbolResolvedCallback: any,
        onResolveErrorCallback: any
      ) => {
        const symbol_stub = {
          name: props.type?.toUpperCase(),
          description: '',
          type: 'crypto',
          session: '24x7',
          timezone: 'Asia/Tokyo',
          ticker: props.type?.toUpperCase(),
          minmov: state.pricescale,
          has_intraday: true,
          pricescale: 1 / state.pricescale,
          intraday_multipliers: ['1', '60', 'D'],
          supported_resolution: ['1', '60', 'D'],
          data_status: 'streaming'
        }
        setTimeout(function () {
          onSymbolResolvedCallback(symbol_stub)
        }, 0)
      },
      getBars: async (
        symbolInfo: any,
        resolution: string,
        periodParams: any,
        onHistoryCallback: any,
        onErrorCallback: any
      ) => {
        // console.log("=========getBars", symbolInfo, resolution, periodParams)
        window.tvWidget.activeChart().setResolution(state.resolution)
        if (!periodParams.firstDataRequest) {
          onHistoryCallback([], {
            noData: true
          })
        } else {
          // console.log("=========getBars", symbolInfo, resolution, periodParams)
          const { contents } = await getCoinRateHistory(
            props.type as string,
            getIntervalType(state.resolution)
          )
          const rawBars = contents.interval
          const bars = rawBars.map(
            (bar: [string, number, number, number, number]) => {
              return {
                time: sortTime(bar[0], resolution),
                open: bar[1],
                high: bar[4],
                low: bar[3],
                close: bar[2],
                volume: 0
              }
            }
          )

          if (bars.length > 0) {
            state.bars = bars
            onHistoryCallback(bars, {
              noData: false
            })
          } else {
            onHistoryCallback([], {
              noData: true
            })
          }
        }
      },
      subscribeBars: function (
        symbolInfo: any,
        resolution: string,
        onRealtimeCallback: any,
        subscriberUID: any,
        onResetCacheNeededCallback: any
      ) {
        const toggleActive = (button: any) => {
          button.classList.toggle('is-active')
          button.style.color =
            button.style.color === 'rgb(255, 255, 255)'
              ? '#b2b5be'
              : 'rgb(255, 255, 255)'
          button.style.fontWeight =
            button.style.fontWeight === 'bold' ? 'normal' : 'bold'
        }

        const toggleButton = (type: string) => {
          if (type === 'ask') {
            toggleActive(askButton)
            if (
              askButton.classList.contains('is-active') &&
              bidButton.classList.contains('is-active')
            )
              toggleActive(bidButton)
          } else {
            toggleActive(bidButton)
            if (
              bidButton.classList.contains('is-active') &&
              askButton.classList.contains('is-active')
            )
              toggleActive(askButton)
          }
        }

        const clickButton = (button: any, type: string) => {
          if (button.classList.contains('is-active')) return
          state.selected_trade_type = type
          toggleButton(state.selected_trade_type)
          onResetCacheNeededCallback()
          window.tvWidget.activeChart().resetData()
        }

        // window.tvWidget.headerReady().then(function () {
        //   if (bidButton) return
        //   bidButton = window.tvWidget.createButton()
        //   bidButton.addEventListener('click', function () {
        //     clickButton(bidButton, 'bid')
        //   })
        //   bidButton.textContent = 'BID'
        // })

        // window.tvWidget.headerReady().then(function () {
        //   if (askButton) return
        //   askButton = window.tvWidget.createButton()
        //   askButton.addEventListener('click', function () {
        //     clickButton(askButton, 'ask')
        //   })
        //   askButton.textContent = 'ASK'
        // })

        window.tvWidget
          .activeChart()
          .onIntervalChanged()
          .subscribe(null, (interval: string) => (state.resolution = interval))

        // console.log('=========subscribeBars', symbolInfo, resolution)
        socket.onmessage = function (msg: any) {
          // onmessage
          const ws_data = JSON.parse(msg.data)
          // const rawBars = ws_data[state.selected_trade_type]
          const crypto = props.type?.toLowerCase()
          const rawBar = ws_data[crypto]
          if (rawBar != null) {
            const lastBar = state.bars[state.bars.length - 1]
            const bar = {
              time: Date.parse(new Date(rawBar.timestamp).toString()),
              open: lastBar.open,
              close: rawBar.average,
              low: rawBar.average < lastBar.low ? rawBar.average : lastBar.low,
              high:
                lastBar.high < rawBar.average ? rawBar.average : lastBar.high,
              volume: 0
            }
            if (bar && state.bars[state.bars.length - 1].time < bar.time) {
              onRealtimeCallback(bar)
            }
          }
        }
      },
      unsubscribeBars: function (subscriberUID: any) {
        // console.log("=========unsubscribeBars", subscriberUID)
      }
    }

    const widgetOptions = {
      debug: false,
      symbol: props.type?.toUpperCase(),
      datafeed: DataFeed,
      interval: '1d',
      container: 'tv_chart_container',
      library_path: `${
        import.meta.env.VITE_APP_STATIC_BASE_URL
      }charting_library/`,
      timezone: 'Asia/Tokyo',
      locale: 'en',
      disabled_features: [
        'header_symbol_search',
        'header_compare',
        'timeframes_toolbar',
        'header_resolutions'
      ],
      enabled_features: [],
      client_id: 'test',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      theme: 'Dark'
    }

    Object.assign(window, { tvWidget: new TradingView.widget(widgetOptions) })

    const timer = setInterval(() => {
      if (window.tvWidget._ready) {
        if (window.tvWidget.activeChart().getAllStudies().length > 1) {
          clearInterval(timer)
          return
        }

        window.tvWidget.activeChart().removeAllStudies()

        window.tvWidget
          .activeChart()
          .createStudy('Moving Average', false, false, [5], null)
        window.tvWidget
          .activeChart()
          .createStudy('Moving Average', false, false, [15], null)
        window.tvWidget
          .activeChart()
          .createStudy('Moving Average', false, false, [30], null)
        window.tvWidget
          .activeChart()
          .createStudy('MACD', false, false, [14, 30, 'close', 9])
        clearInterval(timer)
      }
    }, 500)
  }

  onMounted(() => {
    connectWebsocket()
    initTradingView()
  })

  onBeforeRouteUpdate((to, from) => {
    state.isLink = false
    socket.close()
  })

  onBeforeRouteLeave((to, from) => {
    state.isLink = false
    socket.close()
  })

  watch(
    () => props.type,
    (type) => {
      if (type) {
        initTradingView()
      }
    }
  )
  watch(
    () => props.type,
    (type) => {
      if (type) {
        state.pricescale = notIntegerScale.indexOf(type) >= 0 ? 0.001 : 1
      }
    },
    { immediate: true }
  )
</script>
