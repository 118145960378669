<template>
  <div class="corporation-confirm">
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.name_sei') }}</dt>
      <dd>{{ props.data?.name_sei || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.name_mei') }}</dt>
      <dd>{{ props.data?.name_mei || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.name_sei_kana') }}</dt>
      <dd>{{
        props.data?.name_sei_kana || $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.name_mei_kana') }}</dt>
      <dd>{{
        props.data?.name_mei_kana || $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.birthday') }}</dt>
      <dd>{{ props.data?.birthday || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.post_no') }}</dt>
      <dd>{{ props.data?.post_no || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.addr1') }}</dt>
      <dd>{{ props.data?.addr1 || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.addr2') }}</dt>
      <dd>{{ props.data?.addr2 || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Individual.form.addr3') }}</dt>
      <dd>{{ props.data?.addr3 || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.country') }}</dt>
      <dd>{{
        findOption('country', props.data?.country) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.sex') }}</dt>
      <dd>{{
        findOption('sex', props.data?.sex) || $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.mobile_no') }}</dt>
      <dd>{{ props.data?.mobile_no || $t('pages.Individual.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.profession') }}</dt>
      <dd>{{
        findOption('profession', props.data?.profession) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <template v-if="props.data?.profession_other === '99'">
        <dt>{{ $t('pages.Individual.form.profession_other') }}</dt>
        <dd>{{
          props.data?.profession_other || $t('pages.Individual.form.none')
        }}</dd>
      </template>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.transaction_objective') }}</dt>
      <dd>{{
        findOption(
          'individual_transaction_objective',
          props.data?.transaction_objective
        ) || $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <template v-if="props.data?.transaction_objective?.includes('99')">
        <dt>{{ $t('pages.Individual.form.transaction_objective_other') }}</dt>
        <dd>{{
          props.data?.transaction_objective_other ||
          $t('pages.Individual.form.none')
        }}</dd>
      </template>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.motivation_user') }}</dt>
      <dd>{{
        findOption('motivation_user', props.data?.motivation_user) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <template v-if="props.data?.profession_other === '99'">
        <dt>{{ $t('pages.Individual.form.motivation_user_other') }}</dt>
        <dd>{{
          props.data?.motivation_user_other || $t('pages.Individual.form.none')
        }}</dd>
      </template>
    </dl>
    <dl>
      <dt>{{ $t('pages.Individual.form.peps_kbn') }}</dt>
      <dd>{{
        findOption('peps_kbn', props.data?.peps_kbn) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Individual.form.ability_act') }}</dt>
      <dd>{{
        findOption('ability_act', props.data?.ability_act) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.financial_asset') }}</dt>
      <dd>{{
        findOption('financial_asset', props.data?.financial_asset) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.annual_income') }}</dt>
      <dd>{{
        findOption('annual_income', props.data?.annual_income) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.exp_cripto_actual') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_cripto_actual) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.exp_fx') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_fx) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.exp_stock_trading') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_stock_trading) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Individual.form.exp_stock_credit') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_stock_credit) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.exp_other_text') }}</dt>
      <dd>{{
        props.data?.exp_other_text || $t('pages.Individual.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Individual.form.exp_other') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_other) ||
        $t('pages.Individual.form.none')
      }}</dd>
    </dl>
  </div>
</template>

<script setup lang="ts">
  import { findOption } from '@/utils/tool'
  const props = defineProps({
    data: Object
  })
</script>
