<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <div class="main_contents_text boxs">
        <div class="main_contents_head_sp"></div>

        <div class="policy_inner">
          <p class="">
            {{ $t('pages.Policy.SecurityPolicy.msg1') }}
          </p>
          <ul class="">
            <li class="">
              <h4>{{ $t('pages.Policy.SecurityPolicy.inquiry.title') }}</h4>
              {{ $t('pages.Policy.SecurityPolicy.inquiry.msg') }}<br />
              <dl class="dl_table">
                <dt class="">{{
                  $t('pages.Policy.SecurityPolicy.inquiry.dt1')
                }}</dt>
                <dd>{{ $t('pages.Policy.SecurityPolicy.inquiry.dd1') }}</dd>

                <dt class="">{{
                  $t('pages.Policy.SecurityPolicy.inquiry.dt2')
                }}</dt>
                <dd
                  ><router-link :to="{ name: 'Inquiry' }">{{
                    $t('pages.Policy.SecurityPolicy.inquiry.dd2')
                  }}</router-link></dd
                >
              </dl>
            </li>
            <li class="">
              <span class=""
                ><h4>
                  {{ $t('pages.Policy.SecurityPolicy.msg2') }}
                </h4></span
              >
              <ul class="contents">
                <li class="">
                  <span class="cat_red"
                    >(1) {{ $t('pages.Policy.SecurityPolicy.msg2_1') }}</span
                  >
                </li>
                <li class="">
                  <span class="cat_red"
                    >(2) {{ $t('pages.Policy.SecurityPolicy.msg2_2') }}</span
                  ><br />{{ $t('pages.Policy.SecurityPolicy.msg2_2_1') }}
                </li>
              </ul>
            </li>
            <li class="">
              <span class=""
                ><h4>
                  {{ $t('pages.Policy.SecurityPolicy.msg3') }}
                </h4></span
              >
              <ul class="contents">
                <li class="">
                  <span class="cat_red"
                    >(1) {{ $t('pages.Policy.SecurityPolicy.msg3_1') }}</span
                  ><br />{{ $t('pages.Policy.SecurityPolicy.msg3_1_1') }}
                </li>
                <li class="">
                  <span class="cat_red"
                    >(2) {{ $t('pages.Policy.SecurityPolicy.msg3_2') }}</span
                  ><br />{{ $t('pages.Policy.SecurityPolicy.msg3_2_1') }}
                </li>
                <li class="">
                  <span class="cat_red"
                    >(3) {{ $t('pages.Policy.SecurityPolicy.msg3_3') }}</span
                  ><br />{{ $t('pages.Policy.SecurityPolicy.msg3_3_1') }}
                </li>
                <li class="">
                  <span class="cat_red"
                    >(4) {{ $t('pages.Policy.SecurityPolicy.msg3_4') }}</span
                  ><br />{{ $t('pages.Policy.SecurityPolicy.msg3_4_1') }}
                </li>
              </ul>
            </li>
            <li class="">
              <span class=""
                ><h4>
                  {{ $t('pages.Policy.SecurityPolicy.msg4') }}
                </h4></span
              >
              <ul class="contents">
                <li class="">
                  <span class="cat_red"
                    >(1) {{ $t('pages.Policy.SecurityPolicy.msg4_1') }}</span
                  >
                </li>
                <li class="">
                  <span class="cat_red"
                    >(2) {{ $t('pages.Policy.SecurityPolicy.msg4_2') }}</span
                  ><br />
                  {{ $t('pages.Policy.SecurityPolicy.msg4_2_1') }}<br />
                  <ul class="">
                    <li class="">
                      ①
                      {{ $t('pages.Policy.SecurityPolicy.msg4_2_1_1') }}
                    </li>
                    <li class="">
                      ② {{ $t('pages.Policy.SecurityPolicy.msg4_2_1_2') }}
                    </li>
                    <li class=""
                      >③ {{ $t('pages.Policy.SecurityPolicy.msg4_2_1_3') }}</li
                    >
                    <li class="">
                      ④
                      {{ $t('pages.Policy.SecurityPolicy.msg4_2_1_4') }}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <p style="color: #f44" v-if="language !== 'ja'"
            ><br />{{ $t('language_warning') }}</p
          >
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
