<template>
  <ul class="pagination" role="menubar" aria-label="Pagination">
    <li class="first" v-if="state.current != 1" @click="pageTo(1)">
      <a><span>First</span></a>
    </li>
    <li
      class="previous"
      v-if="state.current != 1"
      @click="pageTo(state.current - 1)"
    >
      <a><span>Previous</span></a>
    </li>
    <li
      v-for="page in state.pages"
      :key="page"
      :class="{ current: page === state.current }"
      @click="pageTo(page)"
    >
      <a :class="{ inactive: page != state.current }">
        {{ page }}
      </a>
    </li>
    <li
      class="next"
      v-if="state.current != props.total"
      @click="pageTo(state.current + 1)"
    >
      <a><span>Next</span></a>
    </li>
    <li
      class="last"
      v-if="state.current != props.total"
      @click="pageTo(props.total)"
    >
      <a><span>Last</span></a>
    </li>
  </ul>
</template>

<script lang="ts" setup>
  import { reactive, onMounted, watch } from 'vue'

  const props = defineProps({
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1 // From one
    }
  })

  const emit = defineEmits<{
    (e: 'onchange', page: number): void
  }>()

  const state = reactive({
    range: 5,
    pages: [] as number[],
    current: props.page
  })

  const pageTo = (page: number) => {
    if (state.current === page) return
    state.current = page
    countPageRange()
    emit('onchange', state.current)
  }

  const countPageRange = () => {
    const halfRange = Math.floor(state.range / 2)
    if (
      state.current - halfRange > 0 &&
      state.current + halfRange <= props.total
    ) {
      state.pages = Array.from(
        { length: state.range },
        (v, i) => i + state.current - halfRange
      )
    } else if (state.current - halfRange <= 0) {
      state.pages = Array.from(
        { length: state.range >= props.total ? props.total : state.range },
        (v, i) => i + 1
      )
    } else {
      state.pages = Array.from({ length: props.total }, (v, i) => i + 1).slice(
        0 - state.range
      )
    }
  }
  
  onMounted(() => {
    countPageRange()
  })
  
  watch(
    () => props,
    async (value: string, oldValue: string) => {
        state.current = props?.page
        countPageRange()
    },
    { deep: true }
  )
  
</script>
<style lang="scss" scoped>
.current {
    border-bottom: unset;
}
</style>