<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.ReportBalanceQuarter.title') }}</h1>

        <history-item
          :detail="state.data_list"
          :title="state.title"
        ></history-item>

        <p class="ml10">{{ $t('pages.ReportBalanceQuarter.msg') }}</p>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import { getReportBalanceQuarter } from '@/api'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()

  const state: {
    title: any[]
    data_list:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    loading: boolean
  } = reactive({
    title: [],
    data_list: undefined,
    page: 1,
    loading: true
  })

  const showList = async () => {
    state.loading = true
    const { contents } = await getReportBalanceQuarter()
    state.data_list = contents
    state.loading = false
  }

  onMounted(async () => {
    state.title = [
      { key: 'target_date', name: t(`pages.ReportBalanceQuarter.item.title1`) },
      {
        key: 'url',
        name: t(`pages.ReportBalanceQuarter.item.title2`),
        url_key: 'url',
        type: 'url',
        url_name: t(`pages.ReportBalanceQuarter.link_name`)
      }
    ]
    await showList()
  })
</script>
<style lang="scss" scoped>
  .text-center {
    text-align: center;
  }
</style>
