<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li class="is-current">２{{ $t('pages.Corporation.title.step2') }}</li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Corporation.step2.title1') }}</h1>
        <ul class="form-normal">
          <SchemaFormWithValidation
            :schema="state.schema"
            :model="state.formJson"
            useCustomFormWrapper
            ref="form"
            class="corporation-form"
          />
        </ul>

        <h1>{{ $t('pages.Corporation.step2.title2') }}</h1>
        <p class="sub__title">{{ $t('pages.Corporation.step2.subTitle') }}</p>
        <ul class="form-normal" style="margin-top: 0; padding-top: 0">
          <SchemaFormWithValidation
            :schema="state.schemaOthers"
            :model="state.formJsonOthers"
            useCustomFormWrapper
            ref="formOthers"
            class="corporation-form"
            style="margin-top: 0; padding-top: 0"
          />

          <li>
            <span>
              <label
                >{{ $t('pages.Corporation.form.transaction_objective') }}
                <span class="required-notice">{{ $t('form.required') }}</span>
              </label>
            </span>
            <div class="checkbox-group" id="transaction_objective">
              <div
                class="checkbox-item"
                v-for="option in options.transaction_objective"
                :key="option.key"
              >
                <input
                  type="checkbox"
                  :id="option.value"
                  name="transaction_objective"
                  :value="option.key"
                  class="uk-checkbox"
                  v-model="transaction_objective"
                />
                <label :for="option.value">{{ option.value }}</label>
              </div>
            </div>
            <label
              class="error"
              style="margin-top: 0.5rem"
              v-if="transaction_objective_error"
              >{{
                $t('validation.required', {
                  content: t('pages.Corporation.form.transaction_objective'),
                  type: t('validation.type.select')
                })
              }}</label
            >
          </li>
          <li class="flex-1-1" v-show="transaction_objective?.includes('05')">
            <span>
              <label class="madd_title"
                >{{ $t('pages.Corporation.form.transaction_objective_abroad') }}
                <span class="required-notice">{{ $t('form.required') }}</span>
              </label>
            </span>
            <input
              type="text"
              required
              v-model="transaction_objective_abroad"
              class="form-control valid"
            />
            <label
              class="error"
              style="margin-top: -0.5rem"
              v-if="transaction_objective_abroad_error"
              >{{
                transaction_objective_abroad.length > 64
                  ? $t('validation.maxLength', {
                      maxLength:
                        validator.transaction_objective_abroad.maxLength
                    })
                  : $t('validation.required', {
                      content: t(
                        'pages.Corporation.step2.transaction_objective_abroad'
                      ),
                      type: t('validation.type.input')
                    })
              }}</label
            >
          </li>
          <li class="flex-1-1" v-show="transaction_objective?.includes('99')">
            <span>
              <label class="madd_title"
                >{{ $t('pages.Corporation.form.transaction_objective_other') }}
                <span class="required-notice">{{ $t('form.required') }}</span>
              </label>
            </span>
            <input
              type="text"
              required
              v-model="transaction_objective_other"
              class="form-control valid"
            />
            <label
              class="error"
              style="margin-top: -0.5rem"
              v-if="transaction_objective_other_error"
              >{{
                transaction_objective_other.length > 64
                  ? $t('validation.maxLength', {
                      maxLength: validator.transaction_objective_other.maxLength
                    })
                  : $t('validation.required', {
                      content: t(
                        'pages.Corporation.form.transaction_objective_other'
                      ),
                      type: t('validation.type.input')
                    })
              }}</label
            >
          </li>

          <SchemaFormWithValidation
            :schema="state.schemaOthers_2"
            :model="state.formJsonOthers_2"
            useCustomFormWrapper
            ref="formOthers_2"
            class="corporation-form"
            style="padding-top: 0"
          />
        </ul>

        <button
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
          v-debounce="{
            func: submit
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.form.submit')
          }"
          type="button"
          >{{ $t('pages.Corporation.form.submit') }}</button
        >
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import {
    addFormStyle,
    assignForm,
    checkDate,
    watchOptions
  } from '@/utils/tool'
  import { getCorporationExp, setCorporationExp } from '@/api'
  import { validator, options } from '@/enum/enums'

  const { t } = useI18n()
  const router = useRouter()

  const state: {
    init: boolean
    schema: any
    schemaOthers: any
    schemaOthers_2: any
    formJson: any
    formJsonOthers: any
    formJsonOthers_2: any
    errorMessage: string
  } = reactive({
    init: false,
    schema: {
      rep_name_sei: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_name_sei'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.rep_name_sei'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_kanji.regex as RegExp,
            t('validation.zenkaku_error')
          )
      },
      rep_name_mei: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_name_mei'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        placeholder: t('validation.naming_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.rep_name_mei'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_kanji.regex as RegExp,
            t('validation.zenkaku_error')
          )
      },
      rep_name_sei_kana: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_name_sei_kana'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        additionalNotesShow: true,
        additionalNotes: t('validation.space_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.rep_name_sei_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_katakana.regex as RegExp,
            t('validation.katakana_error')
          )
      },
      rep_name_mei_kana: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_name_mei_kana'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        placeholder: t('validation.naming_warning'),
        additionalNotesShow: true,
        additionalNotes: t('validation.space_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.rep_name_mei_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_katakana.regex as RegExp,
            t('validation.katakana_error')
          )
      },
      rep_birthday: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_birthday'),
        required: true,
        requiredStyle: 'form',
        type: 'date',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(t('validation.birthday_error'))
          .test('is-date', t('validation.birthday_error'), checkDate)
      },
      rep_country: {
        component: 'Select',
        label: t('pages.Corporation.form.rep_country'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.country,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.rep_country'),
            type: t('validation.type.select')
          })
        )
      },
      rep_position: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_position'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'flex-1-1',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.rep_position'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.rep_position.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.rep_position.maxLength
            })
          )
      },
      rep_peps: {
        component: 'Select',
        label: t('pages.Corporation.form.rep_peps'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.peps,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.rep_peps'),
            type: t('validation.type.select')
          })
        )
      },
      rep_peps_text: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_peps_text'),
        type: 'text',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        show: false,
        validations: undefined
      }
    },
    schemaOthers: {
      exp_stock: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_stock'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_stock'),
            type: t('validation.type.select')
          })
        )
      },
      exp_stock_credit: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_stock_credit'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_stock_credit'),
            type: t('validation.type.select')
          })
        )
      },
      exp_public_bonds: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_public_bonds'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_public_bonds'),
            type: t('validation.type.select')
          })
        )
      },
      exp_investment_trust: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_investment_trust'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_investment_trust'),
            type: t('validation.type.select')
          })
        )
      },
      exp_crypto_spot: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_crypto_spot'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_crypto_spot'),
            type: t('validation.type.select')
          })
        )
      },
      exp_crypto_leverage: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_crypto_leverage'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_crypto_leverage'),
            type: t('validation.type.select')
          })
        )
      }
    },
    schemaOthers_2: {
      motivation_user: {
        component: 'Select',
        label: t('pages.Corporation.form.motivation_user'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.motivation_user,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.motivation_user'),
            type: t('validation.type.select')
          })
        )
      },
      motivation_user_other: {
        component: 'Text',
        label: t('pages.Corporation.form.motivation_user_other'),
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        requiredStyle: 'form',
        show: false,
        validations: undefined
      },
      motivation_user_introducer: {
        component: 'Text',
        label: t('pages.Corporation.form.motivation_user_introducer'),
        type: 'text',
        inputClass: 'form-control valid',
        show: false,
        validations: yup.string().max(
          validator.motivation_user_introducer.maxLength as number,
          t('validation.maxLength', {
            maxLength: validator.motivation_user_introducer.maxLength
          })
        )
      }
    },
    formJson: {},
    formJsonOthers: {},
    formJsonOthers_2: {},
    errorMessage: ''
  })

  const transaction_objective = ref<string[]>([])
  const transaction_objective_abroad = ref('')
  const transaction_objective_other = ref('')
  const transaction_objective_error = ref(false)
  const transaction_objective_abroad_error = ref(false)
  const transaction_objective_other_error = ref(false)

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const formOthers = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const formOthers_2 = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    const validOthers = formOthers.value?.validate()
    const validOthers_2 = formOthers_2.value?.validate()

    if (transaction_objective.value.length === 0) {
      transaction_objective_error.value = true
      return
    }

    setTimeout(async () => {
      if (
        (valid?.meta?.valid &&
          validOthers?.meta?.valid &&
          validOthers_2?.meta?.valid &&
          !transaction_objective_error.value &&
          !transaction_objective_abroad_error.value &&
          !transaction_objective_other_error.value) ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await setCorporationExp({
            contents: {
              ...state.formJson,
              ...state.formJsonOthers,
              ...state.formJsonOthers_2,
              ...{ transaction_objective: transaction_objective.value },
              ...{
                transaction_objective_abroad: transaction_objective_abroad.value
              },
              ...{
                transaction_objective_other: transaction_objective_other.value
              }
            }
          })
          if (
            router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ) {
            router.push({ name: 'CorporationStep8' })
          } else {
            router.push({ name: 'CorporationStep2Confirm' })
          }
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop =
          document.getElementsByTagName('form')[0].offsetTop
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getCorporationExp(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      state.formJson = Object.assign(
        state.formJson,
        assignForm(state.schema, contents)
      )
      state.formJsonOthers = Object.assign(
        state.formJsonOthers,
        assignForm(state.schemaOthers, contents)
      )
      state.formJsonOthers_2 = Object.assign(
        state.formJsonOthers_2,
        assignForm(state.schemaOthers_2, contents)
      )
      transaction_objective.value = contents?.transaction_objective || []
      transaction_objective_abroad.value =
        contents?.transaction_objective_abroad || ''
      transaction_objective_other.value =
        contents?.transaction_objective_other || ''
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => state.formJsonOthers_2.motivation_user,
    async (value: string) => {
      watchOptions(
        value,
        '99',
        'motivation_user_other',
        state.schemaOthers_2,
        state.formJsonOthers_2,
        yup,
        t
      )

      if (value === '05') {
        state.schemaOthers_2.motivation_user_introducer.show = true
      } else {
        state.schemaOthers_2.motivation_user_introducer.show = false
        state.formJsonOthers_2.motivation_user_introducer = ''
      }
    },
    { immediate: true }
  )

  watch(
    () => state.formJson.rep_peps,
    async (value: string) => {
      watchOptions(
        value,
        '2',
        'rep_peps_text',
        state.schema,
        state.formJson,
        yup,
        t
      )
    },
    { immediate: true }
  )

  watch(
    () => transaction_objective.value,
    async (value: string[], oldValue: string[]) => {
      if (value.length === oldValue.length) return

      if (!value.includes('05')) transaction_objective_abroad.value = ''
      if (!value.includes('99')) transaction_objective_other.value = ''

      transaction_objective_error.value = value.length === 0
      transaction_objective_abroad_error.value =
        (value?.includes('05') && !transaction_objective_abroad.value) ||
        transaction_objective_abroad.value.length > 64
      transaction_objective_other_error.value =
        (value?.includes('99') && !transaction_objective_other.value) ||
        transaction_objective_other.value.length > 64
    }
  )

  watch(
    () => transaction_objective_abroad.value,
    async (value: string) => {
      transaction_objective_abroad_error.value =
        (transaction_objective.value?.includes('05') && !value) ||
        value.length > 64
    }
  )

  watch(
    () => transaction_objective_other.value,
    async (value: string) => {
      transaction_objective_other_error.value =
        (transaction_objective.value?.includes('99') && !value) ||
        value.length > 64
    }
  )
</script>

<style lang="scss" scoped>
  .checkbox-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    width: 100%;

    .checkbox-item {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1.25rem;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      label {
        flex: 1;
        font-weight: 300;
      }

      input[type='checkbox'] {
        margin-top: 0;
        margin-right: 5px;
        flex: 0 0 30px;
      }
    }
  }
</style>
