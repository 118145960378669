import i18n from '@/locale'
import Landing from '@/views/UM00/Home/Landing.vue'
import LP from '@/views/UM90/LP/LP.vue'
import { RouteRecordRaw } from 'vue-router'

export const routesArr: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    meta: {
      title: i18n?.global.t('menu.landing')
    },
    component: Landing
  },
  {
    path: '/lp/',
    name: 'LP',
    meta: {
      title: i18n?.global.t('menu.landing')
    },
    component: LP
  },

  // Home
  {
    path: '/home/',
    name: 'Home',
    meta: {
      title: i18n?.global.t('menu.home'),
      auth: true,
      home: true
    },
    component: () => import('@/views/UM01/Home/Home.vue')
  },
  // User
  {
    path: '/address_confirm/',
    name: 'AddressConfirm',
    meta: {
      title: i18n?.global.t('menu.user.addressConfirm'),
      auth: true
    },
    // TODO: File No.
    component: () => import('@/views/UM01/Home/AddressConfirm.vue')
  },
  {
    path: '/info_confirm/',
    name: 'InfoConfirm',
    meta: {
      title: i18n?.global.t('menu.user.infoConfirm'),
      auth: true,
      isCorporation: true
    },
    // TODO: File No.
    component: () => import('@/views/UM01/Confirm.vue')
  },
  {
    path: '/notification/',
    name: 'Notification',
    meta: {
      title: i18n?.global.t('menu.user.notification'),
      auth: true,
      isCorporation: true
    },
    component: () => import('@/views/UM01/Notification/Private.vue')
  },
  {
    path: '/notification/:id',
    name: 'NotificationPrivate',
    meta: {
      title: i18n?.global.t('menu.support.notification_public'),
      relate: 'NotificationPublic',
      breadcrumb: false,
      lang: false
    },
    component: () => import('@/views/UM01/Notification/Article.vue')
  },

  // Sign
  {
    path: '/new_account/:invite(invite)?/:invite_account?/',
    name: 'NewAccount',
    meta: {
      title: i18n?.global.t('menu.newAccount'),
      breadcrumb: true
    },
    component: () => import('@/views/UM00/Sign/NewAccount.vue')
  },
  {
    path: '/new_account/create_success/:email_encode',
    name: 'NewAccountCreateSuccess',
    meta: {
      title: i18n?.global.t('menu.newAccountCreateSuccess'),
      breadcrumb: true
    },
    component: () => import('@/views/UM00/Sign/NewAccountCreateSuccess.vue')
  },
  {
    path: '/pw_modify/:param_key/:email_encode',
    name: 'PWModify',
    meta: {
      title: i18n?.global.t('menu.pwModify'),
      breadcrumb: true
    },
    component: () => import('@/views/UM00/Sign/PWModify.vue')
  },
  {
    path: '/login/',
    name: 'Login',
    meta: {
      title: i18n?.global.t('menu.login'),
      breadcrumb: true
    },
    component: () => import('@/views/UM00/Sign/Login.vue')
  },
  {
    path: '/pw_reset',
    name: 'PasswordReset',
    meta: {
      title: i18n?.global.t('menu.passwordReset'),
      breadcrumb: true
    },
    component: () => import('@/views/UM00/Sign/PasswordReset.vue')
  },
  {
    path: '/pw_reset/success/:email_encode',
    name: 'PasswordResetSuccess',
    meta: {
      title: i18n?.global.t('menu.passwordResetSuccess'),
      breadcrumb: true
    },
    component: () => import('@/views/UM00/Sign/PasswordResetSuccess.vue')
  },

  // Trade
  {
    path: '/trade/:crypto/',
    name: 'Trade',
    meta: {
      title: i18n?.global.t('menu.user.trade.trade'),
      breadcrumb: false,
      menu: 'menu2'
    },
    component: () => import('@/views/UM02/Trade/Trade.vue')
  },
  // Transfer
  {
    path: '/wallet/transfer/',
    name: 'Transfer',
    meta: {
      title: i18n?.global.t('menu.user.transfer'),
      breadcrumb: false
    },
    component: () => import('@/views/UM03/Transfer/Transfer.vue')
  },
  // Deposit
  {
    path: '/wallet/deposit/quick/',
    name: 'DepositQuick',
    meta: {
      title: i18n?.global.t('menu.user.deposit.quick'),
      breadcrumb: false,
      menu: 'menu3'
    },
    component: () => import('@/views/UM04/Fiat/DepositQuick.vue')
  },
  {
    path: '/wallet/deposit/bank/',
    name: 'DepositBank',
    meta: {
      title: i18n?.global.t('menu.user.deposit.deposit'),
      breadcrumb: false,
      menu: 'menu3'
    },
    component: () => import('@/views/UM04/Fiat/DepositBank.vue')
  },
  {
    path: '/wallet/deposit/conveni/agreement/',
    name: 'DepositConveniAgreement',
    meta: {
      title: i18n?.global.t('menu.user.deposit.convenience'),
      breadcrumb: false,
      menu: 'menu3'
    },
    component: () => import('@/views/UM04/Fiat/DepositConveniAgreement.vue')
  },
  {
    path: '/wallet/deposit/conveni/',
    name: 'DepositConveni',
    meta: {
      title: i18n?.global.t('menu.user.deposit.convenience'),
      breadcrumb: false,
      menu: 'menu3'
    },
    component: () => import('@/views/UM04/Fiat/DepositConveni.vue')
  },
  {
    path: '/wallet/deposit/:crypto/',
    name: 'DepositCrypto',
    meta: {
      title: i18n?.global.t('menu.user.deposit.crypto'),
      breadcrumb: false,
      menu: 'menu3'
    },
    component: () => import('@/views/UM04/Crypto/DepositCrypto.vue')
  },
  // Withdrawal
  {
    path: '/wallet/withdrawal/bank/',
    name: 'WithdrawalBank',
    meta: {
      title: i18n?.global.t('menu.user.withdraw.withdraw'),
      breadcrumb: false,
      menu: 'menu4'
    },
    component: () => import('@/views/UM05/Fiat/WithdrawalBank.vue')
  },
  {
    path: '/wallet/withdrawal/:crypto/',
    name: 'WithdrawalCrypto',
    meta: {
      title: i18n?.global.t('menu.user.withdraw.crypto'),
      breadcrumb: false,
      menu: 'menu4'
    },
    component: () => import('@/views/UM05/Crypto/WithdrawalCrypto.vue')
  },

  // Corporation
  {
    path: '/corporation/step1/input/',
    name: 'CorporationStep1Input',
    meta: {
      title: i18n?.global.t('menu.corporation.step1Input'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step1Input.vue')
  },
  {
    path: '/corporation/step1/confirm/',
    name: 'CorporationStep1Confirm',
    meta: {
      title: i18n?.global.t('menu.corporation.step1Confirm'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step1Confirm.vue')
  },
  {
    path: '/corporation/step2/input/',
    name: 'CorporationStep2Input',
    meta: {
      title: i18n?.global.t('menu.corporation.step2Input'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step2Input.vue')
  },
  {
    path: '/corporation/step2/confirm/',
    name: 'CorporationStep2Confirm',
    meta: {
      title: i18n?.global.t('menu.corporation.step2Confirm'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step2Confirm.vue')
  },
  {
    path: '/corporation/step3/input/',
    name: 'CorporationStep3Input',
    meta: {
      title: i18n?.global.t('menu.corporation.step3Input'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Corporation/Step3Input.vue')
  },
  {
    path: '/corporation/step3/confirm/',
    name: 'CorporationStep3Confirm',
    meta: {
      title: i18n?.global.t('menu.corporation.step3Confirm'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step3Confirm.vue')
  },
  {
    path: '/corporation/step4/select/',
    name: 'CorporationStep4Select',
    meta: {
      title: i18n?.global.t('menu.corporation.step4Select'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step4Select.vue')
  },
  {
    path: '/corporation/step4/input/:multi',
    name: 'CorporationStep4Input',
    meta: {
      title: i18n?.global.t('menu.corporation.step4Input'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step4Input.vue')
  },
  {
    path: '/corporation/step4/confirm/:multi',
    name: 'CorporationStep4Confirm',
    meta: {
      title: i18n?.global.t('menu.corporation.step4Confirm'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step4Confirm.vue')
  },
  {
    path: '/corporation/step5/input/',
    name: 'CorporationStep5Input',
    meta: {
      title: i18n?.global.t('menu.corporation.step5Input'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step5Input.vue')
  },
  {
    path: '/corporation/step5/confirm/',
    name: 'CorporationStep5Confirm',
    meta: {
      title: i18n?.global.t('menu.corporation.step5Confirm'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step5Confirm.vue')
  },
  {
    path: '/corporation/step6/input/',
    name: 'CorporationStep6Input',
    meta: {
      title: i18n?.global.t('menu.corporation.step6Input'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step6Input.vue')
  },
  {
    path: '/corporation/step7/input/',
    name: 'CorporationStep7Input',
    meta: {
      title: i18n?.global.t('menu.corporation.step7Input'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step7Input.vue')
  },
  {
    path: '/corporation/step8/',
    name: 'CorporationStep8',
    meta: {
      title: i18n?.global.t('menu.corporation.step8'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step8.vue')
  },
  {
    path: '/corporation/step9/',
    name: 'CorporationStep9',
    meta: {
      title: i18n?.global.t('menu.corporation.step9'),
      footer: false,
      header: false,
      auth: true,
      association: true,
      lang: false
    },
    component: () => import('@/views/UM07/Corporation/Step9.vue')
  },

  {
    path: '/account/',
    name: 'Customer',
    meta: {
      title: i18n?.global.t('menu.user.information'),
      auth: true,
      isCorporation: true
    },
    // TODO: File No.
    component: () => import('@/views/UM07/Customer.vue')
  },

  // Individual
  {
    path: '/agreement/',
    name: 'Agreement',
    meta: {
      title: i18n?.global.t('menu.agreement'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM01/Agreement.vue')
  },
  {
    path: '/important/',
    name: 'Important',
    meta: {
      title: i18n?.global.t('menu.important'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM01/Important.vue')
  },
  {
    path: '/individual/step1/input/',
    name: 'IndividualStep1',
    meta: {
      title: i18n?.global.t('menu.individual.step1'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step1.vue')
  },
  // TwoStep - Login
  {
    path: '/two_step_check/',
    name: 'TwoStepCheck',
    meta: {
      title: i18n?.global.t('menu.user.twoStepCheck'),
      auth: true
    },

    component: () => import('@/views/UM01/TwoStep/TwoStepCheck.vue')
  },

  {
    path: '/individual/step1/confirm/',
    name: 'IndividualStep1Confirm',
    meta: {
      title: i18n?.global.t('menu.individual.step1'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step1Confirm.vue')
  },
  {
    path: '/individual/step2/select/',
    name: 'IndividualStep2',
    meta: {
      title: i18n?.global.t('menu.individual.step2'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step2.vue')
  },

  {
    path: '/individual/step2/qrcode/',
    name: 'IndividualStep2QR',
    meta: {
      title: i18n?.global.t('menu.individual.step2'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step2QR.vue')
  },
  {
    path: '/individual/step2/upload/',
    name: 'IndividualStep2Upload',
    meta: {
      title: i18n?.global.t('menu.individual.step2'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step2Upload.vue')
  },
  {
    path: '/individual/step2/success/:id/',
    name: 'IndividualStep2Redirect',
    meta: {
      title: i18n?.global.t('menu.individual.step2'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step2Redirect.vue')
  },
  {
    path: '/individual/step2/success_sp/:id/',
    name: 'IndividualStep2RedirectSP',
    meta: {
      title: i18n?.global.t('menu.individual.step2'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step2RedirectSP.vue')
  },
  {
    path: '/individual/step3/',
    name: 'IndividualStep3',
    meta: {
      title: i18n?.global.t('menu.individual.step3'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step3.vue')
  },
  {
    path: '/individual/step4/',
    name: 'IndividualStep4',
    meta: {
      title: i18n?.global.t('menu.individual.step4'),
      footer: false,
      header: false,
      auth: true,
      association: true
    },
    component: () => import('@/views/UM07/Individual/Step4.vue')
  },

  // Beginner
  {
    path: '/beginner/',
    name: 'Beginner',
    meta: {
      title: i18n?.global.t('menu.beginner.beginner'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Beginner/Index.vue')
  },
  {
    path: '/beginner/account_opening/',
    name: 'AccountOpening',
    meta: {
      title: i18n?.global.t('menu.beginner.account_opening'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Beginner/AccountOpening.vue')
  },
  {
    path: '/beginner/transaction/',
    name: 'Transaction',
    meta: {
      title: i18n?.global.t('menu.beginner.transaction'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Beginner/Transaction.vue')
  },
  {
    path: '/beginner/required_documents/',
    name: 'RequiredDocuments',
    meta: {
      title: i18n?.global.t('menu.beginner.required_documents'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Beginner/RequiredDocuments.vue')
  },
  {
    path: '/beginner/about_twostep/',
    name: 'AboutTwoStep',
    meta: {
      title: i18n?.global.t('menu.beginner.about_two_step'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Beginner/AboutTwoStep.vue')
  },

  // Service
  {
    path: '/service/',
    name: 'Service',
    meta: {
      title: i18n?.global.t('menu.service.service'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Service/Index.vue')
  },
  {
    path: '/service/strength/',
    name: 'Strength',
    meta: {
      title: i18n?.global.t('menu.service.strength'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Service/Strength.vue')
  },
  {
    path: '/service/tool/',
    name: 'Tool',
    meta: {
      title: i18n?.global.t('menu.service.tool'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Service/Tool.vue')
  },
  {
    path: '/service/fee/',
    name: 'Fee',
    meta: {
      title: i18n?.global.t('menu.service.fee'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Service/Fee.vue')
  },
  {
    path: '/service/corporation/',
    name: 'Corporation',
    meta: {
      title: i18n?.global.t('menu.service.corporation'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Service/Corporation.vue')
  },
  {
    path: '/service/application_crypto_assets/',
    name: 'ApplicationCryptoAssets',
    meta: {
      title: i18n?.global.t('menu.service.application_crypto_assets'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Service/ApplicationCryptoAssets.vue')
  },
  {
    path: '/service/point/',
    name: 'PointService',
    meta: {
      title: i18n?.global.t('menu.service.point'),
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Service/Point.vue')
  },
  {
    path: '/campaign/',
    name: 'Campaign',
    meta: {
      title: i18n?.global.t('menu.service.campaign'),
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Service/Campaign.vue')
  },
  {
    path: '/campaign/kiji/:id',
    name: 'CampaignDetail',
    meta: {
      title: i18n?.global.t('menu.service.campaignDetail'),
      relate: 'Campaign',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Service/CampaignDetail.vue')
  },

  // Coin Info
  {
    path: '/coininfo/',
    name: 'CoinInfo',
    meta: {
      title: i18n?.global.t('menu.coinInfo.coinInfo'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/CoinInfo/Index.vue')
  },
  {
    path: '/coininfo/:coin/',
    name: 'CoinDetail',
    meta: {
      title: i18n?.global.t('menu.coinInfo.coinInfo'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/CoinInfo/Coin.vue')
  },

  // Company
  {
    path: '/company/',
    name: 'Company',
    meta: {
      title: i18n?.global.t('menu.company.company'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Company/Index.vue')
  },
  {
    path: '/company/profile/',
    name: 'Profile',
    meta: {
      title: i18n?.global.t('menu.company.profile'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Company/Profile.vue')
  },
  {
    path: '/company/management/',
    name: 'Management',
    meta: {
      title: i18n?.global.t('menu.company.management'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Company/Management.vue')
  },
  {
    path: '/company/legal/',
    name: 'Legal',
    meta: {
      title: i18n?.global.t('menu.company.legal'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Company/Legal.vue')
  },

  // Common
  {
    path: '/common/career/',
    name: 'Career',
    meta: {
      title: i18n?.global.t('menu.common.career'),
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Common/Career.vue')
  },

  // History
  {
    path: '/history/login/',
    name: 'LoginHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.login'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Resource/LoginHistory.vue')
  },
  {
    path: '/history/wallet/fiat/',
    name: 'WalletFiatHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.fundJpy'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Wallet/WalletFiatHistory.vue')
  },
  {
    path: '/history/wallet/:crypto/',
    name: 'WalletCryptoHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.fundCrypto'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Wallet/WalletCryptoHistory.vue')
  },
  {
    path: '/history/transfer/transfer/',
    name: 'TransferHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.fundTransfer'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Transfer/TransferHistory.vue')
  },
  {
    path: '/history/trade/:crypto/',
    name: 'TradeHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.fundTrade'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Trade/TradeHistory.vue')
  },
  {
    path: '/history/point/buy_point/',
    name: 'BuyPointHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.pointUse'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Point/BuyPointHistory.vue')
  },
  {
    path: '/history/point/buy_crypto/:crypto/',
    name: 'PointBuyCryptoHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.pointCrypto'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Point/PointBuyCryptoHistory.vue')
  },
  {
    path: '/history/balance/',
    name: 'BalanceHistory',
    meta: {
      title: i18n?.global.t('menu.user.history.balance'),
      breadcrumb: false,
      menu: 'menu6'
    },
    component: () => import('@/views/UM06/Balance/BalanceHistory.vue')
  },

  // Setting
  {
    path: '/setting/mail_delivery/',
    name: 'MailSetting',
    meta: {
      title: i18n?.global.t('menu.mailSetting'),
      breadcrumb: false,
      menu: 'menu8'
    },
    component: () => import('@/views/UM08/MailDelivery/MailSetting.vue')
  },
  {
    path: '/setting/bank/',
    name: 'BankSetting',
    meta: {
      title: i18n?.global.t('menu.user.setting.bank'),
      breadcrumb: false,
      menu: 'menu8'
    },
    component: () => import('@/views/UM08/Bank/BankSetting.vue')
  },
  {
    path: '/setting/password/',
    name: 'PasswordSetting',
    meta: {
      title: i18n?.global.t('menu.user.setting.password'),
      breadcrumb: false,
      menu: 'menu8'
    },
    component: () => import('@/views/UM08/Password/PasswordSetting.vue')
  },
  {
    path: '/setting/notification/',
    name: 'NotificationSetting',
    meta: {
      title: i18n?.global.t('menu.user.setting.notification'),
      breadcrumb: false,
      menu: 'menu8'
    },
    component: () => import('@/views/UM08/Notification/NotificationSetting.vue')
  },
  {
    path: '/setting/twostep/',
    name: 'TwoStepStatus',
    meta: {
      title: i18n?.global.t('pages.TwoStepStatus.title'),
      auth: true,
      menu: 'menu8'
    },
    component: () => import('@/views/UM08/TwoStep/TwoStepStatus.vue')
  },
  {
    path: '/setting/twostep/setting/',
    name: 'TwoStepSetting',
    meta: {
      title: i18n?.global.t('pages.TwoStepSetting.title'),
      auth: true,
      menu: 'menu8'
    },
    component: () => import('@/views/UM08/TwoStep/TwoStepSetting.vue')
  },
  /*
  {
    path: '/setting/twostep/update/:type',
    name: 'TwoStepUpdate',
    meta: {
      title: i18n?.global.t('pages.TwoStepUpdate.title'),
      auth: true
    },
    component: () => import('@/views/UM08/TwoStep/TwoStepUpdate.vue')
  },
   */

  // Invite
  {
    path: '/invite/',
    name: 'Invite',
    meta: {
      title: i18n?.global.t('menu.user.invite'),
      auth: true
    },
    component: () => import('@/views/UM12/Invite/Invite.vue')
  },

  // Notification - Public
  {
    path: '/notification_public/',
    name: 'NotificationPublic',
    meta: {
      title: i18n?.global.t('menu.support.notification_public'),
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM00/Notification/Public.vue')
  },
  {
    path: '/notification_public/:id',
    name: 'NotificationArticle',
    meta: {
      title: i18n?.global.t('menu.support.notification_public'),
      relate: 'NotificationPublic',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM00/Notification/Article.vue')
  },

  // Support
  {
    path: '/support/',
    name: 'Support',
    meta: {
      title: i18n?.global.t('menu.support.support'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Support/Support.vue')
  },
  {
    path: '/inquiry/inquiry/:inquiry_type_id?',
    name: 'Inquiry',
    meta: {
      title: i18n?.global.t('menu.support.inquiry'),
      breadcrumb: true,
      user: false,
      menu: 'menu9'
    },
    component: () => import('@/views/UM09/Inquiry/Inquiry.vue')
  },
  {
    path: '/inquiry/success/',
    name: 'InquirySuccess',
    meta: {
      title: i18n?.global.t('menu.support.inquiry'),
      breadcrumb: true,
      user: false,
      menu: 'menu9'
    },
    component: () => import('@/views/UM09/Inquiry/Success.vue')
  },
  {
    path: '/report/balance/quarter/',
    name: 'ReportBalanceQuarter',
    meta: {
      title: i18n?.global.t('menu.user.report.report'),
      breadcrumb: false,
      user: false,
      menu: 'menu7'
    },
    component: () => import('@/views/UM11/Report/ReportBalanceQuarter.vue')
  },
  {
    path: '/report/balance/year/',
    name: 'ReportBalanceYear',
    meta: {
      title: i18n?.global.t('menu.user.report.reportAnnual'),
      breadcrumb: false,
      user: false,
      menu: 'menu7'
    },
    component: () => import('@/views/UM11/Report/ReportBalanceYear.vue')
  },

  // Point
  {
    path: '/wallet_point/deposit/bank/',
    name: 'PointDepositBank',
    meta: {
      title: i18n?.global.t('menu.user.point.jpy'),
      breadcrumb: false,
      menu: 'menu5'
    },
    component: () => import('@/views/UM21/Point/PointDepositBank.vue')
  },
  {
    path: '/wallet_point/crypto/:crypto/',
    name: 'PointCrypto',
    meta: {
      title: i18n?.global.t('menu.user.point.crypto'),
      breadcrumb: false,
      menu: 'menu5'
    },
    component: () => import('@/views/UM21/Point/PointCrypto.vue')
  },
  {
    path: '/wallet_point/balance/',
    name: 'PointBalance',
    meta: {
      title: i18n?.global.t('menu.user.point.balance'),
      breadcrumb: false,
      menu: 'menu5'
    },
    component: () => import('@/views/UM21/Point/PointBalance.vue')
  },
  // HyperWallet
  {
    path: '/hyper_wallet/',
    name: 'HyperWalletPayment',
    meta: {
      title: i18n?.global.t('menu.hyper_payment'),
      breadcrumb: false
    },
    component: () => import('@/views/UM22/Hyper/Payment.vue')
  },

  {
    path: '/support/faq/',
    name: 'FAQ',
    meta: {
      title: i18n?.global.t('menu.support.faq'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Support/FAQ.vue')
  },
  {
    path: '/support/sitemap/',
    name: 'SiteMap',
    meta: {
      title: i18n?.global.t('menu.support.sitemap'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Support/SiteMap.vue')
  },
  {
    path: '/support/foreign_official/',
    name: 'ForeignOfficial',
    meta: {
      title: i18n?.global.t('menu.support.foreign_official'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Support/ForeignOfficial.vue')
  },

  // Column
  {
    path: '/columns/',
    name: 'Column',
    meta: {
      title: '用語集・コラム',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Column/Index.vue')
  },
  {
    path: '/columns/category/glossary/',
    name: 'Glossary',
    meta: {
      title: '用語集',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Column/Glossary.vue')
  },
  {
    path: '/columns/category/beginners/',
    name: 'Beginners',
    meta: {
      title: '初心者向け',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Column/Beginners.vue')
  },
  {
    path: '/columns/category/advanced/',
    name: 'Advanced',
    meta: {
      title: '中・上級者向け',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Column/Advanced.vue')
  },
  {
    path: '/columns/category/trend_commentary/',
    name: 'TrendCommentary',
    meta: {
      title: '動向解説',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Column/TrendCommentary.vue')
  },
  {
    path: '/columns/article/:id',
    name: 'ColumnArticle',
    meta: {
      title: '用語集・コラム',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Column/Article.vue')
  },
  {
    path: '/columns/tag/:name',
    name: 'Tag',
    meta: {
      title: '用語集・コラム',
      breadcrumb: true,
      lang: false
    },
    component: () => import('@/views/UM90/Column/Tag.vue')
  },

  // Policy
  {
    path: '/policy/explanatory_document/',
    name: 'ExplanatoryDocument',
    meta: {
      title: i18n?.global.t('menu.policy.explanatory_document'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/ExplanatoryDocument.vue')
  },
  {
    path: '/policy/antisocial/',
    name: 'AntiSocial',
    meta: {
      title: i18n?.global.t('menu.policy.antisocial'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/AntiSocial.vue')
  },
  {
    path: '/policy/antisocial_elimination/',
    name: 'AntiSocialElimination',
    meta: {
      title: i18n?.global.t('menu.policy.antisocial_elimination'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/AntiSocialElimination.vue')
  },
  {
    path: '/policy/privacypolicy/',
    name: 'PrivacyPolicy',
    meta: {
      title: i18n?.global.t('menu.support.privacy_policy'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/PrivacyPolicy.vue')
  },
  {
    path: '/policy/ethics/',
    name: 'Ethics',
    meta: {
      title: i18n?.global.t('menu.policy.ethics'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/Ethics.vue')
  },
  {
    path: '/policy/debt_fulfillment/',
    name: 'DebtFulfillment',
    meta: {
      title: i18n?.global.t('menu.policy.debt_fulfillment'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/DebtFulfillment.vue')
  },
  {
    path: '/policy/conflict_interest/',
    name: 'ConflictInterest',
    meta: {
      title: i18n?.global.t('menu.policy.conflict_interest'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/ConflictInterest.vue')
  },
  {
    path: '/policy/securitypolicy/',
    name: 'SecurityPolicy',
    meta: {
      title: i18n?.global.t('menu.policy.securitypolicy'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/SecurityPolicy.vue')
  },
  {
    path: '/policy/response_policy/',
    name: 'ResponsePolicy',
    meta: {
      title: i18n?.global.t('menu.policy.response_policy'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/ResponsePolicy.vue')
  },
  {
    path: '/policy/claim/',
    name: 'Claim',
    meta: {
      title: i18n?.global.t('menu.policy.claim'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/Claim.vue')
  },
  {
    path: '/policy/regulation/',
    name: 'Regulation',
    meta: {
      title: i18n?.global.t('menu.policy.regulation_basic_contract'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/Regulation.vue')
  },
  {
    path: '/policy/contract/',
    name: 'Contract',
    meta: {
      title: i18n?.global.t(
        'menu.policy.contract_pre_conclusion_delivery_documents'
      ),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/Contract.vue')
  },
  {
    path: '/policy/bp_terms_of_service/',
    name: 'BPTermsOfService',
    meta: {
      title: i18n?.global.t('menu.policy.bp_terms_of_service'),
      breadcrumb: true
    },
    component: () => import('@/views/UM90/Policy/BPTermsOfService.vue')
  },
  {
    path: '/policy/judging_application_crypto_assets/',
    name: 'JudgingApplicationCryptoAssets',
    meta: {
      title: i18n?.global.t('menu.policy.judging_application_crypto_assets'),
      breadcrumb: true
    },
    component: () =>
      import('@/views/UM90/Policy/JudgingApplicationCryptoAssets.vue')
  },
  // Error Page
  {
    path: '/error/maintenance/',
    name: 'Maintenance',
    meta: {
      title: i18n?.global.t('error')
    },
    component: () => import('@/views/UM90/Error/Maintenance.vue')
  },
  {
    path: '/error/',
    name: 'Error',
    meta: {
      title: i18n?.global.t('error')
    },
    component: () => import('@/views/UM90/Error/Error.vue')
  },
  {
    path: '/err/',
    name: 'ErrorPublic',
    meta: {
      title: i18n?.global.t('error')
    },
    component: () => import('@/views/UM90/Error/ErrorPublic.vue')
  },
  // 500
  {
    path: '/500/',
    name: '500',
    meta: {
      title: i18n?.global.t('500')
    },
    component: () => import('@/views/UM90/Error/500.vue')
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      title: i18n?.global.t('404')
    },
    component: () => import('@/views/UM90/Error/404.vue')
  }
]
