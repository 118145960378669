<template>
  <div v-if="!state.init">
    <the-loading style="margin-top: 100px; margin-bottom: 100px"></the-loading>
  </div>

  <template v-else>
    <div class="tstep">
      <img
        :src="`${s3Url}img/pc_step2.${language}.png?v=1`"
        class="sp_no"
        alt="step"
      />
      <img
        :src="`${s3Url}img/sp_step2.${language}.png?v=1`"
        class="sp"
        alt="step"
      />
    </div>
    <the-message v-if="state.errorMessage" class="form-error">{{
      state.errorMessage
    }}</the-message>
    <div class="individual">
      <div class="individual-wrapper">
        <ol class="step mb30">
          <li class="is-current br-tl-6 br-bl-6"
            >１{{ $t('pages.Individual.title.step1') }}</li
          >
          <li><span style="padding-left: 1em">２</span></li>
          <li><span style="padding-left: 1em">３</span></li>
          <li class="br-tr-6 br-br-6">{{
            $t('pages.Individual.title.step4')
          }}</li>
        </ol>

        <form>
          <h1>{{ $t('pages.Individual.title.step1Confirm') }}</h1>
          <p>{{ $t('pages.Individual.step1.msg4') }}</p>

          <step1 :data="state.formJson"></step1>

          <button
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.Individual.step1.confirm')
            }"
            type="button"
            >{{ $t('pages.Individual.step1.confirm') }}</button
          >
          <button
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
            v-debounce="{
              func: rollback
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.Individual.step1.rollback')
            }"
            type="button"
            >{{ $t('pages.Individual.step1.rollback') }}</button
          >
        </form>
      </div>
    </div>
  </template>
  <!-- ここから Crib Notesユニバーサルタグのコード -->
  <component :is="'script'" v-if="env === 'prod' && state.clib_counter_flg">
    (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/" + a
    + "/atm.js"; e.id = d; e.async = true;
    b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
    "script", "__cribnotesTagMgrCmd", "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
    "https://tag.cribnotes.jp/container_manager");
  </component>
  <!-- ここまで Crib Notesユニバーサルタグのコード -->
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { getIndividual, setCorporationStatus } from '@/api'
  import Step1 from '@/components/individual/Step1.vue'

  const router = useRouter()
  const store = useMainStore()
  const { s3Url, language, env } = storeToRefs(store)

  const state: {
    clib_counter_flg: boolean
    init: boolean
    errorMessage: string
    formJson: any
  } = reactive({
    clib_counter_flg: false,
    init: false,
    errorMessage: '',
    formJson: {}
  })

  const loading = ref(false)

  const submit = async () => {
    console.log('submit')
    loading.value = true
    try {
      await setCorporationStatus('01')
      router.push({ name: 'IndividualStep2' })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    }
    loading.value = false
  }

  const rollback = async () => {
    console.log('rollback')
    router.push({ name: 'IndividualStep1' })
  }

  onMounted(async () => {
    try {
      const { contents } = await getIndividual()
      state.clib_counter_flg = contents?.clib_counter_flg
      state.formJson = Object.assign(state.formJson, contents)
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>
