<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li class="is-current">３{{ $t('pages.Corporation.title.step3') }}</li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Corporation.step3.title') }}</h1>
        <p style="text-align: center; font-weight: bold">{{
          $t('pages.Corporation.step3.msg1')
        }}</p>
        <label class="check_in">
          <input
            type="checkbox"
            class="uk-checkbox"
            v-model="state.isChecked"
            :disabled="state.checkDisabled"
          />
          {{ $t('pages.Corporation.step3.checkbox') }}
        </label>
        <p
          style="color: red"
          v-if="
            router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          "
          >{{ $t('pages.Corporation.step3.warning') }}</p
        >
        <ul class="form-normal">
          <SchemaFormWithValidation
            :schema="state.schema"
            :model="state.formJson"
            useCustomFormWrapper
            ref="form"
            class="corporation-form"
          />
        </ul>

        <button
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
          v-debounce="{
            func: submit
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.form.submit')
          }"
          type="button"
          >{{ $t('pages.Corporation.form.submit') }}</button
        >
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import {
    addFormStyle,
    checkDate,
    assignForm,
    watchOptions
  } from '@/utils/tool'
  import { validator, options } from '@/enum/enums'
  import {
    getTradeHead,
    setTradeHead,
    getCorporationExp,
    getAddrByPostCode
  } from '@/api'

  const { t } = useI18n()
  const router = useRouter()

  const state: {
    schema: any
    formJson: any
    errorMessage: string
    isChecked: boolean
    checkDisabled: boolean
    init: boolean
  } = reactive({
    schema: {
      name_sei: {
        component: 'Text',
        label: t('pages.Corporation.form.name_sei'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.name_sei'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_kanji.regex as RegExp,
            t('validation.zenkaku_error')
          )
      },
      name_mei: {
        component: 'Text',
        label: t('pages.Corporation.form.name_mei'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        placeholder: t('validation.naming_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.name_mei'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_kanji.regex as RegExp,
            t('validation.zenkaku_error')
          )
      },
      name_sei_kana: {
        component: 'Text',
        label: t('pages.Corporation.form.name_sei_kana'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        additionalNotesShow: true,
        additionalNotes: t('validation.space_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.name_sei_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_katakana.regex as RegExp,
            t('validation.katakana_error')
          )
      },
      name_mei_kana: {
        component: 'Text',
        label: t('pages.Corporation.form.name_mei_kana'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        placeholder: t('validation.naming_warning'),
        additionalNotesShow: true,
        additionalNotes: t('validation.space_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.name_mei_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_katakana.regex as RegExp,
            t('validation.katakana_error')
          )
      },
      birthday: {
        component: 'Text',
        label: t('pages.Corporation.form.birthday'),
        required: true,
        requiredStyle: 'form',
        type: 'date',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(t('validation.birthday_error'))
          .test('is-date', t('validation.birthday_error'), checkDate)
      },
      sex: {
        component: 'Select',
        label: t('pages.Corporation.form.sex'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.sex,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.sex'),
            type: t('validation.type.select')
          })
        )
      },
      country: {
        component: 'Select',
        label: t('pages.Corporation.form.country'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        liClass: 'flex-1-1',
        options: options.country,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.country'),
            type: t('validation.type.select')
          })
        )
      },
      post_no: {
        component: 'Text',
        label: t('pages.Corporation.form.post_no'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'flex-1-1',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.post_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.post_no.regex as RegExp,
            t('validation.input_error', {
              content: t('pages.Corporation.form.post_no')
            })
          )
      },
      addr1: {
        component: 'Text',
        label: t('pages.Corporation.form.addr1'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr1.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr1.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.addr1'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr1.regex as RegExp,
            t('validation.input_error', {
              content: t('pages.Corporation.form.addr1')
            })
          )
      },
      addr2: {
        component: 'Text',
        label: t('pages.Corporation.form.addr2'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr2.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.addr2.maxLength
            })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.addr2'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr2.regex as RegExp,
            t('validation.input_error', {
              content: t('pages.Corporation.form.addr2')
            })
          )
      },
      addr3: {
        component: 'Text',
        label: t('pages.Corporation.form.addr3'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        additionalNotesShow: true,
        additionalNotes: t('validation.zen_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr3.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr3.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.addr3'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr3.regex as RegExp,
            t('validation.input_error', {
              content: t('pages.Corporation.form.addr3')
            })
          )
      },
      priority_phone: {
        component: 'Select',
        label: t('pages.Corporation.form.priority_phone'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.priority_phone,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.priority_phone'),
            type: t('validation.type.select')
          })
        )
      },
      mobile_no: {
        component: 'Text',
        label: t('pages.Corporation.form.mobile_no'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.mobile_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.mobile_no.regex as RegExp,
            t('validation.hankaku_number_error', { number: 11 })
          )
      },
      phone_no: {
        component: 'Text',
        label: t('pages.Corporation.form.phone_no'),
        type: 'text',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: undefined
      },
      department: {
        component: 'Text',
        label: t('pages.Corporation.form.department'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.department'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.department.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.department.maxLength
            })
          )
      },
      email: {
        component: 'Text',
        label: t('pages.Corporation.form.email'),
        type: 'text',
        inputClass: 'form-control valid',
        required: true,
        requiredStyle: 'form',
        disabled: true,
        readonly: true,
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.email'),
              type: t('validation.type.input')
            })
          )
          .email(
            t('validation.input_error', {
              content: t('validation.item.email')
            })
          )
          .max(
            validator.email.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.email.maxLength
            })
          )
      },
      position: {
        component: 'Text',
        label: t('pages.Corporation.form.position'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.position'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.position.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.position.maxLength
            })
          )
      },
      relationship: {
        component: 'Text',
        label: t('pages.Corporation.form.relationship'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.relationship'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.relationship.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.relationship.maxLength
            })
          )
      },
      peps: {
        component: 'Select',
        label: t('pages.Corporation.form.peps'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.peps,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.peps'),
            type: t('validation.type.select')
          })
        )
      },
      peps_text: {
        component: 'Text',
        label: t('pages.Corporation.form.peps_text'),
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        show: false,
        validations: undefined
      },
      others: {
        component: 'Others',
        html: '<p class="sub__title" style="margin: 40px 0 20px 0">取引担当者としての投資経験をご選択ください</p>'
      },
      exp_stock: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_stock'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_stock'),
            type: t('validation.type.select')
          })
        )
      },
      exp_stock_credit: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_stock_credit'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_stock_credit'),
            type: t('validation.type.select')
          })
        )
      },
      exp_public_bonds: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_public_bonds'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_public_bonds'),
            type: t('validation.type.select')
          })
        )
      },
      exp_investment_trust: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_investment_trust'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_investment_trust'),
            type: t('validation.type.select')
          })
        )
      },
      exp_crypto_spot: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_crypto_spot'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_crypto_spot'),
            type: t('validation.type.select')
          })
        )
      },
      exp_crypto_leverage: {
        component: 'Select',
        label: t('pages.Corporation.form.exp_crypto_leverage'),
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        required: true,
        requiredStyle: 'form',
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.exp_crypto_leverage'),
            type: t('validation.type.select')
          })
        )
      }
    },
    formJson: {},
    errorMessage: '',
    isChecked: false,
    checkDisabled: false,
    init: false
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await setTradeHead({
            contents: {
              ...state.formJson
            }
          })
          if (
            router.currentRoute.value.query?.uncheck_flg?.toString() === '1' &&
            !router.currentRoute.value.query?.callback
          ) {
            router.push({ name: 'CorporationStep8' })
          } else if (router.currentRoute.value.query?.callback === 'step7') {
            router.push({ name: 'CorporationStep7Input' })
          } else {
            router.push({ name: 'CorporationStep3Confirm' })
          }
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop =
          document.getElementsByTagName('form')[0].offsetTop
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getTradeHead(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      state.formJson = Object.assign(
        state.formJson,
        assignForm(state.schema, contents)
      )
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => state.isChecked,
    async (value: boolean) => {
      state.checkDisabled = true
      if (value) {
        try {
          const { contents } = await getCorporationExp('uncheck')
          Object.keys(state.schema).forEach((key: string) => {
            if (contents[`rep_${key}`])
              state.formJson[key] = contents[`rep_${key}`]
          })
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
        } finally {
          state.checkDisabled = false
        }
      } else {
        Object.keys(state.schema).forEach((key: string) => {
          if (key !== 'email') {
            state.formJson[key] = ''
          }
        })
        state.checkDisabled = false
      }
    }
  )

  watch(
    () => state.formJson.peps,
    async (value: string) => {
      watchOptions(
        value,
        '2',
        'peps_text',
        state.schema,
        state.formJson,
        yup,
        t
      )
    },
    { immediate: true }
  )

  watch(
    () => state.formJson.priority_phone,
    async (value: string) => {
      if (value === '1') {
        state.schema.phone_no.required = true
        state.schema.phone_no.validations = yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.phone_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.phone_no.regex as RegExp,
            t('validation.hankaku_number_error', { number: 10 })
          )
      } else {
        state.schema.phone_no.required = false
        state.schema.phone_no.validations = yup
          .string()
          .trim()
          .max(
            validator.phone_no.maxLength as number,
            t('validation.hankaku_number_error', { number: 10 })
          )
      }
    },
    { immediate: true }
  )

  watch(
    () => state.formJson.post_no,
    async (value: string) => {
      if (validator.post_no?.regex?.exec(value)) {
        const { contents } = await getAddrByPostCode(value)
        if (contents?.prefecture) state.formJson.addr1 = contents?.prefecture
        if (contents?.city) state.formJson.addr2 = contents?.city
        if (contents?.town)
          state.formJson.addr2 = state.formJson.addr2 + contents?.town
      }
    }
  )
</script>

<style lang="scss" scoped>
  .check_in {
    width: 100%;
    padding: 10px;
    border: solid 1px #7daaff;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.5rem;
    text-align: center;
  }
</style>
