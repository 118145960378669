<template>
  <li :class="liClass" v-show="show">
    <span v-if="label">
      <label :class="labelClass"
        >{{ label }}
        <template v-if="required">
          <span v-if="requiredStyle === 'form'" class="required-notice">{{
            $t('form.required')
          }}</span>
          <span v-else class="required">*</span>
        </template>
      </label>
    </span>
    <div class="radio-group" :id="uuid?.toString()">
      <div class="radio-item" v-for="option in options" :key="option.key">
        <input
          type="radio"
          :id="`${name}_${option.key}`"
          :name="name"
          :value="option.key"
          class="uk-radio"
          v-model="localValue"
          @input="(event) => $emit('update:modelValue', (event.target as HTMLInputElement)?.value)"
          @blur="validate"
          :readonly="readonly"
          :disabled="disabled"
        />
        <label :for="`${name}_${option.key}`"
          ><span>{{ option.value }}</span></label
        >
      </div>
    </div>
    <label
      :id="uuid?.toString()"
      class="error"
      :for="uuid?.toString()"
      v-if="validation?.errorMessage"
      >{{ validation.errorMessage }}</label
    >
    <p
      style="color: red; font-size: 0.9rem; margin-top: 5px"
      v-if="additionalNotesShow"
      v-html="additionalNotes"
    ></p>
  </li>
</template>

<script lang="ts" setup>
  import { inject, ref, watch } from 'vue'
  const needValidation = ref(inject('needValidation'))
  defineEmits(['update:modelValue'])

  interface Props {
    modelValue?: any
    show?: boolean
    required?: boolean
    requiredStyle?: string
    name?: string
    disabled?: boolean
    readonly?: boolean
    uuid?: number
    label?: string
    options?: { key: string; value: string }[]
    placeholders?: string
    validation?: any
    validations?: any
    additionalNotes?: string
    additionalNotesShow?: boolean
    liClass?: string
    labelClass?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    show: true
  })

  const validate = () => {
    props.validation.validate()
  }

  const localValue = ref()

  watch(
    props,
    (value) => {
      localValue.value = value?.modelValue
    },
    { immediate: true, deep: true }
  )

  watch(needValidation, () => {
    props.validation.validate()
  })

  defineExpose({
    validate
  })
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0;
  }

  ul,
  li {
    list-style: none;
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    width: 100%;

    .radio-item {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1.25rem;
      margin-bottom: 10px;

      label {
        flex: 1;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      input[type='radio'] {
        margin-right: 5px;
        flex: 0 0 30px;
      }
    }
  }
</style>
