<template>
  <!-- article start -->
  <article id="contents">
    <h1 class="contents_title">{{
      $router.currentRoute?.value?.meta?.title
    }}</h1>
    <div class="main_contents boxm">
      <h2>{{ $router.currentRoute?.value?.params?.name }}</h2>

      <the-loading v-if="state.loading" color="#434343"></the-loading>

      <div class="main_contents_list">
        <div class="column_lists" v-if="state.list">
          <article-preview
            v-for="item in state.list.results"
            :key="item"
            :item="item"
          ></article-preview>
        </div>

        <pagination
          :total="state.list?.total_pages"
          :page="state.page"
          @onchange="changePage"
          v-if="state.list && state.list.results.length > 0"
        ></pagination>

        <column-links type="2"></column-links>
        <tag-list></tag-list>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import Pagination from '@/components/Pagination.vue'
  import ArticlePreview from '@/components/ArticlePreview.vue'
  import TagList from '@/components/TagList.vue'
  import ColumnLinks from '@/components/ColumnLinks.vue'
  import { getArticles } from '@/api/index'
  import { onBeforeMount, reactive, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'

  const state: {
    list:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    pageSize: number
    loading: boolean
  } = reactive({
    list: undefined,
    page: 1,
    pageSize: 9,
    loading: true
  })
  const route = useRoute()
  const store = useMainStore()

  const showTagList = async (tag: string) => {
    state.loading = true
    state.list = undefined
    const { contents } = await getArticles(
      {
        page_size: state.pageSize,
        page: state.page
      },
      { tag: tag }
    )
    state.list = contents
    state.loading = false

    const breadcrumb: SiteMapType = {
      route: route?.name as string,
      name: route.params.name as string
    }

    store.setBreadcrumb(breadcrumb)
    // eslint-disable-next-line
    document!.title = (route.params.name as string) + ' | Coin Estate'
  }

  const changePage = async (page: number) => {
    state.page = page
    await showTagList(route.params.name as string)
  }

  onBeforeMount(() => {
    showTagList(route.params.name as string)
  })

  watch(route, async () => {
    state.page = 1
    await showTagList(route.params.name as string)
  })
</script>
