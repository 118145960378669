<template>
  <user>
    <div id="user_wrap">
      <div id="main_wrap" class="boxs mb_40">
        <!-- article start -->
        <article id="contents" class="boxs loginart">
          <div class="main_contents boxss">
            <div class="main_contents_wrap">
              <div class="main_contents_in" style="padding-top: 0">
                <h1 v-if="router.currentRoute.value.path == '/account/'">{{
                  $t('pages.InfoConfirm.form.individual.title')
                }}</h1>

                <div
                  class="acd-content"
                  v-bind:class="[
                    router.currentRoute.value.path == '/account/'
                      ? 'no-border'
                      : ''
                  ]"
                  style="width: 100%"
                >
                  <!-- 個人情報 -->
                  <template
                    v-if="
                      userInfo?.user_type === '1' &&
                      Object.keys(state.formJson_in).length > 0
                    "
                  >
                    <div
                      v-if="router.currentRoute.value.path != '/account/'"
                      class="acd-content_title"
                      >{{ $t('pages.InfoConfirm.form.individual.title') }}</div
                    >
                    <step1 :data="state.formJson_in"></step1>
                  </template>

                  <!-- 法人情報 -->
                  <template
                    v-else-if="
                      userInfo?.user_type === '2' &&
                      Object.keys(state.formJson_co).length > 0
                    "
                  >
                    <div
                      class="acd-content_title"
                      v-if="router.currentRoute.value.path != '/account/'"
                      >{{
                        $t('pages.InfoConfirm.form.corporation.title1')
                      }}</div
                    >
                    <dl class="acd-content_dl">
                      <dt class="acd-content_dt">{{
                        $t('pages.InfoConfirm.form.corporation.dl1_1')
                      }}</dt>
                      <dd class="acd-content_dd_full">{{
                        userInfo.account_id
                      }}</dd>
                    </dl>
                    <dl class="acd-content_dl">
                      <dt class="acd-content_dt">{{
                        $t('pages.InfoConfirm.form.corporation.dl1_2')
                      }}</dt>
                      <dd class="acd-content_dd_full">{{ userInfo.email }}</dd>
                    </dl>
                    <co-step1
                      :data="state.formJson_co.basic"
                      style="padding-top: 20px"
                    ></co-step1>

                    <div class="acd-content_title">{{
                      $t('pages.InfoConfirm.form.corporation.title2_1')
                    }}</div>
                    <co-step2 :data="state.formJson_co.exp" part1></co-step2>
                    <div class="acd-content_title">{{
                      $t('pages.InfoConfirm.form.corporation.title2_2')
                    }}</div>
                    <co-step2 :data="state.formJson_co.exp" part2></co-step2>

                    <div class="acd-content_title">{{
                      $t('pages.InfoConfirm.form.corporation.title3')
                    }}</div>
                    <co-step3
                      :data="state.formJson_co.trade_head"
                      :subTitle="false"
                    ></co-step3>

                    <div class="acd-content_title">{{
                      $t('pages.InfoConfirm.form.corporation.title4')
                    }}</div>
                    <dl
                      class="acd-content_dl"
                      v-if="state.formJson_co.share_holder"
                    >
                      <dt class="acd-content_dt">{{
                        $t('pages.InfoConfirm.form.corporation.dl4_1')
                      }}</dt>
                      <dd class="acd-content_dd_full"
                        >【{{
                          state.formJson_co.share_holder_type.share_holder_type
                            .toString()
                            .toUpperCase()
                        }}】<span
                          >{{
                            $t(
                              `pages.Corporation.step4.selected.${state.formJson_co.share_holder_type.share_holder_type}`
                            )
                          }}<span
                            v-if="
                              state.formJson_co.share_holder_type
                                .share_holder_type === 'i'
                            "
                            >{{
                              state.formJson_co.share_holder_type
                                .share_holder_company
                            }}</span
                          ></span
                        ></dd
                      >
                    </dl>
                    <template
                      v-if="
                        state.formJson_co.share_holder &&
                        state.formJson_co.share_holder.length > 0
                      "
                    >
                      <template
                        v-for="(item, index) in state.formJson_co.share_holder"
                        :key="index"
                      >
                        <h1>{{
                          $t('pages.Corporation.step4.confirm', {
                            number: index + 1
                          })
                        }}</h1>
                        <co-step4
                          :data="item"
                          style="padding-top: 0"
                        ></co-step4>
                      </template>
                    </template>

                    <div class="acd-content_title">{{
                      $t('pages.InfoConfirm.form.corporation.title5')
                    }}</div>
                    <co-step5 :data="state.formJson_co.bank"></co-step5>
                  </template>

                  <template v-else>
                    <div class="acd-content_title"
                      ><the-loading></the-loading
                    ></div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </article>
        <!-- article end -->
      </div>
    </div>
  </user>
</template>
<script lang="ts" setup>
  import User from '@/components/User.vue'
  import { ref, reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import Step1 from '@/components/individual/Step1.vue'
  import CoStep1 from '@/components/corporation/Step1.vue'
  import CoStep2 from '@/components/corporation/Step2.vue'
  import CoStep3 from '@/components/corporation/Step3.vue'
  import CoStep4 from '@/components/corporation/Step4.vue'
  import CoStep5 from '@/components/corporation/Step5.vue'
  import { getIndividual, getUserCheckInfo, getCorporationConfirm } from '@/api'

  const state: {
    errorMessage: string
    formJson_in: any
    formJson_co: any
    req_id: string
  } = reactive({
    errorMessage: '',
    formJson_in: {},
    formJson_co: {},
    req_id: ''
  })

  const loading = ref(false)
  const router = useRouter()
  const store = useMainStore()
  const { userInfo } = storeToRefs(store)

  onMounted(async () => {
    try {
      if (!userInfo.value?.user_type) await store.checkLogin()

      const { contents } = await getUserCheckInfo()
      state.req_id = contents?.choice_flg
      const individual = await getIndividual('uncheck')
      state.formJson_in = Object.assign(state.formJson_in, individual?.contents)
      if (userInfo.value?.user_type === '1') return

      const corporation = await getCorporationConfirm()
      state.formJson_co = Object.assign(
        state.formJson_co,
        corporation?.contents
      )
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }
  })
</script>

<style lang="scss" scoped>
  .acd-content {
    text-align: left;
    height: auto;
    opacity: 1;
    padding: 0 3rem 3rem 3rem;
    visibility: visible;
    border: solid 3px hsl(44deg 99% 52%);
    border-radius: 30px;
    margin-top: 3rem;
    display: block;
  }

  .acd-content_title {
    font-size: 2rem;
    font-weight: bold;
    padding: 3rem 0 0 0;
    text-align: center;
  }

  @media (max-width: 1024px) {
    .acd-content_title {
      font-size: 1.3rem;
      padding-top: 3rem;
    }
  }

  .modal__error {
    max-width: 500px;
    width: 95%;
    margin: 10px auto;
  }

  .acd-content_dt {
    padding-top: 20px;
  }

  .acd-content_dd_full {
    padding: 8px 0;
  }

  .acd-content_dt {
    padding-bottom: 0;
  }
  .no-border {
    border: unset !important;
    margin-top: unset !important;
    padding: unset !important;
  }
</style>
