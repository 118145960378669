<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.PointCrypto.title') }}</h1>

        <div class="uk-padding uk-form-horizontal uk-margin formbox2">
          <crypto-nav :link_name="state.link" :crypto="state.crypto.toString()">
          </crypto-nav>

          <template v-if="Object.keys(state.formJson).length > 0">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <div v-else>
              <the-message v-if="state.successMessage" type="primary">{{
                state.successMessage
              }}</the-message>
            </div>

            <ul class="uk uk-switcher uk-margin">
              <div class="uk-active">
                <li>
                  <SchemaFormWithValidation
                    :schema="state.schema"
                    :model="state.formJson"
                    useCustomFormWrapper
                    ref="form"
                  />

                  <!-- section -->
                  <div class="uk-margin uk-margin-top">
                    <div class="uk-form-controls button-operation">
                      <button
                        type="button"
                        name="plus1"
                        v-for="obj in state.plus_btn_list"
                        :key="obj"
                        v-on:click="set_amount(obj)"
                        class="uk-button-small uk-button-primary on_plus"
                        >&nbsp;&nbsp;+{{ obj }}</button
                      >
                    </div>
                  </div>

                  <div class="coin_r_set crs_wm">
                    <div class="crs_icon"
                      ><img
                        :src="`${s3Url}img/icon_${state.crypto.toLowerCase()}.png`"
                        :alt="state.crypto.toLowerCase()"
                    /></div>
                    <div class="crs_info">
                      <div
                        >{{ state.crypto.toUpperCase() }}/{{
                          $t('pages.PointCrypto.unit')
                        }}{{ $t('pages.PointCrypto.amount') }}</div
                      >
                      <div class="display_price">
                        <div class="display_price1">
                          <span class="price_active">{{
                            state.rate.toLocaleString()
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ysval">
                    <div class="ta_c">
                      {{ $t('pages.PointCrypto.payment') }}
                      <div class="ysval_val"
                        ><span>{{ state.payment.toLocaleString() }}</span>
                        {{ $t('pages.PointCrypto.unit') }}</div
                      >
                    </div>
                  </div>
                  <div class="ta_c">
                    <!-- buttons -->
                    <p uk-margin="" class="uk-margin-top">
                      <button
                        type="button"
                        class="uk-button uk-button-primary btn_buy uk-first-column"
                        v-debounce="{
                          func: confirm
                        }"
                        style="width: 150px"
                        id="btn_buy"
                      >
                        <div class="uk-text-center">{{
                          $t('pages.PointCrypto.button')
                        }}</div>
                      </button>
                    </p>
                  </div>
                </li>
              </div>
            </ul>

            <the-modal
              v-if="state.modal"
              id="modal-confirm"
              hasClose
              @close="state.modal = false"
            >
              <section class="modal-inner">
                <h1 v-html="$t('pages.PointCrypto.modal.title')"></h1>

                <div class="corporation-confirm">
                  <dl>
                    <dt>{{ $t('pages.PointCrypto.form.balance') }}</dt>
                    <dd
                      >{{ state.formJson?.balance }}
                      {{ $t('pages.PointCrypto.unit') }}</dd
                    >
                  </dl>
                  <dl>
                    <dt>{{ $t('pages.PointCrypto.form.qty') }}</dt>
                    <dd
                      >{{ state.formJson?.qty.toLocaleString() }}
                      {{ state.crypto.toUpperCase() }}</dd
                    >
                  </dl>
                  <dl>
                    <dt>{{ $t('pages.PointCrypto.payment') }}</dt>
                    <dd
                      >{{ state.payment.toLocaleString() }}
                      {{ $t('pages.PointCrypto.unit') }}</dd
                    >
                  </dl>
                </div>

                <p class="text-red">{{ $t('pages.PointCrypto.modal.msg') }}</p>

                <button
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                  v-debounce="{
                    func: submit
                  }"
                  v-loading="{
                    loading: state.loading,
                    value: $t('pages.PointCrypto.button')
                  }"
                  type="button"
                  >{{ $t('pages.PointCrypto.button') }}</button
                >
              </section>
            </the-modal>
          </template>
          <template v-else>
            <the-loading></the-loading>
          </template>
        </div>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import User from '@/components/User.vue'
  import CryptoNav from '@/components/CryptoNav.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import TheModal from '@/components/TheModal.vue'
  import { getPointCrypto, setPointCrypto } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import { BigNumber } from 'bignumber.js'

  const router = useRouter()
  const store = useMainStore()
  const { s3Url, ws } = storeToRefs(store)
  const { t } = useI18n()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const state: {
    title: any[]
    plus_btn_list: any[]
    rate: number
    input_min: number
    input_max: number
    input_digits: number
    payment: number
    formJson: any
    schema: any
    modal: boolean
    loading: boolean
    crypto: string
    successMessage: string
    errorMessage: string
  } = reactive({
    title: [],
    plus_btn_list: [],
    rate: 0,
    input_min: 0,
    input_max: 0,
    input_digits: 0,
    payment: 0,
    formJson: {},
    schema: {
      balance: {
        component: 'Text',
        label: t('pages.PointCrypto.form.balance'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid ta_r',
        labelClass: 'madd_title',
        placeholder: ''
      },
      qty: {
        component: 'Text',
        label: t('pages.PointCrypto.form.qty'),
        type: 'text',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid ta_r',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.PointCrypto.form.qty'),
            type: t('validation.type.input')
          })
        )
      }
    },
    modal: false,
    loading: false,
    crypto: '',
    successMessage: '',
    errorMessage: ''
  })

  const set_amount = (value: string) => {
    const qty = BigNumber(state.formJson.qty || '0')
    const after_qty = qty.plus(BigNumber(value)).toNumber()
    re_calc(after_qty)
  }

  const re_calc = (qty: number) => {
    if (isNaN(qty)) {
      state.formJson.qty = 0
      state.payment = 0
      return
    }

    state.formJson.qty = qty
    if (qty < 0) {
      state.formJson.qty = 0
      state.payment = 0
      return
    }

    const patt = new RegExp('[0-9]*[.]{1}[0-9]{' + state.input_digits + '}')
    const matchedString = qty.toString().match(patt)
    if (matchedString) {
      state.formJson.qty = matchedString[0]
    }

    state.payment = parseInt(
      parseFloat(state.formJson.qty) * parseFloat(state.rate)
    )
  }

  const confirm = async () => {
    state.successMessage = ''
    state.errorMessage = ''

    const valid = form.value?.validate()
    console.log('confirm')

    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        if (state.formJson.qty <= 0) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_limit_amount')
          return
        }

        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const submit = async () => {
    const valid = form.value?.validate()
    console.log('submit')

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
        state.loading = true

        try {
          const { contents } = await setPointCrypto(
            state.crypto,
            state.formJson.qty
          )
          state.loading = false
          state.modal = false
          state.successMessage = t('error.success_point_crypto')
          init()
        } catch (error: any) {
          if (error?.message == 'error_decimal_digits') {
            state.errorMessage = t('error.' + error?.message, {
              digits: state.input_digits
            })
          } else if (error?.message == 'error_min_limit') {
            state.errorMessage = t('error.' + error?.message, {
              min_limit: state.input_min,
              crypto: state.crypto.toUpperCase()
            })
          } else if (error?.message == 'error_max_limit') {
            state.errorMessage = t('error.' + error?.message, {
              max_limit: state.input_max,
              crypto: state.crypto.toUpperCase()
            })
          } else {
            state.errorMessage =
              error.error_translations || error.message_detail
          }
          document.documentElement.scrollTop = 0
          state.loading = false
          state.modal = false
        }
      }
    }, 0)
  }

  const init = async () => {
    try {
      state.crypto = router.currentRoute.value.params.crypto as string
      const { contents } = await getPointCrypto(state.crypto)
      state.formJson.balance = contents?.balance.toLocaleString()
      state.plus_btn_list = contents?.plus_btn_list
      state.rate = contents?.rate
      state.input_min = contents?.input_min
      state.input_max = contents?.input_max
      state.input_digits = contents?.input_digits
      state.formJson.qty = '0'
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.PointCrypto.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  onMounted(async () => {
    init()
    state.schema.qty.label = t('pages.PointCrypto.form.qty', {
      crypto: '(' + state.crypto.toUpperCase() + ')'
    })
  })

  watch(
    () => state.formJson.qty,
    async (value: string, oldValue: string) => {
      re_calc(parseFloat(value))
    }
  )

  watch(
    () => ws.value.crypto,
    async (value: any, oldValue: any) => {
      state.rate = value?.[state.crypto]?.ask || 0
    }
  )
</script>
<style lang="scss" scoped>
  .on_plus {
    margin-left: 5px !important;
  }
  .coin_r_set {
    width: calc(55% - 3rem) !important;
  }
  .text-red {
    display: block;
    margin: -0.75rem 0 1rem;
    color: #df4c5b;
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
  }

  @media (max-width: 630px) {
    .on_plus {
      font-size: 0.1rem;
    }
    .uk-button-small {
      padding: 0 5px;
    }
    .coin_r_set {
      min-width: 300px;
    }
  }
</style>
