<template>
  <!-- article start -->
  <article id="contents">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <div class="management_box boxs">
        <div class="management_box_in">
          <div class="management_text_left">
            <div class="management_title">Mission</div>
            <div class="management_body">
              <h2
                class="management_h2"
                v-html="$t('pages.Company.management.title1')"
              ></h2>
              <div class="management_center">
                <span
                  class="management_span"
                  v-html="$t('pages.Company.management.msg1')"
                ></span>
              </div>
            </div>
          </div>
          <div class="management_flex_img_bg1"></div>
        </div>
      </div>
      <div class="management_box boxs">
        <div class="management_box_in">
          <div class="management_flex_img_bg2"></div>
          <div class="management_text_right">
            <div class="management_title">Vision</div>
            <div class="management_body">
              <h2
                class="management_h2"
                v-html="$t('pages.Company.management.title2')"
              ></h2>
              <div class="management_center">
                <span
                  class="management_span"
                  v-html="$t('pages.Company.management.msg2')"
                ></span>
              </div>
            </div>
          </div>
          <div class="management_flex_img_bgsp"></div>
        </div>
      </div>
      <div class="management_box boxs">
        <div class="management_title_Value">Value</div>
        <div class="management_body_in">
          <img
            class="management_Value_img"
            :src="`${s3Url}img/page/pcsp_03.${language}.png?v=1`"
            alt="Value"
          />
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)
</script>
