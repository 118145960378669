<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="bh4 fw_m fc_y">{{
        $t('pages.Company.legal.table1.title')
      }}</div>
      <dl class="dl_table">
        <dt>{{ $t('pages.Company.legal.table1.dt1') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd1') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt2') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd2') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt3') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd3') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt4') }}</dt>
        <dd v-html="$t('pages.Company.legal.table1.dd4')"></dd>
        <dt>{{ $t('pages.Company.legal.table1.dt5') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd5') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt6') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd6') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt7') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd7') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt8') }}</dt>
        <dd v-html="$t('pages.Company.legal.table1.dd8')"></dd>
        <dt>{{ $t('pages.Company.legal.table1.dt9') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd9') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt10') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd10') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt11') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd11') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt12') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd12') }}</dd>
        <dt>{{ $t('pages.Company.legal.table1.dt13') }}</dt>
        <dd v-html="$t('pages.Company.legal.table1.dd13')"></dd>
        <dt>{{ $t('pages.Company.legal.table1.dt14') }}</dt>
        <dd>{{ $t('pages.Company.legal.table1.dd14') }}</dd>
      </dl>

      <div class="bh4 fw_m fc_y mt_40">{{
        $t('pages.Company.legal.table2.title')
      }}</div>
      <dl class="dl_table">
        <dt>{{ $t('pages.Company.legal.table2.dt1') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd1') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt2') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd2') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt3') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd3') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt4') }}</dt>
        <dd v-html="$t('pages.Company.legal.table2.dd4')"></dd>
        <dt>{{ $t('pages.Company.legal.table2.dt5') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd5') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt6') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd6') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt7') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd7') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt8') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd8') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt9') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd9') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt10') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd10') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt11') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd11') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt12') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd12') }}</dd>
        <dt>{{ $t('pages.Company.legal.table2.dt13') }}</dt>
        <dd v-html="$t('pages.Company.legal.table2.dd13')"></dd>
        <dt>{{ $t('pages.Company.legal.table2.dt14') }}</dt>
        <dd>{{ $t('pages.Company.legal.table2.dd14') }}</dd>
      </dl>
    </div>
  </article>
</template>
