<template>
  <article id="contents">
    <div class="main_contents_head_sp"></div>
    <div class="main_contents boxm">
      <div class="main_contents_text boxs">
        <h1 class="err_head">{{ $t('pages.500.title') }}</h1>
        <div class="err_title" :class="{ 'is-center': language === 'en' }">{{
          $t('pages.500.msg1')
        }}</div>
        <div class="err_message" :class="{ 'is-center': language === 'en' }">{{
          $t('pages.500.msg2')
        }}</div>
        <div class="btn_set"> </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>

<style lang="scss" scoped>
  .err_head {
    margin: 4rem 0 0;
    text-align: center;
    font-size: 2.6rem;
    color: #febd09;
  }
  .err_title {
    margin: 3rem 0 1.25rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .is-center {
    text-align: center;
  }
</style>
