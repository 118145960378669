<template>
  <div class="fdblocks2">
    <Carousel
      :autoplay="2000"
      :wrap-around="true"
      :breakpoints="breakpoints"
      :pauseAutoplayOnHover="true"
      v-if="state.coins.length > 0"
    >
      <template v-for="coin in state.coins" :key="coin">
        <slide>
          <div class="rset">
            <router-link
              :to="{ name: 'CoinDetail', params: { coin: coin.id } }"
              class="rwrap"
            >
              <span class="r1"
                ><img :src="`${s3Url}img/icon_${coin.id}.png`" :alt="coin.id"
              /></span>
              <span class="r2">{{ coin.name }}</span>
              <span class="r3"
                >￥{{
                  (wsRef.crypto &&
                    wsRef.crypto[coin.id]?.average.toLocaleString()) ||
                  coinRateRef[coin.id]?.average.toLocaleString() ||
                  0
                }}</span
              >
              <span class="r4"
                >{{
                  (wsRef.crypto && wsRef.crypto[coin.id]?.day_before_ratio) ||
                  coinRateRef[coin.id]?.day_before_ratio ||
                  0
                }}
                %</span
              >
            </router-link>
          </div>
        </slide>
      </template>
    </Carousel>
  </div>
  <div class="ta_c fs_11 mt_05 mb_0 op_75">{{
    $t('pages.Landing.rate.msg')
  }}</div>
  <div class="tophslide_wrap">
    <div class="tophslides">
      <div class="tophslide" id="toph_p1">
        <div class="tophslide_in">
          <div class="tophs tophs1">
            <div class="topset_l w2s">
              <div class="flex_h">
                <div
                  class="fw_n bh3 ta_l mb_20"
                  v-html="$t('pages.Landing.main.title')"
                ></div>

                <router-link
                  :to="{ name: 'NewAccount' }"
                  class="topna"
                  v-if="language === 'ja'"
                  ><span class="fc_y">３ステップ</span>で簡単に<span
                    class="fc_y"
                    >口座開設</span
                  ></router-link
                >

                <template v-if="language === 'ja'">
                  <div class="ta_c">
                    <div class="fs_14 fw_n mw_20"
                      ><span class="fc_y">{{
                        $t('pages.Landing.explanation.msg1')
                      }}</span
                      ><br />{{ $t('pages.Landing.explanation.msg2') }}
                      <span class="fc_y"></span
                      >{{ $t('pages.Landing.explanation.msg3') }}</div
                    >
                  </div>
                  <div class="fw_n boxss lh175">
                    <span class="fc_y fw_b">{{
                      $t('pages.Landing.explanation.msg4')
                    }}</span
                    >{{ $t('pages.Landing.explanation.msg5')
                    }}{{
                      Object.values(tokenDeploy).filter((i) => i === 'true')
                        .length
                    }}{{ $t('pages.Landing.explanation.msg6') }}
                  </div>
                </template>
                <template v-else>
                  <div class="fw_n">
                    <div class="fs_14 fw_n mw_10"
                      >{{ $t('pages.Landing.explanation.msg1') }}
                      <span class="fc_y">{{
                        $t('pages.Landing.explanation.msg2')
                      }}</span>
                      {{ $t('pages.Landing.explanation.msg3') }}</div
                    >
                    <span class="fc_y fw_b">{{
                      $t('pages.Landing.explanation.msg4')
                    }}</span>
                    {{ $t('pages.Landing.explanation.msg5') }}<br />{{
                      $t('pages.Landing.explanation.msg6')
                    }}
                    {{
                      Object.values(tokenDeploy).filter((i) => i === 'true')
                        .length - 2
                    }}{{ $t('pages.Landing.explanation.msg7') }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tophslide" id="toph_p3">
        <div class="tophslide_in">
          <div class="tophs tophs3">
            <div class="ablock">
              <div class="bh1" v-html="$t('pages.Landing.area1.title')"></div>
              <div
                class="bh2 fw_m"
                v-html="$t('pages.Landing.area2.title')"
              ></div>

              <div class="flex_w">
                <div class="w3s fdblock flex_c">
                  <div class="bh4 fc_y fw_m">{{
                    $t('pages.Landing.area2.part1.title')
                  }}</div>
                  <div class="btxt">
                    {{ $t('pages.Landing.area2.part1.detail') }}
                  </div>
                </div>
                <div class="w3s fdblock flex_c">
                  <div class="bh4 fc_y fw_m">{{
                    $t('pages.Landing.area2.part2.title')
                  }}</div>
                  <div class="btxt">{{
                    $t('pages.Landing.area2.part2.detail')
                  }}</div>
                </div>
                <div class="w3s fdblock flex_c">
                  <div class="bh4 fc_y fw_m">{{
                    $t('pages.Landing.area2.part3.title')
                  }}</div>
                  <div class="btxt">{{
                    $t('pages.Landing.area2.part3.detail')
                  }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tophslide" id="toph_p4">
        <div class="tophslide_in">
          <div class="tophs tophs4">
            <div class="ablock">
              <div
                class="bh2 fw_m"
                v-html="$t('pages.Landing.area3.title')"
              ></div>

              <div class="flex_w">
                <div class="w2s fdblock flex_c">
                  <div class="bh4 fc_y fw_m">{{
                    $t('pages.Landing.area3.part1.title')
                  }}</div>
                  <div class="btxt">{{
                    $t('pages.Landing.area3.part1.detail')
                  }}</div>
                </div>
                <div class="w2s fdblock flex_c">
                  <div class="bh4 fc_y fw_m">{{
                    $t('pages.Landing.area3.part2.title')
                  }}</div>
                  <div class="btxt">{{
                    $t('pages.Landing.area3.part2.detail')
                  }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tophslide" id="toph_p5">
        <div class="tophslide_in">
          <div class="tophs tophs5">
            <div class="ablock">
              <div
                class="bh2 fw_m adjust__text"
                v-html="$t('pages.Landing.area4.title')"
              ></div>
              <div class="steps">
                <div class="flex_w topflow">
                  <div class="w3s flex_c">
                    <div class="fs_14 fw_l mw_15 ta_c ta_l_sp op_65 stepp">{{
                      $t('pages.Landing.area4.part1.title')
                    }}</div>
                    <div
                      class="btxt ta_c fdblock fs_20 fw_n"
                      v-html="$t('pages.Landing.area4.part1.detail')"
                    >
                    </div>
                  </div>
                  <div class="w3s flex_c">
                    <div class="fs_14 fw_l mw_15 ta_c ta_l_sp op_65 stepp">{{
                      $t('pages.Landing.area4.part2.title')
                    }}</div>
                    <div
                      class="btxt ta_c fdblock fs_20 fw_n"
                      v-html="$t('pages.Landing.area4.part2.detail')"
                    >
                    </div>
                  </div>
                  <div class="w3s flex_c">
                    <div class="fs_14 fw_l mw_15 ta_c ta_l_sp op_65 stepp">{{
                      $t('pages.Landing.area4.part3.title')
                    }}</div>
                    <div
                      class="btxt ta_c fdblock fs_20 fw_n"
                      v-html="$t('pages.Landing.area4.part3.detail')"
                    >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tophslide" id="toph_p6">
        <div class="tophslide_in">
          <div class="tophs tophs6">
            <div class="ablock">
              <div
                class="bh2 fw_m"
                v-html="$t('pages.Landing.area5.title')"
              ></div>

              <div class="flex_w">
                <div class="w2s fdblock flex_c">
                  <div class="bh4 fc_y fw_m">{{
                    $t('pages.Landing.area5.part1.title')
                  }}</div>
                  <div class="btxt">
                    {{ $t('pages.Landing.area5.part1.detail') }}
                  </div>
                </div>
                <div class="w2s fdblock flex_c">
                  <div class="bh4 fc_y fw_m">{{
                    $t('pages.Landing.area5.part2.title')
                  }}</div>
                  <div class="btxt">
                    {{ $t('pages.Landing.area5.part2.detail') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- article start -->
  <article id="toppage">
    <div class="fdblockw">
      <div class="topslide flex_c">
        <carousel
          :items-to-show="1"
          :wrap-around="true"
          v-if="state.campaign[0] && language === 'ja'"
        >
          <slide :key="1">
            <div class="flex_w">
              <div class="w2s"><img :src="state.campaign[0].thumb_url" /></div>
              <div class="w2s"
                ><div class="flex_h">
                  <div class="bh3 mb_10 fw_b"
                    ><span class="fc_y">{{ state.campaign[0].title }}</span
                    >キャンペーン</div
                  >
                  <div v-html="state.campaign[0].thumb_comment"></div>
                  <div
                    ><router-link
                      :to="{
                        name: 'CampaignDetail',
                        params: { id: state.campaign[0].id }
                      }"
                      class="lbtn_y"
                      tabindex="0"
                      >キャンペーン詳細</router-link
                    ></div
                  >
                </div></div
              >
            </div>
          </slide>
          <slide :key="2">
            <div class="flex_w">
              <div class="w2s ta_c"
                ><img
                  :src="`${s3Url}img/mypayment.ja.png?v=1`"
                  style="width: 90%; max-width: 440px; margin: 0 auto"
                  alt="mypayment"
              /></div>
              <div class="w2s"
                ><div class="flex_h">
                  <div
                    class="bh3 mb_10 fw_b"
                    v-html="$t('pages.Landing.slide.convenience.title')"
                  ></div>
                  <div class="fw_b mw_10">{{
                    $t('pages.Landing.slide.convenience.msg1')
                  }}</div>
                  <div>{{ $t('pages.Landing.slide.convenience.msg2') }}</div>
                </div></div
              >
            </div>
          </slide>

          <template #addons>
            <Navigation />
          </template>
        </carousel>

        <div class="flex_w" v-else>
          <div class="w2s ta_c"
            ><img
              :src="`${s3Url}img/mypayment.ja.png?v=1`"
              style="width: 90%; max-width: 440px; margin: 0 auto"
              alt="mypayment"
          /></div>
          <div class="w2s"
            ><div class="flex_h">
              <div
                class="bh3 mb_10 fw_b"
                v-html="$t('pages.Landing.slide.convenience.title')"
              ></div>
              <div class="fw_b mw_10">{{
                $t('pages.Landing.slide.convenience.msg1')
              }}</div>
              <div>{{ $t('pages.Landing.slide.convenience.msg2') }}</div>
            </div></div
          >
        </div>
      </div>
    </div>

    <div class="fdblockw btnone bbnone" v-if="language === 'ja'"
      ><router-link :to="{ name: 'NewAccount' }" class="topna2"
        ><span class="fc_y">３ステップ</span>で簡単に<span class="fc_y"
          >口座開設</span
        ></router-link
      ></div
    >

    <div class="fdblockw">
      <div class="flex_c">
        <div class="wblock">
          <div class="flex_w">
            <div class="w5-1s flex_c fc_y fs_20 fw_b">
              {{ $t('pages.Landing.tradingHours.title') }}
            </div>
            <div class="w5-2s flex_c">
              <span class="fw_b">{{
                $t('pages.Landing.tradingHours.msg1')
              }}</span
              ><br />
              <span class="fs_14">{{
                $t('pages.Landing.tradingHours.msg2')
              }}</span>
            </div>
            <div class="w5-3s flex_c is_sp">
              {{
                $t('pages.Landing.tradingHours.msg3', {
                  msg: $t('pages.Landing.tradingHours.fromTop')
                })
              }}
            </div>
            <div class="w5-3s flex_c is_pc">
              {{
                $t('pages.Landing.tradingHours.msg3', {
                  msg: $t('pages.Landing.tradingHours.fromLeft')
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="top_info"
      v-if="state.notification.length > 0 && language === 'ja'"
    >
      <div class="bh2 fw_m mb_0">{{ $t('pages.Landing.news.title') }}</div>
      <div class="info_sets boxs">
        <notification-item
          v-for="item in state.notification"
          :key="item.notification_id"
          :new="isNotificationNew(item.reg_date)"
          :detail="item"
        ></notification-item>
      </div>
      <div class="ta_c">
        <router-link :to="{ name: 'NotificationPublic' }" class="lbtn_y">{{
          $t('pages.Landing.news.more')
        }}</router-link>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive, onMounted, Ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { useRouter } from 'vue-router'
  import type { CoinInfo, WebSocketData } from '@/@types/websocket'
  import NotificationItem from '@/components/NotificationItem.vue'
  import { getNotificationList } from '@/api/index'
  import { isNotificationNew } from '@/utils/tool'
  import { getCampaigns } from '@/api'
  import { coins } from '@/enum/coins'

  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Navigation } from 'vue3-carousel'

  const store = useMainStore()
  const { s3Url, ws, coinRate, tokenDeploy, language } = storeToRefs(store)
  const wsRef = ws as Ref<WebSocketData>
  const coinRateRef = coinRate as Ref<{ [key: string]: CoinInfo }>
  const router = useRouter()
  const state: {
    notification: {
      notification_id: number
      reg_date: string
    }[]
    campaign: {
      title: string
      thumb_url: string
      thumb_comment: string
      id: number
    }[]
    domain: string | undefined
    coins: {
      id: string
      name: string
    }[]
  } = reactive({
    notification: [],
    campaign: [],
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN,
    coins: []
  })

  const breakpoints = {
    550: {
      itemsToShow: 2,
      snapAlign: 'center'
    },
    900: {
      itemsToShow: 3.5,
      snapAlign: 'center'
    },
    1024: {
      itemsToShow: 3,
      snapAlign: 'center'
    },
    1310: {
      itemsToShow: 3.5,
      snapAlign: 'start'
    },
    1635: {
      itemsToShow: 5,
      snapAlign: 'start'
    }
  }

  const showNotification = async () => {
    const { contents } = await getNotificationList(1)
    state.notification = contents?.results ?? []
    state.notification = state.notification.slice(0, 5)
  }

  const showCampaign = async () => {
    const { contents } = await getCampaigns(1)
    state.campaign = contents?.results?.filter(
      (i: { landing_flg: string }) => i.landing_flg === '1'
    )
  }

  onMounted(async () => {
    router.push(router.currentRoute.value.path)
    store.getCoinRate('all')

    Object.keys(tokenDeploy.value).forEach((key: string) => {
      if (tokenDeploy.value[key] === 'true') {
        state.coins.push(
          coins.find(
            (i) => i.id.toLocaleLowerCase() === key.toLocaleLowerCase()
          ) as {
            id: string
            name: string
          }
        )
      }
    })

    showCampaign()
    showNotification()
  })
</script>

<style lang="scss" scoped>
  .flex_w {
    width: 100%;
  }

  @media (max-width: 1024px) {
    .flex_w {
      padding-bottom: 2rem;
    }

    .adjust__text {
      margin: 0 -20px;
      letter-spacing: -1px;
    }
  }
</style>
<style lang="scss">
  .carousel__prev,
  .carousel__next {
    background-color: transparent;
    & svg {
      display: none;
    }
    &::before {
      content: '' !important;
      width: 48px !important;
      height: 48px !important;
      line-height: 48px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #4c4e62;
      color: #febd09;
      font-weight: 700;
      border-radius: 8px;
      position: absolute;
      font-size: 20px;
      opacity: 0.75;
      -webkit-font-smoothing: antialiased;
    }
  }

  .carousel__prev {
    top: unset;
    bottom: -2rem;
    right: calc(-1.5rem + 48px);
    left: unset;
    width: 48px !important;
    height: 48px !important;
    line-height: 48px;
    z-index: 9;
    &::before {
      content: '＜' !important;
    }
  }

  .carousel__next {
    top: unset;
    bottom: -2rem;
    right: 1rem;
    width: 48px !important;
    height: 48px !important;
    line-height: 48px;
    z-index: 9;
    &::before {
      content: '＞' !important;
    }
  }

  @media (max-width: 1024px) {
    .carousel__prev {
      right: calc(-0.5rem + 48px);
      bottom: -2rem;
    }

    .carousel__next {
      right: 2rem;
      bottom: -2rem;
    }
  }

  body#maintop #footer {
    background: url(~@static/img/footback.png) #171a37 no-repeat 0% 100%;
    background-size: 40% auto;
  }
</style>
