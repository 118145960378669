<template>
  <the-message v-if="state.errorMessage" class="modal__error">{{
    state.errorMessage
  }}</the-message>

  <div v-if="!state.init">
    <the-loading style="margin-top: 100px; margin-bottom: 100px"></the-loading>
  </div>

  <div id="new_account_modal" v-else>
    <!-- UM00-01-01-1 START -->
    <div class="bh4 fw_b fc_y mt_20">{{ $t('pages.Agreement.title') }}</div>
    <ul class="agreement">
      <li v-for="item in state.check_list" :key="item">
        <label :for="item">
          <input
            type="checkbox"
            name="checkboxes"
            :value="item"
            :id="item"
            class="uk-checkbox"
            v-model="state.agreement[`${item}`]"
          />
          <span v-html="agreement.find((i) => i.key === item)?.value"></span>
        </label>
      </li>
    </ul>

    <div class="new_account_modal_button mw_20 ta_c">
      <button
        type="button"
        class="uk-button"
        id="non_modal"
        :disabled="state.disabled"
        :class="{
          'uk-button-primary': !state.disabled,
          'uk-button-default': state.disabled
        }"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Agreement.submit')
        }"
        >{{ $t('pages.Agreement.submit') }}</button
      >
      <a :href="`${state.domain}/logout/`" class="uk-button ls-05rem">{{
        $t('pages.Agreement.logout')
      }}</a>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { getAgreement, setAgreement } from '@/api'
  import { agreement } from '@/enum/enums'
  import { useRouter } from 'vue-router'
  import { hasCallbackUrl } from '@/utils/tool'

  const state: {
    errorMessage: string
    check_list: string[]
    agreement: Record<string, boolean>
    init: boolean
    disabled: boolean
    domain: string
  } = reactive({
    errorMessage: '',
    check_list: [],
    agreement: {},
    init: false,
    disabled: true,
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN as string
  })

  const loading = ref(false)
  const router = useRouter()

  const submit = async () => {
    console.log('submit')
    if (state.disabled) return
    loading.value = true
    try {
      await setAgreement(state.check_list)
      router.push({ name: 'Home' })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    } finally {
      loading.value = false
    }
  }

  onMounted(async () => {
    try {
      const { contents } = await getAgreement()
      state.check_list = contents.check_list || []
      contents?.redirect && hasCallbackUrl(contents?.redirect)

      state.init = true
    } catch (error: any) {
      state.init = true
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => state.agreement,
    (agreement) => {
      const checked: string[] = []
      Object.keys(agreement).map((i) => {
        if (agreement[i]) checked.push(i)
      })

      const disabled =
        checked.length === state.check_list.length &&
        checked.filter((t: string) => !state.check_list.includes(t))

      state.disabled = disabled === false
    },
    { deep: true }
  )
</script>

<style lang="scss" scoped>
  #new_account_modal {
    width: 50%;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    #new_account_modal {
      width: 90%;
    }
  }

  .new_account_modal_button a {
    text-decoration: underline;
  }

  #non_modal.uk-button-default,
  #non_modal:disabled {
    color: #414751 !important;
    background-color: #eee !important;
    padding: 9px 26px;
    border-radius: 3px !important;
  }

  .modal__error {
    width: 50%;
    margin: 10px auto;
  }
  @media (max-width: 800px) {
    .modal__error {
      width: 90%;
    }
  }
</style>
<style lang="scss">
  .agreement {
    padding-top: 30px;
    margin-bottom: 50px;
    li {
      margin-bottom: 10px;
      input {
        margin-right: 5px;
      }
      a {
        display: inline;
        color: #febd09;
        &::before {
          font-family: 'Font Awesome 5 Free';
          content: '\f0c1';
          font-weight: 900;
          font-size: 0.7rem;
          padding: 0 0.2rem;
          opacity: 0.75;
        }
      }
    }
  }

  #new_account_modal ul li {
    text-indent: -1.25rem;
  }
</style>
