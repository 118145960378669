<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{
        $t('pages.Beginner.about_two_step.title')
      }}</div>
      <div class="ta_c"
        >{{ $t('pages.Beginner.about_two_step.description') }}
      </div>
      <div class="bh4 fw_m fc_y mt_40">{{
        $t('pages.Beginner.about_two_step.area1.title')
      }}</div>
      <div class="ta_c">{{
        $t('pages.Beginner.about_two_step.area1.description')
      }}</div>
      <div class="boxz fdblock mt_30">{{
        $t('pages.Beginner.about_two_step.area1.msg')
      }}</div>
      <div class="cat_red ta_c">{{
        $t('pages.Beginner.about_two_step.area1.warning')
      }}</div>
      <div class="bh3 fw_m fc_y mt_40">{{
        $t('pages.Beginner.about_two_step.area2.title')
      }}</div>
      <div class="btn_mb">
        <a href="#twostep_1" class="btn_link"
          ><i class="fas fa-chevron-right"></i>&nbsp;{{
            $t('pages.Beginner.about_two_step.area2.link1')
          }}</a
        >
        <a href="#twostep_2" class="btn_link"
          ><i class="fas fa-chevron-right"></i>&nbsp;{{
            $t('pages.Beginner.about_two_step.area2.link2')
          }}</a
        >
      </div>
    </div>

    <div class="wblock pb_40" id="twostep_1">
      <div class="bh3 fw_m">{{
        $t('pages.Beginner.about_two_step.area3.title')
      }}</div>

      <div class="wblock fdblock boxm mb_35 flex_w">
        <div class="w3-2s"
          ><div class="flex_h btxt ta_c">
            <div class="bh5 fw_m ta_c"
              ><span class="fc_y">1. </span
              >{{ $t('pages.Beginner.about_two_step.area3.step1') }}</div
            >
            <div>{{ $t('pages.Beginner.about_two_step.area3.msg1') }}</div>
          </div></div
        >
        <div class="w3s whitebox box_area"
          ><img :src="`${s3Url}img/page/about_twostep1.${language}.png?v=5`"
        /></div>
      </div>

      <div class="wblock fdblock boxm mb_35 flex_w">
        <div class="w3-2s"
          ><div class="flex_h btxt ta_c">
            <div class="bh5 fw_m ta_c"
              ><span class="fc_y">2. </span
              >{{ $t('pages.Beginner.about_two_step.area3.step2') }}</div
            >
            <div>{{ $t('pages.Beginner.about_two_step.area3.msg2') }}</div>
          </div></div
        >
        <div class="w3s whitebox box_area"
          ><img :src="`${s3Url}img/page/about_twostep2.${language}.png?v=5`"
        /></div>
      </div>

      <div class="wblock fdblock boxm mb_35 flex_w">
        <div class="w3-2s"
          ><div class="flex_h btxt ta_c">
            <div class="bh5 fw_m ta_c"
              ><span class="fc_y">3. </span
              >{{ $t('pages.Beginner.about_two_step.area3.step3') }}</div
            >
            <div>{{ $t('pages.Beginner.about_two_step.area3.msg3') }}</div>
          </div></div
        >
        <div class="w3s whitebox box_area"
          ><img :src="`${s3Url}img/page/about_twostep3.${language}.png?v=5`"
        /></div>
      </div>

      <div class="wblock fdblock boxm mb_35 flex_w">
        <div class="w3-2s"
          ><div class="flex_h btxt ta_c">
            <div class="bh5 fw_m ta_c"
              ><span class="fc_y">4. </span
              >{{ $t('pages.Beginner.about_two_step.area3.step4') }}</div
            >
            <div>{{ $t('pages.Beginner.about_two_step.area3.msg4') }}</div>
          </div></div
        >
        <div class="w3s whitebox box_area"
          ><img :src="`${s3Url}img/page/about_twostep4.${language}.png?v=5`"
        /></div>
      </div>

      <div class="wblock fdblock boxm mb_35 flex_w">
        <div class="w3-2s"
          ><div class="flex_h btxt ta_c">
            <div class="bh5 fw_m ta_c"
              ><span class="fc_y">5. </span
              >{{ $t('pages.Beginner.about_two_step.area3.step5') }}</div
            >
            <div>{{ $t('pages.Beginner.about_two_step.area3.msg5') }}</div>
          </div></div
        >
        <div class="w3s whitebox box_area"
          ><img :src="`${s3Url}img/page/about_twostep6.${language}.png?v=5`"
        /></div>
      </div>
    </div>

    <div class="wblock pb_40" id="twostep_2">
      <div class="bh3 fw_m">{{
        $t('pages.Beginner.about_two_step.area4.title')
      }}</div>
      <div class="wblock boxm mb_35 ta_c">
        <div class="bh5 fw_m ta_c"
          ><span class="fc_y">{{
            $t('pages.Beginner.about_two_step.area4.subTitle1')
          }}</span></div
        >
        <div>{{ $t('pages.Beginner.about_two_step.area4.msg1') }}</div>
        <div class="ta_c mw_20">
          <a
            href="https://itunes.apple.com/jp/app/google-authenticator/id388497605?mt=8"
            ><img
              src="@static/img/badge_app_store_s.png"
              alt="app store"
              height="24px"
              class="app_icon"
          /></a>
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=ja&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            ><img
              alt="Google Play で手に入れよう"
              src="@static/img/badge_google_play_s.png"
              height="24px"
              class="app_icon"
          /></a>
        </div>
      </div>

      <div class="ablock">
        <div class="bh5 fw_m ta_c"
          ><span class="fc_y">{{
            $t('pages.Beginner.about_two_step.area4.subTitle2')
          }}</span></div
        >

        <div class="ta_c mt_20">
          <div class="whitebox twostep7">
            <img
              :src="`${s3Url}img/page/about_twostep7.${language}.png?v=5`"
              :alt="$t('pages.Beginner.about_two_step.area4.step1')"
              class="t-fac_img"
            />
          </div>
        </div>

        <div class="steps">
          <div class="flex_wt topflow">
            <div class="w3s flex_c">
              <div class="fs_14 fw_l mw_15 ta_c ta_l_sp op_65 stepp">{{
                $t('pages.Beginner.about_two_step.area4.step1_title')
              }}</div>
              <div class="btxt ta_c fdblock">
                {{ $t('pages.Beginner.about_two_step.area4.step1') }}
              </div>
            </div>
            <div class="w3s flex_c">
              <div class="fs_14 fw_l mw_15 ta_c ta_l_sp op_65 stepp">{{
                $t('pages.Beginner.about_two_step.area4.step2_title')
              }}</div>
              <div class="btxt ta_c fdblock">
                {{ $t('pages.Beginner.about_two_step.area4.step2') }}
              </div>
            </div>
            <div class="w3s flex_c">
              <div class="fs_14 fw_l mw_15 ta_c ta_l_sp op_65 stepp">{{
                $t('pages.Beginner.about_two_step.area4.step3_title')
              }}</div>
              <div class="btxt ta_c fdblock">{{
                $t('pages.Beginner.about_two_step.area4.step3')
              }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="boxs mt_25 mb_20">{{
        $t('pages.Beginner.about_two_step.area4.msg2')
      }}</div>
      <div class="cat_red boxs">{{
        $t('pages.Beginner.about_two_step.area4.warning')
      }}</div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)
</script>

<style lang="scss" scoped>
  .twostep7 {
    width: 36%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    .box_area {
      margin: 0 auto;
      width: 80%;
      text-align: center;
    }
  }
</style>
