<template>
  <the-message-trade :message="state.message"></the-message-trade>

  <div class="order-switch clearfix order_type">
    <ul class=""
      ><li class="wal_list">
        <input
          id="limit_type_limit"
          type="radio"
          name="limit_type"
          value="limit"
          v-model="state.order_type"
        />

        <label for="limit_type_limit"
          ><span class="box-right">{{
            $t('pages.Trade.order_type.limit')
          }}</span></label
        >

        <input
          id="limit_type_stop"
          type="radio"
          name="limit_type"
          value="stop"
          v-model="state.order_type"
        />

        <label for="limit_type_stop"
          ><span class="box-right">{{
            $t('pages.Trade.order_type.stop')
          }}</span></label
        ></li
      ></ul
    >
  </div>

  <div class="data bg-dark orderslippage">
    <ul>
      <li
        >{{ $t('pages.Trade.order.qty') }}({{ props.type?.toUpperCase() }})</li
      >
      <li>
        <input
          type="number"
          title=""
          inputmode="decimal"
          class="form-control text-right"
          autocomplete="off"
          v-bind:placeholder="props.input_min"
          v-model="state.qty"
          onkeydown="return event.keyCode !== 69"
        />
      </li>
    </ul>

    <div class="form-select-price">
      <button
        type="button"
        name="market_qty_plus"
        :value="(obj as string)"
        class="plus-btn"
        v-for="obj in props.btn_list"
        :key="(obj as string)"
        v-on:click="state.qty = qty_plus(state.qty, obj as string)"
        >+{{ obj }}</button
      >

      <button
        type="button"
        value="0"
        class="plus-btn"
        v-on:click="state.qty = '0'"
        style="width: auto"
        >✕</button
      >
    </div>
  </div>

  <div class="data price-switch orderslippage order-price">
    <div class="form-select-price">
      <input
        id="limit-position-bid"
        type="radio"
        name="limit_trade_type"
        class="trade_type"
        v-model="state.trade_type"
        value="sell"
      />
      <label
        for="limit-position-bid"
        class="mat-btn uk-button bg-red"
        style="width: 50%"
        v-on:click="
          state.target_rate =
            (store.ws.crypto &&
              store.ws.crypto[props.type?.toLocaleLowerCase()]?.bid) ||
            0
        "
        >{{ $t('pages.Trade.order.sell') }}
        {{
          (
            (store.ws.crypto &&
              store.ws.crypto[props.type?.toLocaleLowerCase()]?.bid) ||
            0
          ).toLocaleString()
        }}
      </label>

      <input
        id="limit-position-ask"
        type="radio"
        name="limit_trade_type"
        class="trade_type"
        v-model="state.trade_type"
        value="buy"
      />
      <label
        for="limit-position-ask"
        class="mat-btn uk-button bg-green"
        style="width: 50%"
        v-on:click="
          state.target_rate =
            (store.ws.crypto &&
              store.ws.crypto[props.type?.toLocaleLowerCase()]?.ask) ||
            0
        "
        >{{ $t('pages.Trade.order.buy') }}
        {{
          (
            (store.ws.crypto &&
              store.ws.crypto[props.type?.toLocaleLowerCase()]?.ask) ||
            0
          ).toLocaleString()
        }}
      </label>
    </div>
  </div>
  <div class="data bg-dark price-switch orderslippage order-price">
    <ul>
      <li>{{ $t('pages.Trade.order.order_amount') }}</li>
      <li>
        <div class="jpys">
          <input
            type="number"
            title=""
            inputmode="decimal"
            class="form-control text-right"
            maxlength="8"
            v-model="state.target_rate"
            onkeydown="return event.keyCode !== 69"
          />
        </div>
      </li>
    </ul>
  </div>

  <div>
    <div class="data bg-dark">
      <ul>
        <li style="padding: 10px 10px 10px 0px"
          >{{ $t('pages.Trade.order.approx_amount') }}：<span
            class="approx-amount"
            >{{ state.approx_amount }}</span
          >
        </li>
      </ul>
    </div>
  </div>

  <ul class="data-4col">
    <button
      class="mat-btn uk-button btn-warning"
      v-debounce="{
        func: confirm
      }"
      >{{ $t('pages.Trade.order.order') }}</button
    >
    <button
      data-bs-toggle="modal"
      id="limitModalDisplayBtn"
      data-bs-target="#open_limit_order_block"
      style="display: none"
    ></button>
  </ul>

  <the-modal
    v-if="state.modal"
    id="modal-confirm"
    hasClose
    @close="state.modal = false"
  >
    <section class="modal-inner">
      <h1>{{ $t('pages.Trade.order.order_confirm') }}</h1>

      <div class="corporation-confirm">
        <dl>
          <dt>{{ $t('pages.Trade.order.brand_name') }}</dt>
          <dd
            >{{ $t('pages.Trade.order.spot')
            }}{{ props.type?.toUpperCase() }}JPY</dd
          >
        </dl>
        <dl>
          <dt>{{ $t('pages.Trade.order.qty') }}</dt>
          <dd>{{ state.qty }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('pages.Trade.order.order_type') }}</dt>
          <dd>{{
            state.order_type == 'limit'
              ? $t('pages.Trade.order_type.limit')
              : $t('pages.Trade.order_type.stop')
          }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('pages.Trade.order.limit_rate') }}</dt>
          <dd>{{ state.target_rate.toLocaleString() }}</dd>
        </dl>
      </div>

      <button
        class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: state.loading,
          value: [
            state.trade_type == 'buy'
              ? $t('pages.Trade.order.buy')
              : $t('pages.Trade.order.sell')
          ]
        }"
        v-bind:class="[state.trade_type == 'buy' ? 'bg-green' : 'bg-red']"
        type="button"
        >{{
          state.trade_type == 'buy'
            ? $t('pages.Trade.order.buy')
            : $t('pages.Trade.order.sell')
        }}</button
      >
    </section>
  </the-modal>
</template>

<script lang="ts" setup>
  import { reactive, onMounted, watch } from 'vue'
  import { useMainStore } from '@/store/pinia'
  import { qty_plus, make_massage } from '@/utils/tool'
  import { useI18n } from 'vue-i18n'
  import TheModal from '@/components/TheModal.vue'
  import { orderLimit } from '@/api'

  const { t } = useI18n()
  const store = useMainStore()

  const props = defineProps({
    type: String,
    tradetoken: String,
    btn_list: Array,
    input_min: String,
    rate_ask: String,
    setting: Object,
    other: Object
  })
  const emit = defineEmits<{
    (e: 'onchange'): void
  }>()
  const state: {
    qty: string
    approx_amount: string
    order_type: string
    trade_type: string
    target_rate: string
    loading: boolean
    modal: boolean
    message: any
  } = reactive({
    qty: '0',
    approx_amount: '0',
    order_type: 'limit',
    trade_type: 'buy',
    target_rate: '0',
    loading: false,
    modal: false,
    message: {}
  })

  const confirm = async () => {
    state.modal = true
  }

  const submit = async () => {
    try {
      state.loading = true
      const { contents } = await orderLimit(
        (props.type as string)?.toLowerCase(),
        {
          contents: {
            qty: state.qty,
            order_type: state.order_type,
            trade_type: state.trade_type,
            target_rate: state.target_rate,
            bgauthtoken: props.tradetoken
          }
        }
      )
      emit('onchange')
      state.message = {
        success: t('error.success_order_limit')
      }
    } catch (error: any) {
      state.message = {
        error: make_massage(error?.message, t)
      }
    } finally {
      state.modal = false
      setTimeout(function () {
        state.loading = false
      }, 3000)
    }
  }

  onMounted(async () => {
    state.qty = props.input_min || '0'
    state.target_rate = props.rate_ask || '0'
  })

  watch(
    () => props.input_min as string,
    async (value: string, oldValue: string) => {
      state.qty = props.input_min || '0'
    }
  )

  watch(
    () => props.rate_ask as string,
    async (value: string, oldValue: string) => {
      state.target_rate = props.rate_ask || '0'
    }
  )

  watch(
    () => state.qty as string,
    async (value: string, oldValue: string) => {
      state.approx_amount = parseInt(value * state.target_rate).toLocaleString()
    }
  )

  watch(
    () => state.target_rate as string,
    async (value: string, oldValue: string) => {
      state.approx_amount = parseInt(state.qty * value).toLocaleString()
    }
  )
</script>

<style lang="scss" scoped>
  @use '@/static/styles/trade-list.scss';
  @use '@/static/styles/trade-order.scss';

  .order-price {
    height: 52px;
  }
  h1 {
    font-size: 2rem;
    margin: 2rem auto;
  }
  .corporation-confirm {
    padding-top: unset !important;
  }
</style>
