<template>
  <!-- article start -->
  <article id="contents">
    <h1 class="contents_title">{{
      $router.currentRoute?.value?.meta?.title
    }}</h1>
    <div class="main_contents boxm">
      <div class="main_contents_text boxs">
        <div class="main_contents_head_sp"></div>

        <div class="policy_inner">
          <p>{{ $t('pages.ForeignOfficial.title') }}</p>

          <p>{{ $t('pages.ForeignOfficial.msg1') }}</p>
          <p>{{ $t('pages.ForeignOfficial.msg2') }}</p>
          <ul class="policy_list">
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg2_1') }} </li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg2_2') }} </li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg2_3') }}</li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg2_4') }} </li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg2_5') }} </li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg2_6') }}</li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg2_7') }} </li>
          </ul>

          <p>{{ $t('pages.ForeignOfficial.msg3') }}</p>
          <p>{{ $t('pages.ForeignOfficial.msg4') }}</p>
          <ul class="policy_list">
            <li><span></span>{{ $t('pages.ForeignOfficial.msg4_0') }} </li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg4_1') }} </li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg4_2') }}</li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg4_3') }}</li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg4_4') }}</li>
            <li><span>・</span>{{ $t('pages.ForeignOfficial.msg4_5') }}</li>
          </ul>

          <p>{{ $t('pages.ForeignOfficial.msg5') }}</p>
          <ul class="policy_list">
            <li><span>※</span>{{ $t('pages.ForeignOfficial.msg6') }}</li>
            <li><span>※</span>{{ $t('pages.ForeignOfficial.msg7') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<style lang="scss" scoped>
  .policy_list {
    margin-top: 0;
    padding-top: 0;
  }
</style>
