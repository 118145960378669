import { DirectiveBinding } from 'vue'
import { debounce } from 'lodash'

export const debounceConfig = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const {
      func,
      event = 'click',
      options = {
        leading: true,
        trailing: false
      },
      delay = 1000
    } = binding.value
    el.addEventListener(event, debounce(func, delay, options))
  }
}

export const loadingConfig = {
  updated(el: HTMLButtonElement, binding: DirectiveBinding) {
    const { color = '#fff', value, loading = false } = binding.value
    if (loading) {
      el.innerHTML = getLoading(color)
      el.disabled = true
    } else {
      el.innerHTML = value
      el.disabled = false
    }
  }
}

const getLoading = (color: string) => {
  return `<div class="loader" style="border: 2px solid ${color} ;border-bottom-color: transparent"></div>`
}
