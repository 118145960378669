<template>
  <div class="tstep">
    <img
      :src="`${s3Url}img/pc_step3.${language}.png?v=1`"
      class="sp_no"
      alt="step"
    />
    <img
      :src="`${s3Url}img/sp_step3.${language}.png?v=1`"
      class="sp"
      alt="step"
    />
  </div>

  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>

  <div class="individual">
    <div class="individual-wrapper">
      <ol class="step mb30">
        <li><span style="padding-left: 1em">１</span></li>
        <li class="is-current br-tl-6 br-bl-6"
          >２{{ $t('pages.Individual.title.step2') }}</li
        >
        <li><span style="padding-left: 1em">３</span></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Individual.title.step4')
        }}</li>
      </ol>

      <form style="padding-bottom: 50px">
        <h1>{{ $t('pages.Individual.title.step2') }}</h1>

        <p v-html="$t('pages.Individual.step2.msg1')"></p>
        <p>{{ $t('pages.Individual.step2.msg2') }}</p>
        <p style="color: red">{{ $t('pages.Individual.step2.msg3') }}</p>
        <p class="select" v-html="$t('pages.Individual.step2.msg4')"></p>
        <router-link v-slot="{ navigate }" :to="{ name: 'IndividualStep2QR' }"
          ><button
            @click="navigate"
            role="link"
            style="margin-top: 30px"
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
            >{{ $t('pages.Individual.step2.button1') }}</button
          >
        </router-link>
        <p class="select" v-html="$t('pages.Individual.step2.msg5')"></p>
        <router-link
          v-slot="{ navigate }"
          :to="{ name: 'IndividualStep2Upload' }"
          ><button
            @click="navigate"
            role="link"
            style="margin-top: 30px"
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
            >{{ $t('pages.Individual.step2.button2') }}</button
          >
        </router-link>
      </form>
    </div>
  </div>
  <!-- ここから Crib Notesユニバーサルタグのコード -->
  <component :is="'script'" v-if="env === 'prod' && state.clib_counter_flg">
    (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/" + a
    + "/atm.js"; e.id = d; e.async = true;
    b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
    "script", "__cribnotesTagMgrCmd", "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
    "https://tag.cribnotes.jp/container_manager");
  </component>
  <!-- ここまで Crib Notesユニバーサルタグのコード -->
  <component
    :is="'script'"
    src="https://reward.gree.net/static/js/worker_fdwt_a.min.js"
  ></component>
</template>

<script setup lang="ts">
  import { onMounted, reactive } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { getIndividualStep2 } from '@/api'

  const store = useMainStore()
  const { s3Url, language, env } = storeToRefs(store)

  const state: {
    clib_counter_flg: boolean
    init: boolean
    errorMessage: string
  } = reactive({
    clib_counter_flg: false,
    init: false,
    errorMessage: ''
  })

  onMounted(async () => {
    try {
      const { contents } = await getIndividualStep2()
      state.clib_counter_flg = contents?.clib_counter_flg

      const _gar_tag = document.getElementById('_gar_achieve')
      _gar_tag?.setAttribute('_gar_cid', contents?.cid)
      _gar_tag?.setAttribute('_gar_ad', contents?.ad)
      _gar_tag?.setAttribute('_gar_uid', contents?.uid)
      _gar_tag?.setAttribute('_gar_digest', contents?.digest)
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>

<style lang="scss" scoped>
  .select {
    width: 60%;
    margin: 40px auto 0 auto;
  }

  @media (max-width: 800px) {
    .select {
      width: 80%;
    }
  }

  @media (max-width: 600px) {
    .select {
      width: 100%;
    }
  }
</style>
