<template>
  <transition name="the-message">
    <div
      class="uk-alert the-message"
      v-if="!isClosed"
      :class="{
        'uk-alert-danger': props.type === 'error',
        'uk-alert-success': props.type === 'success',
        'uk-alert-primary': props.type === 'primary'
      }"
    >
      <a
        class="uk-alert-close uk-close uk-icon"
        uk-close=""
        @click="isClosed = true"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          xmlns="http://www.w3.org/2000/svg"
          ratio="1"
        >
          <line
            fill="none"
            stroke="#000"
            stroke-width="1.1"
            x1="1"
            y1="1"
            x2="13"
            y2="13"
          ></line>
          <line
            fill="none"
            stroke="#000"
            stroke-width="1.1"
            x1="13"
            y1="1"
            x2="1"
            y2="13"
          ></line>
        </svg>
      </a>
      <p id="p_message"><slot></slot></p>
    </div>
  </transition>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'
  const isClosed = ref(false)

  interface Props {
    type?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    type: 'error'
  })
</script>

<style lang="scss">
  .the-message {
    a {
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .the-message-enter-active,
  .the-message-leave-active {
    transition: all 0.2s linear;
    opacity: 1;
  }

  .the-message-enter-from,
  .the-message-leave-to {
    opacity: 0;
  }
</style>
