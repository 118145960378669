<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.WithdrawalBank.title') }}</h1>

        <template v-if="Object.keys(state.formJson).length > 0">
          <div class="formbox">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <div v-else>
              <the-message v-if="state.successMessage" type="primary">{{
                state.successMessage
              }}</the-message>
            </div>

            <div>
              <p class="ta-center mw_10">
                {{ $t('pages.WithdrawalBank.bank_msg') }}
              </p>
            </div>

            <div class="fee_table_wrap">
              <table class="fee_table">
                <tbody>
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.WithdrawalBank.bank.title1')
                    }}</td>
                    <td class="fee_td">{{
                      state.bank_info == null
                        ? $t('pages.WithdrawalBank.not_exist_bank')
                        : state.bank_info?.bank
                    }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.WithdrawalBank.bank.title2')
                    }}</td>
                    <td class="fee_td">{{
                      state.bank_info == null
                        ? $t('pages.WithdrawalBank.not_exist_bank')
                        : state.bank_info?.branch
                    }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.WithdrawalBank.bank.title3')
                    }}</td>
                    <td class="fee_td">{{
                      state.bank_info == null
                        ? $t('pages.WithdrawalBank.not_exist_bank')
                        : state.account_type.find(
                            (i) => i.key === state.bank_info?.type
                          )?.value
                    }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.WithdrawalBank.bank.title4')
                    }}</td>
                    <td class="fee_td">{{
                      state.bank_info == null
                        ? $t('pages.WithdrawalBank.not_exist_bank')
                        : state.bank_info?.account
                    }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.WithdrawalBank.bank.title5')
                    }}</td>
                    <td class="fee_td">{{
                      state.bank_info == null
                        ? $t('pages.WithdrawalBank.not_exist_bank')
                        : state.bank_info?.holder
                    }}</td></tr
                  >
                </tbody>
              </table>
            </div>

            <template v-if="state.twostep_flg === '0'">
              <router-link
                :to="{ name: 'TwoStepStatus' }"
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                >{{ $t('pages.WithdrawalBank.button_twostep') }}
              </router-link>
            </template>
            <template v-else>
              <template v-if="state.bank_info != null">
                <SchemaFormWithValidation
                  :schema="state.schema"
                  :model="state.formJson"
                  useCustomFormWrapper
                  ref="form"
                />
              </template>
            </template>

            <div class="w60 ml20p mr20p tbw-w100 tbw-ml0p tbw-mr0p mb20">
              <p class="indent-1">{{ $t('pages.WithdrawalBank.msg1') }}</p>
              <p class="indent-1">{{ $t('pages.WithdrawalBank.msg2') }}</p>
              <p class="indent-1">{{ $t('pages.WithdrawalBank.msg3') }}</p>
              <p class="indent-1">{{ $t('pages.WithdrawalBank.msg4') }}</p>
              <p class="indent-1"
                >{{ $t('pages.WithdrawalBank.msg5') }}
                <router-link :to="{ name: 'BankSetting' }">{{
                  $t('pages.WithdrawalBank.link')
                }}</router-link>
                {{ $t('pages.WithdrawalBank.msg6') }}</p
              >
            </div>

            <template
              v-if="state.twostep_flg !== '0' && state.bank_info != null"
            >
              <button
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                v-debounce="{
                  func: confirm
                }"
                type="button"
                >{{ $t('pages.WithdrawalBank.button_confirm') }}</button
              >
            </template>
          </div>

          <h1 class="history-title">{{
            $t('pages.WithdrawalBank.title_history1')
          }}</h1>

          <history-item
            :detail="state.history1"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history1.total_pages"
            :page="state.page1"
            @onchange="changePage1"
            v-if="state.history1 && state.history1?.results?.length > 0"
          ></pagination>

          <h1 class="history-title">{{
            $t('pages.WithdrawalBank.title_history2')
          }}</h1>

          <history-item
            :detail="state.history2"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history2.total_pages"
            :page="state.page2"
            @onchange="changePage2"
            v-if="state.history2 && state.history2?.results?.length > 0"
          ></pagination>

          <the-modal
            v-if="state.modal"
            id="modal-confirm"
            hasClose
            @close="state.modal = false"
          >
            <section class="modal-inner">
              <h1 v-html="$t('pages.WithdrawalBank.button_confirm')"></h1>

              <div class="corporation-confirm">
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalBank.bank.title1') }}</dt>
                  <dd>{{ state.bank_info.bank }}</dd>
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalBank.bank.title2') }}</dt>
                  <dd>{{ state.bank_info.branch }}</dd>
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalBank.bank.title3') }}</dt>
                  <dd>{{
                    state.account_type.find(
                      (i) => i.key === state.bank_info.type
                    )?.value
                  }}</dd>
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalBank.bank.title4') }}</dt>
                  <dd>{{ state.bank_info.account }}</dd>
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalBank.bank.title5') }}</dt>
                  <dd>{{ state.bank_info.holder }}</dd>
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalBank.form.fee') }}</dt>
                  <dd
                    >{{ state.fee.toLocaleString() }}
                    {{ $t('pages.WithdrawalBank.unit') }}</dd
                  >
                </dl>
                <dl>
                  <dt>{{ $t('pages.WithdrawalBank.form.amount') }}</dt>
                  <dd
                    >{{ parseInt(state.formJson?.amount).toLocaleString() }}
                    {{ $t('pages.WithdrawalBank.unit') }}</dd
                  >
                </dl>
              </div>

              <div id="div_id_twostep_code">
                <label
                  for="id_twostep_code"
                  class="col-form-label requiredField"
                >
                  <span class="font_size">{{
                    $t('pages.TwoStepCheck.form.issueLabel')
                  }}</span>
                  <button
                    class="uk-button uk-button-primary"
                    style="margin: 0 auto; display: block"
                    v-if="state.twostep_flg !== '3'"
                    v-debounce="{
                      func: issueCode
                    }"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.TwoStepCheck.form.issue')
                    }"
                    type="button"
                    >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                  >
                  <div v-else>&nbsp;</div>
                </label>
              </div>

              <SchemaFormWithValidation
                :schema="state.schema2"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
              />

              <button
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                v-debounce="{
                  func: submit
                }"
                v-loading="{
                  loading: state.loading,
                  value: $t('pages.WithdrawalBank.modal_button')
                }"
                type="button"
                >{{ $t('pages.WithdrawalBank.modal_button') }}</button
              >
            </section>
          </the-modal>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import TheModal from '@/components/TheModal.vue'
  import { useRouter } from 'vue-router'
  import {
    getWithdrawal,
    twoStepIssue,
    getWalletFiatHistory,
    setWithdrawal
  } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const router = useRouter()
  const { t } = useI18n()
  const state: {
    title: any[]
    code_mst: any[]
    account_type: any[]
    history1:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    history2:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    bank_info: any
    fee: number
    page1: number
    page2: number
    formJson: any
    schema: any
    schema2: any
    loading: boolean
    modal: boolean
    isIssued: boolean
    twostep_flg: string
    crypto: string | string[]
    errorMessage: string
    successMessage: string
  } = reactive({
    title: [],
    code_mst: [],
    account_type: [],
    history1: undefined,
    history2: undefined,
    bank_info: {},
    fee: 0,
    page1: 1,
    page2: 1,
    formJson: {},
    schema: {
      limit_amount: {
        component: 'Text',
        label: t('pages.WithdrawalBank.form.limit_amount'),
        type: 'number',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: ''
      },
      amount: {
        component: 'Text',
        label: t('pages.WithdrawalBank.form.amount'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalBank.form.amount'),
            type: t('validation.type.input')
          })
        )
      }
    },
    schema2: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    loading: true,
    modal: false,
    isIssued: false,
    twostep_flg: '',
    crypto: '',
    errorMessage: '',
    successMessage: ''
  })

  const confirm = async () => {
    const valid = form.value?.validate()

    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        if (state.formJson.amount < 1) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_limit_amount')
          return
        }
        if (state.formJson.limit_amount < state.formJson.amount) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_limit_amount')
          return
        }

        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const issueCode = async () => {
    state.loading = true
    await twoStepIssue('db_check', null)
    state.isIssued = true
    state.loading = false
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
        state.loading = true
        try {
          const { contents } = await setWithdrawal({
            contents: {
              ...state.formJson
            }
          })
          state.loading = false
          state.modal = false
          state.successMessage = t('error.success_withdrawal_request')
          state.formJson.amount = ''
          init()
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          state.loading = false
          state.modal = false
        } finally {
          state.formJson.passcode = ''
        }
      }
    }, 0)
  }

  const showHistory1 = async () => {
    const { contents } = await getWalletFiatHistory(
      state.page1,
      '2',
      'incomplete'
    )
    state.history1 = contents
  }

  const showHistory2 = async () => {
    const { contents } = await getWalletFiatHistory(
      state.page2,
      '2',
      'complete'
    )
    state.history2 = contents
  }

  const changePage1 = async (page: number) => {
    state.page1 = page
    state.loading = true
    await showHistory1()
    state.loading = false
  }

  const changePage2 = async (page: number) => {
    state.page2 = page
    state.loading = true
    await showHistory2()
    state.loading = false
  }

  const init = async () => {
    try {
      const { contents } = await getWithdrawal()
      state.bank_info = contents?.bank
      state.twostep_flg = contents?.twostep_flg
      state.fee = contents?.fee
      state.formJson.limit_amount = contents?.limit_amount
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.WithdrawalBank.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  onMounted(async () => {
    init()

    state.title = [
      { key: 'upd_date', name: t(`pages.WithdrawalBank.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.WithdrawalBank.item.title2`),
        type: 'code'
      },
      {
        key: 'amount',
        name: t(`pages.WithdrawalBank.item.title3`),
        type: 'number'
      },
      {
        key: 'fee',
        name: t(`pages.WithdrawalBank.item.title4`),
        type: 'number'
      }
    ]
    state.code_mst = options.deposit_fiat
    state.account_type = options.account_type
    state.loading = true
    await showHistory1()
    await showHistory2()
    state.loading = false
  })
</script>
<style lang="scss" scoped>
  p > a {
    display: inline;
    color: #febd09;
  }
  .corporation-confirm {
    padding-top: unset !important;
    margin-bottom: 0px !important;
  }
  #div_id_twostep_code {
    margin-top: 0px !important;
  }
</style>
