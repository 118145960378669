import { createI18n } from 'vue-i18n'
import { DEFAULT_LOCALE } from '../@types'
import { LOCALE_KEY } from '../@types'

// Preloaded translation files
import commonEn from './messages/en/common.en.json'
import commonJa from './messages/ja/common.ja.json'
import commonCn from './messages/cn/common.cn.json'
import pageEn from './messages/en/page.en.json'
import pageJa from './messages/ja/page.ja.json'
import pageCn from './messages/cn/page.cn.json'
import errorJa from './messages/ja/error.ja.json'
import errorEn from './messages/en/error.en.json'
import errorCn from './messages/cn/error.cn.json'

// Initialize messages with translations
const messages = {
  en: {
    ...commonEn,
    ...pageEn,
    ...errorEn
  },
  ja: {
    ...commonJa,
    ...pageJa,
    ...errorJa
  },
  cn: {
    ...commonCn,
    ...pageCn,
    ...errorCn
  }
}

// Creating an i18n Instance
const i18n = createI18n({
  locale: window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  messages,
  legacy: false,
  globalInjection: true,
  warnHtmlMessage: false
})

// Triggers the translation file loading completion event
window.dispatchEvent(new Event('i18n-loaded'))

export default i18n
