<template>
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh4 fw_m">キャンペーン情報</div>
      <div class="main_contents boxm">
        <div class="camp_sets">
          <the-loading v-if="state.loading" color="#434343"></the-loading>
          <router-link
            :to="{ name: 'CampaignDetail', params: { id: item.id } }"
            class="camp_set"
            v-for="item in state.list?.results"
            :key="item.id"
          >
            <figure class="camp_box_l"><img :src="item.thumb_url" /></figure>
            <span class="camp_box_r">
              <span class="title">{{ item.title }}</span>
              <span class="text">
                {{ item.thumb_comment }}<br />
                キャンペーン期間<br />
                {{ formatTime(item.start_date) }} ～
                {{ formatTime(item.end_date) }}
              </span>
            </span>
          </router-link>
        </div>

        <pagination
          :total="state.list.total_pages"
          :page="state.page"
          @onchange="changePage"
          v-if="state.list?.results?.length > 0"
        ></pagination>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { onMounted, reactive } from 'vue'
  import { getCampaigns } from '@/api'
  import Pagination from '@/components/Pagination.vue'
  import { formatTime } from '@/utils/tool'

  const state: {
    list: any
    page: number
    loading: boolean
  } = reactive({
    list: [],
    page: 1,
    loading: true
  })

  const showCampaigns = async () => {
    state.loading = true
    const { contents } = await getCampaigns(state.page)
    state.list = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showCampaigns()
  }

  onMounted(() => {
    showCampaigns()
  })
</script>
