<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.Transfer.title') }}</h1>

        <template v-if="Object.keys(state.formJson).length > 0">
          <div class="boxss">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <div v-else>
              <the-message v-if="state.successMessage" type="primary">{{
                state.successMessage
              }}</the-message>
            </div>

            <div class="account-info">
              <ul>
                <li class="account-title"
                  ><span class="column_01"></span
                  ><span class="column_02">{{
                    $t('pages.Transfer.wallet')
                  }}</span
                  ><span class="column_02"></span
                  ><span class="column_03">{{
                    $t('pages.Transfer.trade')
                  }}</span></li
                >
                <li
                  ><span class="column_01">
                    <input
                      type="radio"
                      name="currency"
                      value="jpy"
                      required
                      v-model="currency"
                      class="uk-radio uk-margin-left"
                      checked
                    />
                    JPY</span
                  ><span
                    class="column_02"
                    id="amount_wallet_jpy"
                    v-on:click="
                      set_amount(
                        'jpy',
                        state.summary_objs?.wallet_balance?.qty_jpy
                      )
                    "
                    >{{
                      disp_amount(
                        'jpy',
                        state.summary_objs?.wallet_balance?.qty_jpy
                      )
                    }}</span
                  ><span class="column_02">
                    <template v-if="currency === 'jpy'">⇔</template> </span
                  ><span
                    class="column_03"
                    v-on:click="
                      set_amount(
                        'jpy',
                        state.summary_objs?.trade_balance?.usable_jpy
                      )
                    "
                    >{{
                      disp_amount(
                        'jpy',
                        state.summary_objs?.trade_balance?.usable_jpy
                      )
                    }}</span
                  ></li
                >
                <li v-for="coin in state.coins" :key="coin"
                  ><span class="column_01">
                    <input
                      type="radio"
                      name="currency"
                      required
                      v-bind:value="coin.toLowerCase()"
                      v-model="currency"
                      class="uk-radio uk-margin-left"
                    />
                    {{ coin }}</span
                  ><span
                    class="column_02"
                    v-on:click="
                      set_amount(
                        coin.toLowerCase(),
                        state.summary_objs?.wallet_balance?.[
                          `qty_${coin.toLocaleLowerCase()}`
                        ]
                      )
                    "
                    >{{
                      disp_amount(
                        coin.toLowerCase(),
                        state.summary_objs?.wallet_balance?.[
                          `qty_${coin.toLocaleLowerCase()}`
                        ]
                      )
                    }}</span
                  ><span class="column_02">
                    <template v-if="currency === coin.toLowerCase()"
                      >⇔</template
                    > </span
                  ><span
                    class="column_03"
                    v-on:click="
                      set_amount(
                        coin.toLowerCase(),
                        state.summary_objs?.trade_balance?.[
                          `usable_${coin.toLocaleLowerCase()}`
                        ]
                      )
                    "
                    >{{
                      disp_amount(
                        coin.toLowerCase(),
                        state.summary_objs?.trade_balance?.[
                          `usable_${coin.toLocaleLowerCase()}`
                        ]
                      )
                    }}</span
                  ></li
                >
              </ul>
            </div>

            <ul>
              <li>
                <div class="">
                  <p>{{ $t('pages.Transfer.msg2') }}</p>
                  <p
                    >{{ $t('pages.Transfer.msg3')
                    }}<a :href="`${state.domain}/home/`" class="is-link">{{
                      $t('pages.Transfer.msg4')
                    }}</a
                    >{{ $t('pages.Transfer.msg5') }}</p
                  >
                  <p>{{ $t('pages.Transfer.msg6') }}</p>
                  <p>&nbsp;</p>
                  <p v-if="currency !== 'jpy'">
                    {{ $t('pages.Transfer.msg1') }}<br />
                    {{ $t('pages.Transfer.msg.' + currency) }}
                  </p>
                </div>
              </li>
            </ul>

            <div class="input-area">
              <SchemaFormWithValidation
                :schema="state.schema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
              />

              <div class="item-center">
                <button
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary confirm_button"
                  v-debounce="{
                    func: confirm1
                  }"
                  type="button"
                  >{{ $t('pages.Transfer.btn_wallet') }}</button
                >
                <button
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary confirm_button"
                  v-debounce="{
                    func: confirm2
                  }"
                  type="button"
                  >{{ $t('pages.Transfer.btn_trade') }}</button
                >
              </div>
            </div>
          </div>

          <h1 class="history-title">{{
            $t('pages.Transfer.title_history')
          }}</h1>

          <history-item
            :detail="state.history"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history.total_pages"
            :page="state.page"
            @onchange="changePage"
            v-if="state.history && state.history?.results?.length > 0"
          ></pagination>

          <the-modal
            v-if="state.modal"
            id="modal-confirm"
            hasClose
            @close="state.modal = false"
          >
            <section class="modal-inner">
              <h1 v-html="$t('pages.Transfer.modal.title')"></h1>

              <div class="corporation-confirm">
                <dl>
                  <dt>{{ $t('pages.Transfer.modal.title1') }}</dt>
                  <dd>{{ $t('pages.Transfer.modal.' + state.to_account) }}</dd>
                </dl>
                <dl>
                  <dt>{{ $t('pages.Transfer.modal.title2') }}</dt>
                  <dd
                    >{{ state.formJson?.amount.toLocaleString() }}
                    {{
                      currency == 'jpy'
                        ? $t('pages.Transfer.jpy_unit')
                        : currency.toUpperCase()
                    }}</dd
                  >
                </dl>
              </div>

              <div id="div_id_twostep_code" v-if="state.twostep_flg != '0'">
                <label
                  for="id_twostep_code"
                  class="col-form-label requiredField"
                >
                  <span class="font_size">{{
                    $t('pages.TwoStepCheck.form.issueLabel')
                  }}</span>
                  <button
                    class="uk-button uk-button-primary"
                    style="margin: 0 auto; display: block"
                    v-if="state.twostep_flg !== '3'"
                    v-debounce="{
                      func: issueCode
                    }"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.TwoStepCheck.form.issue')
                    }"
                    type="button"
                    >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                  >
                  <div v-else>&nbsp;</div>
                </label>

                <SchemaFormWithValidation
                  :schema="state.schema2"
                  :model="state.formJson"
                  useCustomFormWrapper
                  ref="form"
                />
              </div>

              <button
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                v-debounce="{
                  func: submit
                }"
                v-loading="{
                  loading: state.loading,
                  value: $t('pages.Transfer.modal.button')
                }"
                type="button"
                >{{ $t('pages.Transfer.modal.button') }}</button
              >
            </section>
          </the-modal>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import { useRouter } from 'vue-router'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import TheModal from '@/components/TheModal.vue'
  import {
    getTransfer,
    getTransferHistory,
    twoStepIssue,
    setTransfer
  } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'
  import { BigNumber } from 'bignumber.js'

  const router = useRouter()
  const currency = ref('jpy')
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const coinFlag =
    import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG &&
    JSON.parse(import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG)
  const { t } = useI18n()
  const state: {
    coins: string[]
    summary_objs: any
    decimal_unit: any
    decimal_length: any
    title: any[]
    code_mst: any[]
    history:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    formJson: any
    schema: any
    schema2: any
    page: number
    loading: boolean
    modal: boolean
    isIssued: boolean
    from_account: string
    to_account: string
    twostep_flg: string
    errorMessage: string
    successMessage: string
  } = reactive({
    coins: Object.keys(coinFlag).filter((i) => coinFlag[i] === 'true'),
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN as string,
    summary_objs: {},
    decimal_unit: {},
    decimal_length: {},
    title: [],
    code_mst: [],
    history: undefined,
    formJson: {},
    schema: {
      amount: {
        component: 'Text',
        label: t('pages.Transfer.input.amount'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Transfer.input.amount'),
            type: t('validation.type.input')
          })
        )
      }
    },
    schema2: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    page: 1,
    loading: true,
    modal: false,
    isIssued: false,
    from_account: '',
    to_account: '',
    twostep_flg: '',
    errorMessage: '',
    successMessage: ''
  })

  const rounddown = (num: number, digit: number) => {
    BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
    return BigNumber(num).dp(digit)
  }

  const use_amount = (crypto: string, value: string) => {
    if (crypto == 'jpy') {
      return parseFloat(value).toLocaleString()
    } else {
      const len = state.decimal_length[crypto]
      const num = rounddown(parseFloat(value), len)
      return num.toLocaleString(undefined, {
        maximumFractionDigits: len
      })
    }
  }

  const disp_amount = (crypto: string, value: string) => {
    const amount = use_amount(crypto, value)
    return isNaN(Number(value)) ? '0' : amount
  }

  const set_amount = (crypto: string, value: string) => {
    const amount = use_amount(crypto, value)
    state.formJson.amount = amount.replaceAll(',', '')
    currency.value = crypto
  }

  const confirm1 = async () => {
    state.from_account = 'trade'
    state.to_account = 'wallet'
    confirmProcess()
  }

  const confirm2 = async () => {
    state.from_account = 'wallet'
    state.to_account = 'trade'
    confirmProcess()
  }

  const confirmProcess = async () => {
    state.successMessage = ''
    state.errorMessage = ''

    if (currency.value == 'jpy' && parseInt(state.formJson.amount) < 1) {
      document.documentElement.scrollTop = 0
      state.errorMessage = t('error.error_transfer_amount')
      return
    }
    if (currency.value != 'jpy' && parseFloat(state.formJson.amount) <= 0) {
      document.documentElement.scrollTop = 0
      state.errorMessage = t('error.error_transfer_amount')
      return
    }

    const balance =
      state.summary_objs?.[state.from_account + '_' + currency.value]
    if (parseFloat(balance) < parseFloat(state.formJson.amount)) {
      document.documentElement.scrollTop = 0
      state.errorMessage = t('error.error_transfer_amount')
      return
    }

    const valid = form.value?.validate()
    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const issueCode = async () => {
    state.loading = true
    await twoStepIssue('db_check', null)
    state.isIssued = true
    state.loading = false
  }

  const submit = async () => {
    state.successMessage = ''
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
        state.loading = true
        try {
          const { contents } = await setTransfer({
            contents: {
              transfer_account: state.to_account,
              currency: currency.value,
              amount: state.formJson.amount,
              passcode: state.formJson.passcode
            }
          })
          state.loading = false
          state.modal = false
          init()
          state.successMessage = t('error.success_transfer')
        } catch (error: any) {
          if (error?.message == 'error_transfer_decimal_unit') {
            state.errorMessage = t('error.' + error?.message, {
              crypto: currency.value.toUpperCase(),
              decimal_unit: state.decimal_unit?.[currency.value]
            })
          } else {
            state.errorMessage =
              error.error_translations || error.message_detail
          }
          document.documentElement.scrollTop = 0
          state.loading = false
          state.modal = false
        }
      }
    }, 0)
  }

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getTransferHistory(state.page)
    state.history = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  const init = async () => {
    try {
      state.formJson.amount = ''

      const { contents } = await getTransfer()
      state.summary_objs = contents?.summary_objs
      state.twostep_flg = contents?.two_step_flg
      state.decimal_unit = contents?.decimal_unit
      state.decimal_length = contents?.decimal_length
      state.formJson.loading = true
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.Transfer.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }

    await changePage(1)
  }

  onMounted(async () => {
    state.title = [
      { key: 'upd_date', name: t(`pages.Transfer.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.Transfer.item.title2`),
        type: 'code'
      },
      { key: 'currency', name: t(`pages.Transfer.item.title3`) },
      { key: 'amount', name: t(`pages.Transfer.item.title4`), type: 'number' },
      { key: 'balance', name: t(`pages.Transfer.item.title5`), type: 'number' }
    ]
    state.code_mst = options.transfer_type

    init()
  })
</script>
<style lang="scss" scoped>
  .input-area {
    margin-top: 20px;
  }
  .item-center {
    text-align: center;
  }
  .confirm_button {
    margin: 10px;
    width: 150px;
  }
  .is-link {
    display: inline;
    color: #febd09;
    &::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f0c1';
      font-weight: 900;
      font-size: 0.7rem;
      padding: 0 0.2rem;
      opacity: 0.75;
    }
  }
</style>
