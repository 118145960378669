<template>
  <div class="corporation-confirm">
    <dl>
      <dt>{{ $t('pages.Corporation.form.name_kanji') }}</dt>
      <dd>{{ props.data?.name_kanji || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.name_kana') }}</dt>
      <dd>{{ props.data?.name_kana || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.corporation_no') }}</dt>
      <dd>{{
        props.data?.corporation_no || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.post_no') }}</dt>
      <dd>{{ props.data?.post_no || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.addr1') }}</dt>
      <dd>{{ props.data?.addr1 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.addr2') }}</dt>
      <dd>{{ props.data?.addr2 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.addr3') }}</dt>
      <dd>{{ props.data?.addr3 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.rep_phone_no') }}</dt>
      <dd>{{ props.data?.phone_no || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.establishment_date') }}</dt>
      <dd>{{
        props.data?.establishment_date || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.capital') }}</dt>
      <dd>{{ props.data?.capital || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.industry') }}</dt>
      <dd>{{ props.data?.industry || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.financial_status_date') }}</dt>
      <dd>{{
        props.data?.financial_status_date || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.final_settlement_date') }}</dt>
      <dd>{{
        insertStr(props.data?.final_settlement_date, 2, '-') ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.business_contents') }}</dt>
      <dd>{{
        props.data?.business_contents || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.tax_type') }}</dt>
      <dd>{{
        findOption('tax_type', props.data?.tax_type) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.listed_company') }}</dt>
      <dd>{{
        findOption('yesOrNo', props.data?.listed_company) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.domestic_corporation') }}</dt>
      <dd>{{
        findOption('yesOrNo', props.data?.domestic_corporation) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.us_tax_obligation') }}</dt>
      <dd>{{
        findOption('withOrWithout', props.data?.us_tax_obligation) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.crypto_holding') }}</dt>
      <dd>{{
        findOption('withOrWithout', props.data?.crypto_holding) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <template v-if="props.data?.crypto_holding === '1'">
        <dt>{{ $t('pages.Corporation.form.crypto_held_text') }}</dt>
        <dd>{{
          props.data?.crypto_held_text || $t('pages.Corporation.form.none')
        }}</dd>
      </template>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.nature_funds') }}</dt>
      <dd>{{
        findOption('nature_funds', props.data?.nature_funds) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <template v-if="props.data?.nature_funds === '99'">
        <dt>{{ $t('pages.Corporation.form.nature_funds_other') }}</dt>
        <dd>{{
          props.data?.nature_funds_other || $t('pages.Corporation.form.none')
        }}</dd>
      </template>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.liabilities_asset') }}</dt>
      <dd>{{
        findOption('liabilities_asset', props.data?.liabilities_asset) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.annual_sales') }}</dt>
      <dd>{{
        findOption('annual_sales', props.data?.annual_sales) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.financial_asset') }}</dt>
      <dd>{{
        findOption(
          'financial_asset_corporation',
          props.data?.financial_asset
        ) || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.overseas_bases') }}</dt>
      <dd>{{
        findOption('withOrWithout', props.data?.overseas_bases) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <template v-if="props.data?.overseas_bases === '1'">
        <dt>{{ $t('pages.Corporation.form.overseas_bases_name') }}</dt>
        <dd>{{
          props.data?.overseas_bases_name || $t('pages.Corporation.form.none')
        }}</dd>
      </template>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.homepage') }}</dt>
      <dd>{{ props.data?.homepage || $t('pages.Corporation.form.none') }}</dd>
    </dl>
  </div>
</template>

<script setup lang="ts">
  import { findOption, insertStr } from '@/utils/tool'
  const props = defineProps({
    data: Object
  })
</script>
