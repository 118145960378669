<template>
  <div class="main_contents boxm" v-if="!(props.silent && state.loading)">
    <h2><i class="fas fa-book glossary"></i>&nbsp;用語集</h2>

    <the-loading v-if="state.loading" color="#434343"></the-loading>

    <div class="main_contents_list">
      <div class="column_glossary" v-if="state.glossary.length > 0">
        <ul class="glossary_navs">
          <li
            class="gnav_child"
            v-for="item of state.glossary"
            :key="item.category_jp"
          >
            <h3 class="title">{{ item.category_jp }}</h3>
            <ul class="glossary_nav">
              <li v-for="i in item.results" :key="i.idx">
                <router-link
                  :to="{ name: 'ColumnArticle', params: { id: i.idx } }"
                  class="asic"
                  >{{ i.title }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { getGlossary } from '@/api'
  import { reactive, onMounted } from 'vue'

  const props = defineProps({
    silent: {
      type: Boolean,
      default: false
    }
  })

  const state: { glossary: any[]; loading: boolean } = reactive({
    glossary: [],
    loading: true
  })

  onMounted(async () => {
    const { contents } = await getGlossary()
    Object.keys(contents)
      .sort()
      .forEach((v) => {
        state.glossary.push(contents[v])
      })
    state.loading = false
  })
</script>

<style lang="scss" scoped>
  .main_contents {
    padding-bottom: 10px;
  }
</style>
