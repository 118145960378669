<template>
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <form id="landingPageForm">
        <the-message v-if="state.errorMessage">{{
          state.errorMessage
        }}</the-message>

        <SchemaFormWithValidation
          :schema="state.inquirySchema"
          :model="state.formJson"
          useCustomFormWrapper
          ref="form"
        />

        <div>
          <button
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom bank_info_submit u-regist-btn"
            id="submitBtn"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.PasswordReset.form.submit')
            }"
            type="button"
            >{{ $t('pages.PasswordReset.form.submit') }}</button
          >
        </div>

        <p class="m_center p_small"
          >{{ $t('pages.PasswordReset.form.corp') }}
        </p>
        <p class="m_center p_small">
          <router-link
            :to="{ name: 'Inquiry', params: { inquiry_type_id: '02' } }"
            class="a_link"
            >{{ $t('pages.PasswordReset.link') }}</router-link
          >
        </p>
      </form>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'
  import { pwReset } from '@/api'
  import { useReCaptcha } from 'vue-recaptcha-v3'

  const recaptchaInstance = useReCaptcha()
  const { t } = useI18n()
  const store = useMainStore()
  const { language, isRecaptchaRequired } = storeToRefs(store)
  const router = useRouter()

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)

  const state: {
    googleToken: string
    inquirySchema: any
    formJson: any
    errorMessage: string
  } = reactive({
    googleToken: '',
    inquirySchema: {
      email: {
        component: 'Text',
        label: t('pages.PasswordReset.form.email'),
        required: true,
        type: 'email',
        inputClass: 'form-control',
        placeholder: t('pages.PasswordReset.form.email'),
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.email'),
              type: t('validation.type.input')
            })
          )
          .email(
            t('validation.input_error', {
              content: t('validation.item.email')
            })
          )
      },
      name: {
        component: 'Text',
        label: t('pages.PasswordReset.form.name'),
        required: true,
        type: 'text',
        inputClass: 'form-control',
        placeholder: 'アンゴウタロウ',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.name'),
              type: t('validation.type.input')
            })
          )
          .matches(
            /^[ァ-ヶー]+$/,
            t('validation.input_error', {
              content: t('validation.item.name')
            })
          )
      },
      birthday: {
        component: 'Text',
        label: t('pages.PasswordReset.form.birthday'),
        required: true,
        type: 'text',
        inputClass: 'form-control',
        placeholder: '20100331',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.birthday'),
              type: t('validation.type.input')
            })
          )
          .matches(
            /((^((1[8-9]\d{2})|([2-9]\d{3}))(10|12|0?[13578])(3[01]|[12][0-9]|0?[1-9])$)|(^((1[8-9]\d{2})|([2-9]\d{3}))(11|0?[469])(30|[12][0-9]|0?[1-9])$)|(^((1[8-9]\d{2})|([2-9]\d{3}))(0?2)(2[0-8]|1[0-9]|0?[1-9])$)|(^([2468][048]00)(0?2)(29)$)|(^([3579][26]00)(0?2)(29)$)|(^([1][89][0][48])(0?2)(29)$)|(^([2-9][0-9][0][48])(0?2)(29)$)|(^([1][89][2468][048])(0?2)(29)$)|(^([2-9][0-9][2468][048])(0?2)(29)$)|(^([1][89][13579][26])(0?2)(29)$)|(^([2-9][0-9][13579][26])(0?2)(29)$))/gi,
            t('validation.input_error', {
              content: t('validation.item.birthday')
            })
          )
      },
      post_no: {
        component: 'Text',
        label: t('pages.PasswordReset.form.post_no'),
        required: true,
        type: 'text',
        inputClass: 'form-control',
        placeholder: '1100016',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.post_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            /^[0-9]{7}$/,
            t('validation.input_error', {
              content: t('validation.item.post_no')
            })
          )
      }
    },
    formJson: {},
    errorMessage: ''
  })

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha('pw_reset')
    state.googleToken = token || ''
    if (!state.googleToken && isRecaptchaRequired.value) {
      state.errorMessage = t('validation.reCAPTCHA')
      return
    }

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true

        const data = {
          ...state.formJson,
          language: language.value,
          'g-recaptcha-response': state.googleToken
        }

        try {
          const { contents } = await pwReset({
            contents: data
          })

          router.push({
            name: 'PasswordResetSuccess',
            params: { email_encode: contents.encrypt_email }
          })
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      }
    }, 0)
  }

  onMounted(() => {
    const breadcrumb: SiteMapType = {
      route: router.currentRoute.value.name as string,
      name: router.currentRoute.value.meta.title
    }
    store.setBreadcrumb(breadcrumb)
  })
</script>
<style lang="scss">
  #landingPageForm {
    .error {
      margin-top: -0.5rem;
    }
    .madd_title {
      display: block;
    }
  }
</style>
