<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{
          $t('pages.WithdrawalCrypto.title', {
            crypto: state.crypto.toUpperCase()
          })
        }}</h1>

        <crypto-nav :crypto="state.crypto.toString()"> </crypto-nav>

        <template v-if="Object.keys(state.formJson).length > 0">
          <div class="formbox">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <div v-else>
              <the-message v-if="state.successMessage" type="primary">{{
                state.successMessage
              }}</the-message>
            </div>

            <ul class="form-normal">
              <template v-if="state.twostep_flg !== '0'">
                <SchemaFormWithValidation
                  :schema="state.schema"
                  :model="state.formJson"
                  useCustomFormWrapper
                  ref="form"
                  class="individual-form"
                />

                <div class="schema-row">
                  <li class="schema-col" labelclass="madd_title">
                    <span style="display: flex; align-items: flex-end">
                      <label
                        >{{ $t('pages.WithdrawalCrypto.form.address') }}
                        <span class="required-notice">{{
                          $t('form.required')
                        }}</span>
                      </label>
                    </span>
                    <input
                      type="text"
                      required
                      class="form-control valid"
                      v-on:change="changeAddress"
                      placeholder=""
                      v-model="state.formJson.address"
                    />
                  </li>
                </div>

                <SchemaFormWithValidation
                  :schema="state.schema2"
                  :model="state.formJson2"
                  useCustomFormWrapper
                  ref="form"
                  class="individual-form"
                />

                <template v-if="0 < state.formJson2.fee">
                  <SchemaFormWithValidation
                    :schema="state.schema3"
                    :model="state.formJson3"
                    useCustomFormWrapper
                    ref="form"
                    class="individual-form"
                  />
                </template>
              </template>

              <div>
                <p class="indent-1">{{ $t('pages.WithdrawalCrypto.msg1') }}</p>
                <p class="indent-1">{{
                  $t('pages.WithdrawalCrypto.msg2', {
                    crypto: state.crypto.toUpperCase()
                  })
                }}</p>
                <p class="indent-1">{{ $t('pages.WithdrawalCrypto.msg3') }}</p>
              </div>

              <template v-if="state.twostep_flg === '0'">
                <router-link
                  :to="{ name: 'TwoStepStatus' }"
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                  >{{ $t('pages.WithdrawalCrypto.button_twostep') }}
                </router-link>
              </template>
              <template v-else>
                <button
                  class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                  v-debounce="{
                    func: confirm
                  }"
                  type="button"
                  >{{ $t('pages.WithdrawalCrypto.button_confirm') }}</button
                >
              </template>
            </ul>
          </div>

          <h1 class="history-title">{{
            $t('pages.WithdrawalCrypto.title_history1', {
              crypto: state.crypto.toUpperCase()
            })
          }}</h1>

          <history-item
            :detail="state.history1"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history1.total_pages"
            :page="state.page1"
            @onchange="changePage1"
            v-if="state.history1 && state.history1?.results?.length > 0"
          ></pagination>

          <h1 class="history-title">{{
            $t('pages.WithdrawalCrypto.title_history2', {
              crypto: state.crypto.toUpperCase()
            })
          }}</h1>

          <history-item
            :detail="state.history2"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history2.total_pages"
            :page="state.page2"
            @onchange="changePage2"
            v-if="state.history2 && state.history2?.results?.length > 0"
          ></pagination>

          <the-modal
            v-if="state.modal"
            id="modal-confirm"
            hasClose
            @close="state.modal = false"
          >
            <section class="modal-inner">
              <h1 v-html="$t('pages.WithdrawalCrypto.title')"></h1>

              <div class="corporation-confirm">
                <dl>
                  <dt>{{ $t('pages.WithdrawalCrypto.modal.address') }}</dt>
                  <dd>{{ state.formJson.address }}</dd>
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalCrypto.modal.limit_amount_finish') }}</dt>
                  <dd
                    >{{ state.formJson2.limit_amount_finish
                    }}{{ state.crypto.toUpperCase() }}</dd
                  >
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.WithdrawalCrypto.modal.fee') }}</dt>
                  <dd
                    >{{ state.formJson2.fee
                    }}{{ state.crypto.toUpperCase() }}</dd
                  >
                </dl>

                <template v-if="0 < state.formJson2.fee">
                  <dl class="flex-1-1">
                    <dt>{{ $t('pages.WithdrawalCrypto.modal.dest_self') }}</dt>
                    <dd>{{
                      findOption('dest_self', state.formJson3?.dest_self)
                    }}</dd>
                  </dl>
                  <dl class="flex-1-1">
                    <dt>{{
                      $t('pages.WithdrawalCrypto.modal.dest_target')
                    }}</dt>
                    <dd>{{
                      findOption('dest_target', state.formJson3?.dest_target)
                    }}</dd>
                  </dl>
                  <dl class="flex-1-1" v-if="state.formJson3.dest_other != ''">
                    <dt>{{ $t('pages.WithdrawalCrypto.modal.dest_other') }}</dt>
                    <dd>{{ state.formJson3.dest_other }}</dd>
                  </dl>
                  <dl
                    class="flex-1-1"
                    v-if="state.formJson3.dest_exchange != ''"
                  >
                    <dt>{{
                      $t('pages.WithdrawalCrypto.modal.dest_exchange')
                    }}</dt>
                    <dd>{{
                      findOption(
                        'dest_exchange',
                        state.formJson3?.dest_exchange
                      )
                    }}</dd>
                  </dl>
                  <dl
                    class="flex-1-1"
                    v-if="state.formJson3.dest_exchange_other != ''"
                  >
                    <dt>{{
                      $t('pages.WithdrawalCrypto.modal.dest_exchange_other')
                    }}</dt>
                    <dd>{{ state.formJson3?.dest_exchange_other }}</dd>
                  </dl>
                  <dl>
                    <dt>{{ $t('pages.WithdrawalCrypto.modal.dest_name') }}</dt>
                    <dd>{{ state.formJson3?.dest_name }}</dd>
                  </dl>
                  <dl class="flex-1-1">
                    <dt>{{
                      $t('pages.WithdrawalCrypto.modal.dest_country')
                    }}</dt>
                    <dd>{{
                      findOption('country', state.formJson3?.dest_country)
                    }}</dd>
                  </dl>
                  <dl
                    class="flex-1-1"
                    v-if="state.formJson3.dest_addr_jp != ''"
                  >
                    <dt>{{
                      $t('pages.WithdrawalCrypto.modal.dest_addr_jp')
                    }}</dt>
                    <dd>{{
                      findOption('dest_addr', state.formJson3?.dest_addr_jp)
                    }}</dd>
                  </dl>
                  <dl
                    class="flex-1-1"
                    v-if="state.formJson3.dest_addr_other != ''"
                  >
                    <dt>{{
                      $t('pages.WithdrawalCrypto.modal.dest_addr_other')
                    }}</dt>
                    <dd>{{ state.formJson3?.dest_addr_other }}</dd>
                  </dl>
                  <dl class="flex-1-1">
                    <dt>{{ $t('pages.WithdrawalCrypto.modal.dest_trob') }}</dt>
                    <dd>{{
                      findOption('dest_trob', state.formJson3?.dest_trob)
                    }}</dd>
                  </dl>
                  <dl v-if="state.formJson3.dest_trob_other != ''">
                    <dt>{{
                      $t('pages.WithdrawalCrypto.modal.dest_trob_other')
                    }}</dt>
                    <dd>{{ state.formJson3?.dest_trob_other }}</dd>
                  </dl>
                </template>
              </div>

              <div id="div_id_twostep_code">
                <label
                  for="id_twostep_code"
                  class="col-form-label requiredField"
                >
                  <span class="font_size">{{
                    $t('pages.TwoStepCheck.form.issueLabel')
                  }}</span>
                  <button
                    class="uk-button uk-button-primary"
                    style="margin: 0 auto; display: block"
                    v-if="state.twostep_flg !== '3'"
                    v-debounce="{
                      func: issueCode
                    }"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.TwoStepCheck.form.issue')
                    }"
                    type="button"
                    >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                  >
                  <div v-else>&nbsp;</div>
                </label>
              </div>

              <SchemaFormWithValidation
                :schema="state.schema4"
                :model="state.formJson4"
                useCustomFormWrapper
                ref="form"
              />

              <button
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                v-debounce="{
                  func: submit
                }"
                v-loading="{
                  loading: state.loading,
                  value: $t('pages.WithdrawalCrypto.modal_button')
                }"
                type="button"
                >{{ $t('pages.WithdrawalCrypto.modal_button') }}</button
              >
            </section>
          </the-modal>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import User from '@/components/User.vue'
  import CryptoNav from '@/components/CryptoNav.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import TheModal from '@/components/TheModal.vue'
  import {
    getWithdrawalCrypto,
    getWithdrawalFee,
    twoStepIssue,
    getWalletCryptoHistory,
    setWithdrawalCrypto
  } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'
  import { addFormStyle, findOption } from '@/utils/tool'
  import { BigNumber } from 'bignumber.js'

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const { t } = useI18n()
  const router = useRouter()
  const state: {
    title: any[]
    code_mst: any[]
    history1:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    history2:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page1: number
    page2: number
    page3: number
    input_min: number
    input_max: number
    input_digits: number
    formJson: any
    formJson2: any
    formJson3: any
    formJson4: any
    schema: any
    schema2: any
    schema3: any
    schema4: any
    loading: boolean
    isIssued: boolean
    modal: boolean
    twostep_flg: string
    crypto: string
    successMessage: string
    errorMessage: string
  } = reactive({
    title: [],
    code_mst: [],
    history1: undefined,
    history2: undefined,
    page1: 1,
    page2: 1,
    page3: 1,
    input_min: 0,
    input_max: 0,
    input_digits: 0,
    formJson: {},
    formJson2: {},
    formJson3: {},
    formJson4: {},
    schema: {
      account: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.acocunt'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: ''
      },
      name: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.name'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: ''
      }
    },
    schema2: {
      addr_type: {
        component: 'Select',
        label: t('pages.WithdrawalCrypto.form.addr_type'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.addr_type,
        liClass: 'is-half',
        show: false,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.addr_type'),
            type: t('validation.type.select')
          })
        )
      },
      addr_tag: {
        component: 'Text',
        type: 'text',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        show: false,
        placeholder: '',
        validations: undefined
      },
      amount: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.amount'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: '',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.amount'),
            type: t('validation.type.input')
          })
        )
      },
      limit_amount: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.limit_amount'),
        type: 'number',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: ''
      },
      fee: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.fee'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'flex-1-1',
        placeholder: ''
      },
      limit_amount_finish: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.limit_amount_finish'),
        type: 'number',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'flex-1-1',
        placeholder: ''
      }
    },
    schema3: {
      dest_self: {
        component: 'Select',
        label: t('pages.WithdrawalCrypto.form.dest_self'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.dest_self,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_self'),
            type: t('validation.type.select')
          })
        )
      },
      dest_target: {
        component: 'Select',
        label: t('pages.WithdrawalCrypto.form.dest_target'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        liClass: 'is-half',
        options: options.dest_target,
        additionalNotes: t('pages.WithdrawalCrypto.form_msg.dest_target'),
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_target'),
            type: t('validation.type.select')
          })
        )
      },
      dest_exchange: {
        component: 'Select',
        label: t('pages.WithdrawalCrypto.form.dest_exchange'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        liClass: 'is-half',
        options: options.dest_exchange,
        show: false,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_exchange'),
            type: t('validation.type.select')
          })
        )
      },
      dest_exchange_other: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.dest_exchange_other'),
        type: 'text',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        show: false,
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_exchange_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_exchange_other.maxLength
            })
          )
      },
      dest_other: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.dest_other'),
        type: 'text',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        show: false,
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_other.maxLength
            })
          )
      },
      dest_name: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.dest_name'),
        type: 'text',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        additionalNotesShow: true,
        additionalNotes: t('pages.WithdrawalCrypto.form_msg.dest_name'),
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_name'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_name.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_name.maxLength
            })
          )
      },
      dest_country: {
        component: 'Select',
        label: t('pages.WithdrawalCrypto.form.dest_country'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        labelClass: 'madd_title',
        additionalNotesShow: true,
        additionalNotes: t('pages.WithdrawalCrypto.form_msg.dest_country'),
        options: options.country,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_country'),
            type: t('validation.type.select')
          })
        )
      },
      dest_addr_jp: {
        component: 'Select',
        label: t('pages.WithdrawalCrypto.form.dest_addr_jp'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        liClass: 'is-half',
        options: options.dest_addr,
        show: false,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_addr_jp'),
            type: t('validation.type.select')
          })
        )
      },
      dest_addr_other: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.dest_addr_other'),
        type: 'text',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        show: false,
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_addr_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_addr_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_addr_other.maxLength
            })
          )
      },
      dest_trob: {
        component: 'Select',
        label: t('pages.WithdrawalCrypto.form.dest_trob'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        liClass: 'is-half',
        options: options.dest_trob,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_trob'),
            type: t('validation.type.select')
          })
        )
      },
      dest_trob_other: {
        component: 'Text',
        label: t('pages.WithdrawalCrypto.form.dest_trob_other'),
        type: 'text',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: '',
        show: false,
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_trob_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_trob_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_trob_other.maxLength
            })
          )
      }
    },
    schema4: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    loading: false,
    isIssued: false,
    modal: false,
    twostep_flg: '',
    crypto: '',
    successMessage: '',
    errorMessage: ''
  })

  const confirm = async () => {
    state.successMessage = ''
    state.errorMessage = ''

    const valid = form.value?.validate()

    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        if (parseFloat(state.formJson2.amount) < state.input_min) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_min_limit', {
            min_limit: state.input_min,
            crypto: state.crypto.toUpperCase()
          })
          return
        }
        if (
          parseFloat(state.formJson2.limit_amount) <
          parseFloat(state.formJson2.amount) - parseFloat(state.formJson2.fee)
        ) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_max_limit', {
            max_limit: parseFloat(state.formJson2.limit_amount),
            crypto: state.crypto.toUpperCase()
          })
          return
        }

        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const issueCode = async () => {
    state.loading = true
    await twoStepIssue('db_check', null)
    state.isIssued = true
    state.loading = false
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
        state.loading = true
        try {
          const { contents } = await setWithdrawalCrypto(state.crypto, {
            contents: {
              ...state.formJson,
              ...state.formJson2,
              ...state.formJson3,
              ...state.formJson4
            }
          })
          state.successMessage = t('error.success_withdrawal_crypto')
          init()
        } catch (error: any) {
          if (error?.message == 'error_decimal_digits') {
            state.errorMessage = t('error.' + error?.message, {
              digits: state.input_digits
            })
          } else if (error?.message == 'error_min_limit') {
            state.errorMessage = t('error.' + error?.message, {
              min_limit: state.input_min,
              crypto: state.crypto.toUpperCase()
            })
          } else if (error?.message == 'error_max_limit') {
            state.errorMessage = t('error.' + error?.message, {
              max_limit:
                parseFloat(state.formJson2.limit_amount),
              crypto: state.crypto.toUpperCase()
            })
          } else {
            state.errorMessage =
              error.error_translations || error.message_detail
          }
        } finally {
          document.documentElement.scrollTop = 0
          state.loading = false
          state.modal = false
          state.formJson3.passcode = ''
        }
      }
    }, 0)
  }

  const showHistory1 = async () => {
    const { contents } = await getWalletCryptoHistory(
      state.crypto?.toString(),
      state.page1,
      '2',
      'incomplete'
    )
    state.history1 = contents
  }

  const showHistory2 = async () => {
    const { contents } = await getWalletCryptoHistory(
      state.crypto?.toString(),
      state.page2,
      '2',
      'complete'
    )
    state.history2 = contents
  }

  const changePage1 = async (page: number) => {
    state.page1 = page
    state.loading = true
    await showHistory1()
    state.loading = false
  }

  const changePage2 = async (page: number) => {
    state.page2 = page
    state.loading = true
    await showHistory2()
    state.loading = false
  }

  const init = async () => {
    try {
      state.formJson = {}
      state.formJson2 = {}
      state.formJson3 = {}
      state.formJson4 = {}
      state.crypto = router.currentRoute.value.params.crypto as string
      const { contents } = await getWithdrawalCrypto(state.crypto)
      state.formJson.account = contents?.account
      state.formJson.name = contents?.name
      state.formJson2.limit_amount = contents?.limit_amount
      state.formJson2.limit_amount_finish = 0
      state.formJson2.fee = 0
      state.twostep_flg = contents?.twostep_flg
      state.input_min = contents?.min_amount
      state.input_max = contents?.limit_amount
      state.input_digits = contents?.input_digits
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.WithdrawalCrypto.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }

    if (state.crypto == 'xrp') {
      state.schema2.addr_type = {}
      state.schema2.addr_tag.show = true
      state.schema2.addr_tag.liClass = 'flex-1-1'
      state.schema2.addr_tag.label = t(
        'pages.WithdrawalCrypto.form.addr_tag_xrp'
      )
      state.schema2.addr_tag.validations = yup
        .string()
        .required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.addr_tag_xrp'),
            type: t('validation.type.input')
          })
        )
        .max(
          validator.addr_tag.maxLength as number,
          t('validation.maxLength', {
            maxLength: validator.addr_tag.maxLength
          })
        )
    } else if (state.crypto == 'xlm') {
      // state.schema.addr_tag.show = true
      state.schema2.addr_tag.label = t(
        'pages.WithdrawalCrypto.form.addr_tag_xlm'
      )
      state.schema2.addr_tag.validations = yup
        .string()
        .required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.addr_tag_xlm'),
            type: t('validation.type.input')
          })
        )
        .max(
          validator.addr_tag.maxLength as number,
          t('validation.maxLength', {
            maxLength: validator.addr_tag.maxLength
          })
        )

      state.schema2.addr_type.show = true
    } else {
      state.schema2.addr_type = {}
      state.schema2.addr_tag = {}
    }

    addFormStyle()
  }

  onMounted(async () => {
    init()
    state.schema2.amount.label = t('pages.WithdrawalCrypto.form.amount', {
      crypto: '(' + state.crypto.toUpperCase() + ')'
    })
    state.schema2.limit_amount.label = t(
      'pages.WithdrawalCrypto.form.limit_amount',
      { crypto: '(' + state.crypto.toUpperCase() + ')' }
    )
    state.schema2.limit_amount_finish.label = t(
      'pages.WithdrawalCrypto.form.limit_amount_finish',
      { crypto: '(' + state.crypto.toUpperCase() + ')' }
    )
    state.schema2.fee.label = t('pages.WithdrawalCrypto.form.fee', {
      crypto: '(' + state.crypto.toUpperCase() + ')'
    })

    state.title = [
      { key: 'upd_date', name: t(`pages.WithdrawalCrypto.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.WithdrawalCrypto.item.title2`),
        type: 'code'
      },
      {
        key: 'amount',
        name:
          t(`pages.WithdrawalCrypto.item.title3`) +
          '(' +
          state.crypto.toString() +
          ')',
        type: 'number'
      },
      {
        key: 'fee',
        name:
          t(`pages.WithdrawalCrypto.item.title4`) +
          '(' +
          state.crypto.toString() +
          ')',
        type: 'number'
      },
      {
        key: 'balance',
        name:
          t(`pages.WithdrawalCrypto.item.title5`) +
          '(' +
          state.crypto.toString() +
          ')',
        type: 'number'
      },
      {
        key: 'tx',
        name: t(`pages.WithdrawalCrypto.item.title6`),
        type: 'url',
        url_key: 'tx_url'
      }
    ]
    state.code_mst = options.deposit_crypto

    await showHistory1()
    await showHistory2()
  })

  const changeAddress = async (e: any) => {
    const value = e.target.value
    if (value == null || value == '') {
      state.formJson.address == ''
      state.formJson2.fee == 0
      return
    }
    const { contents } = await getWithdrawalFee(state.crypto, {
      contents: {
        address: value,
        addr_tag: state.formJson2?.addr_tag
      }
    })
    state.formJson2.fee = contents?.fee
    state.formJson2.limit_amount_finish = BigNumber(
      state.formJson2.amount
    ).minus(BigNumber(contents?.fee))

    addFormStyle()
  }

  watch(
    () => state.formJson2.addr_tag,
    async (value: string, oldValue: string) => {
      const { contents } = await getWithdrawalFee(state.crypto, {
        contents: {
          address: state.formJson.address,
          addr_tag: value
        }
      })
      state.formJson2.fee = contents?.fee
      addFormStyle()
    }
  )

  watch(
    () => state.formJson2.amount,
    async (value: string, oldValue: string) => {
      state.formJson2.limit_amount_finish = BigNumber(value).minus(
        BigNumber(state.formJson2.fee)
      )
    }
  )

  watch(
    () => state.formJson2.addr_type,
    async (value: string, oldValue: string) => {
      if (value != '01') {
        state.schema2.addr_tag.show = true
      } else {
        state.schema2.addr_tag.show = false
      }
    }
  )

  watch(
    () => state.formJson3.dest_self,
    async (value: string, oldValue: string) => {
      if (value == '01') {
        state.schema3.dest_target.options = options.dest_target
      } else {
        state.schema3.dest_target.options = options.dest_target.slice(1)
      }
    }
  )

  watch(
    () => state.formJson3.dest_exchange,
    async (value: string, oldValue: string) => {
      if (value == '99') {
        state.schema3.dest_exchange_other.show = true
      } else {
        state.schema3.dest_exchange_other.show = false
      }

      if (state.schema3.dest_exchange_other.show) {
        state.schema3.dest_exchange_other.validations = yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_exchange_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_exchange_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_exchange_other.maxLength
            })
          )
      } else {
        state.schema3.dest_exchange_other.validations = undefined
        state.formJson3.dest_exchange_other = ''
      }
    }
  )

  watch(
    () => state.formJson3.dest_target,
    async (value: string, oldValue: string) => {
      if (value == '01') {
        state.schema3.dest_exchange.show = false
        state.schema3.dest_other.show = false
        state.schema3.dest_target.additionalNotesShow = true
      } else if (value == '02') {
        state.schema3.dest_exchange.show = true
        state.schema3.dest_other.show = false
        state.schema3.dest_target.additionalNotesShow = false
      } else if (value == '99') {
        state.schema3.dest_exchange.show = false
        state.schema3.dest_other.show = true
        state.schema3.dest_target.additionalNotesShow = false
      }

      if (state.schema3.dest_exchange.show) {
        state.schema3.dest_exchange.validations = yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_exchange'),
            type: t('validation.type.select')
          })
        )
      } else {
        state.schema3.dest_exchange.validations = undefined
        state.formJson3.dest_exchange = ''
      }

      if (state.schema3.dest_other.show) {
        state.schema3.dest_other.validations = yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_other.maxLength
            })
          )
      } else {
        state.schema3.dest_other.validations = undefined
        state.formJson3.dest_other = ''
      }
    }
  )

  watch(
    () => state.formJson3.dest_country,
    async (value: string, oldValue: string) => {
      if (value == 'JP') {
        state.schema3.dest_addr_jp.show = true
        state.schema3.dest_addr_other.show = false
      } else {
        state.schema3.dest_addr_jp.show = false
        state.schema3.dest_addr_other.show = true
      }

      if (state.schema3.dest_addr_jp.show) {
        state.schema3.dest_addr_jp.validations = yup.string().required(
          t('validation.required', {
            content: t('pages.WithdrawalCrypto.form.dest_addr_jp'),
            type: t('validation.type.select')
          })
        )
      } else {
        state.schema3.dest_addr_jp.validations = undefined
        state.formJson3.dest_addr_jp = ''
      }

      if (state.schema3.dest_addr_other.show) {
        state.schema3.dest_addr_other.validations = yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_addr_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_addr_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_addr_other.maxLength
            })
          )
      } else {
        state.schema3.dest_addr_other.validations = undefined
        state.formJson3.dest_addr_other = ''
      }
    }
  )

  watch(
    () => state.formJson3.dest_trob,
    async (value: string, oldValue: string) => {
      if (value == '99') {
        state.schema3.dest_trob_other.show = true
      } else {
        state.schema3.dest_trob_other.show = false
      }

      if (state.schema3.dest_trob_other.show) {
        state.schema3.dest_trob_other.validations = yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.WithdrawalCrypto.form.dest_trob_other'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.dest_trob_other.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.dest_trob_other.maxLength
            })
          )
      } else {
        state.schema3.dest_trob_other.validations = undefined
        state.formJson3.dest_trob_other = ''
      }
    }
  )
</script>
<style lang="scss" scoped>
  .tbl_menu {
    margin-bottom: unset;
  }
  .individual-form {
    padding-top: unset;
  }
</style>
