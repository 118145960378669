import { defineStore } from 'pinia'
import {
  SiteMapType,
  LOCALE_KEY,
  i18nType,
  CoinInfoType,
  UserInfoType,
  SHARE_HOLDERS_KEY,
  DEFAULT_SHARE_HOLDERS,
  DEFAULT_LOCALE
} from '@/@types'
import { getCoinRate, checkLogin, getHome, changeLanguage } from '@/api/index'
import { make_token } from '@/utils/helper'

export const useMainStore = defineStore('main', {
  state: () => ({
    breadcrumb: [] as SiteMapType[],
    language: (window.localStorage.getItem(LOCALE_KEY) ||
      DEFAULT_LOCALE) as i18nType,
    coinRate: {} as {
      btc?: CoinInfoType
      eth?: CoinInfoType
      hcn?: CoinInfoType
      xrp?: CoinInfoType
      bch?: CoinInfoType
      etc?: CoinInfoType
      xlm?: CoinInfoType
    },
    isLogin: null as null | boolean,
    column: {} as {
      career?: string
      column_article?: string
      glossary?: string
    },
    siteMap: [] as SiteMapType[],
    userInfo: {} as UserInfoType,
    tokenDeploy:
      import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG &&
      JSON.parse(import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG),
    isRecaptchaRequired:
      import.meta.env.VITE_APP_RECAPTCHA_FLG === 'true' &&
      !window.location.href.includes('localhost') &&
      !window.location.href.includes('127.0.0.1'),
    ws: {
      crypto: null as null | WebSocket,
      bid: null as null | number[],
      ask: null as null | number[],
      socket: null as null | WebSocket
    },
    corporation: {
      share_holders:
        window.localStorage.getItem(SHARE_HOLDERS_KEY) || DEFAULT_SHARE_HOLDERS
    },
    s3Url: import.meta.env.VITE_APP_S3_URL,
    env: import.meta.env.VITE_APP_SETTINGS_NAME,
    sessionOut: false,
    home: undefined as any,
    message: {} as {
      type?: string
      style?: string
      position?: string
      text?: string
    },
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN
  }),

  actions: {
    setBreadcrumb(payload: SiteMapType) {
      if (this.breadcrumb.includes(payload)) return
      this.breadcrumb.push(payload)
    },
    resetBreadcrumb() {
      this.breadcrumb = []
    },
    setIsLogin(payload: boolean | string) {
      this.isLogin = typeof payload === 'boolean' ? payload : payload === '1'
    },
    setUserInfo(payload: UserInfoType) {
      this.userInfo = payload
    },
    setLocale(payload: i18nType) {
      this.language = payload
      window.localStorage.setItem(LOCALE_KEY, payload)
    },
    changeLocale(payload: i18nType) {
      this.language = payload
      window.localStorage.setItem(LOCALE_KEY, payload)
      window.location.reload()
    },
    setCoinRate(payload: {
      btc?: CoinInfoType
      eth?: CoinInfoType
      hcn?: CoinInfoType
      xrp?: CoinInfoType
      bch?: CoinInfoType
      etc?: CoinInfoType
      xlm?: CoinInfoType
    }) {
      this.coinRate = {
        btc: payload.btc ? payload.btc : this.coinRate.btc,
        eth: payload.eth ? payload.eth : this.coinRate.eth,
        hcn: payload.hcn ? payload.hcn : this.coinRate.hcn,
        xrp: payload.xrp ? payload.xrp : this.coinRate.xrp,
        bch: payload.bch ? payload.bch : this.coinRate.bch,
        etc: payload.etc ? payload.etc : this.coinRate.etc,
        xlm: payload.xlm ? payload.xlm : this.coinRate.xlm
      }
    },
    setColumnSetting(payload: {
      career?: string
      column_article?: string
      glossary?: string
    }) {
      this.column = payload
    },
    setSiteMap(payload: SiteMapType[]) {
      this.siteMap = payload
    },
    setShareHolders(payload: string) {
      this.corporation.share_holders = payload
      window.localStorage.setItem(SHARE_HOLDERS_KEY, payload)
    },
    removeShareHolders() {
      this.corporation.share_holders = DEFAULT_SHARE_HOLDERS
      window.localStorage.removeItem(SHARE_HOLDERS_KEY)
    },
    checkSession(payload: boolean) {
      this.sessionOut = payload
    },
    setHome(payload: any) {
      this.home = payload
    },
    openMessage(payload: {
      type?: string
      style?: string
      position?: string
      text?: string
    }) {
      this.message = payload
    },
    clearMessage() {
      this.message = {}
    },
    openCryptoWs() {
      const connectWebsocket = () => {
        const token = make_token(128)
        const wsUrl = `${
          import.meta.env.VITE_APP_WS_RATE_URL
        }?id=${token}&server=btc&interval=1`

        const socket = new WebSocket(wsUrl)
        this.ws.socket = socket

        socket.onerror = () => {
          socket.close()
        }

        socket.onclose = () => {
          if (socket.readyState === 3) {
            connectWebsocket()
          }
        }

        socket.onmessage = (msg: MessageEvent) => {
          const data = msg.data && JSON.parse(msg.data)
          if (data.bid || data.ask) {
            this.ws.bid = data.bid
            this.ws.ask = data.ask
          } else {
            this.ws.crypto = data
          }
        }
      }

      connectWebsocket()
    },

    // Async actions
    async getCoinRate(payload?: string) {
      const { contents } = await getCoinRate(payload || 'btc')
      const { btc, eth, hcn, xrp, bch, etc, xlm } = contents
      this.setCoinRate({
        btc,
        eth,
        hcn,
        xrp,
        bch,
        etc,
        xlm
      })
    },
    async checkLogin() {
      try {
        const { contents } = await checkLogin()
        this.isLogin = contents.login === '1' || false
        this.userInfo = contents
      } catch {
        this.isLogin = false
        this.userInfo = {}
      }
    },
    async getHome() {
      const { contents } = await getHome()
      this.home = contents
      return contents
    },
    async changeLanguage(payload: i18nType) {
      try {
        if (this.userInfo.login === '1') {
          await changeLanguage(payload)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.setLocale(payload)
      }
    },
    setSiteMapByCampaign() {
      const sitemap = this.siteMap
      const service = sitemap.find(
        (i: { route: string }) => i.route === 'Service'
      )
      const campaign = service?.children?.find(
        (i: { route: string }) => i.route === 'Campaign'
      )
      if (campaign) {
        campaign.en_hide = false
        campaign.cn_hide = false
        campaign.ja_hide = false
      }
    }
  }
})
