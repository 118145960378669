<template>
  <!-- footer start -->
  <footer id="footer">
    <div class="footer_w_wrap" v-if="props.meta?.footer !== false">
      <div class="boxm">
        <h6>{{ $t('menu.policy.please_read') }}</h6>
        <div class="policy_set">
          <div
            class="text-frame"
            v-for="(item, index) in state.pleaseRead"
            :key="index"
          >
            <ul class="lower01">
              <li class="item" v-for="route in item" :key="route.name">
                <router-link v-if="!route.pdf" :to="{ name: route.route }">{{
                  route.name
                }}</router-link>
                <a
                  v-else
                  :href="
                    route.pdf + '_' + language + '.pdf?v=' + state.pdf_version
                  "
                  target="_blank"
                  >{{ route.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>

        <h6>{{ $t('menu.policy.member_association') }}</h6>
        <div class="association_set">
          <a href="https://jvcea.or.jp/" target="_blank">
            <img
              :src="`${s3Url}img/bnr_jvcea_w180_1.ja.png?v=1`"
              class="jvcea_img"
              alt="jvcea_img"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="footer_c_wrap">
      <div class="footer_cs">
        <div class="f_corp">
          <div class="f_logo">
            <img src="@static/img/coinestate_logo.png" alt="HEX" />
          </div>
          <div class="f_logo_text">
            {{ $t('company') }}<br />
            <span>{{ $t('license') }}</span>
            <span>{{ $t('license2') }}</span>
          </div>
          <div class="f_isms">
            <img src="@static/img/bnr_isms.png" alt="bnr_isms" />
          </div>
        </div>
        <div class="f_c_ulw" v-if="props.meta?.footer !== false">
          <ul
            class="footer_menu"
            v-for="(item, index) in state.siteMap"
            :key="index"
          >
            <site-map :list="item"></site-map>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright" :class="{ center: props.meta?.footer === false }">
      Copyright©
      {{ new Date().getFullYear() }}
      Coin Estate Co., Ltd. All rights reserved.
    </div>
  </footer>
  <!-- footer end -->
</template>

<script lang="ts" setup>
  import { reactive } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { siteMap } from '@/enum/siteMap'
  import { pleaseRead } from '@/enum/pleaseRead'
  import SiteMap from '@/components/SiteMap.vue'
  import { SiteMapType, PleaseReadType } from '@/@types'

  const props = defineProps({
    meta: Object
  })

  const store = useMainStore()
  const { language, s3Url } = storeToRefs(store)

  const state: {
    siteMap: SiteMapType[][]
    pleaseRead: PleaseReadType[][]
    pdf_version: string
  } = reactive({
    siteMap: [
      siteMap.slice(0, 3),
      siteMap.slice(3, 5),
      siteMap.slice(-5, siteMap.length)
    ],
    pleaseRead: [
      pleaseRead.slice(0, Math.ceil(pleaseRead.length / 2)),
      pleaseRead.slice(-Math.floor(pleaseRead.length / 2), pleaseRead.length)
    ],
    pdf_version: import.meta.env.VITE_APP_PDF_VERSION as string
  })
</script>

<style lang="scss" scoped>
  .association_set {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      margin: 5px;
      display: block;
    }
    img {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    .association_set {
      display: block;
    }
  }
  .copyright.center {
    text-align: center;
  }
</style>
