<template>
  <the-message v-if="state.errorMessage" class="modal__error">{{
    state.errorMessage
  }}</the-message>

  <div v-if="!state.init">
    <the-loading style="margin-top: 100px; margin-bottom: 100px"></the-loading>
  </div>

  <div id="new_account_modal" :class="language" v-else>
    <!-- UM00-01-01-1 START -->
    <div class="bh4 fw_b fc_y mt_20">{{ $t('pages.Important.title') }}</div>
    <div class="mw_15 lh_125">{{ $t('pages.Important.msg1') }}</div>
    <ul class="">
      <li>{{ $t('pages.Important.item1') }}</li>
      <li>{{ $t('pages.Important.item2') }}</li>
      <li>{{ $t('pages.Important.item3') }}</li>
      <li>{{ $t('pages.Important.item4') }}</li>
      <li>{{ $t('pages.Important.item5') }}</li>
      <li>{{ $t('pages.Important.item6') }}</li>
      <li>{{ $t('pages.Important.item7') }}</li>
      <li>{{ $t('pages.Important.item8') }}</li>
    </ul>
    <!-- UM00-01-01-1 END -->

    <div class="new_account_modal_button mw_20 ta_c">
      <button
        type="button"
        class="uk-button uk-button-primary"
        id="non_modal"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Important.submit')
        }"
        >{{ $t('pages.Important.submit') }}</button
      >
      <a :href="`${state.domain}/logout/`" class="uk-button ls-05rem">{{
        $t('pages.Important.logout')
      }}</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { getImportant, setImportant } from '@/api'
  import { useRouter } from 'vue-router'
  import { hasCallbackUrl } from '@/utils/tool'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)

  const state = reactive({
    errorMessage: '',
    init: false,
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN
  })

  const loading = ref(false)
  const router = useRouter()

  const submit = async () => {
    console.log('submit')
    loading.value = true
    try {
      await setImportant()
      router.push({ name: 'Home' })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    } finally {
      loading.value = false
    }
  }

  onMounted(async () => {
    try {
      const { contents } = await getImportant()
      if (!contents?.redirect) return
      hasCallbackUrl(contents?.redirect)
    } catch (error: any) {
      state.errorMessage =
        error.message !== 'important_subjects_not_check'
          ? error.message_detail
          : ''
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>

<style lang="scss" scoped>
  #new_account_modal,
  .modal__error {
    width: 90%;
    max-width: 800px;
    &.cn {
      max-width: 600px;
    }
  }
  #new_account_modal {
    margin: 0 auto;
  }
  .modal__error {
    margin: 10px auto;
  }
  .new_account_modal_button a {
    text-decoration: underline;
  }
</style>
