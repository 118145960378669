import { SiteMapType } from '@/@types'
import i18n from '@/locale'

const coinFlag =
  import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG &&
  JSON.parse(import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG)

export const siteMap: SiteMapType[] = [
  {
    route: 'Landing',
    name: i18n.global.t('menu.home')
  },
  {
    route: 'Beginner',
    name: i18n.global.t('menu.beginner.beginner'),
    children: [
      {
        route: 'AccountOpening',
        name: i18n.global.t('menu.beginner.account_opening')
      },
      {
        route: 'Transaction',
        name: i18n.global.t('menu.beginner.transaction')
      },
      {
        route: 'RequiredDocuments',
        name: i18n.global.t('menu.beginner.required_documents')
      },
      {
        route: 'AboutTwoStep',
        name: i18n.global.t('menu.beginner.about_two_step')
      },
      {
        route: '',
        link: `${import.meta.env.VITE_APP_S3_URL}resource/flow_of_opening_a_corporate_account.pdf?v=4`,
        name: i18n.global.t(
          'menu.beginner.flow_of_opening_a_corporate_account'
        ),
        en_hide: true,
        cn_hide: true
      }
    ]
  },
  {
    route: 'Service',
    name: i18n.global.t('menu.service.service'),
    children: [
      {
        route: 'Tool',
        name: i18n.global.t('menu.service.tool')
      },
      {
        route: 'Fee',
        name: i18n.global.t('menu.service.fee')
      },
      {
        route: 'Corporation',
        name: i18n.global.t('menu.service.corporation')
      },
      {
        route: 'ApplicationCryptoAssets',
        name: i18n.global.t('menu.service.application_crypto_assets')
      },
      {
        route: 'PointService',
        name: i18n.global.t('menu.service.point'),
        en_hide: true,
        cn_hide: true
      },
      {
        route: 'Campaign',
        name: i18n?.global.t('menu.service.campaign'),
        en_hide: true,
        cn_hide: true,
        ja_hide: true
      },
      {
        route: 'Estate',
        name: i18n.global.t('menu.service.estate'),
        en_hide: true,
        cn_hide: true,
        link: `https://realestate.coinestate.co.jp`
      },      
    ]
  },
  {
    route: 'CoinInfo',
    name: i18n.global.t('menu.coinInfo.coinInfo'),
    children: [
      {
        route: 'CoinDetail',
        params: { coin: 'btc' },
        name: i18n.global.t('coins.btc'),
        en_hide: coinFlag.BTC !== 'true',
        cn_hide: coinFlag.BTC !== 'true',
        ja_hide: coinFlag.BTC !== 'true'
      },
      {
        route: 'CoinDetail',
        params: { coin: 'eth' },
        name: i18n.global.t('coins.eth'),
        en_hide: coinFlag.ETH !== 'true',
        cn_hide: coinFlag.ETH !== 'true',
        ja_hide: coinFlag.ETH !== 'true'
      },
      {
        route: 'CoinDetail',
        params: { coin: 'hcn' },
        name: i18n.global.t('coins.hcn'),
        en_hide: coinFlag.HCN !== 'true',
        cn_hide: coinFlag.HCN !== 'true',
        ja_hide: coinFlag.HCN !== 'true'
      },
      {
        route: 'CoinDetail',
        params: { coin: 'xrp' },
        name: i18n.global.t('coins.xrp'),
        en_hide: coinFlag.XRP !== 'true',
        cn_hide: coinFlag.XRP !== 'true',
        ja_hide: coinFlag.XRP !== 'true'
      },
      {
        route: 'CoinDetail',
        params: { coin: 'bch' },
        name: i18n.global.t('coins.bch'),
        en_hide: coinFlag.BCH !== 'true',
        cn_hide: coinFlag.BCH !== 'true',
        ja_hide: coinFlag.BCH !== 'true'
      },
      {
        route: 'CoinDetail',
        params: { coin: 'etc' },
        name: i18n.global.t('coins.etc'),
        en_hide: coinFlag.ETC !== 'true',
        cn_hide: coinFlag.ETC !== 'true',
        ja_hide: coinFlag.ETC !== 'true'
      },
      {
        route: 'CoinDetail',
        params: { coin: 'xlm' },
        name: i18n.global.t('coins.xlm'),
        en_hide: coinFlag.XLM !== 'true',
        cn_hide: coinFlag.XLM !== 'true',
        ja_hide: coinFlag.XLM !== 'true'
      }
    ]
  },
  {
    route: 'Company',
    name: i18n.global.t('menu.company.company'),
    children: [
      {
        route: 'Profile',
        name: i18n.global.t('menu.company.profile')
      },
      {
        route: 'Management',
        name: i18n.global.t('menu.company.management')
      },
      {
        route: 'Legal',
        name: i18n.global.t('menu.company.legal')
      },
      {
        route: 'Career',
        name: i18n.global.t('menu.common.career'),
        ja_hide: null,
        en_hide: true,
        cn_hide: true
      }
    ]
  },
  {
    route: 'MailSetting',
    name: i18n.global.t('menu.mailSetting'),
    ja_hide: null,
    en_hide: true,
    cn_hide: true,
    children: [
      {
        route: 'MailSetting',
        name: i18n.global.t('menu.mailSetting'),
        ja_hide: null,
        en_hide: true,
        cn_hide: true,
        link: `${import.meta.env.VITE_APP_DJANGO_DOMAIN}/mail_delivery/setting/`
      }
    ]
  },
  {
    route: 'Column',
    name: i18n.global.t('menu.column.column'),
    ja_hide: null,
    en_hide: true,
    cn_hide: true,
    children: [
      {
        route: 'Glossary',
        name: i18n.global.t('menu.column.glossary')
      },
      {
        route: 'Beginners',
        name: i18n.global.t('menu.column.beginners')
      },
      {
        route: 'Advanced',
        name: i18n.global.t('menu.column.advanced')
      },
      {
        route: 'TrendCommentary',
        name: i18n.global.t('menu.column.trend_commentary')
      }
    ]
  },
  {
    route: 'NotificationPublic',
    name: i18n.global.t('menu.support.notification_public'),
    en_hide: true,
    cn_hide: true
  },
  {
    route: 'Support',
    name: i18n.global.t('menu.support.support'),
    children: [
      {
        route: 'Inquiry',
        name: i18n.global.t('menu.support.inquiry')
      },
      {
        route: 'FAQ',
        name: i18n.global.t('menu.support.faq'),
        en_hide: true,
        cn_hide: true
      },
      {
        route: 'SiteMap',
        name: i18n.global.t('menu.support.sitemap')
      },
      {
        route: 'PrivacyPolicy',
        name: i18n.global.t('menu.support.privacy_policy')
      }
    ]
  },
  {
    route: 'ForeignOfficial',
    name: i18n.global.t('menu.support.foreign_official'),
    ja_hide: true,
    en_hide: true,
    cn_hide: true
  },
  {
    route: 'Police',
    name: i18n.global.t('menu.policy.please_read'),
    isText: true,
    ja_hide: true,
    en_hide: true,
    cn_hide: true,
    children: [
      {
        route: 'ExplanatoryDocument',
        name: i18n?.global.t('menu.policy.explanatory_document')
      },
      {
        route: 'AntiSocial',
        name: i18n?.global.t('menu.policy.antisocial')
      },
      {
        route: 'AntiSocialElimination',
        name: i18n?.global.t('menu.policy.antisocial_elimination')
      },
      {
        route: 'Ethics',
        name: i18n?.global.t('menu.policy.ethics')
      },
      {
        route: 'DebtFulfillment',
        name: i18n?.global.t('menu.policy.debt_fulfillment')
      },
      {
        route: 'ConflictInterest',
        name: i18n?.global.t('menu.policy.conflict_interest')
      },
      {
        route: 'SecurityPolicy',
        name: i18n?.global.t('menu.policy.securitypolicy')
      },
      {
        route: 'ResponsePolicy',
        name: i18n?.global.t('menu.policy.response_policy')
      },
      {
        route: 'Claim',
        name: i18n?.global.t('menu.policy.claim')
      },
      {
        route: 'Regulation',
        name: i18n?.global.t('menu.policy.regulation_basic_contract')
      },
      {
        route: 'Contract',
        name: i18n?.global.t(
          'menu.policy.contract_pre_conclusion_delivery_documents'
        )
      },
      {
        route: 'BPTermsOfService',
        name: i18n?.global.t('menu.policy.bp_terms_of_service')
      },
      {
        route: 'InformationSecurityBasicPolicy',
        name: i18n?.global.t('menu.policy.information_security_basic_policy')
      },
      {
        route: 'BasicPolicyOnSystemRiskManagement',
        name: i18n?.global.t('menu.policy.basic_policy_on_system_risk_management')
      },
      {
        route: 'JudgingApplicationCryptoAssets',
        name: i18n?.global.t('menu.policy.judging_application_crypto_assets')
      }
    ]
  }
]
