<template>
  <!-- article start -->
  <article id="toppage">
    <div class="topset3">
      <div class="ablock">
        <div class="bh1" v-html="$t('pages.Service.strength.title')"></div>
        <div class="bh2 fw_m" v-html="$t('pages.Landing.area2.title')"></div>
        <div class="flex_w">
          <div class="w3s fdblock flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Landing.area2.part1.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Landing.area2.part1.detail')
            }}</div>
          </div>
          <div class="w3s fdblock flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Landing.area2.part2.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Landing.area2.part2.detail')
            }}</div>
          </div>
          <div class="w3s fdblock flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Landing.area2.part3.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Landing.area2.part3.detail')
            }}</div>
          </div>
        </div>
      </div>

      <div class="ablock">
        <div class="bh2 fw_m" v-html="$t('pages.Landing.area3.title')"></div>
        <div class="flex_w">
          <div class="w2s fdblock flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Landing.area3.part1.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Landing.area3.part1.detail')
            }}</div>
          </div>
          <div class="w2s fdblock flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Landing.area3.part2.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Landing.area3.part2.detail')
            }}</div>
          </div>
        </div>
      </div>

      <div class="ablock">
        <div class="bh2 fw_m" v-html="$t('pages.Landing.area5.title')"></div>
        <div class="flex_w">
          <div class="w2s fdblock flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Landing.area5.part1.title')
            }}</div>
            <div class="btxt ta_c">
              {{ $t('pages.Landing.area5.part1.detail') }}
            </div>
          </div>
          <div class="w2s fdblock flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Landing.area5.part2.title')
            }}</div>
            <div class="btxt ta_c">
              {{ $t('pages.Landing.area5.part2.detail') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fdblockw">
      <div class="flex_c">
        <div class="wblock">
          <div class="flex_w">
            <div class="w5-1s flex_c fc_y fs_20 fw_b">
              {{ $t('pages.Landing.tradingHours.title') }}
            </div>
            <div class="w5-2s flex_c">
              <span class="fw_b">{{
                $t('pages.Landing.tradingHours.msg1')
              }}</span
              ><br />
              <span class="fs_14">{{
                $t('pages.Landing.tradingHours.msg2')
              }}</span>
            </div>
            <div class="w5-3s flex_c is_sp">
              {{
                $t('pages.Landing.tradingHours.msg3', {
                  msg: $t('pages.Landing.tradingHours.fromTop')
                })
              }}
            </div>
            <div class="w5-3s flex_c is_pc">
              {{
                $t('pages.Landing.tradingHours.msg3', {
                  msg: $t('pages.Landing.tradingHours.fromLeft')
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { reactive } from 'vue'
  const state = reactive({
    tradeToolFlag: import.meta.env.VITE_APP_NEW_TRADE_TOOL_FLG
  })
</script>

<style lang="scss" scoped>
  .boxs {
    max-width: 950px;
    .ta1in_1 {
      padding-left: 30px;
      @media screen and (max-width: 800px) {
        & {
          padding-left: 0;
        }
      }
    }
  }
</style>
