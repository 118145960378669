<template>
  <template
    v-if="
      exceptArr.includes($router.currentRoute.value.name?.toString() || '') &&
      store.home?.callback_url
    "
    ><slot></slot
  ></template>
  <template v-else>
    <the-loading
      v-if="
        !store.home ||
        store.home?.callback_url ||
        store.home?.account_status === '000' ||
        ($router.currentRoute.value.fullPath.includes('inquiry') &&
          store.isLogin === null)
      "
      style="margin-top: 100px; margin-bottom: 100px"
    ></the-loading>
    <div
      id="user_wrap"
      :class="[router.currentRoute.value.name == 'Trade' ? 'trade_wrap' : '']"
      v-else
    >
      <side-nav></side-nav>
      <div
        class="boxs"
        :class="[
          router.currentRoute.value.name == 'Trade' ? 'trade_box' : 'mb_40'
        ]"
      >
        <the-message
          v-if="store.message?.text && store.message?.position == 'user'"
          :type="store.message?.type"
          :style="store.message?.style"
          class="user"
          >{{ store.message?.text }}</the-message
        >
        <slot></slot>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
  import { onMounted, watch } from 'vue'
  import { useMainStore } from '@/store/pinia'
  import SideNav from '@/components/SideNav.vue'
  import { hasCallbackUrl, loopUserInformation } from '@/utils/tool'
  import { useRouter } from 'vue-router'

  const store = useMainStore()
  const router = useRouter()
  const exceptArr = ['Inquiry', 'InquirySuccess']

  const initial = async () => {
    const contents = await store.getHome()
    if (contents?.account_status === '000') {
      router.push({
        name:
          contents.user_type === '1'
            ? 'IndividualStep1'
            : 'CorporationStep1Input'
      })
      return
    }

    if (
      !contents?.callback_url ||
      exceptArr.includes(router.currentRoute.value.name?.toString() || '')
    )
      return
    hasCallbackUrl(contents?.callback_url)
  }

  onMounted(async () => {
    loopUserInformation()
    if (
      (store.home && !router.currentRoute.value.meta.home) ||
      (router.currentRoute.value.meta.user === false && !store.isLogin)
    )
      return
    initial()
  })

  watch(
    () => store.isLogin,
    async (flg: boolean) => {
      if (router.currentRoute.value.meta.user === false && flg) {
        initial()
      }
    }
  )
</script>

<style lang="scss" scoped>
  .trade_wrap {
    max-width: unset !important;
  }
  .trade_box {
    max-width: unset !important;
  }
</style>
