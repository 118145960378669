<template>
  <div v-html="html" class="fields-others"></div>
</template>

<script lang="ts">
  export default {
    props: {
      html: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss">
  .fields-others {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    h2 {
      color: #000;
      margin: 20px 0;
      font-size: 120%;
    }
    p {
      flex: 0 0 100%;
    }
    a {
      text-decoration: underline;
      margin-left: 5px;
    }
  }
</style>
