<template>
  <div v-if="!state.init">
    <the-loading style="margin-top: 100px; margin-bottom: 100px"></the-loading>
  </div>
  <template v-else>
    <div class="tstep">
      <img
        :src="`${s3Url}img/pc_step3.${language}.png?v=1`"
        class="sp_no"
        alt="step"
      />
      <img
        :src="`${s3Url}img/sp_step3.${language}.png?v=1`"
        class="sp"
        alt="step"
      />
    </div>
    <the-message v-if="state.errorMessage" class="form-error">{{
      state.errorMessage
    }}</the-message>
    <div class="individual">
      <div class="individual-wrapper">
        <ol class="step mb30">
          <li><span style="padding-left: 1em">１</span></li>
          <li class="is-current br-tl-6 br-bl-6"
            >２{{ $t('pages.Individual.title.step2') }}</li
          >
          <li><span style="padding-left: 1em">３</span></li>
          <li class="br-tr-6 br-br-6">{{
            $t('pages.Individual.title.step4')
          }}</li>
        </ol>

        <form>
          <h1>{{ $t('pages.Individual.title.step2') }}</h1>

          <p v-html="$t('pages.Individual.step2.upload.msg1')"></p>

          <p
            class="text-center"
            style="font-size: 1.75rem; margin: 30px 0 15px 0; color: red"
          >
            {{ $t('pages.Individual.step2.upload.msg2') }}
          </p>

          <p class="text-center">{{
            $t('pages.Individual.step2.upload.msg3')
          }}</p>

          <ul class="form-normal">
            <SchemaFormWithValidation
              :schema="state.schema"
              :model="state.formJson"
              useCustomFormWrapper
              ref="form"
              class="individual-form"
            />
          </ul>

          <button
            class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
            :disabled="!state.canSubmit"
            :class="{
              'uk-button-primary': state.canSubmit,
              'uk-button-default': !state.canSubmit
            }"
            @click="confirm"
            type="button"
            >{{ $t('pages.Individual.step2.upload.submit') }}</button
          >

          <div class="upload-info">
            <ul>
              <li style="font-size: 18px">{{
                $t('pages.Individual.step2.upload.msg4')
              }}</li>
              <li>&nbsp;</li>
              <li v-html="$t('pages.Individual.step2.upload.msg5')"></li>
              <li v-html="$t('pages.Individual.step2.upload.msg6')"></li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>

            <h5>{{ $t('pages.Individual.step2.upload.groupA.title') }}</h5>
            <ul>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg1')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg2')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg3')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg4')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg5')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg6')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg7')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg8')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg9')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg10')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg11')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg12')
              }}</li>

              <li class="indent-1">&nbsp;</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupA.msg13')
              }}</li>
              <li class="indent-1">&nbsp;</li>
            </ul>

            <h5>{{ $t('pages.Individual.step2.upload.groupB.title') }}</h5>
            <ul>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupB.msg1')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupB.msg2')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.groupB.msg3')
              }}</li>

              <li class="indent-1">&nbsp;</li>
            </ul>

            <h5>{{ $t('pages.Individual.step2.upload.notice.title') }}</h5>
            <ul>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg1')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg1_1')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg1_2')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg1_3')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg1_4')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg1_5')
              }}</li>
              <li class="indent-1">&nbsp;</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg2')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg2_1')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg2_2')
              }}</li>
              <li class="indent-1">{{
                $t('pages.Individual.step2.upload.notice.msg2_3')
              }}</li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  </template>
  <the-modal
    v-if="state.modal"
    id="modal-inquiry"
    hasClose
    @close="state.modal = false"
  >
    <section class="modal-inner">
      <p style="margin: 30px 0 50px 0">{{
        $t('pages.Individual.step2.upload.modal.msg')
      }}</p>
      <p class="uk-text-center">
        <button
          class="uk-button uk-button-primary uk-align-center"
          v-debounce="{
            func: submit
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Individual.step2.upload.modal.submit')
          }"
          style="margin-bottom: 10px"
          type="button"
          >{{ $t('pages.Individual.step2.upload.modal.submit') }}</button
        >
        <button
          class="uk-button uk-button-default uk-align-center"
          @click="state.modal = false"
          type="button"
          style="margin: 0 auto; padding-left: 30px; padding-right: 30px"
          >{{ $t('pages.Individual.step2.upload.modal.close') }}</button
        >
      </p>
    </section>
  </the-modal>
  <!-- ここから Crib Notesユニバーサルタグのコード -->
  <component :is="'script'" v-if="env === 'prod' && state.clib_counter_flg">
    (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/" + a
    + "/atm.js"; e.id = d; e.async = true;
    b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
    "script", "__cribnotesTagMgrCmd", "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
    "https://tag.cribnotes.jp/container_manager");
  </component>
  <!-- ここまで Crib Notesユニバーサルタグのコード -->
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { addFormStyle } from '@/utils/tool'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import {
    corporationFileUpload,
    setCorporationStatus,
    getFileUpload
  } from '@/api'
  import TheModal from '@/components/TheModal.vue'

  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { s3Url, language, env } = storeToRefs(store)

  const state: {
    clib_counter_flg: boolean
    init: boolean
    schema: any
    formJson: any
    errorMessage: string
    canSubmit: boolean
    modal: boolean
  } = reactive({
    clib_counter_flg: false,
    init: false,
    schema: {
      file1: {
        component: 'File',
        label: t('pages.Individual.form.file1'),
        required: true,
        requiredStyle: 'form',
        accept: '.pdf,image/*',
        validations: yup
          .mixed()
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.file1'),
              type: t('validation.type.upload')
            })
          )
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      },
      file2: {
        component: 'File',
        label: t('pages.Individual.form.file2'),
        accept: '.pdf,image/*',
        validations: yup
          .mixed()
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      },
      file3: {
        component: 'File',
        label: t('pages.Individual.form.file3'),
        required: true,
        requiredStyle: 'form',
        accept: '.pdf,image/*',
        validations: yup
          .mixed()
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.file3'),
              type: t('validation.type.upload')
            })
          )
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      },
      file4: {
        component: 'File',
        label: t('pages.Individual.form.file4'),
        accept: '.pdf,image/*',
        validations: yup
          .mixed()
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      }
    },
    formJson: {},
    errorMessage: '',
    canSubmit: false,
    modal: false
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  function checkIfFilesAreTooBig(files?: [File]): boolean {
    let valid = true
    if (files) {
      const size = files[0].size / 1024 / 1024
      if (size > 5) {
        valid = false
      }
    }
    return valid
  }

  const confirm = () => {
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await corporationFileUpload({
            contents: {
              ...state.formJson
            }
          })
          await setCorporationStatus('02')
          router.push({
            name: 'IndividualStep3'
          })
        } catch (error: any) {
          state.modal = false
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getFileUpload()
      state.clib_counter_flg = contents?.clib_counter_flg
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => state,
    async (value: any) => {
      state.canSubmit = value.formJson.file1 && value.formJson.file3
    },
    { deep: true }
  )
</script>

<style lang="scss" scoped>
  .upload-info {
    margin: 50px 0;
    line-height: 1.25rem;
  }

  .indent-1 {
    padding-left: 1rem !important;
    text-indent: -1rem !important;
  }
</style>
