<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.BalanceHistory.title') }}</h1>

        <ul class="pagination" role="menubar" aria-label="Pagination">
          <li
            v-for="page in state.pages"
            :key="page"
            :class="{ current: page === state.page }"
            @click="changePage(page)"
          >
            <a :class="{ inactive: page != state.page }">
              {{ page }}
            </a>
          </li>
        </ul>

        <table class="uk-table uk-table-divider balance-table">
          <tbody>
            <template
              v-for="(data, index) in state.history?.results"
              :key="index"
            >
              <tr>
                <th class="col_currency" rowspan="3">{{ index }}</th>
                <td class="col_title">{{
                  $t('pages.BalanceHistory.wallet')
                }}</td>
                <td class="col_balance">{{ data.wallet }}</td>
              </tr>
              <tr>
                <td class="col_title">{{
                  $t('pages.BalanceHistory.trade')
                }}</td>
                <td class="col_balance">{{ data.trade }}</td>
              </tr>
              <tr>
                <td class="col_title col_total">{{
                  $t('pages.BalanceHistory.total')
                }}</td>
                <td class="col_balance col_total">{{ data.total }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import { getBalanceHistory } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { options } from '@/enum/enums'

  const { t } = useI18n()
  const state: {
    history: any
    page: number
    pages: number
    loading: boolean
  } = reactive({
    history: {},
    page: 1,
    pages: 0,
    loading: true
  })

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getBalanceHistory(state.page.toString())
    state.history = contents
    state.pages = contents?.page_range
    state.page = contents?.current
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  onMounted(async () => {
    await showHistory()
  })
</script>
<style lang="scss" scoped>
  .col_currency {
    font-size: large;
    font-weight: bold;
    background: #000;
    border: 1px solid #fff;
    text-align: center;
    vertical-align: middle;
    width: 34%;
    color: #febd09;
  }
  .col_title {
    border: 1px solid #fff;
    text-align: center;
    width: 33%;
  }
  .col_balance {
    border: 1px solid #fff;
    text-align: right;
    width: 33%;
  }
  .col_total {
    border-top: double white;
    color: #febd09;
  }
  .pagination a {
    width: 100px;
  }
  .current {
    border-bottom: unset !important;
  }
  .balance-table {
    display: table;
    border: 1px solid #fff;
    background: #081828;
  }
</style>
