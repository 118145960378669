<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ router.currentRoute.value?.meta?.title }}</div>

      <div class="footer_wrap boxm">
        <ul
          class="footer_menu"
          v-for="(item, index) in state.siteMap"
          :key="index"
        >
          <site-map :list="item"></site-map>
        </ul>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive } from 'vue'
  import { useRouter } from 'vue-router'
  import { siteMap } from '@/enum/siteMap'
  import SiteMap from '@/components/SiteMap.vue'

  const router = useRouter()
  const state = reactive({
    siteMap: [
      siteMap.slice(0, 3),
      siteMap.slice(3, 5),
      siteMap.slice(-5, siteMap.length)
    ]
  })
</script>

<style lang="scss" scoped>
  .boxs {
    max-width: 1200px;
  }
</style>
