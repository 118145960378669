<template>
  <user v-if="isLogin !== false"><success></success></user>
  <success v-else></success>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import User from '@/components/User.vue'
  import Success from '@/components/inquiry/Success.vue'

  const store = useMainStore()
  const { isLogin } = storeToRefs(store)
</script>
