<template>
  <div>
    <li class="login-info">
      <span class="info-data">{{ store.userInfo.email }}</span>
      <span class="info-data">{{ store.userInfo.account_id }}</span>
    </li>
    <li>
      <div
        @click="state.show.menu1 = !state.show.menu1"
        v-if="store.home?.account_status === '500'"
      >
        <a>{{ $t('menu.user.account') }}</a
        ><span class="open_7"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade" v-if="store.home?.account_status === '500'">
        <ul class="ulopen_7" v-if="state.show.menu1">
          <li class="wal_list">
            <span class="box-left">{{ $t('pages.User.menu.crypto') }}</span>
            <span class="box-center">{{ $t('pages.User.menu.holding') }}</span>
            <span class="box-right">{{ $t('pages.User.menu.price') }}</span>
          </li>
          <li class="wal_list">
            <span class="box-left">{{ $t('pages.User.menu.total') }}</span>
            <span class="box-center"></span>
            <span class="box-right"
              >￥{{ store.home?.summary_objs?.wallet_total || 0 }}</span
            >
          </li>

          <li
            class="wal_list"
            v-if="
              parseFloat(store.home?.summary_objs?.wallet_balance?.qty_jpy) > 0
            "
          >
            <span class="box-left">JPY</span>
            <span class="box-center"
              >￥{{
                store.home?.summary_objs?.wallet_balance?.qty_jpy.toLocaleString(
                  undefined,
                  { maximumFractionDigits: [8] }
                ) || '0'
              }}</span
            >
            <span class="box-right"
              >￥{{
                store.home?.summary_objs?.wallet_balance?.qty_jpy.toLocaleString(
                  undefined,
                  { maximumFractionDigits: [8] }
                ) || '0'
              }}</span
            >
          </li>

          <template v-for="coin in state.coins" :key="coin">
            <li
              class="wal_list"
              v-if="
                parseFloat(
                  store.home?.summary_objs?.wallet_balance?.[
                    `qty_${coin.toLocaleLowerCase()}`
                  ]
                ) > 0
              "
            >
              <span class="box-left">{{ coin }}</span>
              <span class="box-center">{{
                store.home?.summary_objs?.wallet_balance?.[
                  `qty_${coin.toLocaleLowerCase()}`
                ].toLocaleString(undefined, {
                  maximumFractionDigits: [8]
                }) || '0'
              }}</span>
              <span class="box-right"
                >￥{{
                  store.home?.summary_objs?.wallet_jpy?.[
                    coin.toLocaleLowerCase()
                  ] || 0
                }}</span
              >
            </li>
          </template>

          <li
            class="wal_list"
            v-if="store.home?.summary_objs?.wallet_balance?.bitgate > 0"
          >
            <span class="box-left">{{ $t('coins.point') }}</span>
            <span class="box-center">{{
              store.home?.summary_objs?.wallet_balance?.bitgate?.toLocaleString(
                undefined,
                { maximumFractionDigits: [8] }
              ) || '0'
            }}</span>
            <span class="box-right">{{
              store.home?.summary_objs?.wallet_balance?.bitgate?.toLocaleString(
                undefined,
                { maximumFractionDigits: [8] }
              ) || '0'
            }}</span>
          </li>
        </ul>
      </transition>
    </li>
    <li
      ><router-link :to="{ name: 'Home' }">{{
        $t('menu.home')
      }}</router-link></li
    >
    <li>
      <router-link
        :to="{ name: 'Notification' }"
        :class="[
          ['Notification', 'NotificationPrivate'].includes(
            router.currentRoute.value.name
          )
            ? 'box-selected'
            : ''
        ]"
        ><span class="news"
          >{{ $t('menu.user.notification') }}
          <span class="notice" v-if="0 < store.userInfo.notification_unread">{{
            store.userInfo.notification_unread
          }}</span></span
        ></router-link
      >
    </li>
    <li>
      <div @click="state.show.menu2 = !state.show.menu2">
        <a>{{ $t('menu.user.trade.trade') }}</a
        ><span class="open_8"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_8" v-if="state.show.menu2">
          <li>
            <router-link
              :to="{ name: 'Trade', params: { crypto: 'btc' } }"
              :class="[
                router.currentRoute.value.name == 'Trade' ? 'box-selected' : ''
              ]"
              ><span>{{ $t('menu.user.trade.tool') }}</span></router-link
            ></li
          >
          <li
            ><a href="https://jvcea.or.jp/about/refer_rate/" target=".">{{
              $t('menu.user.trade.JVCEA')
            }}</a></li
          >
        </ul>
      </transition>
    </li>
    <li>
      <router-link
        :to="{ name: 'Transfer' }"
        :class="[
          router.currentRoute.value.name == 'Transfer' ? 'box-selected' : ''
        ]"
        ><span>{{ $t('menu.user.transfer') }}</span></router-link
      >
    </li>

    <li>
      <div @click="state.show.menu3 = !state.show.menu3">
        <a>{{ $t('menu.user.deposit.deposit') }}</a
        ><span class="open_10"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_10" v-if="state.show.menu3">
          <li
            ><router-link
              :to="{ name: 'DepositQuick' }"
              :class="[
                router.currentRoute.value.name == 'DepositQuick'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.deposit.quick') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'DepositBank' }"
              :class="[
                router.currentRoute.value.name == 'DepositBank'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.deposit.jpy') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'DepositConveni' }"
              :class="[
                router.currentRoute.value.name == 'DepositConveni'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.deposit.convenience') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'DepositCrypto', params: { crypto: 'btc' } }"
              :class="[
                router.currentRoute.value.name == 'DepositCrypto'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.deposit.crypto') }}</router-link
            ></li
          >
        </ul>
      </transition>
    </li>
    <li>
      <div @click="state.show.menu4 = !state.show.menu4">
        <a>{{ $t('menu.user.withdraw.withdraw') }}</a
        ><span class="open_11"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_11" v-if="state.show.menu4">
          <li
            ><router-link
              :to="{ name: 'WithdrawalBank' }"
              :class="[
                router.currentRoute.value.name == 'WithdrawalBank'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.withdraw.jpy') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'WithdrawalCrypto', params: { crypto: 'btc' } }"
              :class="[
                router.currentRoute.value.name == 'WithdrawalCrypto'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.withdraw.crypto') }}</router-link
            ></li
          >
        </ul>
      </transition>
    </li>
    <li>
      <div @click="state.show.menu5 = !state.show.menu5">
        <a>{{ $t('menu.user.point.point') }}</a
        ><span class="open_12"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_12" v-if="state.show.menu5">
          <li
            ><router-link
              :to="{ name: 'PointDepositBank' }"
              :class="[
                router.currentRoute.value.name == 'PointDepositBank'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.point.jpy') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'PointCrypto', params: { crypto: 'btc' } }"
              :class="[
                router.currentRoute.value.name == 'PointCrypto'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.point.crypto') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'PointBalance' }"
              :class="[
                router.currentRoute.value.name == 'PointBalance'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.point.balance') }}</router-link
            ></li
          >
        </ul>
      </transition>
    </li>

    <li>
      <div @click="state.show.menu6 = !state.show.menu6">
        <a>{{ $t('menu.user.history.history') }}</a
        ><span class="open_13"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_13" v-if="state.show.menu6">
          <li
            ><router-link
              :to="{ name: 'LoginHistory' }"
              :class="[
                router.currentRoute.value.name == 'LoginHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.login') }}</router-link
            ></li
          >
          <li class="parent__menu"
            ><a>{{ $t('menu.user.history.fund') }}</a></li
          >
          <li class="child__menu"
            ><router-link
              :to="{ name: 'WalletFiatHistory' }"
              :class="[
                router.currentRoute.value.name == 'WalletFiatHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.fundJpy') }}</router-link
            ></li
          >
          <li class="child__menu last"
            ><router-link
              :to="{ name: 'WalletCryptoHistory', params: { crypto: 'btc' } }"
              :class="[
                router.currentRoute.value.name == 'WalletCryptoHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.fundCrypto') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'TransferHistory' }"
              :class="[
                router.currentRoute.value.name == 'TransferHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.fundTransfer') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'TradeHistory', params: { crypto: 'btc' } }"
              :class="[
                router.currentRoute.value.name == 'TradeHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.fundTrade') }}</router-link
            ></li
          >
          <li class="parent__menu"
            ><a>{{ $t('menu.user.history.point') }}</a></li
          >
          <li class="child__menu"
            ><router-link
              :to="{ name: 'BuyPointHistory' }"
              :class="[
                router.currentRoute.value.name == 'BuyPointHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.pointUse') }}</router-link
            ></li
          >
          <li class="child__menu last"
            ><router-link
              :to="{ name: 'PointBuyCryptoHistory', params: { crypto: 'btc' } }"
              :class="[
                router.currentRoute.value.name == 'PointBuyCryptoHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.pointCrypto') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'BalanceHistory' }"
              :class="[
                router.currentRoute.value.name == 'BalanceHistory'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.history.balance') }}</router-link
            ></li
          >
        </ul>
      </transition>
    </li>

    <li
      ><router-link
        :to="{ name: 'Customer' }"
        :class="[
          router.currentRoute.value.name == 'Customer' ? 'box-selected' : ''
        ]"
        >{{ $t('menu.user.information') }}</router-link
      ></li
    >

    <li>
      <div @click="state.show.menu7 = !state.show.menu7">
        <a>{{ $t('menu.user.report.report') }}</a
        ><span class="open_14"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_14" v-if="state.show.menu7">
          <li
            ><router-link
              :to="{ name: 'ReportBalanceQuarter' }"
              :class="[
                router.currentRoute.value.name == 'ReportBalanceQuarter'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.report.report') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'ReportBalanceYear' }"
              :class="[
                router.currentRoute.value.name == 'ReportBalanceYear'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.report.reportAnnual') }}</router-link
            ></li
          >
        </ul>
      </transition>
    </li>

    <li>
      <div @click="state.show.menu8 = !state.show.menu8">
        <a>{{ $t('menu.user.setting.setting') }}</a
        ><span class="open_15"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_15" v-if="state.show.menu8">
          <li
            ><router-link
              :to="{ name: 'BankSetting' }"
              :class="[
                router.currentRoute.value.name == 'BankSetting'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.setting.bank') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'TwoStepStatus' }"
              :class="[
                router.currentRoute.value.name == 'TwoStepStatus'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.setting.twoStep') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'PasswordSetting' }"
              :class="[
                router.currentRoute.value.name == 'PasswordSetting'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.setting.password') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'NotificationSetting' }"
              :class="[
                router.currentRoute.value.name == 'NotificationSetting'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.setting.notification') }}</router-link
            ></li
          >
          <li
            ><router-link
              :to="{ name: 'MailSetting' }"
              :class="[
                router.currentRoute.value.name == 'MailSetting'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.setting.mail') }}</router-link
            ></li
          >
        </ul>
      </transition>
    </li>

    <li
      ><router-link
        :to="{ name: 'Invite' }"
        :class="[
          router.currentRoute.value.name == 'Invite' ? 'box-selected' : ''
        ]"
        >{{ $t('menu.user.invite') }}</router-link
      ></li
    >

    <li>
      <div @click="state.show.menu9 = !state.show.menu9">
        <a>{{ $t('menu.user.support.support') }}</a
        ><span class="open_16"><i class="fas fa-chevron-down"></i></span>
      </div>
      <transition name="slide-fade">
        <ul class="ulopen_16" v-if="state.show.menu9">
          <li v-if="store.language === 'ja'"
            ><router-link :to="{ name: 'FAQ' }">{{
              $t('menu.user.support.FAQ')
            }}</router-link></li
          >
          <li
            ><router-link
              :to="{ name: 'Inquiry' }"
              :class="[
                router.currentRoute.value.name == 'Inquiry'
                  ? 'box-selected'
                  : ''
              ]"
              >{{ $t('menu.user.support.inquiry') }}</router-link
            ></li
          >
        </ul>
      </transition>
    </li>
    <li v-if="props.logout">
      <a :href="`${state.domain}/logout/`">{{ $t('menu.user.logout') }}</a>
    </li>
  </div>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useMainStore } from '@/store/pinia'

  interface Props {
    logout?: boolean
  }

  const router = useRouter()
  const store = useMainStore()

  const props = withDefaults(defineProps<Props>(), {
    logout: false
  })

  const coinFlag =
    import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG &&
    JSON.parse(import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG)

  const state = reactive({
    show: {
      menu1: true,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false
    },
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN,
    coins: Object.keys(coinFlag).filter((i) => coinFlag[i] === 'true')
  })

  onMounted(async () => {
    Array(8)
      .fill(2)
      .map((_, i) => (state.show['menu' + (i + 2)] = false))
    const selected_menu = router.currentRoute.value.meta?.menu
    state.show[selected_menu] = true
  })
</script>

<style lang="scss" scoped>
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-5px);
    opacity: 0;
  }

  .parent__menu {
    border-bottom: none !important;
  }
  .child__menu {
    padding-left: 1rem !important;
    border-bottom: none !important;
    &.last {
      border-bottom: 1px solid #4c4e62 !important;
    }
  }
  .box-selected {
    color: #febd09 !important;
    opacity: 1 !important;
  }

  .news {
    position: relative;
  }

  .news span {
    position: absolute;
    top: -2px;
    left: calc(100% + 3px);
    color: #fff;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    background: #fe5709;
    border-radius: 10px;
    min-width: 20px;
    padding: 0 3px;
    box-sizing: border-box;
  }
</style>
