<template>
  <user v-if="isLogin !== false"><inquiry></inquiry></user>
  <inquiry v-else></inquiry>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import User from '@/components/User.vue'
  import Inquiry from '@/components/inquiry/Inquiry.vue'

  const store = useMainStore()
  const { isLogin } = storeToRefs(store)
</script>
