<template>
  <div class="tab-content clearfix">
    <div class="tab_content2 clearfix" id="side-2-tab1" style="display: block">
      <div class="order-switch clearfix order_type">
        <ul class=""
          ><li class="wal_list">
            <input
              id="order_type_market"
              type="radio"
              name="order"
              value="market"
              checked
              v-model="state.order_type"
            />

            <label for="order_type_market"
              ><span class="box-left">{{
                $t('pages.Trade.order_condition.market')
              }}</span></label
            >
            <input
              id="order_type_limit"
              type="radio"
              name="order"
              value="limit"
              v-model="state.order_type"
            />

            <label for="order_type_limit"
              ><span class="box-right">{{
                $t('pages.Trade.order_condition.limit')
              }}</span></label
            >

            <input
              id="order_type_oco"
              type="radio"
              name="order"
              value="oco"
              v-model="state.order_type"
            />

            <label for="order_type_oco"
              ><span class="box-right">{{
                $t('pages.Trade.order_condition.oco')
              }}</span></label
            ></li
          ></ul
        >
      </div>

      <div
        v-bind:class="[
          state.order_type == 'market' ? 'display_on' : 'display_off'
        ]"
      >
        <template v-if="0 < (props.btn_list || []).length">
          <order-market
            :type="(props.type as string)"
            :tradetoken="props.tradetoken"
            :btn_list="props.btn_list"
            :input_min="props.input_min"
            :setting="props.setting"
            :other="props.other"
            :business_time="props.business_time"
            :rate_ask="props.rate_ask"
            :rate_bid="props.rate_bid"
            @onchange="reflash"
          ></order-market>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>

      <div
        v-bind:class="[
          state.order_type == 'limit' ? 'display_on' : 'display_off'
        ]"
      >
        <template v-if="0 < (props.btn_list || []).length">
          <order-limit
            :type="(props.type as string)"
            :tradetoken="props.tradetoken"
            :btn_list="props.btn_list"
            :input_min="props.input_min"
            :rate_ask="props.rate_ask"
            :setting="props.setting"
            :other="props.other"
            @onchange="reflash"
          ></order-limit>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>

      <div
        v-bind:class="[
          state.order_type == 'oco' ? 'display_on' : 'display_off'
        ]"
      >
        <template v-if="0 < (props.btn_list || []).length">
          <order-oco
            :type="(props.type as string)"
            :tradetoken="props.tradetoken"
            :btn_list="props.btn_list"
            :input_min="props.input_min"
            :rate_ask="props.rate_ask"
            :setting="props.setting"
            :other="props.other"
            @onchange="reflash"
          ></order-oco>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { reactive } from 'vue'
  import OrderMarket from '@/components/trade/OrderMarket.vue'
  import OrderLimit from '@/components/trade/OrderLimit.vue'
  import OrderOco from '@/components/trade/OrderOco.vue'

  const props = defineProps({
    type: String,
    tradetoken: String,
    btn_list: Array,
    input_min: String,
    rate_ask: String,
    rate_bid: String,
    setting: Object,
    other: Object,
    business_time: Boolean
  })

  const emit = defineEmits<{
    (e: 'onchange'): void
  }>()

  const state: {
    order_type: string
  } = reactive({
    order_type: 'market'
  })

  const reflash = async () => {
    emit('onchange')
  }
</script>
<style lang="scss" scoped>
  @use '@/static/styles/trade-list.scss';
  @use '@/static/styles/trade-order.scss';

  .display_on {
    display: block;
  }
  .display_off {
    display: none;
  }
</style>
