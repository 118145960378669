<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="is-current">６{{ $t('pages.Corporation.title.step6') }}</li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Corporation.step6.title') }}</h1>
        <p
          style="text-align: center"
          v-html="$t('pages.Corporation.step6.msg1')"
        ></p>
        <ul class="form-normal" style="margin-top: -50px">
          <SchemaFormWithValidation
            :schema="state.schemaCorporation_1"
            :model="state.formJsonCorporation_1"
            useCustomFormWrapper
            ref="form1"
            class="corporation-form"
          />
        </ul>
        <ul class="form-normal" style="margin-top: -50px">
          <SchemaFormWithValidation
            :schema="state.schemaCorporation_2"
            :model="state.formJsonCorporation_2"
            useCustomFormWrapper
            ref="form2"
            class="corporation-form"
          />
        </ul>

        <p
          v-if="state.power_of_attorney_required"
          v-html="$t('pages.Corporation.step6.msg2')"
        ></p>
        <a
          :href="`${s3Url}resource/power_of_attorney.pdf`"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom poa-download"
          type="button"
          target="_blank"
          v-if="state.power_of_attorney_required"
          >{{ $t('pages.Corporation.step6.download') }}
        </a>

        <h1 style="margin-top: 150px">{{
          $t('pages.Corporation.step6.subTitle')
        }}</h1>
        <p
          style="text-align: center"
          v-html="$t('pages.Corporation.step6.msg3')"
        ></p>
        <ul class="form-normal" style="margin-top: -50px">
          <SchemaFormWithValidation
            :schema="state.schemaPerson"
            :model="state.formJsonPerson"
            useCustomFormWrapper
            ref="form3"
            class="corporation-form"
          />
        </ul>

        <button
          class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
          :disabled="!state.canSubmit"
          :class="{
            'uk-button-primary': state.canSubmit,
            'uk-button-default': !state.canSubmit
          }"
          v-debounce="{
            func: submit
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.form.next')
          }"
          type="button"
          >{{ $t('pages.Corporation.form.next') }}</button
        >
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { addFormStyle } from '@/utils/tool'
  import {
    corporationFileUpload,
    setCorporationStatus,
    getFileUpload,
    getCorporationBasic
  } from '@/api'
  import { validator } from '@/enum/enums'

  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { s3Url } = storeToRefs(store)

  const state: {
    init: boolean
    schemaCorporation_1: any
    formJsonCorporation_1: any
    schemaCorporation_2: any
    formJsonCorporation_2: any
    schemaPerson: any
    formJsonPerson: any
    errorMessage: string
    canSubmit: boolean
    power_of_attorney_required: boolean
  } = reactive({
    init: false,
    schemaCorporation_1: {
      file1: {
        component: 'File',
        label: t('pages.Corporation.form.file1'),
        required: true,
        requiredStyle: 'form',
        accept: '.pdf,image/*',
        validations: yup
          .mixed()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.file1'),
              type: t('validation.type.upload')
            })
          )
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      }
    },
    formJsonCorporation_1: {},
    schemaCorporation_2: {
      file2: {
        component: 'File',
        label: t('pages.Corporation.form.file2'),
        required: true,
        requiredStyle: 'form',
        accept: '.pdf,image/*',
        additionalNotesShow: true,
        additionalNotes: t('pages.Corporation.step6.addition1'),
        validations: yup
          .mixed()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.file2'),
              type: t('validation.type.upload')
            })
          )
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      },
      file3: {
        component: 'File',
        label: t('pages.Corporation.form.file3'),
        required: true,
        requiredStyle: 'form',
        accept: '.pdf,image/*',
        show: false,
        validations: yup
          .mixed()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.file3'),
              type: t('validation.type.upload')
            })
          )
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      }
    },
    formJsonCorporation_2: {},
    schemaPerson: {
      file4: {
        component: 'File',
        label: t('pages.Corporation.form.file4'),
        required: true,
        requiredStyle: 'form',
        accept: 'image/*',
        validations: yup
          .mixed()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.file4'),
              type: t('validation.type.upload')
            })
          )
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      },
      file5: {
        component: 'File',
        label: t('pages.Corporation.form.file5'),
        accept: 'image/*',
        labelAddition: t('pages.Corporation.step6.addition2'),
        validations: yup
          .mixed()
          .test(
            'is-big-file',
            t('validation.is_big_file'),
            checkIfFilesAreTooBig
          )
      }
    },
    formJsonPerson: {},
    errorMessage: '',
    canSubmit: false,
    power_of_attorney_required: false
  })

  const form1 = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const form2 = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const form3 = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const submit = async () => {
    console.log('submit')
    const valid1 = form1.value?.validate()
    const valid2 = form2.value?.validate()
    const valid3 = form3.value?.validate()
    setTimeout(async () => {
      if (
        valid1?.meta?.valid ||
        valid2?.meta?.valid ||
        valid3?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await corporationFileUpload({
            contents: {
              ...state.formJsonCorporation_1,
              ...state.formJsonCorporation_2,
              ...state.formJsonPerson
            }
          })
          try {
            await setCorporationStatus('06')
            router.push({
              name: 'CorporationStep7Input'
            })
          } catch (error: any) {
            state.errorMessage =
              error.error_translations || error.message_detail
            document.documentElement.scrollTop = 0
          }
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  function checkIfFilesAreTooBig(files?: [File]): boolean {
    let valid = true
    if (files) {
      const size = files[0].size / 1024 / 1024
      if (size > 5) {
        valid = false
      }
    }
    return valid
  }

  onMounted(async () => {
    try {
      const { contents } = await getFileUpload(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      if (!contents?.power_of_attorney_required) {
        state.schemaCorporation_2.file3.required = false
        state.schemaCorporation_2.file3.validations = undefined
      } else {
        state.schemaCorporation_2.file3.show = true
      }

      state.power_of_attorney_required = contents?.power_of_attorney_required
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => state,
    async (value: any) => {
      if (
        value.formJsonCorporation_1.file1 &&
        value.formJsonCorporation_2.file2 &&
        (value.formJsonCorporation_2.file3 ||
          !state.schemaCorporation_2.file3.required) &&
        value.formJsonPerson.file4
      ) {
        state.canSubmit = true
      }
    },
    { deep: true }
  )
</script>

<style lang="scss" scoped>
  .poa-download {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
</style>
