<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.BuyPointHistory.title') }}</h1>

        <history-item
          :detail="state.history"
          :title="state.title"
          :code_mst="state.code_mst"
        ></history-item>
      </div>

      <pagination
        :total="state.history.total_pages"
        :page="state.page"
        @onchange="changePage"
        v-if="state.history && state.history?.results?.length > 0"
      ></pagination>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import { getBuyPointHistory } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { options } from '@/enum/enums'

  const { t } = useI18n()
  const state: {
    title: any[]
    code_mst: any[]
    history:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    loading: boolean
    crypto: string | string[]
  } = reactive({
    title: [],
    code_mst: [],
    history: undefined,
    page: 1,
    loading: true,
    crypto: ''
  })

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getBuyPointHistory(state.page)
    state.history = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  onMounted(async () => {
    state.title = [
      { key: 'upd_date', name: t(`pages.BuyPointHistory.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.BuyPointHistory.item.title2`),
        type: 'code'
      },
      {
        key: 'buy_amount',
        name: t(`pages.BuyPointHistory.item.title3`),
        type: 'number'
      },
      {
        key: 'useble_amount',
        name: t(`pages.BuyPointHistory.item.title4`),
        type: 'number'
      },
      { key: 'limit_date', name: t(`pages.BuyPointHistory.item.title5`) }
    ]
    state.code_mst = options.point_trade_type
    await showHistory()
  })
</script>
<style lang="scss" scoped></style>
