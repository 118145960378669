<template>
  <!-- article start -->
  <article id="contents">
    <h1 class="contents_title">{{
      $router.currentRoute?.value?.meta?.title
    }}</h1>
    <div class="main_contents boxm">
      <h2
        ><i class="fas fa-graduation-cap advanced"></i>&nbsp;中・上級者向け
        記事一覧</h2
      >

      <the-loading v-if="state.loading" color="#434343"></the-loading>

      <div class="main_contents_list">
        <div class="column_lists" v-if="state.list">
          <article-preview
            v-for="item in state.list.results"
            :key="item"
            :item="item"
          ></article-preview>
        </div>

        <pagination
          :total="state.list?.total_pages"
          :page="state.page"
          @onchange="changePage"
          v-if="state.list && state.list.results.length > 0"
        ></pagination>

        <column-links type="2"></column-links>
        <tag-list></tag-list>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import Pagination from '@/components/Pagination.vue'
  import ArticlePreview from '@/components/ArticlePreview.vue'
  import TagList from '@/components/TagList.vue'
  import ColumnLinks from '@/components/ColumnLinks.vue'
  import { getAdvanced } from '@/api/index'
  import { onMounted, reactive } from 'vue'

  const state: {
    list:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    pageSize: number
    loading: boolean
  } = reactive({
    list: undefined,
    page: 1,
    pageSize: 9,
    loading: true
  })

  const showAdvanced = async () => {
    state.loading = true
    const { contents } = await getAdvanced({
      page_size: state.pageSize,
      page: state.page
    })
    state.list = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showAdvanced()
  }

  onMounted(() => {
    showAdvanced()
  })
</script>
