<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.PointDepositBank.title') }}</h1>

        <template v-if="Object.keys(state.formJson).length > 0">
          <div class="boxxs text-red" style="padding: 1rem">
            <p class="ta_c" style="font-size: 125%; margin-bottom: 1rem">
              <strong>{{ $t('pages.PointDepositBank.alert') }}</strong>
            </p>
            <p>
              {{ $t('pages.PointDepositBank.alert_msg1') }}
              <br />{{ $t('pages.PointDepositBank.alert_msg2') }} <br />{{
                $t('pages.PointDepositBank.alert_msg3')
              }}
              <br />{{ $t('pages.PointDepositBank.alert_msg4') }}
            </p>
          </div>

          <div class="formbox">
            <div>
              <p class="ta-center mw_10">
                {{ $t('pages.PointDepositBank.bank_msg') }}
              </p>
            </div>

            <div class="fee_table_wrap">
              <table class="fee_table">
                <tbody>
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.DepositBank.bank.title1')
                    }}</td>
                    <td class="fee_td">{{ state.formJson.bank }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.DepositBank.bank.title2')
                    }}</td>
                    <td class="fee_td">{{ state.formJson.branch }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.DepositBank.bank.title3')
                    }}</td>
                    <td class="fee_td">{{ state.formJson.type }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.DepositBank.bank.title4')
                    }}</td>
                    <td class="fee_td">{{ state.formJson.account }}</td></tr
                  >
                  <tr>
                    <td class="fee_th">{{
                      $t('pages.DepositBank.bank.title5')
                    }}</td>
                    <td class="fee_td">{{ state.formJson.holder }}</td></tr
                  >
                </tbody>
              </table>
            </div>

            <div class="w60 ml20p mr20p tbw-w100 tbw-ml0p tbw-mr0p mb20">
              <p class="indent-1">{{ $t('pages.PointDepositBank.msg1') }}</p>
              <p class="indent-1">{{ $t('pages.PointDepositBank.msg2') }}</p>
              <p class="indent-1">{{ $t('pages.PointDepositBank.msg3') }}</p>
            </div>
          </div>

          <h1 class="history-title">{{
            $t('pages.PointDepositBank.title_history')
          }}</h1>

          <history-item
            :detail="state.history"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history.total_pages"
            :page="state.page"
            @onchange="changePage"
            v-if="state.history && state.history?.results?.length > 0"
          ></pagination>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import { getDeposit, getBuyPointHistory } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { options } from '@/enum/enums'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const { t } = useI18n()
  const state: {
    title: any[]
    code_mst: any[]
    history:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    formJson: any
    page: number
    loading: boolean
    crypto: string | string[]
  } = reactive({
    title: [],
    code_mst: [],
    history: undefined,
    formJson: {},
    page: 1,
    loading: true,
    crypto: ''
  })

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getBuyPointHistory(state.page)
    state.history = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  const init = async () => {
    try {
      const { contents } = await getDeposit('point')
      state.formJson = contents
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.PointDepositBank.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  onMounted(async () => {
    init()

    state.title = [
      { key: 'upd_date', name: t(`pages.PointDepositBank.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.PointDepositBank.item.title2`),
        type: 'code'
      },
      {
        key: 'buy_amount',
        name: t(`pages.PointDepositBank.item.title3`),
        type: 'number'
      },
      {
        key: 'useble_amount',
        name: t(`pages.PointDepositBank.item.title4`),
        type: 'number'
      },
      { key: 'limit_date', name: t(`pages.PointDepositBank.item.title5`) }
    ]
    state.code_mst = options.point_trade_type
    await showHistory()
  })
</script>
<style lang="scss" scoped>
  .text-red {
    color: #df4c5b;
  }
</style>
