<template>
  <!-- pc-table -->
  <table class="uk-table uk-table-responsive uk-table-divider pc-table">
    <thead>
      <tr>
        <th v-for="item in props.title" :key="item" v-bind:class="[item.type=='number'? 'text-right': '']">{{ item.name }}</th>
      </tr>
    </thead>

    <template v-if="props.detail && props.detail?.results?.length > 0">
      <tbody>
        <tr v-for="item in props.detail.results" :key="item.seq">
          <td
            v-for="item_key in props.title"
            :key="item_key"
            v-bind:class="[item_key.type === 'number' ? 'text-right' : '']"
          >
            <template v-if="item_key.type === 'code'">
              {{
                props.code_mst.find((i) => i.key === item[item_key.key]).value
              }}
            </template>
            <template v-else-if="item_key.type === 'url'">
              <template v-if="item_key.url_name != null">
                <a :href="item[item_key.url_key]" target=".">{{
                  item_key.url_name
              }}</a>
              </template>
              <template v-else>
                <a :href="item[item_key.url_key]" target=".">{{
                  item[item_key.key].length > 15
                    ? item[item_key.key].substring(0, 15) + '...'
                    : item[item_key.key]
                }}</a>
              </template>
            </template>
            <template v-else>
              {{ item[item_key.key] }}
            </template>
          </td>
        </tr>
      </tbody>
    </template>
  </table>
  <!-- pc-table -->

  <!-- sp-table -->
  <table class="uk-table sp-table tl-fixed">
    <tbody class="b-top4">
      <template v-if="props.detail && props.detail?.results?.length > 0">
        <template v-for="item in props.detail.results" :key="item.seq">
          <template v-for="(item_key, index) in props.title" :key="item_key">
            <tr
              class="b-bot"
              v-bind:class="[
                index < Object.keys(props.title).length - 1 ? 'b-bot' : 'b-bot4'
              ]"
            >
              <th class="w40 va-top ww-ba">{{ item_key.name }}</th>
              <td class="w60 ww-ba">
                <template v-if="item_key.type === 'code'">
                  {{
                    props.code_mst.find((i) => i.key === item[item_key.key])
                      .value
                  }}
                </template>
                <template v-else-if="item_key.type === 'url'">
                  <template v-if="item_key.url_name != null">
                    <a :href="item[item_key.url_key]" target=".">{{
                      item_key.url_name
                    }}</a>
                  </template>
                  <template v-else>
                    <a :href="item[item_key.url_key]" target=".">{{
                      item[item_key.key].length > 15
                        ? item[item_key.key].substring(0, 15) + '...'
                        : item[item_key.key]
                    }}</a>
                  </template>
                </template>
                <template v-else>
                  {{ item[item_key.key] }}
                </template>
              </td>
            </tr>
          </template>
        </template>
      </template>
    </tbody>
  </table>

  <!-- sp-table -->
</template>

<script lang="ts" setup>
  import CDMST from '@/enum/cd_mst.json'
  import { CDMSTType } from '@/@types'

  const TheENUM = CDMST as CDMSTType

  const props = defineProps({
    title: Object,
    code_mst: Object,
    detail: Object
  })
</script>
<style lang="scss">
  .text-right {
    text-align: right !important;
  }
  td > a {
    display: inline;
    color: #FEBD09;
  }
</style>
