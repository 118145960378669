<template>
  <div class="price_left">
    <ul class=""
      ><li class="wal_list"
        ><span class="box-left">{{ $t('pages.Trade.price1.item1') }}</span
        ><span class="box-right">{{ $t('pages.Trade.price1.item2') }}</span
        ><span class="box-right">{{ $t('pages.Trade.price1.item3') }}</span
        ><span class="box-right">{{ $t('pages.Trade.price1.item4') }}</span></li
      ><li
        class="wal_list"
        v-bind:class="[props.type == coin.toLowerCase() ? 'list_select' : '']"
        v-for="coin in state.coins"
        :key="coin"
        v-on:click="
          $router.push({
            name: 'Trade',
            params: { crypto: coin.toLowerCase() }
          })
        "
        ><span class="box-left">{{ coin.toUpperCase() }}JPY</span
        ><span class="box-right">
          ￥{{
            (
              (store.ws.crypto &&
                store.ws.crypto[coin.toLocaleLowerCase()]?.bid) ||
              0
            ).toLocaleString()
          }}</span
        ><span class="box-right"
          >￥{{
            (
              ((store.ws.crypto &&
                store.ws.crypto[coin.toLocaleLowerCase()]?.ask) ||
                0) -
              ((store.ws.crypto &&
                store.ws.crypto[coin.toLocaleLowerCase()]?.bid) ||
                0)
            ).toLocaleString()
          }}</span
        ><span class="box-right"
          >￥{{
            (
              (store.ws.crypto &&
                store.ws.crypto[coin.toLocaleLowerCase()]?.ask) ||
              0
            ).toLocaleString()
          }}</span
        ></li
      ><p class="left_time">{{
        store.ws?.crypto?.btc?.timestamp.substring(0, 19)
      }}</p>
    </ul>
  </div>
  <div class="price_right">
    <ul class=""
      ><li class="wal_list"
        ><span class="box-left">{{ $t('pages.Trade.price2.item1') }}</span
        ><span class="box-right">{{ $t('pages.Trade.price2.item2') }}</span
        ><span class="box-right">{{ $t('pages.Trade.price2.item3') }}</span></li
      >
      <template v-for="coin in state.coins" :key="coin">
        <li
          class="wal_list"
          v-bind:class="[props.type == coin.toLowerCase() ? 'list_select' : '']"
          v-on:click="
            $router.push({
              name: 'Trade',
              params: { crypto: coin.toLowerCase() }
            })
          "
          ><span class="box-left">{{ coin.toUpperCase() }}</span
          ><span class="box-right">{{
            (props.data || {})[
              `cashBalance${capitalize(coin)}`
            ]?.toLocaleString(undefined, { maximumFractionDigits: [8] }) || 0
          }}</span
          ><span class="box-right"
            >￥{{
              (props.data || {})[
                `cashBalance${capitalize(coin)}ConvJpy`
              ]?.toLocaleString() || 0
            }}</span
          ></li
        >
      </template>

      <li class="wal_list"
        ><span class="box-left">JPY</span
        ><span class="box-right"
          >￥{{
            (props.data || {})[`cashBalanceJpy`]?.toLocaleString() || 0
          }}</span
        ><span class="box-right"
          >￥{{
            (props.data || {})[`cashBalanceJpy`]?.toLocaleString() || 0
          }}</span
        ></li
      >
      <p class="right_time">{{
        store.ws?.crypto?.btc?.timestamp.substring(0, 19)
      }}</p>
    </ul>
  </div>
</template>
<script lang="ts" setup>
  import { reactive } from 'vue'
  import { capitalize } from '@/utils/helper'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()

  const coinFlag =
    import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG &&
    JSON.parse(import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG)

  const props = defineProps({
    type: String,
    data: Object
  })

  const state: {
    coins: string[]
  } = reactive({
    coins: Object.keys(coinFlag).filter((i) => coinFlag[i] === 'true')
  })
</script>
<style lang="scss" scoped>
  @use '@/static/styles/trade-list.scss';
  .price_left {
    width: 50%;
  }
  .price_right {
    width: 50%;
    border-left: 1px solid #febd09 !important;
  }

  .left_time,
  .right_time {
    text-align: center;
    padding: 6px;
  }
  .left_time {
    display: block;
  }
  .right_time {
    display: none;
  }
  @media screen and (max-width: 1540px) {
    .price_left {
      width: 100%;
      border-top: 1px solid #febd09 !important;
    }
    .price_right {
      width: 100%;
      border-left: unset !important;
      border-top: 1px solid #febd09 !important;
    }
    .left_time {
      display: none;
    }
    .right_time {
      display: block;
    }
    .wal_list .box-left {
      min-width: 100px;
      max-width: 100px;
    }
  }
</style>
