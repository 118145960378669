<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="is-current">８{{ $t('pages.Corporation.title.step8') }}</li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <the-loading
        v-if="state.loading"
        color="#febd09"
        style="margin: 30px auto"
      ></the-loading>

      <template v-if="state.formJson.basic">
        <h1>{{ $t('pages.Corporation.title.step1Confirm') }}</h1>
        <router-link
          v-slot="{ navigate }"
          v-if="state.formJson.update_permission"
          :to="{ name: 'CorporationStep1Input', query: { uncheck_flg: '1' } }"
          ><button
            @click="navigate"
            role="link"
            class="uk-button uk-button-primary uk-align-center"
            >{{ $t('pages.Corporation.step8.modify') }}</button
          ></router-link
        >
        <step1 :data="state.formJson.basic" style="padding-top: 0"></step1>
      </template>

      <template v-if="state.formJson.exp">
        <h1>{{ $t('pages.Corporation.title.step2Confirm') }}</h1>
        <router-link
          v-slot="{ navigate }"
          v-if="state.formJson.update_permission"
          :to="{ name: 'CorporationStep2Input', query: { uncheck_flg: '1' } }"
          ><button
            @click="navigate"
            role="link"
            class="uk-button uk-button-primary uk-align-center"
            >{{ $t('pages.Corporation.step8.modify') }}</button
          ></router-link
        >
        <step2 part1 :data="state.formJson.exp" style="padding-top: 0"></step2>
        <p class="sub__title">{{
          $t('pages.Corporation.step2.subTitleConfirm')
        }}</p>
        <step2 part2 :data="state.formJson.exp" style="padding-top: 0"></step2>
      </template>

      <template v-if="state.formJson.trade_head">
        <h1>{{ $t('pages.Corporation.title.step3Confirm') }}</h1>
        <router-link
          v-slot="{ navigate }"
          v-if="state.formJson.update_permission"
          :to="{ name: 'CorporationStep3Input', query: { uncheck_flg: '1' } }"
          ><button
            @click="navigate"
            role="link"
            class="uk-button uk-button-primary uk-align-center"
            >{{ $t('pages.Corporation.step8.modify') }}</button
          ></router-link
        >
        <step3 :data="state.formJson.trade_head" style="padding-top: 0"></step3>
      </template>

      <template v-if="state.formJson.share_holder_type">
        <h1>{{ $t('pages.Corporation.step4.title') }}</h1>
        <button
          class="uk-button uk-button-primary uk-align-center"
          v-if="state.formJson.update_permission"
          @click="state.modal1 = true"
          >{{ $t('pages.Corporation.step8.modify') }}</button
        >

        <p class="share_holder_type">
          <span>{{
            state.formJson.share_holder_type.share_holder_type
              .toString()
              .toUpperCase()
          }}</span>
          <span
            >{{
              $t(
                `pages.Corporation.step4.selected.${state.formJson.share_holder_type.share_holder_type}`
              )
            }}<span
              v-if="state.formJson.share_holder_type.share_holder_type === 'i'"
              >{{ state.formJson.share_holder_type.share_holder_company }}</span
            ></span
          >
        </p>
      </template>

      <template
        v-if="
          state.formJson.share_holder && state.formJson.share_holder.length > 0
        "
      >
        <template
          v-for="(item, index) in state.formJson.share_holder"
          :key="index"
        >
          <h1>{{
            $t('pages.Corporation.step4.confirm', {
              number: numberSymbols[(index + 1).toString()]
            })
          }}</h1>
          <router-link
            v-slot="{ navigate }"
            v-if="state.formJson.update_permission"
            :to="{
              name: 'CorporationStep4Input',
              params: {
                multi: (index + 1).toString()
              },
              query: { uncheck_flg: '1' }
            }"
            ><button
              @click="navigate"
              role="link"
              class="uk-button uk-button-primary uk-align-center"
              >{{ $t('pages.Corporation.step8.modify') }}</button
            ></router-link
          >
          <step4 :data="item" style="padding-top: 0"></step4>
        </template>
      </template>

      <template v-if="state.formJson.bank">
        <h1>{{ $t('pages.Corporation.title.step5Confirm') }}</h1>
        <router-link
          v-slot="{ navigate }"
          v-if="state.formJson.update_permission"
          :to="{ name: 'CorporationStep5Input', query: { uncheck_flg: '1' } }"
          ><button
            @click="navigate"
            role="link"
            class="uk-button uk-button-primary uk-align-center"
            >{{ $t('pages.Corporation.step8.modify') }}</button
          ></router-link
        >
        <step5 :data="state.formJson.bank" style="padding-top: 0"></step5>
      </template>

      <template v-if="state.formJson.fileupload">
        <h1>{{ $t('pages.Corporation.title.step6Confirm') }}</h1>
        <button
          v-if="state.formJson.update_permission"
          @click="state.modal2 = true"
          class="uk-button uk-button-primary uk-align-center"
          >{{ $t('pages.Corporation.step8.modify') }}
        </button>
        <div class="corporation-confirm">
          <dl class="is-half">
            <dt>{{ $t('pages.Corporation.form.file1') }}</dt>
            <dd>{{ $t(`form.${state.formJson.fileupload['1']}`) }}</dd>
          </dl>
          <dl class="flex-1-1">
            <dt>{{ $t('pages.Corporation.form.file2') }}</dt>
            <dd>{{ $t(`form.${state.formJson.fileupload['2']}`) }}</dd>
          </dl>
          <dl class="flex-1-1">
            <dt>{{ $t('pages.Corporation.form.file3') }}</dt>
            <dd>{{ $t(`form.${state.formJson.fileupload['3']}`) }}</dd>
          </dl>
          <dl class="flex-1-1">
            <dt>{{ $t('pages.Corporation.form.file4') }}</dt>
            <dd>{{ $t(`form.${state.formJson.fileupload['4']}`) }}</dd>
          </dl>
          <dl class="flex-1-1">
            <dt>{{ $t('pages.Corporation.form.file5') }}</dt>
            <dd>{{ $t(`form.${state.formJson.fileupload['5']}`) }}</dd>
          </dl>
        </div>
      </template>

      <button
        v-if="
          Object.keys(state.formJson).length > 0 &&
          state.formJson.update_permission
        "
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Corporation.step8.submit')
        }"
        type="button"
        >{{ $t('pages.Corporation.step8.submit') }}</button
      >

      <router-link
        :to="{ name: 'Home' }"
        v-if="
          Object.keys(state.formJson).length > 0 &&
          state.formJson.update_permission === false
        "
        style="margin-top: 50px"
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
        >{{ $t('pages.Corporation.step8.link') }}</router-link
      >
    </div>
  </div>
  <the-modal
    v-if="state.modal1"
    id="modal-inquiry"
    hasClose
    @close="state.modal1 = false"
  >
    <section class="modal-inner">
      <p style="margin: 30px 0 50px 0">{{
        $t('pages.Corporation.step8.step4Modal.msg1')
      }}</p>
      <p class="uk-text-center">
        <router-link
          v-slot="{ navigate }"
          :to="{ name: 'CorporationStep4Select', query: { uncheck_flg: '1' } }"
          ><button
            @click="navigate"
            role="link"
            class="uk-button uk-button-primary uk-align-center"
            style="margin-bottom: 15px"
            >{{ $t('pages.Corporation.step8.step4Modal.modify') }}</button
          ></router-link
        >
        <a
          class="uk-button uk-button-primary uk-align-center"
          style="display: inline-block; margin-top: 0"
          @click="state.modal1 = false"
          >{{ $t('pages.Corporation.step8.step4Modal.return') }}</a
        >
      </p>
    </section>
  </the-modal>

  <the-modal
    v-if="state.modal2"
    id="modal-inquiry"
    hasClose
    @close="state.modal2 = false"
  >
    <section class="modal-inner">
      <p style="margin: 30px 0 50px 0">{{
        $t('pages.Corporation.step8.step6Modal.msg1')
      }}</p>
      <p class="uk-text-center">
        <router-link
          v-slot="{ navigate }"
          :to="{ name: 'CorporationStep6Input', query: { uncheck_flg: '1' } }"
          ><button
            @click="navigate"
            role="link"
            class="uk-button uk-button-primary uk-align-center"
            style="margin-bottom: 15px"
            >{{ $t('pages.Corporation.step8.step4Modal.modify') }}</button
          ></router-link
        >
        <a
          class="uk-button uk-button-primary uk-align-center"
          style="display: inline-block; margin-top: 0"
          @click="state.modal2 = false"
          >{{ $t('pages.Corporation.step8.step4Modal.return') }}</a
        >
      </p>
    </section>
  </the-modal>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { addFormStyle } from '@/utils/tool'
  import { getCorporationConfirm, setCorporationStatus } from '@/api'
  import { numberSymbols } from '@/enum/enums'
  import Step1 from '@/components/corporation/Step1.vue'
  import Step2 from '@/components/corporation/Step2.vue'
  import Step3 from '@/components/corporation/Step3.vue'
  import Step4 from '@/components/corporation/Step4.vue'
  import Step5 from '@/components/corporation/Step5.vue'
  import TheModal from '@/components/TheModal.vue'

  const router = useRouter()

  const state: {
    init: boolean
    formJson: any
    errorMessage: string
    loading: boolean
    modal1: boolean
    modal2: boolean
  } = reactive({
    init: false,
    formJson: {},
    errorMessage: '',
    loading: true,
    modal1: false,
    modal2: false
  })

  const loading = ref(false)

  const submit = async () => {
    loading.value = true
    try {
      await setCorporationStatus('08')
      router.push({ name: 'CorporationStep9' })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    }
    loading.value = false
  }

  onMounted(async () => {
    try {
      const { contents } = await getCorporationConfirm()
      state.formJson = Object.assign(state.formJson, contents)
      state.loading = false
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped>
  .share_holder_type {
    display: flex;
    margin-bottom: 60px;
    span {
      flex: 1;
    }
    span:first-of-type {
      flex: 0 0 80px;
      text-align: center;
    }
  }
</style>
