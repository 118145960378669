<template>
  <!-- article start -->
  <article id="new_account">
    <h1 class="contents_title">{{
      $t('pages.newAccountCreateSuccess.title')
    }}</h1>

    <div class="na_success">
      <p>{{ $t('pages.newAccountCreateSuccess.msg1') }}</p>

      <p class="m_center" v-if="state.email"
        ><span id="email">{{ state.email }}</span
        >{{ $t('pages.newAccountCreateSuccess.msg2') }}</p
      >
      <p>{{ $t('pages.newAccountCreateSuccess.msg3') }}</p>

      <p class="na_cat">{{ $t('pages.newAccountCreateSuccess.msg4') }}</p>

      <router-link :to="{ name: 'Landing' }" class="btn_link boxz"
        ><i class="fas fa-chevron-right"></i>&nbsp;{{
          $t('pages.newAccountCreateSuccess.link')
        }}</router-link
      >
    </div>
  </article>
  <!-- article end -->
</template>
<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { decodeEmail } from '@/api'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'

  const router = useRouter()
  const state = reactive({
    email: ''
  })
  const store = useMainStore()

  const getEmail = async (): Promise<string> => {
    const { contents } = await decodeEmail(
      router.currentRoute.value.params.email_encode as string
    )
    return contents?.text
  }

  onMounted(async () => {
    const breadcrumb: SiteMapType = {
      route: router.currentRoute.value.name as string,
      name: router.currentRoute.value.meta.title
    }
    store.setBreadcrumb(breadcrumb)

    state.email = await getEmail()
  })
</script>
