<template>
  <div class="tstep">
    <img
      :src="`${s3Url}img/pc_step5.${language}.png?v=1`"
      class="sp_no"
      alt="step"
    />
    <img
      :src="`${s3Url}img/sp_step5.${language}.png?v=1`"
      class="sp"
      alt="step"
    />
  </div>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="individual">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="individual-wrapper" v-else>
      <ol class="step mb30">
        <li><span style="padding-left: 1em">１</span></li>
        <li><span style="padding-left: 1em">２</span></li>
        <li><span style="padding-left: 1em">３</span></li>
        <li class="is-current br-tl-6 br-bl-6">{{
          $t('pages.Individual.title.step4')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Individual.step4.title') }}</h1>
        <p style="text-align: center">{{
          $t('pages.Individual.step4.msg1')
        }}</p>
        <router-link
          :to="{ name: 'Home' }"
          style="margin-top: 50px"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
          >{{ $t('pages.Individual.step4.link') }}</router-link
        >
      </form>
    </div>
  </div>
  <!-- Event snippet for Coin Estate（口座開設申し込み完了） conversion page -->
  <component :is="'script'" v-if="env === 'prod'">
    gtag('event', 'conversion', { 'send_to': 'AW-519656955/3OkhCKyxivwBEPur5fcB'
    });
  </component>
  <!-- ここから Crib Notesユニバーサルタグのコード -->
  <component :is="'script'" v-if="env === 'prod' && state.clib_counter_flg">
    (function (b, f, d, a, c) {var e = b.createElement(f);e.src = c + "/" + a +
    "/atm.js";e.id = d;e.async =
    true;b.getElementsByTagName(f)[0].parentElement.appendChild(e)})(document,"script","__cribnotesTagMgrCmd","4f5ad26c-b405-4a75-8a16-17fc85ce53bd","https://tag.cribnotes.jp/container_manager");
  </component>
  <!-- ここまで Crib Notesユニバーサルタグのコード -->
</template>
<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { getFinished } from '@/api'

  const store = useMainStore()
  const { s3Url, language, env } = storeToRefs(store)

  const state = reactive({
    clib_counter_flg: false,
    init: false,
    errorMessage: ''
  })

  onMounted(async () => {
    try {
      const { contents } = await getFinished()
      state.clib_counter_flg = contents?.clib_counter_flg
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>
