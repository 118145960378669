<template>
  <!-- article start -->
  <article id="toppage">
    <div class="boxs pw_40">
      <div class="bh3 fw_m">{{ router.currentRoute?.value?.meta?.title }}</div>

      <div class="bh5 fw_m fc_y">{{
        $t('pages.Service.fee.table1.title')
      }}</div>
      <div class="fee_table_wrap">
        <table class="fee_table">
          <tbody>
            <!-- 
            <tr>
              <td class="fee_th">{{ $t('pages.Service.fee.table1.th1') }}</td>
              <td class="fee_td">{{
                $t('pages.Service.fee.table1.td1')
              }}</td></tr
            >
             -->
            <tr>
              <td class="fee_th">{{ $t('pages.Service.fee.table1.th2') }}</td>
              <td class="fee_td">{{
                $t('pages.Service.fee.table1.td2')
              }}</td></tr
            >
            <tr>
              <td class="fee_th">{{ $t('pages.Service.fee.table1.th3') }}</td>
              <td
                class="fee_td"
                v-html="$t('pages.Service.fee.table1.td3')"
              ></td
            ></tr>
            <tr>
              <td class="fee_th">{{ $t('pages.Service.fee.table1.th4') }}</td>
              <td class="fee_td">{{
                $t('pages.Service.fee.table1.td4')
              }}</td></tr
            >
          </tbody>
        </table>
      </div>

      <div class="bh5 fw_m fc_y">{{
        $t('pages.Service.fee.table2.title')
      }}</div>

      <div class="fee_table_wrap">
        <table class="fee_table">
          <tbody>
            <tr>
              <td class="fee_th">{{ $t('pages.Service.fee.table2.th1') }}</td>
              <td class="fee_td2 fee_td_bold">{{
                $t('pages.Service.fee.table2.td1_1')
              }}</td>
              <td class="fee_td2 fee_td_bold">{{
                $t('pages.Service.fee.table2.td1_2')
              }}</td></tr
            >
            <tr>
              <td class="fee_th">{{ $t('pages.Service.fee.table2.th2') }}</td>
              <td class="fee_td2">{{
                $t('pages.Service.fee.table2.td2_1')
              }}</td>
              <td class="fee_td2">{{
                $t('pages.Service.fee.table2.td2_2')
              }}</td></tr
            >
          </tbody>
        </table>
      </div>

      <div class="fee_table_wrap">
        <div class="sliderArea">
          <div class="thumb slick-initialized slick-slider">
            <div class="slick-track">
              <div
                class="slick-slide slick-active"
                :class="{ 'slick-current': index === state.carouselIndex }"
                v-for="(item, index) in state.crypto"
                @click="changeCrypto(index)"
                :key="item"
                :modelValue="state.currentIndex"
                ><img
                  :src="`${s3Url}img/icon_${item.toLocaleLowerCase()}.png`"
                  :alt="item.toLocaleLowerCase()"
                />
                {{ item.toLocaleUpperCase() }}
              </div>
            </div>
          </div>
          <carousel
            :items-to-show="1"
            :wrap-around="true"
            v-model="state.carouselIndex"
            v-if="state.crypto.length > 0"
          >
            <template v-for="item in state.crypto" :key="item">
              <slide>
                <div class="slide-item">
                  <div class="bh5 fw_m fc_y"
                    >{{ item.toLocaleUpperCase() }}（{{
                      $t(`coins.${item.toLocaleLowerCase()}`)
                    }}）</div
                  >
                  <div class="">
                    <table class="fee_table">
                      <tbody>
                        <tr>
                          <td class="fee_th" colspan="2">{{
                            $t('pages.Service.fee.table3.table.title1')
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th1_1')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td1_1`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th1_2')
                          }}</td>
                          <td
                            class="fee_td"
                            v-html="
                              $t(
                                `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td1_2`
                              )
                            "
                          ></td>
                        </tr>
                        <tr>
                          <td class="fee_th" colspan="2">{{
                            $t('pages.Service.fee.table3.table.title2')
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_1')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_1`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_2')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_2`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_3')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_3`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_4')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_4`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_5')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_5`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_6')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_6`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_7')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_7`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_8')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_8`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_9')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_9`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_10')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_10`
                            )
                          }}</td>
                        </tr>
                        <tr>
                          <td class="fee_th">{{
                            $t('pages.Service.fee.table3.table.th2_11')
                          }}</td>
                          <td class="fee_td">{{
                            $t(
                              `pages.Service.fee.table3.${item.toLocaleLowerCase()}.td2_11`
                            )
                          }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </slide>
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { ref, reactive } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { useRouter } from 'vue-router'
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide } from 'vue3-carousel'

  const store = useMainStore()
  const router = useRouter()
  const { tokenDeploy, s3Url } = storeToRefs(store)
  const state: {
    carouselIndex: number
    currentIndex: number
    crypto: string[]
  } = reactive({
    carouselIndex: 0,
    currentIndex: 0,
    crypto: []
  })

  Object.keys(tokenDeploy.value).map((key: string) => {
    if (tokenDeploy.value[key] === 'true') state.crypto.push(key)
  })

  const changeCrypto = (index: number) => {
    state.carouselIndex = index
  }
</script>
<style lang="scss" scoped>
  .slide-item {
    width: 100%;
    .fee_table {
      text-align: left;
    }
  }
</style>
<style lang="scss">
  .fee_td_in {
    margin: 0.25rem 0 0 0.5rem;
    font-size: 0.9rem;
  }
  .fee_td2_in {
    margin: 0.25rem 0 0 0.5rem;
    font-size: 0.9rem;
  }
  .fee_th2 {
    width: 30%;
    padding: 1rem;
    text-align: center;
    background: #111430;
    color: #f1e0b0;
    font-weight: normal;
    vertical-align: middle;
    font-weight: bold;
  }
  .thumb {
    margin: 0 0 3rem;
    background: #070a27;
    .slick-track {
      display: flex;
    }
    .slick-slide {
      text-align: center;
      padding: 0.85rem 0 0.75rem;
      font-size: 0.9rem;
      flex: 1;
      img {
        display: block;
        height: 1.25rem;
        margin: 0 auto 0.25rem;
      }
    }
    .slick-current {
      color: #febd09;
      background: #171a37;
    }
  }

  .ls_min {
    letter-spacing: -0.1rem;
  }
  @media (max-width: 1024px) {
    .fee_th2 {
      font-size: 0.96rem;
    }
    .fee_td_in,
    .fee_td2_in {
      font-size: 0.86rem;
    }
    .thumb {
      margin: 0 0 1.5rem;
      .slick-slide {
        padding: 0.5rem 0 0.4rem;
        font-size: 0.8rem;
        img {
          height: 1rem;
          margin: 0 auto 0.2rem;
        }
      }
    }
  }
</style>
