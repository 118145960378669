<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li></li>
        <li class="is-current">４{{ $t('pages.Corporation.title.step4') }}</li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Corporation.step4.msg1') }}</h1>
        <p>{{ $t('pages.Corporation.step4.msg2') }}</p>

        <div class="step4">
          <p
            ><span style="font-weight: bold">{{
              $t('pages.Corporation.step4.msg3')
            }}</span>
            <br />{{ $t('pages.Corporation.step4.msg4') }}
          </p>
          <div class="radio-group">
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_A"
                name="voting_rights_held"
                value="a"
                class="uk-radio"
                v-model="state.radio"
              />
              <label for="voting_rights_held_A"
                ><b>A</b>{{ $t('pages.Corporation.step4.select.a') }}</label
              >
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_B"
                name="voting_rights_held"
                class="uk-radio"
                value="b"
                v-model="state.radio"
              />
              <label for="voting_rights_held_B"
                ><b>B</b>{{ $t('pages.Corporation.step4.select.b') }}</label
              >
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_C"
                name="voting_rights_held"
                class="uk-radio"
                value="c"
                v-model="state.radio"
              />
              <label for="voting_rights_held_C"
                ><b>C</b>{{ $t('pages.Corporation.step4.select.c') }}</label
              >
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_D"
                name="voting_rights_held"
                class="uk-radio"
                value="d"
                v-model="state.radio"
              />
              <label for="voting_rights_held_D"
                ><b>D</b>{{ $t('pages.Corporation.step4.select.d') }}</label
              >
            </div>
          </div>
          <p>{{ $t('pages.Corporation.step4.msg5') }}</p>
          <p style="margin-top: 40px"
            ><span style="font-weight: bold">{{
              $t('pages.Corporation.step4.msg6')
            }}</span>
            <br />{{ $t('pages.Corporation.step4.msg7') }}
          </p>
          <div class="radio-group">
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_E"
                name="voting_rights_held"
                value="e"
                class="uk-radio"
                v-model="state.radio"
              />
              <label for="voting_rights_held_E"
                ><b>E</b>{{ $t('pages.Corporation.step4.select.e') }}
              </label>
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_F"
                name="voting_rights_held"
                class="uk-radio"
                value="f"
                v-model="state.radio"
              />
              <label for="voting_rights_held_F"
                ><b>F</b>{{ $t('pages.Corporation.step4.select.f') }}
              </label>
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_G"
                name="voting_rights_held"
                class="uk-radio"
                value="g"
                v-model="state.radio"
              />
              <label for="voting_rights_held_G"
                ><b>G</b>{{ $t('pages.Corporation.step4.select.g') }}
              </label>
            </div>
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_H"
                name="voting_rights_held"
                class="uk-radio"
                value="h"
                v-model="state.radio"
              />
              <label for="voting_rights_held_H"
                ><b>H</b>{{ $t('pages.Corporation.step4.select.h') }}</label
              >
            </div>
          </div>
        </div>
        <p>{{ $t('pages.Corporation.step4.msg8') }} </p>
        <div class="step4">
          <div class="radio-group">
            <div class="radio-item">
              <input
                type="radio"
                id="voting_rights_held_I"
                name="voting_rights_held"
                class="uk-radio"
                value="i"
                v-model="state.radio"
              />
              <label for="voting_rights_held_I"
                ><b>I</b
                ><span style="font-weight: bold">{{
                  $t('pages.Corporation.step4.select.i')
                }}</span
                ><span v-if="state.radio === 'i'" class="required-notice"
                  >必須</span
                ></label
              >
            </div>
            <SchemaFormWithValidation
              :schema="state.schema"
              :model="state.formJson"
              useCustomFormWrapper
              ref="form"
              class="corporation-form"
              style="padding-top: 0"
              v-if="state.radio === 'i'"
            />
          </div>
        </div>
        <button
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
          v-debounce="{
            func: submit
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.form.next')
          }"
          type="button"
          >{{ $t('pages.Corporation.form.next') }}</button
        >
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import { useMainStore } from '@/store/pinia'
  import { addFormStyle } from '@/utils/tool'
  import { shareHolders, validator } from '@/enum/enums'
  import {
    setCorporationStatus,
    selectShareHolder,
    getShareHolderType
  } from '@/api'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'

  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()

  const state: {
    init: boolean
    errorMessage: string
    radio: string
    errorForI: boolean
    schema: any
    formJson: any
  } = reactive({
    init: false,
    errorMessage: '',
    radio: '',
    errorForI: false,
    schema: {
      name: {
        component: 'Text',
        type: 'test',
        inputClass: 'form-control valid',
        placeholder: '法人名を入力',
        validations: yup
          .string()
          .trim()
          .nullable()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: '法人名',
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name.regex as RegExp,
            t('validation.input_error', {
              content: '法人名'
            })
          )
      }
    },
    formJson: {}
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const submit = async () => {
    state.errorMessage = ''
    if (state.radio === '') {
      state.errorMessage = '必須項目を選択してください'
      document.documentElement.scrollTop = 0
      return
    }

    store.setShareHolders(shareHolders[state.radio])

    if (state.radio === 'i') {
      const valid = form.value?.validate()
      setTimeout(async () => {
        if (
          valid?.meta?.valid ||
          document.getElementsByClassName('error').length === 0
        ) {
          loading.value = true
          try {
            await selectShareHolder(state.radio, state.formJson.name)
            await setCorporationStatus('04')
            router.push({ name: 'CorporationStep5Input' })
          } catch (error: any) {
            state.errorMessage =
              error.error_translations || error.message_detail
            document.documentElement.scrollTop = 0
          } finally {
            loading.value = false
          }
        }
      }, 0)
    } else {
      loading.value = true
      try {
        await selectShareHolder(state.radio)
        router.push({
          name: 'CorporationStep4Input',
          params: { multi: '1' },
          query: {
            need_input:
              router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
                ? '1'
                : undefined
          }
        })
      } catch (error: any) {
        state.errorMessage = error.error_translations || error.message_detail
        document.documentElement.scrollTop = 0
      } finally {
        loading.value = false
      }
    }
  }

  onMounted(async () => {
    store.removeShareHolders()
    try {
      const { contents } = await getShareHolderType(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      state.radio = contents?.share_holder_type
      state.formJson.name = contents?.share_holder_company
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })
</script>

<style lang="scss" scoped>
  .step4 {
    width: 90%;
    margin: 50px auto;
  }

  @media (max-width: 800px) {
    .step4 {
      width: 100%;
    }
  }
</style>
