<template>
  <user>
    <div class="content_box">
      <div class="top_box">
        <div class="left_box">
          <div class="trend">
            <div class="price_box">
              <price
                :type="(state.crypto as string)"
                :data="state.latest_data"
              ></price>
            </div>
            <div class="chart_box">
              <chart
                :type="(state.crypto as string)"
                :reflashtoken="state.reflashtoken"
                @onchange="reflash"
                @businessTime="businessTime"
                @tokenClose="tokenClose"
              ></chart>
            </div>
          </div>
        </div>
        <div class="right_box">
          <div class="order_box">
            <order-board
              :type="(state.crypto as string)"
              :tradetoken="state.tradetoken"
              :btn_list="state.btn_list"
              :input_min="state.input_min"
              :rate_ask="state.rate_ask"
              :rate_bid="state.rate_bid"
              :setting="state.data_setting"
              :other="state.data_other"
              :business_time="state.business_time"
              @onchange="reflash"
            ></order-board>
          </div>
          <div class="balance_box">
            <balance
              :type="(state.crypto as string)"
              :data="state.latest_data"
              :decimal_unit="state.decimal_unit"
            ></balance>
          </div>
        </div>
      </div>
      <div class="bottom_box">
        <order-list
          :type="(state.crypto as string)"
          :tradetoken="state.tradetoken"
          :data="state.latest_data"
          :decimal_unit="state.decimal_unit"
          @onchange="reflash"
        ></order-list>
      </div>
    </div>

    <div
      class="token_error"
      v-if="state.token_error && state.isTokenCheck === 'true'"
    >
      <div class="token_error_content">
        <p v-html="t('pages.Trade.token_error')"></p>
        <div class="btn_set">
          <router-link :to="{ name: 'Home' }" class="btn_link"
            ><i class="fas fa-chevron-right"></i>&nbsp;{{
              $t('pages.Error.toTop')
            }}</router-link
          >
        </div>
      </div>
    </div>
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import Price from '@/components/trade/Price.vue'
  import OrderBoard from '@/components/trade/OrderBoard.vue'
  import OrderList from '@/components/trade/OrderList.vue'
  import Chart from '@/components/trade/Chart.vue'
  import Balance from '@/components/trade/Balance.vue'
  import { useRouter } from 'vue-router'
  import { getTradeInit, getTradeLatest } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { useMainStore } from '@/store/pinia'

  const router = useRouter()
  const store = useMainStore()
  const coinFlag =
    import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG &&
    JSON.parse(import.meta.env.VITE_APP_TOKEN_DEPLOY_FLG)
  const { t } = useI18n()
  const state: {
    coins: string[]
    latest_data: any
    init_data: any
    btn_list: string[]
    input_min: string
    data_setting: any
    data_other: any
    loading: boolean
    modal: boolean
    crypto: string
    tradetoken: string
    reflashtoken: string
    decimal_unit: number
    rate_ask: string
    rate_bid: string
    business_time: boolean
    token_error: boolean
    errorMessage: string
    successMessage: string
    isTokenCheck: string
  } = reactive({
    coins: Object.keys(coinFlag).filter((i) => coinFlag[i] === 'true'),
    latest_data: {},
    init_data: {},
    btn_list: [],
    input_min: '0',
    data_setting: {},
    data_other: {},
    loading: true,
    modal: false,
    crypto: '',
    tradetoken: '',
    reflashtoken: '',
    decimal_unit: 0,
    rate_ask: '0',
    rate_bid: '0',
    business_time: true,
    token_error: false,
    errorMessage: '',
    successMessage: '',
    isTokenCheck: import.meta.env.VITE_APP_TOKEN_CHECK
  })

  const init = async () => {
    try {
      const { contents } = await getTradeInit(state.crypto)
      state.init_data = contents
      state.tradetoken = contents?.tradetoken
      state.reflashtoken = contents?.reflashtoken
      state.btn_list = contents?.plus_btn_list
      state.input_min = contents?.input_min
      state.data_setting = contents?.setting
      state.data_other = contents?.other
      state.decimal_unit = contents?.order_rate_length
      state.rate_ask = contents?.rate_ask
      state.rate_bid = contents?.rate_bid
      state.business_time = contents?.business_time
      state.loading = true
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.Transfer.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  const callTradeLatest = async () => {
    const { contents } = await getTradeLatest(state.crypto)
    state.latest_data = contents
    await store.getHome()
  }

  const reflash = async () => {
    callTradeLatest()
  }
  const businessTime = async (flg: boolean) => {
    state.business_time = flg
  }
  const tokenClose = async () => {
    console.log('tokenClose')
    state.token_error = true
  }

  onMounted(async () => {
    state.crypto = router.currentRoute.value.params.crypto as string
    init()
    callTradeLatest()
  })
</script>
<style lang="scss" scoped>
  @use '@/static/styles/trade-customer.scss';
  .trend {
    border-right: 1px solid #febd09 !important;
    border-left: 1px solid #febd09 !important;
  }

  .content_box {
    width: 100%;
  }
  .top_box {
    display: flex;
  }
  .bottom_box {
    border-top: 1px solid #febd09 !important;
    border-left: 1px solid #febd09 !important;
  }
  .left_box {
    width: 100%;
  }
  .right_box {
    border-top: 1px solid #febd09 !important;
    padding-top: 0px !important;
    min-width: 470px;
    position: relative;
  }

  .balance_box {
    border-top: 1px solid #febd09 !important;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .price_box {
    border-top: 1px solid #febd09 !important;
    display: flex;
  }

  .chart_box {
    width: 1280px;
    height: 400px;
    background: #171b26;
    border: unset !important;
    border-top: 1px solid #febd09 !important;
    margin: 0 auto 0rem;
  }

  .token_error {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  }
  .token_error_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  a.btn_link {
    margin: unset !important;
  }

  @media screen and (max-width: 1540px) {
    .price_box {
      display: unset;
    }
    .balance_box {
      position: unset;
      border-bottom: 1px solid #febd09 !important;
    }
  }
  @media screen and (max-width: 1220px) {
    .top_box {
      display: unset;
    }
    .balance_box {
      position: unset;
    }
    .right_box {
      min-width: unset;
    }
    .order_box,
    .balance_box,
    .bottom_box {
      border-left: 1px solid #febd09 !important;
      border-right: 1px solid #febd09 !important;
    }
  }
</style>
<style lang="scss">
  #sidenav {
    margin: 0 0 0 2px !important;
  }
</style>
