<template>
  <div class="wrapper">
    <div :class="`progress step${state.level}`"></div>
  </div>
</template>

<script lang="ts" setup>
  import { reactive, watch } from 'vue'

  const state: {
    level: number
  } = reactive({
    level: 0
  })
  const props = defineProps({
    value: {
      type: String,
      default: ''
    }
  })

  const pattern1 = /[0-9a-zA-Z]+/
  const pattern2 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{1,}$/
  const pattern3 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{14,15}$/
  const pattern4 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{16,}$/

  watch(
    () => props.value,
    (value) => {
      if (pattern4.test(value)) {
        state.level = 4
        return
      }
      if (pattern3.test(value)) {
        state.level = 3
        return
      }
      if (pattern2.test(value.replace(/[^a-zA-Z0-9]/gi, ''))) {
        state.level = 2
        return
      }
      if (pattern1.test(value)) {
        state.level = 1
        return
      }
    },
    { immediate: true, deep: true }
  )
</script>
<style lang="scss" scoped>
  .wrapper {
    width: 100%;
    height: 5px;
    border-radius: 20px;
    background-color: #eee;
    overflow: hidden;
    margin-left: 20px;
  }

  .progress {
    width: 0;
    height: 100%;
    transition: width 0.5s;
    -webkit-transition: width 0.5s;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
      0 1px rgba(255, 255, 255, 0.08);

    &.step1 {
      width: 25%;
      background-color: red;
    }
    &.step2 {
      width: 50%;
      background-color: orange;
    }
    &.step3 {
      width: 75%;
      background-color: #febd09;
    }
    &.step4 {
      width: 100%;
      background-color: #198754;
    }
  }
</style>
