export interface SiteMapType {
  route: string
  params?: any
  name: string
  isText?: boolean
  ja_hide?: boolean | null
  en_hide?: boolean | null
  cn_hide?: boolean | null
  children?: {
    route: string
    params?: any
    name: string
    ja_hide?: boolean | null
    en_hide?: boolean | null
    cn_hide?: boolean | null
    link?: string
  }[]
}

export interface PleaseReadType {
  route?: string
  name: string
  pdf?: string
  hide?: string[]
}

export interface CoinInfoType {
  ask: number
  average: number
  bid: number
  currency: string
  daily_base_rate: number
  daily_max_rate: number
  daily_min_rate: number
  day_before_ratio: number
  init_base_rate: number
  market: string
  success: string
  timestamp: string
}

export interface LanguagesType {
  id: i18nType
  name: string
  abbreviation: string
}

export interface PageType {
  page?: number
  page_size?: number
}
export interface ArticleFilterType {
  tag?: string
  category?: string
}

export type i18nType = 'ja' | 'en' | 'cn' | ''

export const LOCALE_KEY = '__LOCALE__'
export const DEFAULT_LOCALE: i18nType = 'ja'
export const SHARE_HOLDERS_KEY = '__SHARE_HOLDERS__'
export const DEFAULT_SHARE_HOLDERS = '0'

export interface UserInfoType {
  account_id?: string
  user_type?: string
  email?: string
  login?: string
  name?: string
  career?: string
  column_article?: string
  glossary?: string
  campaign?: string
  notification_unread?: boolean
}

export interface CDMSTType {
  success: boolean
  status: string
  message: string
  message_detail: string
  contents: Record<string, any>
}
