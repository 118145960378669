<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">

      <h1 class="contents_title">{{
        $router.currentRoute?.value?.meta?.title
      }}</h1>

      <the-loading v-if="state.loading"></the-loading>

      <div class="boxm" v-else>
        <div class="boxs">
          <template
            v-if="state.notification && state.notification?.results?.length > 0"
          >
            <notification-private
              v-for="item in state.notification.results"
              :key="item.notification_id"
              :new="item.new_flg=='1'"
              :read="item.read_flg=='1'"
              :detail="item"
            ></notification-private>
          </template>
          <p v-else>今のお知らせはありません。</p>
        </div>
      </div>

      <pagination
        :total="state.notification.total_pages"
        :page="state.page"
        @onchange="changePage"
        v-if="state.notification && state.notification?.results?.length > 0"
      ></pagination>

      </div>

    </article>

    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import NotificationPrivate from '@/components/NotificationPrivate.vue'
  import Pagination from '@/components/Pagination.vue'
  import { getNotificationPrivList } from '@/api/index'

  const state: {
    notification:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    loading: boolean
  } = reactive({
    notification: undefined,
    page: 1,
    loading: true
  })

  const showNotification = async () => {
    state.loading = true
    const { contents } = await getNotificationPrivList(state.page)
    state.notification = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showNotification()
  }

  onMounted(async () => {
    await showNotification()
  })
</script>
