<template>
  <dt
    class="open_dt btn_open dropdown"
    :class="{ dropdown_toggle: state.show, active: state.show }"
    @click="toggleItem()"
  >
    <span>Q.</span>{{ props.question }}<br />{{ props.subQuestion }}
    <img
      src="@static/img/arrow_circle.png"
      alt="arrow"
      class="arrow"
      style="opacity: 0.7"
    />
  </dt>
  <transition name="toggle">
    <dd class="info" v-show="state.show">
      <span>A.</span>
      <slot></slot>
    </dd>
  </transition>
</template>

<script lang="ts" setup>
  import { reactive } from 'vue'

  const props = defineProps({
    question: String,
    subQuestion: String
  })
  const state = reactive({
    show: false
  })
  const toggleItem = () => {
    state.show = !state.show
  }
</script>

<style lang="scss" scoped>
  .toggle-enter-active,
  .toggle-leave-active {
    transition: all 0.3s ease-in-out;
    line-height: 1.6;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    opacity: 1;
  }

  .toggle-enter-from,
  .toggle-leave-to {
    line-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
</style>
