<template>
  <main
    id="root"
    @click="closeLocale"
    :class="{
      gray__background: state.route?.name
        ?.toString()
        .toLocaleLowerCase()
        .includes('corporation'),
      new_root: state.tradeToolFlag === 'true'
    }"
  >
    <the-header ref="header" :meta="state.route.meta"></the-header>
    <breadcrumb :meta="state.route.meta"></breadcrumb>
    <router-view :key="router.currentRoute.value.path" />
    <note :meta="state.route.meta"></note>
    <association :meta="state.route.meta"></association>
    <the-footer :meta="state.route.meta"></the-footer>
    <scroll-button></scroll-button>
  </main>
  <div class="session-wrapper" v-if="state.sessionOut">
    <div class="session-content">
      <p>セッションがタイムアウトしました。再度ログインしてください。</p>
      <router-link
        :to="{ name: 'Login' }"
        class="uk-button uk-button-primary uk-align-center"
        >OK</router-link
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { watch, reactive, ref, onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import {
    useRouter,
    RouteRecordRaw,
    RouteLocationNormalizedLoaded
  } from 'vue-router'
  import TheHeader from '@/components/TheHeader.vue'
  import Breadcrumb from '@/components/Breadcrumb.vue'
  import Note from '@/components/Note.vue'
  import Association from '@/components/Association.vue'
  import TheFooter from '@/components/TheFooter.vue'
  import ScrollButton from '@/components/ScrollButton.vue'
  import { useMainStore } from '@/store/pinia'

  const router = useRouter()
  const header = ref<InstanceType<typeof TheHeader> | null>(null)
  const store = useMainStore()
  const { sessionOut } = storeToRefs(store)

  const closeLocale = () => {
    header.value?.closeLocale()
  }

  const state: {
    route: RouteRecordRaw | RouteLocationNormalizedLoaded
    noBgArr: string[]
    tradeToolFlag: string | undefined
    sessionOut: boolean
  } = reactive({
    route: {} as RouteRecordRaw | RouteLocationNormalizedLoaded,
    noBgArr: [
      'ColumnArticle',
      'TopicArticle',
      'ApplicationCryptoAssets',
      'TelService',
      'PointService'
    ],
    tradeToolFlag: import.meta.env.VITE_APP_NEW_TRADE_TOOL_FLG,
    sessionOut: false
  })
  watch(
    () => router.currentRoute.value,
    (route) => {
      if (route.name === 'Landing' || route.name === 'LP') {
        document.body.setAttribute('id', 'maintop')
      } else {
        document.body.removeAttribute('id')
      }

      if (route.meta === undefined) return
      state.route = route
    },
    { immediate: true, deep: true }
  )

  watch(
    sessionOut,
    (value) => {
      state.sessionOut = value
    },
    { immediate: true }
  )

  onMounted(() => {
    store.openCryptoWs()
  })
</script>

<style lang="scss">
  /* Base CSS files */
  @import '@static/css/himalaya_reset.css';
  @import '@static/css/himalaya_login.css';
  @import '@static/css/himalaya_heads.css';
  @import '@static/css/himalaya_style.css';
</style>

<style lang="scss">
  @use '@/static/styles/index.scss';
</style>
