<template>
  <!-- article start -->
  <article id="infopage">
    <h1 class="contents_title" v-if="state.article?.title">
      {{ state.article?.title }}
    </h1>

    <the-loading v-if="state.loading"></the-loading>

    <div
      id="column_body"
      class="boxm"
      v-if="state.article?.body"
      v-html="state.article?.body"
    ></div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { getNotificationDetail } from '@/api/index'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'

  interface Article {
    title: string
    body: string
  }

  const state = reactive({
    article: undefined as Article | undefined,
    loading: true
  })

  const route = useRoute()
  const store = useMainStore()

  const showNotificationDetail = async () => {
    const { contents } = await getNotificationDetail(
      route?.params?.id as string
    )
    state.article = contents

    const breadcrumb: SiteMapType = {
      route: route?.name as string,
      name: contents.title
    }

    store.setBreadcrumb(breadcrumb)
    // eslint-disable-next-line
    document!.title = (state.article?.title as string) + ' | Coin Estate'
    state.loading = false
  }

  onMounted(async () => {
    await showNotificationDetail()
  })
</script>
