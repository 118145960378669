import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import i18n from '@/locale'
import router from '@/router'
import { createHead } from '@vueuse/head'

import TheLoading from '@/components/TheLoading.vue'
import TheMessage from '@/components/TheMessage.vue'
import TheMessageTrade from '@/components/TheMessageTrade.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import { debounceConfig, loadingConfig } from '@/utils/directives'

const head = createHead()
const app = createApp(App)
  .use(createPinia())
  .use(i18n)
  .use(router)
  .use(VueReCaptcha, {
    siteKey: import.meta.env.VITE_APP_GOOGLE_SITE_KEY,
    loaderOptions: {
      useRecaptchaNet: true
    }
  })
  .use(head)
  .component('TheLoading', TheLoading)
  .component('TheMessage', TheMessage)
  .component('TheMessageTrade', TheMessageTrade)

app.directive('debounce', debounceConfig)
app.directive('loading', loadingConfig)

router.isReady().then(() => app.mount('#app'))
