<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="box_set" v-if="language === 'ja'">
        <a
          :href="`${s3Url}resource/account_opening_manual.pdf?v=4`"
          class="step_pdf btn_link_box"
          target="blank"
          ><i class="far fa-file-pdf"></i>
          口座開設から暗号資産購入までの流れ（PDFファイルダウンロード）</a
        >
        <a
          :href="`${s3Url}resource/process_for_submitting_identity_document_online_pc.pdf?v=4`"
          class="step_pdf btn_link_box"
          target="blank"
          ><i class="far fa-file-pdf"></i>
          オンラインで本人確認書類提出のご登録（PCでのご登録）</a
        >
        <a
          :href="`${s3Url}resource/process_for_submitting_identity_document_online_sp.pdf?v=4`"
          class="step_pdf btn_link_box"
          target="blank"
          ><i class="far fa-file-pdf"></i>
          オンラインで本人確認書類提出のご登録（スマートフォンでのご登録）</a
        >
      </div>

      <div class="ablock boxs">
        <div class="bh4 fw_m fc_y">{{
          $t('pages.Beginner.account_opening.area1.title')
        }}</div>
        <div class="flex_w">
          <div class="w2s fdblock flex_c">
            <div class="btxt ta_c"
              ><img src="@static/img/t1.png" alt="t1"
            /></div>
            <div class="fc_y fw_m ta_c mt_10">{{
              $t('pages.Beginner.account_opening.area1.msg1')
            }}</div>
          </div>
          <div class="w2s fdblock flex_c">
            <div class="btxt ta_c"
              ><img src="@static/img/t2.png" alt="t2"
            /></div>
            <div class="fc_y fw_m ta_c mt_10">{{
              $t('pages.Beginner.account_opening.area1.msg2')
            }}</div>
          </div>
        </div>
      </div>

      <div class="ablock boxss w1s">
        <router-link :to="{ name: 'RequiredDocuments' }" class="btn_link"
          ><i class="fas fa-chevron-right"></i>&nbsp;{{
            $t('pages.Beginner.account_opening.area1.link')
          }}</router-link
        >
      </div>
    </div>

    <div class="wblock">
      <div class="bh4 fw_m fc_y">{{
        $t('pages.Beginner.account_opening.area2.title')
      }}</div>
      <div class="step_set"
        ><span class="step_img"
          ><img src="@static/img/step1.png" alt="step1" /></span
        ><div class="step_wrap fdblock"
          ><div class="step_in_img"
            ><div class="whitebox"
              ><img
                :src="`${s3Url}img/page/kaisetsu.${language}.png?v=1`"
                alt="How to open account"
            /></div>
          </div>
          <div class="step_in_txt"
            ><div>
              <div class="bh5 fc_y mt_15">{{
                $t('pages.Beginner.account_opening.area2.step1.title')
              }}</div>
              <p>{{ $t('pages.Beginner.account_opening.area2.step1.msg') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step_set">
      <span class="step_img"
        ><img src="@static/img/step2.png" alt="step2"
      /></span>
      <div class="step_wrap fdblock">
        <div class="step_in_img">
          <div>
            <img
              src="@static/img/upload.png"
              alt="Uploading Identity Verification Documents"
            />
          </div>
        </div>
        <div class="step_in_txt"
          ><div
            ><div class="bh5 fc_y mt_15">{{
              $t('pages.Beginner.account_opening.area2.step2.title')
            }}</div>
            <p>{{ $t('pages.Beginner.account_opening.area2.step2.msg') }}</p>
            <p>
              <router-link
                :to="{ name: 'RequiredDocuments' }"
                class="btn_link boxz"
                ><i class="fas fa-chevron-right"></i>&nbsp;{{
                  $t('pages.Beginner.account_opening.area2.step2.link')
                }}</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="step_cat"
      ><div class="bh5 fc_y">{{
        $t('pages.Beginner.account_opening.area2.step2.subTitle')
      }}</div>
      <p>{{ $t('pages.Beginner.account_opening.area2.step2.subMsg') }}</p>
    </div>
    <div class="step_set"
      ><span class="step_img"
        ><img src="@static/img/step3.png" alt="step3" /></span
      ><div class="step_wrap fdblock"
        ><div class="step_in_img"
          ><div
            ><img
              src="@static/img/code.png"
              alt="Completion of account opening"
          /></div>
        </div>
        <div class="step_in_txt"
          ><div
            ><div class="bh5 fc_y mt_15">{{
              $t('pages.Beginner.account_opening.area2.step3.title')
            }}</div>
            <p>{{ $t('pages.Beginner.account_opening.area2.step3.msg') }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)
</script>
