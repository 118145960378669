<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li></li>
        <li class="is-current">４{{ $t('pages.Corporation.title.step4') }}</li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <h1>{{
        $t('pages.Corporation.step4.confirm', {
          number:
            numberSymbols[router.currentRoute.value.params.multi.toString()]
        })
      }}</h1>
      <step4 :data="state.formJson"></step4>

      <button
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
        v-debounce="{
          func: multiSubmit
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Corporation.step4.multi')
        }"
        type="button"
        v-if="~~state.limit > ~~router.currentRoute.value.params.multi"
        >{{ $t('pages.Corporation.step4.multi') }}</button
      >
      <button
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Corporation.form.next')
        }"
        type="button"
        >{{ $t('pages.Corporation.form.next') }}</button
      >
      <button
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
        v-debounce="{
          func: rollback
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.Corporation.step4.return', {
            number:
              numberSymbols[router.currentRoute.value.params.multi.toString()]
          })
        }"
        type="button"
        >{{
          $t('pages.Corporation.step4.return', {
            number:
              numberSymbols[router.currentRoute.value.params.multi.toString()]
          })
        }}</button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { numberSymbols } from '@/enum/enums'
  import { getShareHolder, setCorporationStatus } from '@/api'
  import { SHARE_HOLDERS_KEY } from '@/@types'
  import Step4 from '@/components/corporation/Step4.vue'

  const router = useRouter()

  const state: {
    init: boolean
    errorMessage: string
    formJson: any
    limit: string
  } = reactive({
    init: false,
    errorMessage: '',
    formJson: {},
    limit: window.localStorage.getItem(SHARE_HOLDERS_KEY) || '1'
  })

  const loading = ref(false)

  const multiSubmit = async () => {
    console.log('multiSubmit')
    try {
      await setCorporationStatus(
        '04',
        router.currentRoute.value.params.multi as string
      )
      router.push({
        name: 'CorporationStep4Input',
        params: {
          multi: ~~router.currentRoute.value.params.multi + 1 + ''
        },
        query: {
          need_input: router.currentRoute.value.query?.need_input || undefined
        }
      })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    }
  }

  const submit = async () => {
    console.log('submit')
    loading.value = true
    try {
      await setCorporationStatus('04', '0')
      if (router.currentRoute.value.query?.need_input?.toString() === '1') {
        router.push({ name: 'CorporationStep8' })
      } else {
        router.push({ name: 'CorporationStep5Input' })
      }
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    }
    loading.value = false
  }

  const rollback = async () => {
    console.log('roll back')
    router.push({
      name: 'CorporationStep4Input',
      params: { multi: router.currentRoute.value.params.multi },
      query: {
        need_input: router.currentRoute.value.query?.need_input || undefined
      }
    })
  }

  onMounted(async () => {
    try {
      const { contents } = await getShareHolder(
        router.currentRoute.value.params.multi as string,
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1' ||
          router.currentRoute.value.query?.need_input?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      state.formJson = Object.assign(state.formJson, contents)
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>
