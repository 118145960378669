<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="is-current">５{{ $t('pages.Corporation.title.step5') }}</li>
        <li></li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Corporation.step5.title') }}</h1>

        <button
          class="uk-button uk-button-primary uk-align-center"
          v-debounce="{ func: resetShareHolder }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.step5.return')
          }"
          type="button"
          v-if="state.back_permission"
          style="margin: 20px auto 50px auto"
          >{{ $t('pages.Corporation.step5.return') }}</button
        >

        <ul class="form-normal">
          <li style="padding-top: 40px; padding-bottom: 0">
            <span class="search-operation">
              <div class="search-operation-left">
                <label class="madd_title"
                  >{{ t('pages.Corporation.form.bank_code') }}
                  <span class="required-notice">{{ $t('form.required') }}</span>
                </label>
                <button
                  class="uk-button uk-button-primary uk-button-small bank-search"
                  type="button"
                  @click="bankSearch"
                  >{{ $t('pages.Corporation.step5.searchButton') }}</button
                >
              </div>

              <span class="search-operation-right"
                >{{ $t('pages.Corporation.step5.msg1') }}
              </span>
            </span>
            <input
              type="text"
              required
              readonly
              disabled
              :value="state.formJson.bank_name"
              class="form-control valid"
            />
            <label
              class="error"
              style="margin-top: -10px"
              v-if="state.validationError.bank"
              >{{
                $t('validation.required', {
                  content: t('pages.Corporation.form.bank_code'),
                  type: t('validation.type.select')
                })
              }}</label
            >
          </li>
          <li style="padding-bottom: 0">
            <span>
              <label class="madd_title"
                >{{ t('pages.Corporation.form.branch_code') }}
                <span class="required-notice">{{ $t('form.required') }}</span>
              </label>
            </span>
            <input
              type="text"
              required
              readonly
              disabled
              :value="state.formJson.branch_name"
              class="form-control valid"
            />
            <label
              class="error"
              style="margin-top: -10px"
              v-if="state.validationError.branch"
              >{{
                $t('validation.required', {
                  content: t('pages.Corporation.form.branch_code'),
                  type: t('validation.type.select')
                })
              }}</label
            >
          </li>
          <SchemaFormWithValidation
            :schema="state.schema"
            :model="state.formJson"
            useCustomFormWrapper
            ref="form"
            class="corporation-form"
            style="padding-top: 0"
          />
        </ul>

        <button
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
          v-debounce="{ func: submit }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.form.submit')
          }"
          type="button"
          >{{ $t('pages.Corporation.form.submit') }}</button
        >
      </form>
    </div>
    <the-modal
      v-if="state.modal"
      id="modal-inquiry"
      hasClose
      @close="state.modal = false"
    >
      <section class="modal-inner" v-if="state.modalStep === '1'">
        <h1>{{ $t('pages.Corporation.step5.search.title1') }}</h1>
        <p>{{ $t('pages.Corporation.step5.search.msg1') }}</p>
        <ul
          class="bank-list"
          v-if="state.bankList && state.bankList.length > 0"
        >
          <li
            v-for="bank in state.bankList"
            :key="bank.key"
            @click="onSelectMajorBank(bank)"
            >{{ bank.value }}</li
          >
        </ul>
        <p>{{ $t('pages.Corporation.step5.search.msg2') }}</p>
        <ul class="bank-list2">
          <template v-for="(i, index) in tagList" :key="index">
            <li
              v-if="i.name"
              @click="searchBank(i.name)"
              :class="{ 'is-selected': state.selectedName === i.name }"
              >{{ i.name }}</li
            >
            <br v-else-if="i.name === undefined" />
            <li v-else></li>
          </template>
        </ul>
        <p>{{ $t('pages.Corporation.step5.search.msg3') }}</p>
        <template v-if="state.searchBankList">
          <select
            class="uk-select color-1e valid"
            style="border-radius: 5px; margin-top: 5px; margin-bottom: 10px"
            v-model="state.selectedBank"
            @change="onSelectBank"
            v-if="Object.keys(state.searchBankList).length > 0"
          >
            <option disabled selected value hidden>{{
              $t('pages.Corporation.step5.search.option1')
            }}</option>
            <option
              v-for="(value, key) of state.searchBankList"
              :key="key"
              :value="key"
              >{{ value }}</option
            >
          </select>
          <p v-else>{{ $t('pages.Corporation.step5.search.error1') }}</p>
        </template>

        <p v-if="state.bank"
          >{{ $t('pages.Corporation.step5.search.bank') }} 【
          {{ state.bank.name }} 】</p
        >
        <button
          v-if="state.bank"
          class="uk-button uk-button-primary"
          type="button"
          style="margin-top: 10px"
          @click="confirmBank"
          >{{ $t('pages.Corporation.step5.search.next') }}</button
        >
      </section>

      <section class="modal-inner" v-if="state.modalStep === '2'">
        <h1>{{ $t('pages.Corporation.step5.search.title2') }}</h1>
        <p v-if="state.bank"
          >{{ $t('pages.Corporation.step5.search.bank') }} 【
          {{ state.bank.name }} 】</p
        >
        <p>{{ $t('pages.Corporation.step5.search.msg4') }}</p>
        <ul class="bank-list2">
          <template v-for="(i, index) in tagList" :key="index">
            <li
              v-if="i.name"
              @click="searchBranch(i.name)"
              :class="{ 'is-selected': state.selectedName === i.name }"
              >{{ i.name }}</li
            >
            <br v-else-if="i.name === undefined" />
            <li v-else></li>
          </template>
        </ul>
        <template v-if="state.searchBranchList">
          <select
            class="uk-select color-1e valid"
            style="border-radius: 5px; margin-top: 5px; margin-bottom: 10px"
            v-model="state.selectedBranch"
            @change="onSelectBranch"
            v-if="Object.keys(state.searchBranchList).length > 0"
          >
            <option disabled selected value hidden>{{
              $t('pages.Corporation.step5.search.option2')
            }}</option>
            <option
              v-for="(value, key) of state.searchBranchList"
              :key="key"
              :value="key"
              >{{ value }}</option
            >
          </select>
          <p v-else>{{ $t('pages.Corporation.step5.search.error2') }}</p>
        </template>

        <p v-if="state.branch"
          >{{ $t('pages.Corporation.step5.search.branch') }} 【
          {{ state.branch.name }} 】</p
        >
        <button
          class="uk-button uk-button-primary"
          type="button"
          style="margin-top: 10px; margin-right: 10px"
          @click="rollbackBank"
          >{{ $t('pages.Corporation.step5.search.return') }}</button
        >
        <button
          v-if="state.bank && state.branch"
          class="uk-button uk-button-primary"
          type="button"
          style="margin-top: 10px"
          @click="confirmBranch"
          >{{ $t('pages.Corporation.step5.search.submit') }}</button
        >
      </section>
    </the-modal>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { addFormStyle, assignForm } from '@/utils/tool'
  import {
    getMajorBankList,
    getBankByName,
    getBranchList,
    setBankAccount,
    getBankInfo,
    selectShareHolder
  } from '@/api'
  import TheModal from '@/components/TheModal.vue'
  import { validator, options } from '@/enum/enums'

  const { t } = useI18n()
  const router = useRouter()

  const tagList = [
    { name: 'あ' },
    { name: 'か' },
    { name: 'さ' },
    { name: 'た' },
    { name: 'な' },
    { name: 'は' },
    { name: 'ま' },
    { name: 'や' },
    { name: 'ら' },
    { name: 'わ' },
    { name: undefined },
    { name: 'い' },
    { name: 'き' },
    { name: 'し' },
    { name: 'ち' },
    { name: 'に' },
    { name: 'ひ' },
    { name: 'み' },
    { name: null },
    { name: 'り' },
    { name: undefined },
    { name: 'う' },
    { name: 'く' },
    { name: 'す' },
    { name: 'つ' },
    { name: 'ぬ' },
    { name: 'ふ' },
    { name: 'む' },
    { name: 'ゆ' },
    { name: 'る' },
    { name: undefined },
    { name: 'え' },
    { name: 'け' },
    { name: 'せ' },
    { name: 'て' },
    { name: 'ね' },
    { name: 'へ' },
    { name: 'め' },
    { name: null },
    { name: 'れ' },
    { name: undefined },
    { name: 'お' },
    { name: 'こ' },
    { name: 'そ' },
    { name: 'と' },
    { name: 'の' },
    { name: 'ほ' },
    { name: 'も' },
    { name: 'よ' },
    { name: 'ろ' }
  ]

  const state: {
    init: boolean
    bankList: Record<string, string>[] | undefined
    searchBankList: Record<string, string>[] | undefined
    searchBranchList: Record<string, string>[] | undefined
    selectedBank: string
    bank: { code: string; name: string } | undefined
    selectedBranch: string
    branch: { code: string; name: string } | undefined
    schema: any
    formJson: any
    errorMessage: string
    validationError: {
      bank: boolean
      branch: boolean
    }
    modal: boolean
    modalStep: string
    back_permission: boolean
    selectedName: string
  } = reactive({
    init: false,
    bankList: undefined,
    searchBankList: undefined,
    searchBranchList: undefined,
    selectedBank: '',
    bank: undefined,
    selectedBranch: '',
    branch: undefined,
    schema: {
      account_type: {
        component: 'Select',
        label: t('pages.Corporation.form.account_type'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.account_type,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.account_type'),
            type: t('validation.type.select')
          })
        )
      },
      account_no: {
        component: 'Text',
        label: t('pages.Corporation.form.account_no'),
        required: true,
        requiredStyle: 'form',
        type: 'number',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.account_no.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.account_no.maxLength
            })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.account_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.account_no.regex as RegExp,
            t('validation.input_error', {
              content: t('pages.Corporation.form.account_no')
            })
          )
      },
      account_holder_kana: {
        component: 'Text',
        label: t('pages.Corporation.form.account_holder_kana'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        additionalNotesShow: true,
        additionalNotes:
          '※金融機関にご登録されている口座名義カナをご入力ください。',
        validations: yup
          .string()
          .trim()
          .max(
            validator.account_kana.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.account_kana.maxLength
            })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.account_holder_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.account_kana.regex as RegExp,
            t('validation.katakana_error')
          )
      }
    },
    formJson: {},
    errorMessage: '',
    validationError: {
      bank: false,
      branch: false
    },
    modal: false,
    modalStep: '1',
    back_permission: false,
    selectedName: ''
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const bankSearch = () => {
    console.log('bankSearch')
    state.modal = true
  }

  const onSelectBank = () => {
    if (state.searchBankList && state.selectedBank) {
      state.bank = {
        code: state.selectedBank,
        name: state.searchBankList[state.selectedBank]
      }
      // clear selected option
      state.selectedName = ''
      state.branch = undefined
      state.selectedBranch = ''
      state.searchBranchList = undefined
    }
  }

  const onSelectMajorBank = (bank: Record<string, string>) => {
    state.searchBankList = undefined
    state.bank = { code: bank.key, name: bank.value }
    state.modalStep = '2'
  }

  const onSelectBranch = () => {
    if (state.searchBranchList && state.selectedBranch) {
      state.branch = {
        code: state.selectedBranch,
        name: state.searchBranchList[state.selectedBranch]
      }
    }
  }

  const searchBank = async (name: string) => {
    state.selectedName = name
    state.bank = undefined
    state.selectedBank = ''
    state.searchBankList = undefined
    const { contents } = await getBankByName(name)
    state.searchBankList = contents
  }

  const searchBranch = async (name: string) => {
    state.selectedName = name
    state.branch = undefined
    state.selectedBranch = ''
    state.searchBranchList = undefined
    if (state.bank?.code) {
      const { contents } = await getBranchList(state.bank?.code, name)
      state.searchBranchList = contents
    }
  }

  const confirmBank = () => {
    console.log('confirmBank')
    state.modalStep = '2'
  }

  const rollbackBank = () => {
    console.log('rollbackBank')
    state.modalStep = '1'
  }

  const confirmBranch = () => {
    console.log('confirmBranch')
    state.modal = false
    state.modalStep = '1'
    state.formJson.bank_code = state.bank?.code
    state.formJson.bank_name = state.bank?.name
    state.formJson.branch_code = state.branch?.code
    state.formJson.branch_name = state.branch?.name
  }

  const resetShareHolder = async () => {
    loading.value = true
    try {
      await selectShareHolder()
      router.push({
        name: 'CorporationStep4Select',
        query: {
          uncheck_flg: router.currentRoute.value.query?.uncheck_flg || undefined
        }
      })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      document.documentElement.scrollTop = 0
    } finally {
      loading.value = false
    }
  }

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        (valid?.meta?.valid ||
          document.getElementsByClassName('error').length === 0) &&
        state.formJson.bank_code &&
        state.formJson.branch_code
      ) {
        loading.value = true
        try {
          await setBankAccount({
            contents: {
              ...state.formJson
            }
          })
          if (
            router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ) {
            router.push({ name: 'CorporationStep8' })
          } else {
            router.push({ name: 'CorporationStep5Confirm' })
          }
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        state.validationError.bank = !state.formJson.bank_code
        state.validationError.branch = !state.formJson.branch_code
        document.documentElement.scrollTop =
          document.getElementsByTagName('form')[0].offsetTop
      }
    }, 0)
  }

  watch(
    () => state.modalStep,
    (step: string) => {
      state.selectedName = ''
      if (step === '1') {
        state.bank = undefined
        state.selectedBank = ''
        state.searchBankList = undefined
      } else {
        state.branch = undefined
        state.selectedBranch = ''
        state.searchBranchList = undefined
      }
    }
  )

  watch(
    () => state.formJson.bank_code,
    (code) => {
      state.validationError.bank = !state.formJson.bank_code
    }
  )

  watch(
    () => state.formJson.branch_code,
    (code) => {
      state.validationError.branch = !state.formJson.branch_code
    }
  )

  onMounted(async () => {
    try {
      const { contents } = await getMajorBankList()
      state.bankList = Object.keys(contents).map((key) => {
        return { key: key, value: contents[key] }
      })
    } catch (error) {
      console.log(error)
    }

    try {
      const data = await getBankInfo(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      state.back_permission = data.contents?.back_permission
      state.formJson = Object.assign(
        state.formJson,
        assignForm(
          {
            ...state.schema,
            ...{
              bank_code: undefined,
              bank_name: undefined,
              branch_code: undefined,
              branch_name: undefined
            }
          },
          data.contents
        )
      )
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })
</script>

<style lang="scss" scoped>
  .poa-download {
    display: block;
    width: 50%;
    margin: 0 auto;
  }

  .bank-search {
    margin-left: 10px;
  }

  .bank-list {
    list-style: none;
    padding: 0;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    margin: 15px -5px;
    font-weight: 500;
    li {
      flex: 0 0 auto;
      padding: 5px 10px;
      cursor: pointer;
      background-color: #febd09;
      border-radius: 5px;
      margin: 5px;
    }
  }

  .bank-list2 {
    list-style: none;
    padding: 0;
    margin: 15px -3px;
    color: #fff;
    font-weight: 500;
    li {
      width: 35px;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
      background-color: #febd09;
      border-radius: 5px;
      display: inline-block;
      margin: 3px;
      &.is-selected {
        background-color: orange;
        box-shadow: inset 2px 2px 1px rgba($color: #000000, $alpha: 0.3);
      }
    }
  }

  .search-operation {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    &-left {
      flex: 0 0 auto;
    }

    &-right {
      flex: 1;
      padding-left: 15px;
      font-size: 0.875rem;
    }
  }

  @media (max-width: 640px) {
    .search-operation-right {
      flex: 0 0 100%;
      padding-left: 0;
      padding-top: 5px;
    }
  }
</style>
