<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="main_contents_text">
        <div class="boxmx inquiry_form">
          <template v-if="language === 'ja'">
            <p
              style="
                font-weight: bold;
                text-align: center;
                margin-bottom: 0.5rem;
              "
              >{{ $t('pages.Support.inquiry.beforeInquiry') }}</p
            >
            <router-link
              :to="{ name: 'FAQ' }"
              class="btn_link"
              style="margin-bottom: 60px"
            >
              <i class="fas fa-chevron-right"></i>&nbsp;{{
                $t('pages.Support.inquiry.faq')
              }}
            </router-link>
          </template>

          <the-message v-if="state.errorMessage">{{
            state.errorMessage
          }}</the-message>
          <form id="inquiryForm" enctype="multipart/form-data" class="">
            <ul class="form-normal">
              <SchemaFormWithValidation
                :schema="state.inquirySchema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
              />

              <!-- UM09-03-01-1 START -->
              <li v-html="$t('pages.Support.inquiry.supportTime')"></li>
            </ul>

            <p>
              {{ $t('pages.Support.inquiry.beforeInput') }}
            </p>
            <p>
              {{ $t('pages.Support.inquiry.agreeToPP') }}
            </p>

            <div class="main_contents_text boxs inquiry_pp">
              <vue-pdf-embed :source="s3_url" />
            </div>
            <label for="ppchk" class="ppchk_box">
              <input
                type="checkbox"
                id="ppchk"
                name="ppchk"
                required
                v-model="state.privacyCheck"
              />{{ $t('pages.Support.inquiry.agreeButton') }}</label
            >

            <button
              type="button"
              id="verifyButton-inquiry"
              class="uk-button user_info_submit uk-button-primary"
              @click="confirm()"
              :disabled="!state.privacyCheck"
            >
              {{ $t('pages.Support.inquiry.confirmButton') }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <the-modal
      v-if="state.modal"
      id="modal-inquiry"
      hasClose
      @close="state.modal = false"
    >
      <section class="modal-inner">
        <h1 v-html="$t('pages.Support.inquiry.confirmTitle')"></h1>
        <dl class="modal_dl">
          <dt id="div_id_inquiry_title">
            <label for="id_inquiry_title" class="col-form-label">
              {{ $t('pages.Support.inquiry.form.items') }}
            </label>
          </dt>
          <dd>
            {{
              state.options.find((v) => v.key == state.formJson.inquiry_type)
                ?.value || $t('form.not_entered')
            }}
          </dd>
          <dt id="div_id_inquiry_name">
            <label for="id_inquiry_name" class="col-form-label">{{
              $t('pages.Support.inquiry.form.name')
            }}</label>
          </dt>
          <dd>
            {{ state.formJson.name || $t('form.not_entered') }}
          </dd>
          <dt id="div_id_inquiry_email">
            <label for="id_inquiry_email" class="col-form-label">
              {{ $t('pages.Support.inquiry.form.email') }}
            </label>
          </dt>
          <dd>
            {{ state.formJson.email || $t('form.not_entered') }}
          </dd>
          <dt id="div_id_inquiry_body">
            <label for="id_inquiry_body" class="col-form-label">
              {{ $t('pages.Support.inquiry.form.contents') }}
            </label>
          </dt>
          <dd>
            <p class="div_id_inquiry_body_text">{{
              state.formJson.contents || $t('form.not_entered')
            }}</p>
          </dd>
          <template v-for="(name, index) in ['', '2', '3', '4']" :key="index">
            <dt class="div_id_inquiry_file">
              <label for="id_inquiry_file1" class="col-form-label">
                {{ $t('pages.Support.inquiry.form.file') }} {{ index + 1 }}
              </label>
            </dt>
            <img
              :src="getImgURL(state.formJson[`file${name}`])"
              alt=""
              v-if="state.formJson[`file${name}`]"
              style="display: block; margin-bottom: 10px"
            />
            <dd class="div_id_inquiry_file_name" v-else>
              {{ $t('form.no_files_attached') }}
            </dd>
          </template>
        </dl>
        <p class="modal_cat">{{ $t('pages.Support.inquiry.confirmTip') }}</p>
        <p class="uk-text-center">
          <button
            class="uk-button uk-button-primary"
            id="confirmButton"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.Support.inquiry.submit')
            }"
            >{{ $t('pages.Support.inquiry.submit') }}</button
          >
        </p>
      </section>
    </the-modal>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { ref, reactive, watch, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { createInquiry } from '@/api'
  import TheModal from '@/components/TheModal.vue'
  import CDMST from '@/enum/cd_mst.json'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import * as yup from 'yup'
  import { useI18n } from 'vue-i18n'
  import { CDMSTType } from '@/@types'
  import { useReCaptcha } from 'vue-recaptcha-v3'
  import VuePdfEmbed from 'vue-pdf-embed'

  const recaptchaInstance = useReCaptcha()
  const route = useRoute()
  const router = useRouter()
  const store = useMainStore()
  const { language, isLogin, userInfo } = storeToRefs(store)
  const { t } = useI18n()
  const loading = ref(false)

  const TheENUM = CDMST as CDMSTType
  const s3_url =
    import.meta.env.VITE_APP_S3_URL +
    'resource/personal_information_protection_policy_ja.pdf?v=20241223_01'

  const state: {
    privacyCheck: boolean
    modal: boolean
    options: {
      key: string
      value: string
    }[]
    formJson: any
    inquirySchema: any
    googleToken: string
    isLogin: boolean
    errorMessage: string
  } = reactive({
    privacyCheck: false,
    modal: false,
    options: [],
    formJson: {},
    inquirySchema: {},
    googleToken: '',
    isLogin: false,
    errorMessage: ''
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  state.inquirySchema = {
    inquiry_type: {
      component: 'Select',
      label: t('pages.Support.inquiry.form.items'),
      required: true,
      placeholders: t('pages.Support.inquiry.form.itemsPlaceholder'),
      options: state.options,
      validations: yup.string().required(
        t('validation.required', {
          content: t('validation.item.inquiry_items'),
          type: t('validation.type.select')
        })
      ),
      additionalNotes: '',
      additionalNotesShow: false
    },
    name: {
      component: 'Text',
      label: t('pages.Support.inquiry.form.name'),
      required: true,
      readonly: false,
      validations: yup
        .string()
        .trim()
        .required(
          t('validation.required', {
            content: t('validation.item.name'),
            type: t('validation.type.input')
          })
        )
    },
    email: {
      component: 'Text',
      label: t('pages.Support.inquiry.form.email'),
      required: true,
      type: 'email',
      readonly: false,
      validations: yup
        .string()
        .trim()
        .required(
          t('validation.required', {
            content: t('validation.item.email'),
            type: t('validation.type.input')
          })
        )
        .email(
          t('validation.input_error', {
            content: t('validation.item.email')
          })
        )
    },
    contents: {
      component: 'Textarea',
      label: t('pages.Support.inquiry.form.contents'),
      required: true,
      type: 'textarea',
      validations: yup
        .string()
        .trim()
        .required(
          t('validation.required', {
            content: t('validation.item.inquiry_contents'),
            type: t('validation.type.input')
          })
        )
    },
    file: {
      component: 'File',
      label: t('pages.Support.inquiry.form.file1'),
      validations: yup
        .mixed()
        .test('is-big-file', t('validation.is_big_file'), checkIfFilesAreTooBig)
    },
    file2: {
      component: 'File',
      label: t('pages.Support.inquiry.form.file2'),
      validations: yup
        .mixed()
        .test('is-big-file', t('validation.is_big_file'), checkIfFilesAreTooBig)
    },
    file3: {
      component: 'File',
      label: t('pages.Support.inquiry.form.file3'),
      validations: yup
        .mixed()
        .test('is-big-file', t('validation.is_big_file'), checkIfFilesAreTooBig)
    },
    file4: {
      component: 'File',
      label: t('pages.Support.inquiry.form.file4'),
      validations: yup
        .mixed()
        .test('is-big-file', t('validation.is_big_file'), checkIfFilesAreTooBig)
    }
  }

  function checkIfFilesAreTooBig(files?: [File]): boolean {
    let valid = true
    if (files) {
      const size = files[0].size / 1024 / 1024
      if (size > 5) {
        valid = false
      }
    }
    return valid
  }

  const confirm = async () => {
    const valid = form.value?.validate()

    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const submit = async () => {
    loading.value = true

    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha('inquiry')
    state.googleToken = token || ''

    try {
      await createInquiry({
        contents: {
          ...state.formJson,
          language: language.value,
          'g-recaptcha-response': state.googleToken
        }
      })
      router.push({ name: 'InquirySuccess' })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
      state.modal = false
      document.documentElement.scrollTop = 0
    }
    loading.value = false
  }

  const getImgURL = (file: FileList) => {
    return URL.createObjectURL(file[0])
  }

  const getOriginalOptions = () => {
    // option初期値設定
    const options = TheENUM.contents['10009'].results
    Object.keys(options)
      .sort(
        (a, b) =>
          parseInt(options[a].key2 as string) -
          parseInt(options[b].key2 as string)
      )
      .forEach((v) => {
        state.options.push({
          key: v,
          value: t(`form.options.inquiry_${options[v].key1}`)
        })
      })
  }

  // ログイン状態をチェック
  watch(
    () => isLogin.value,
    (isLoggedIn) => {
      // ログインの場合
      state.isLogin = isLoggedIn ?? false
      state.inquirySchema.name.readonly = isLoggedIn ?? false
      state.inquirySchema.email.readonly = isLoggedIn ?? false
    },
    { immediate: true }
  )
  // ログインの場合、ユーザー情報を取得
  watch(
    () => userInfo.value,
    (user) => {
      // ログインの場合
      state.formJson.name = user.name
      state.formJson.email = user.email
    },
    { immediate: true }
  )
  watch(
    () => state.formJson.inquiry_type,
    (type) => {
      // 08: 口座解約について, 10: 本人確認書類の提出
      state.inquirySchema.inquiry_type.additionalNotesShow =
        type === '08' || type === '10' || type === '02'
      if (type === '08') {
        state.inquirySchema.inquiry_type.additionalNotes = state.isLogin
          ? t('pages.Support.inquiry.loggedInCancellation')
          : t('pages.Support.inquiry.notLoggedInCancellation')
      } else if (type === '10') {
        state.inquirySchema.inquiry_type.additionalNotes = state.isLogin
          ? ''
          : t('pages.Support.inquiry.notLoggedInRequirement')
      } else if (type === '02') {
        state.inquirySchema.inquiry_type.additionalNotes = t(
          'pages.Support.inquiry.pwdForget'
        )
      } else {
        state.inquirySchema.inquiry_type.additionalNotes = ''
      }
    },
    { immediate: true }
  )

  onMounted(async () => {
    getOriginalOptions()
    // URLから設定値を取得
    const default_inquiry_type = route?.params?.inquiry_type_id
    if (default_inquiry_type) {
      state.formJson.inquiry_type = default_inquiry_type
    }
  })
</script>

<style lang="scss" scoped>
  input[disabled],
  textarea[disabled],
  input[readonly],
  textarea[readonly],
  button[disabled] {
    background-color: rgb(235, 235, 228) !important;
  }

  .inquiry_pp {
    overflow: scroll;
  }
</style>
