/*
任意のトークンを作成
 */
const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
export const make_token = (value: number) => {
  return Array.from(Array(value))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join('')
}

/*
文字列の先頭のみ大文字に変換
*/
export const capitalize = function (str: string) {
  if (typeof str !== 'string' || !str) return str
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
