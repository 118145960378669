<template>
  <!-- article start -->
  <article id="new_account">
    <h1 class="contents_title">{{
      $router.currentRoute?.value?.meta?.title
    }}</h1>
    <form id="loginForm" class="login">
      <the-message v-if="state.errorMessage">{{
        state.errorMessage
      }}</the-message>

      <SchemaFormWithValidation
        :schema="state.inquirySchema"
        :model="state.formJson"
        useCustomFormWrapper
        ref="form"
      />

      <button
        class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction signin-button"
        id="btn_pw_modify"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: loading,
          value: $t('pages.PwModify.form.submit')
        }"
        type="button"
        >{{ $t('pages.PwModify.form.submit') }}</button
      >

      <p class="p_small">{{ $t('pages.PwModify.msg') }}</p>
    </form>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { pwModify, decodeEmail } from '@/api'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'
  import { hasCallbackUrl } from '@/utils/tool'
  import { useReCaptcha } from 'vue-recaptcha-v3'

  const recaptchaInstance = useReCaptcha()
  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { isRecaptchaRequired } = storeToRefs(store)

  const loading = ref(false)
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const state: {
    googleToken: string
    inquirySchema: any
    formJson: any
    errorMessage: string
  } = reactive({
    googleToken: '',
    inquirySchema: {
      email: {
        component: 'Text',
        label: t('pages.PwModify.form.email'),
        required: true,
        type: 'email',
        readonly: false,
        inputClass: 'form-control valid',
        placeholder: 'メールアドレス',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.email'),
              type: t('validation.type.input')
            })
          )
          .email(
            t('validation.input_error', {
              content: t('validation.item.email')
            })
          )
      },
      password: {
        component: 'Text',
        label: t('pages.PwModify.form.password'),
        required: true,
        type: 'password',
        inputClass: 'form-control valid',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.password'),
              type: t('validation.type.input')
            })
          )
      },
      re_password: {
        component: 'Text',
        label: t('pages.PwModify.form.re_password'),
        required: true,
        type: 'password',
        inputClass: 'form-control valid',
        passwordLevel: true,
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.password'),
              type: t('validation.type.input')
            })
          )
          .matches(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{14,128}/,
            t('validation.password')
          )
      },
      re_password_confirm: {
        component: 'Text',
        label: t('pages.PwModify.form.re_password_confirm'),
        required: true,
        type: 'password',
        inputClass: 'form-control valid',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.password'),
              type: t('validation.type.input')
            })
          )
          .matches(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{14,128}/,
            t('validation.password')
          )
      }
    },
    formJson: {},
    errorMessage: ''
  })

  const getEmail = async (): Promise<string> => {
    const { contents } = await decodeEmail(
      router.currentRoute.value.params.email_encode as string
    )
    return contents?.text
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    await recaptchaInstance?.recaptchaLoaded()
    const token = await recaptchaInstance?.executeRecaptcha('pw_modify')
    state.googleToken = token || ''
    if (!state.googleToken && isRecaptchaRequired.value) {
      state.errorMessage = t('validation.reCAPTCHA')
      return
    }

    if (state.formJson.re_password !== state.formJson.re_password_confirm) {
      state.errorMessage = t('validation.password_confirm')
      return
    }

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          const { contents } = await pwModify(
            {
              contents: {
                ...state.formJson,
                'g-recaptcha-response': state.googleToken
              }
            },
            router.currentRoute.value.params.param_key as string,
            router.currentRoute.value.params.email_encode as string
          )

          if (!contents?.callback_url) {
            router.push({
              name: 'Home'
            })
            return
          }
          hasCallbackUrl(contents?.callback_url)
        } catch (error: any) {
          state.errorMessage =
            error.error_translations || error.message_detail || error
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      }
    }, 0)
  }

  onMounted(async () => {
    const breadcrumb: SiteMapType = {
      route: router.currentRoute.value.name as string,
      name: router.currentRoute.value.meta.title
    }
    store.setBreadcrumb(breadcrumb)

    state.formJson.email = await getEmail()
    state.inquirySchema.email.readonly = true
  })
</script>

<style lang="scss">
  #loginForm {
    .error {
      margin-top: -0.5rem;
    }
    .madd_title {
      display: block;
    }
  }
</style>
