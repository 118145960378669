<template>
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh4 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <the-loading v-if="state.loading"></the-loading>
      <div
        id="column_body"
        class="boxm"
        v-html="state.career?.body"
        v-if="state.career"
      ></div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { getCareer } from '@/api'
  import { reactive, onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { useRouter } from 'vue-router'
  import { SiteMapType } from '@/@types'

  const store = useMainStore()
  const { siteMap, language } = storeToRefs(store)
  const router = useRouter()

  const state: {
    career:
      | {
          body: string
        }
      | undefined
    loading: boolean
  } = reactive({
    career: undefined,
    loading: true
  })

  onMounted(async () => {
    const company = siteMap.value.find(
      (i: SiteMapType) => i.route === 'Company'
    )
    const career = company?.children?.find(
      (i: SiteMapType) => i.route === 'Career'
    )
    if (!career) {
      router.replace({ name: '404' })
      return
    }
    if (language.value === 'ja' && career.ja_hide) {
      router.replace({ name: '404' })
      return
    }
    if (language.value === 'en' && career.en_hide) {
      router.replace({ name: '404' })
      return
    }
    if (language.value === 'cn' && career.cn_hide) {
      router.replace({ name: '404' })
      return
    }

    const { contents } = await getCareer()
    state.career = contents
    state.loading = false
  })
</script>
