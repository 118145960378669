<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh4 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>
      <div class="bh3 fw_m fc_y">{{
        $t('pages.Service.corporation.title1')
      }}</div>

      <div class="bh4 fw_m">{{
        $t('pages.Service.corporation.subTitle1')
      }}</div>
      <div class="boxs ta_c">
        {{ $t('pages.Service.corporation.msg1') }}
      </div>
    </div>

    <div class="fdblockw"
      ><div class="boxs">
        <img
          :src="`${s3Url}img/page/top_figure.${language}.png?v=1`"
          alt="top_figure" /></div
    ></div>

    <div class="topset3">
      <div class="ablock mt_40 mb_40">
        <div class="bh4 fw_m fc_y">{{
          $t('pages.Service.corporation.title2')
        }}</div>
        <div class="flex_w">
          <div class="w4s fdblock flex_c">
            <div class="btxt ta_c">{{
              $t('pages.Service.corporation.msg2_1')
            }}</div>
          </div>
          <div class="w4s fdblock flex_c">
            <div class="btxt ta_c">
              {{ $t('pages.Service.corporation.msg2_2') }}
            </div>
          </div>
          <div class="w4s fdblock flex_c">
            <div class="btxt ta_c">
              {{ $t('pages.Service.corporation.msg2_3') }}
            </div>
          </div>
          <div class="w4s fdblock flex_c">
            <div class="btxt ta_c">{{
              $t('pages.Service.corporation.msg2_4')
            }}</div>
          </div>
        </div>
      </div>

      <div class="bh4 fw_m fc_y">{{
        $t('pages.Service.corporation.title3')
      }}</div>

      <div class="flex_w mb_25">
        <div class="w1s fdblock flex_c">
          <div class="btxt ta_c">
            <div class="bh5 fw_m">{{
              $t('pages.Service.corporation.subTitle3_1')
            }}</div>
            <div class="btxt ta_c">
              {{ $t('pages.Service.corporation.msg3_1') }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex_w mb_25">
        <div class="w1s fdblock flex_c">
          <div class="btxt ta_c">
            <div class="bh5 fw_m">{{
              $t('pages.Service.corporation.subTitle3_2')
            }}</div>
            <div class="btxt ta_c">
              {{ $t('pages.Service.corporation.msg3_2') }}
            </div>
            <div class="cat_red">
              {{ $t('pages.Service.corporation.warning3_2') }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex_w mb_40">
        <div class="w1s fdblock flex_c">
          <div class="btxt ta_c">
            <div class="bh5 fw_m">{{
              $t('pages.Service.corporation.subTitle3_3')
            }}</div>
            <div class="btxt ta_c">
              {{ $t('pages.Service.corporation.msg3_3') }}
            </div>
          </div>
        </div>
      </div>

      <div class="ablock">
        <div class="bh5 mb_35 fw_m fc_y">{{
          $t('pages.Service.corporation.title4')
        }}</div>
        <div class="imgbox">
          <img
            :src="`${s3Url}img/page/buttom_figure.${language}.png?v=1`"
            alt="figure"
          />
        </div>
        <div class="flex_w mb_40">
          <div class="w1s fdblock flex_c">
            <div class="btxt ta_c">
              <div class="btxt ta_c">
                {{ $t('pages.Service.corporation.msg4') }}
                <br />
                <br />
                <br />
                <div class="boxs">
                  <router-link :to="{ name: 'Inquiry' }" class="btn_link"
                    ><i class="fas fa-chevron-right"></i
                    ><span
                      v-html="$t('pages.Service.corporation.inquiry')"
                    ></span
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)
</script>
