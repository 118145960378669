<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="main_contents_text boxs">
        <div class="main_contents_head_sp"></div>

        <div class="policy_inner ethics">
          <p>{{ $t('pages.Policy.ConflictInterest.msg') }}</p>

          <h2 style="text-align: left"
            >1.{{ $t('pages.Policy.ConflictInterest.msg1') }}</h2
          >
          <p>{{ $t('pages.Policy.ConflictInterest.msg1_1') }}</p>

          <table class="fee_table" border="1px" style="border: 1px solid #ccc">
            <tbody
              ><tr class="">
                <td class="fee_th">{{
                  $t('pages.Policy.ConflictInterest.msg1_th1')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_th2')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_th3')
                }}</td>
              </tr>
              <tr class="">
                <td class="fee_th">{{
                  $t('pages.Policy.ConflictInterest.msg1_td1_1')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_td1_2')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_td1_3')
                }}</td>
              </tr>
              <tr class="">
                <td class="fee_th">{{
                  $t('pages.Policy.ConflictInterest.msg1_td2_1')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_td2_2')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_td2_3')
                }}</td>
              </tr>
              <tr class="">
                <td class="fee_th">{{
                  $t('pages.Policy.ConflictInterest.msg1_td3_1')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_td3_2')
                }}</td>
                <td class="fee_td2">{{
                  $t('pages.Policy.ConflictInterest.msg1_td3_3')
                }}</td>
              </tr>
            </tbody></table
          >

          <h2 style="text-align: left"
            >2.{{ $t('pages.Policy.ConflictInterest.msg2') }}</h2
          >
          <p>{{ $t('pages.Policy.ConflictInterest.msg2_1') }}</p>
          <ul>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg2_1_1')
            }}</li>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg2_1_2')
            }}</li>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg2_1_3')
            }}</li>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg2_1_4')
            }}</li>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg2_1_5')
            }}</li>
          </ul>

          <h2 style="text-align: left"
            >3.{{ $t('pages.Policy.ConflictInterest.msg3') }}</h2
          >
          <p>{{ $t('pages.Policy.ConflictInterest.msg3_1') }}</p>
          <ul>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg3_1_1')
            }}</li>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg3_1_2')
            }}</li>
            <li style="margin-left: 2.5em; text-indent: -2.5em">{{
              $t('pages.Policy.ConflictInterest.msg3_1_3')
            }}</li>
            <li style="margin-left: 2.5em; text-indent: -2.5em"></li>
          </ul>

          <h2 style="text-align: left"
            >4.{{ $t('pages.Policy.ConflictInterest.msg4') }}</h2
          >
          <p>{{ $t('pages.Policy.ConflictInterest.msg4_1') }}</p>

          <h2 style="text-align: left"
            >5.{{ $t('pages.Policy.ConflictInterest.msg5') }}</h2
          >
          <p>{{ $t('pages.Policy.ConflictInterest.msg5_1') }}</p>
          <p style="color: #f44" v-if="language !== 'ja'"
            ><br />{{ $t('language_warning') }}</p
          >
          <p style="text-align: right">{{
            $t('pages.Policy.ConflictInterest.end')
          }}</p>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
