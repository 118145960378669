<template>
  <div class="main_contents boxm">
    <div class="main_contents_list" v-if="state.tags.length > 0">
      <div class="column_tags">
        <h4>タグ一覧</h4>
        <div class="column_taglist">
          <router-link
            :to="{ name: 'Tag', params: { name: tag.name } }"
            v-for="tag in state.tags"
            :key="tag.name"
            ><i class="fas fa-tag"></i>&nbsp;{{ tag.name }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { getTags } from '@/api'
  import { reactive, onBeforeMount } from 'vue'

  const props = defineProps({
    silent: {
      type: Boolean,
      default: false
    }
  })

  const state: {
    tags: {
      name: string
    }[]
    loading: boolean
  } = reactive({
    tags: [],
    loading: true
  })

  onBeforeMount(async () => {
    const { contents } = await getTags()
    state.tags = contents
    state.loading = false
  })
</script>
<style lang="scss" scoped>
  .column_tags {
    margin-top: 0;
  }
</style>
