<template>
  <!-- fixed area -->
  <transition name="fade">
    <div v-show="state.show">
      <a
        href="javascript:void(0);"
        id="scroll"
        title="Scroll to Top"
        @click="scrollTop"
      >
        Top
        <span></span>
      </a>
    </div>
  </transition>
  <!-- fixed area -->
</template>

<script lang="ts" setup>
  import { onMounted, reactive } from 'vue'

  const state = reactive({
    show: false
  })

  const showButton = () => {
    if (
      !!document.documentElement.scrollTop &&
      document.documentElement.scrollTop > 100 &&
      document.documentElement.scrollTop <
        document.documentElement.scrollHeight - 1300
    ) {
      state.show = true
    } else {
      state.show = false
    }
  }

  const scrollTop = () => {
    document.documentElement.scrollTop = 0
  }

  onMounted(() => {
    window.addEventListener('scroll', showButton)
  })
</script>

<style lang="scss" scoped>
  .existing_customer img {
    width: 130px;
  }
  #scroll {
    display: block;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s linear;
    opacity: 1;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
