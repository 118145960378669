<template>
  <the-message
    v-if="state.errorMessage"
    class="form-error"
    style="margin-top: 20px"
    >{{ state.errorMessage }}</the-message
  >
  <div class="individual">
    <div class="individual-wrapper">
      <p class="individual-redirect-text">{{
        $t('pages.Individual.step2.redirect.msg')
      }}</p>
      <router-link
        :to="{ name: 'Home' }"
        class="uk-button uk-button-primary uk-margin-small-bottom individual-redirect-link"
        >{{ $t('pages.Individual.step2.redirect.link') }}</router-link
      >
    </div>
  </div>
</template>
<script setup lang="ts">
  import { onMounted, reactive } from 'vue'
  import { isTwoStepSuccess } from '@/api'
  import { useRouter } from 'vue-router'

  const state: {
    errorMessage: string
  } = reactive({
    errorMessage: ''
  })
  const router = useRouter()

  onMounted(async () => {
    try {
      await isTwoStepSuccess(
        router.currentRoute.value.params.id as string,
        router.currentRoute.value.query
      )
      router.push({ name: 'IndividualStep3' })
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped>
  .individual-redirect {
    &-text {
      text-align: center;
      font-size: 1.125rem;
      margin: 40px auto;
    }
    &-link {
      display: block;
      width: 30%;
      min-width: 200px;
      margin: 60px auto 10px auto;
    }
  }
</style>
