<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="is-current br-tl-6 br-bl-6"
          >１{{ $t('pages.Corporation.title.step1') }}</li
        >
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <form>
        <h1>{{ $t('pages.Corporation.title.step1') }}</h1>
        <p>{{ $t('pages.Corporation.step1.msg1') }}</p>
        <p v-html="$t('pages.Corporation.step1.msg2')"></p>
        <a
          :href="`${s3Url}resource/power_of_attorney.pdf?v=1`"
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom poa-download"
          type="button"
          target="_blank"
          >{{ $t('pages.Corporation.form.download') }}
        </a>

        <ul class="form-normal">
          <SchemaFormWithValidation
            :schema="state.schema"
            :model="state.formJson"
            useCustomFormWrapper
            ref="form"
            class="corporation-form"
          />
        </ul>

        <button
          class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
          v-debounce="{
            func: submit
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.form.submit')
          }"
          type="button"
          >{{ $t('pages.Corporation.form.submit') }}</button
        >
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import {
    addFormStyle,
    checkDate,
    assignForm,
    watchOptions,
    insertStr
  } from '@/utils/tool'
  import { validator, options } from '@/enum/enums'
  import {
    getCorporationBasic,
    setCorporationBasic,
    getAddrByPostCode
  } from '@/api'

  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { s3Url } = storeToRefs(store)

  const state: {
    init: boolean
    schema: any
    formJson: any
    errorMessage: string
  } = reactive({
    init: false,
    schema: {
      name_kanji: {
        component: 'Text',
        label: t('pages.Corporation.form.name_kanji'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.name_kanji'),
              type: t('validation.type.input')
            })
          )
      },
      name_kana: {
        component: 'Text',
        label: t('pages.Corporation.form.name_kana'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        additionalNotesShow: true,
        additionalNotes: t('validation.zen_kana_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.name_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_katakana.regex as RegExp,
            t('validation.katakana_error')
          )
      },
      corporation_no: {
        component: 'Text',
        label: t('pages.Corporation.form.corporation_no'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'flex-1-1 has-addition',
        additionalNotesShow: true,
        additionalNotes: t('pages.Corporation.form.corporation_no_warning'),
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.corporation_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.co_number.regex as RegExp,
            t('validation.hankaku_number_error', {
              number: validator.co_number.maxLength
            })
          )
      },
      post_no: {
        component: 'Text',
        label: t('pages.Corporation.form.post_no'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'flex-1-1',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.post_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.post_no.regex as RegExp,
            t('validation.hankaku_number_error', {
              number: validator.post_no.maxLength
            })
          )
      },
      addr1: {
        component: 'Text',
        label: t('pages.Corporation.form.addr1'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr1.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr1.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.addr1'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr1.regex as RegExp,
            t('validation.zenkaku_error')
          )
      },
      addr2: {
        component: 'Text',
        label: t('pages.Corporation.form.addr2'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr2.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr2.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.addr2'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr2.regex as RegExp,
            t('validation.zenkaku_error')
          )
      },
      addr3: {
        component: 'Text',
        label: t('pages.Corporation.form.addr3'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        additionalNotesShow: true,
        additionalNotes: t('validation.zen_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr3.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr3.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.addr3'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr3.regex as RegExp,
            t('validation.zenkaku_error')
          )
      },
      phone_no: {
        component: 'Text',
        label: t('pages.Corporation.form.rep_phone_no'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.rep_phone_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.rep_phone_no.regex as RegExp,
            t('validation.corporation.phone_no_error')
          )
      },
      establishment_date: {
        component: 'Text',
        type: 'text',
        label: t('pages.Corporation.form.establishment_date'),
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        additionalNotesShow: true,
        additionalNotes: t('pages.Corporation.form.establishment_date_warning'),
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.establishment_date'),
              type: t('validation.type.input')
            })
          )
          .test('is-date', t('validation.date_error'), checkDate)
      },
      capital: {
        component: 'Text',
        label: t('pages.Corporation.form.capital'),
        required: true,
        requiredStyle: 'form',
        type: 'number',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        positive: true,
        validations: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.capital'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.capital.max as number,
            t('validation.maxLength', {
              maxLength: validator.capital.maxLength
            })
          )
          .positive(t('validation.integer'))
          .integer(t('validation.integer'))
      },
      industry: {
        component: 'Text',
        label: t('pages.Corporation.form.industry'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.industry'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.industry.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.industry.maxLength
            })
          )
      },
      final_settlement_date: {
        component: 'Text',
        label: t('pages.Corporation.form.final_settlement_date'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'flex-1-1',
        additionalNotesShow: true,
        additionalNotes: '※4桁数字(MMDD)入力をお願いいたします。',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.final_settlement_date'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.final_settlement_date.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.final_settlement_date.maxLength
            })
          )
          .matches(
            validator.final_settlement_date.regex as RegExp,
            t('validation.month_error')
          )
      },
      business_contents: {
        component: 'Text',
        label: t('pages.Corporation.form.business_contents'),
        required: true,
        requiredStyle: 'form',
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.business_contents'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.business_contents.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.business_contents.maxLength
            })
          )
      },
      tax_type: {
        component: 'Select',
        label: t('pages.Corporation.form.tax_type'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.tax_type,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.tax_type'),
            type: t('validation.type.select')
          })
        )
      },
      listed_company: {
        component: 'Select',
        label: t('pages.Corporation.form.listed_company'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.yesOrNo,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.listed_company'),
            type: t('validation.type.select')
          })
        )
      },
      domestic_corporation: {
        component: 'Select',
        label: t('pages.Corporation.form.domestic_corporation'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.yesOrNo.reverse(),
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.domestic_corporation'),
            type: t('validation.type.select')
          })
        )
      },
      us_tax_obligation: {
        component: 'Select',
        label: t('pages.Corporation.form.us_tax_obligation'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.withOrWithout,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.us_tax_obligation'),
            type: t('validation.type.select')
          })
        )
      },
      crypto_holding: {
        component: 'Select',
        label: t('pages.Corporation.form.crypto_holding'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.withOrWithout.reverse(),
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.crypto_holding'),
            type: t('validation.type.select')
          })
        )
      },
      crypto_held_text: {
        component: 'Text',
        label: t('pages.Corporation.form.crypto_held_text'),
        type: 'text',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        show: false,
        validations: undefined
      },
      financial_status_date: {
        component: 'Text',
        type: 'text',
        label: t('pages.Corporation.form.financial_status_date'),
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        additionalNotesShow: true,
        additionalNotes: t(
          'pages.Corporation.form.financial_status_date_warning'
        ),
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.financial_status_date'),
              type: t('validation.type.input')
            })
          )
          .test('is-date', t('validation.date_error'), checkDate)
      },
      financial_asset: {
        component: 'Select',
        label: t('pages.Corporation.form.financial_asset'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.financial_asset_corporation,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.financial_asset'),
            type: t('validation.type.select')
          })
        )
      },
      liabilities_asset: {
        component: 'Select',
        label: t('pages.Corporation.form.liabilities_asset'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.liabilities_asset,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.liabilities_asset'),
            type: t('validation.type.select')
          })
        )
      },
      annual_sales: {
        component: 'Select',
        label: t('pages.Corporation.form.annual_sales'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.annual_sales,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.annual_sales'),
            type: t('validation.type.select')
          })
        )
      },
      nature_funds: {
        component: 'Select',
        label: t('pages.Corporation.form.nature_funds'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.nature_funds,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.nature_funds'),
            type: t('validation.type.select')
          })
        )
      },
      nature_funds_other: {
        component: 'Text',
        label: t('pages.Corporation.form.nature_funds_other'),
        placeholders: '',
        type: 'text',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        show: false,
        validations: undefined
      },
      overseas_bases: {
        component: 'Select',
        label: t('pages.Corporation.form.overseas_bases'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.withOrWithout,
        liClass: 'is-half',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Corporation.form.overseas_bases'),
            type: t('validation.type.select')
          })
        )
      },
      overseas_bases_name: {
        component: 'Text',
        label: t('pages.Corporation.form.overseas_bases_name'),
        type: 'text',
        inputClass: 'form-control valid',
        liClass: 'is-half',
        requiredStyle: 'form',
        show: false,
        validations: undefined
      },
      homepage: {
        component: 'Text',
        label: t('pages.Corporation.form.homepage'),
        type: 'text',
        inputClass: 'form-control valid',
        placeholder: '',
        validations: yup.string().max(
          validator.homepage.maxLength as number,
          t('validation.maxLength', {
            maxLength: validator.homepage.maxLength
          })
        )
      }
    },
    formJson: {},
    errorMessage: ''
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          const formData = Object.assign({}, state.formJson)
          formData.establishment_date = insertStr(
            insertStr(formData.establishment_date, 4, '-'),
            7,
            '-'
          )
          formData.financial_status_date = insertStr(
            insertStr(formData.financial_status_date, 4, '-'),
            7,
            '-'
          )
          await setCorporationBasic({
            contents: {
              ...formData
            }
          })
          if (
            router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ) {
            router.push({ name: 'CorporationStep8' })
          } else {
            router.push({ name: 'CorporationStep1Confirm' })
          }
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop =
          document.getElementsByTagName('form')[0].offsetTop
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getCorporationBasic(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      state.formJson = Object.assign(
        state.formJson,
        assignForm(state.schema, contents)
      )
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => state.formJson.nature_funds,
    async (value: string) => {
      watchOptions(
        value,
        '99',
        'nature_funds_other',
        state.schema,
        state.formJson,
        yup,
        t
      )
    },
    { immediate: true }
  )

  watch(
    () => state.formJson.crypto_holding,
    async (value: string) => {
      watchOptions(
        value,
        '1',
        'crypto_held_text',
        state.schema,
        state.formJson,
        yup,
        t
      )
    },
    { immediate: true }
  )

  watch(
    () => state.formJson.overseas_bases,
    async (value: string) => {
      watchOptions(
        value,
        '1',
        'overseas_bases_name',
        state.schema,
        state.formJson,
        yup,
        t
      )
    },
    { immediate: true }
  )

  watch(
    () => state.formJson.post_no,
    async (value: string) => {
      if (validator.post_no?.regex?.exec(value)) {
        const { contents } = await getAddrByPostCode(value)
        if (contents?.prefecture) state.formJson.addr1 = contents?.prefecture
        if (contents?.city) state.formJson.addr2 = contents?.city
        if (contents?.town)
          state.formJson.addr2 = state.formJson.addr2 + contents?.town
      }
    }
  )
</script>

<style lang="scss" scoped>
  .poa-download {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
</style>
