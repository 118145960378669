<template>
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="main_contents boxm">
        <div class="bh4 mt_35 fc_y fw_m">{{
          $t('pages.Support.inquiry.success.title')
        }}</div>
        <div class="main_contents_text boxs">
          <p>{{ $t('pages.Support.inquiry.success.msg1') }}</p>
          <div class="main_contents_head_sp"></div>
          <p>{{ $t('pages.Support.inquiry.success.msg2') }}</p>
          <p>{{ $t('pages.Support.inquiry.success.msg3') }}</p>
          <div class="main_contents_very_sp"></div>
          <div class="btn_set">
            <router-link
              v-if="!isLogin"
              :to="{ name: 'Landing' }"
              class="btn_link"
              ><i class="fas fa-chevron-right"></i>&nbsp;{{
                $t('pages.Support.inquiry.success.link')
              }}</router-link
            >
            <router-link v-else :to="{ name: 'Home' }" class="btn_link"
              ><i class="fas fa-chevron-right"></i>&nbsp;{{
                $t('pages.Support.inquiry.success.link')
              }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { isLogin } = storeToRefs(store)
</script>
