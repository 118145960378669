<template>
  <SchemaFormWithValidation :schema="props.schema" ref="schemaForm" />
</template>

<script lang="ts" setup>
  import { SchemaFormFactory, useSchemaForm } from 'formvuelate'
  import Text from '@/components/fields/Text.vue'
  import Radio from '@/components/fields/Radio.vue'
  import Checkbox from '@/components/fields/Checkbox.vue'
  import Date from '@/components/fields/Date.vue'
  import Textarea from '@/components/fields/Textarea.vue'
  import Select from '@/components/fields/Select.vue'
  import File from '@/components/fields/File.vue'
  import Others from '@/components/fields/Others.vue'
  import VeeValidatePlugin from '@/utils/VeeValidatePlugin'
  import { ref, provide } from 'vue'

  const SchemaFormWithValidation = SchemaFormFactory([VeeValidatePlugin()], {
    Text,
    Radio,
    Checkbox,
    Date,
    Textarea,
    Select,
    File,
    Others
  })

  const schemaForm = ref<InstanceType<typeof SchemaFormWithValidation> | null>(
    null
  )

  const needValidation = ref('')
  provide('needValidation', needValidation)

  const validate = () => {
    needValidation.value += '0'

    let originalTarget: any = { ...schemaForm.value }

    return originalTarget.slotBinds?.validation
  }

  const props = defineProps({
    schema: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  })

  useSchemaForm(props.model)

  defineExpose({
    schemaForm,
    validate
  })
</script>

<style lang="scss">
  .error {
    margin-top: 0;
  }
</style>
