<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.DepositQuick.title') }}</h1>

        <template v-if="Object.keys(state.formJson).length > 0">
          <div class="form-box">
            <div class="w60 ml20p mr20p tbw-w100 tbw-ml0p tbw-mr0p mb20">
              <p class="indent-1">{{ $t('pages.DepositQuick.msg2') }}</p>
            </div>
          </div>
          <!-- 
          <div class="form-box">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <div v-else>
              <the-message v-if="state.successMessage" type="primary">{{
                state.successMessage
              }}</the-message>
            </div>

            <ul class="form-normal">
              <li style="padding-top: 40px; padding-bottom: 0">
                <span class="search-operation">
                  <div class="search-operation-left">
                    <label class="madd_title"
                      >{{ t('pages.Corporation.form.bank_code') }}
                      <span class="required-notice">{{
                        $t('form.required')
                      }}</span>
                    </label>
                    <search-bank-quick @bank_name="selectedBank">
                    </search-bank-quick>
                  </div>
                </span>
                <input
                  type="text"
                  required
                  readonly
                  disabled
                  :value="state.formJson.bank_name"
                  class="form-control valid"
                />
              </li>

              <SchemaFormWithValidation
                :schema="state.schema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
              />
            </ul>

            <div class="w60 ml20p mr20p tbw-w100 tbw-ml0p tbw-mr0p mb20">
              <p class="indent-1">{{ $t('pages.DepositQuick.msg1') }}</p>
            </div>

            <button
              class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
              v-debounce="{
                func: confirm
              }"
              type="button"
              >{{ $t('pages.DepositQuick.button_confirm') }}</button
            >
          </div>
           -->

          <h1 class="history-title">{{
            $t('pages.DepositQuick.title_history')
          }}</h1>

          <history-item
            :detail="state.history"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history.total_pages"
            :page="state.page"
            @onchange="changePage"
            v-if="state.history && state.history?.results?.length > 0"
          ></pagination>

          <the-modal
            v-if="state.modal"
            id="modal-confirm"
            hasClose
            @close="state.modal = false"
          >
            <section class="modal-inner">
              <h1 v-html="$t('pages.DepositQuick.modal.title')"></h1>

              <div class="corporation-confirm">
                <dl>
                  <dt>{{ $t('pages.DepositQuick.modal.title1') }}</dt>
                  <dd>{{ state.formJson?.bank_name }}</dd>
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.DepositQuick.modal.title2') }}</dt>
                  <dd
                    >{{ parseInt(state.formJson?.amount).toLocaleString() }}
                    {{ $t('pages.DepositQuick.unit') }}</dd
                  >
                </dl>
                <dl class="flex-1-1">
                  <dt>{{ $t('pages.DepositQuick.modal.title3') }}</dt>
                  <dd
                    >{{ parseInt(state.formJson?.fee).toLocaleString() }}
                    {{ $t('pages.DepositQuick.unit') }}</dd
                  >
                </dl>
                <dl>
                  <dt>{{ $t('pages.DepositQuick.modal.title4') }}</dt>
                  <dd
                    >{{ parseInt(state.formJson?.total).toLocaleString() }}
                    {{ $t('pages.DepositQuick.unit') }}</dd
                  >
                </dl>
              </div>

              <button
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                v-debounce="{
                  func: submit
                }"
                v-loading="{
                  loading: state.loading,
                  value: $t('pages.DepositQuick.modal_button')
                }"
                type="button"
                >{{ $t('pages.DepositQuick.modal_button') }}</button
              >
            </section>
          </the-modal>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import User from '@/components/User.vue'
  import SearchBankQuick from '@/components/SearchBankQuick.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import TheModal from '@/components/TheModal.vue'
  import { getDepositQuick, getWalletFiatHistory, setDepositQuick } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { options } from '@/enum/enums'

  const router = useRouter()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const { t } = useI18n()
  const state: {
    title: any[]
    code_mst: any[]
    schema: any
    formJson: any
    history:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    loading: boolean
    modal: boolean
    successMessage: string
    errorMessage: string
  } = reactive({
    title: [],
    code_mst: [],
    schema: {
      amount: {
        component: 'Text',
        label: t('pages.DepositQuick.form.amount'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.DepositQuick.form.amount'),
            type: t('validation.type.input')
          })
        )
      },
      fee: {
        component: 'Text',
        label: t('pages.DepositQuick.form.fee'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: ''
      }
    },
    formJson: {},
    history: undefined,
    page: 1,
    loading: false,
    modal: false,
    successMessage: '',
    errorMessage: ''
  })

  const selectedBank = (e: any) => {
    state.formJson.bank_name = e.bank?.name
    state.formJson.bank_code = e.bank?.code
  }

  const confirm = async () => {
    state.successMessage = ''
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(() => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        if (state.formJson.amount < 1) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_limit_amount')
          return
        }

        if (state.formJson.limit_amount < state.formJson.amount) {
          document.documentElement.scrollTop = 0
          state.errorMessage = t('error.error_limit_amount')
          return
        }

        state.formJson.total =
          parseInt(state.formJson.amount) + parseInt(state.formJson.fee)

        state.modal = true
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  const submit = async () => {
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.modal = true
        state.loading = true
        try {
          const { contents } = await setDepositQuick({
            contents: {
              ...state.formJson
            }
          })
          modal_process(contents)

          state.formJson.amount = ''
          state.formJson.bank_code = ''
          state.formJson.bank_name = ''
          state.loading = false
          state.modal = false
          state.successMessage = t('error.success_deposit_quick')
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          state.loading = false
          state.modal = false
        }
      }
    }, 0)
  }

  const modal_process = async (data: any) => {
    // ウィンドウのサイズ
    var w_size = 1200
    var h_size = 700
    // ウィンドウの位置
    var left = Number((window.screen.width - w_size) / 2)
    var top = Number((window.screen.height - h_size) / 4)
    var childWindow = window.open(
      'about:blank',
      'popup',
      'width=' +
        w_size +
        ',height=' +
        h_size +
        ',left=' +
        left +
        ',top=' +
        top +
        ',location=no,menubar=no,toolbar=no,scrollbars=yes,resizable=yes,status=no'
    )

    // 金融機関ログイン画面へ
    var param_url = data.param_url
    var param_skno = data.param_skno
    var param_bptn = data.param_bptn
    var param_bill = data.param_bill
    var dummy_flg = data.dummy_flg

    //form作成
    var form = document.createElement('form')
    //target属性は、どこにアクションURLを開くかを指定します。
    form.target = 'popup'
    //POST通信設定。
    form.method = 'post'
    //遷移先のaction設定。
    form.action = param_url

    //input type='hidden' を作成し、送信データを設定。
    var hidden_skno = document.createElement('input')
    hidden_skno.setAttribute('name', 'skno')
    hidden_skno.setAttribute('type', 'hidden')
    hidden_skno.setAttribute('value', param_skno)

    var hidden_bptn = document.createElement('input')
    hidden_bptn.setAttribute('name', 'bptn')
    hidden_bptn.setAttribute('type', 'hidden')
    hidden_bptn.setAttribute('value', param_bptn)

    var hidden_bill = document.createElement('input')
    hidden_bill.setAttribute('name', 'bill')
    hidden_bill.setAttribute('type', 'hidden')
    hidden_bill.setAttribute('value', param_bill)

    //form に作成したinput要素を追加。
    form.appendChild(hidden_skno)
    form.appendChild(hidden_bptn)
    form.appendChild(hidden_bill)

    if (dummy_flg) {
      var torihiki_no = data.torihiki_no
      var amount = data.amount
      var amount_trans = data.amount_trans
      var bank_name = data.bank_name

      var hidden_torihiki_no = document.createElement('input')
      hidden_torihiki_no.setAttribute('name', 'torihiki_no')
      hidden_torihiki_no.setAttribute('type', 'hidden')
      hidden_torihiki_no.setAttribute('value', torihiki_no)

      var hidden_amount = document.createElement('input')
      hidden_amount.setAttribute('name', 'amount')
      hidden_amount.setAttribute('type', 'hidden')
      hidden_amount.setAttribute('value', amount)

      var hidden_amount_trans = document.createElement('input')
      hidden_amount.setAttribute('name', 'amount_trans')
      hidden_amount.setAttribute('type', 'hidden')
      hidden_amount.setAttribute('value', amount_trans)

      var hidden_bank_name = document.createElement('input')
      hidden_bank_name.setAttribute('name', 'bank_name')
      hidden_bank_name.setAttribute('type', 'hidden')
      hidden_bank_name.setAttribute('value', bank_name)

      //form に作成したinput要素を追加。
      form.appendChild(hidden_torihiki_no)
      form.appendChild(hidden_amount)
      form.appendChild(hidden_amount_trans)
      form.appendChild(hidden_bank_name)
    }

    var body = document.getElementsByTagName('body')[0]

    //一旦domに書き出して
    body.appendChild(form)
    //送信！
    form.submit()
    //送信後に作成したform要素の削除
    body.removeChild(form)
  }

  const showHistory = async () => {
    state.loading = true
    const { contents } = await getWalletFiatHistory(state.page, '3', '')
    state.history = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showHistory()
    document.documentElement.scrollTop = 0
  }

  const init = async () => {
    try {
      const { contents } = await getDepositQuick()
      state.formJson.fee = contents?.fee
      state.formJson.limit_amount = contents?.limit_amount
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.DepositQuick.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  onMounted(async () => {
    init()

    state.title = [
      { key: 'upd_date', name: t(`pages.DepositQuick.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.DepositQuick.item.title2`),
        type: 'code'
      },
      {
        key: 'amount',
        name: t(`pages.DepositQuick.item.title3`),
        type: 'number'
      },
      { key: 'fee', name: t(`pages.DepositQuick.item.title4`), type: 'number' },
      {
        key: 'balance',
        name: t(`pages.DepositQuick.item.title5`),
        type: 'number'
      }
    ]
    state.code_mst = options.deposit_fiat
    await showHistory()
  })
</script>
<style lang="scss" scoped></style>
