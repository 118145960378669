<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('menu.user.addressConfirm') }}</h1>
        <div class="boxss">
          <p style="text-align: center">
            {{ $t('pages.AddressConfirm.msg1') }}
          </p>

          <form enctype="multipart/form-data" class="user-form sm">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <ul class="form-normal">
              <SchemaFormWithValidation
                :schema="state.inquirySchema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
              />
            </ul>

            <button
              class="uk-button uk-button-primary"
              v-debounce="{
                func: submit
              }"
              v-loading="{
                loading: loading,
                value: $t('pages.AddressConfirm.form.submit')
              }"
              type="button"
              >{{ $t('pages.AddressConfirm.form.submit') }}</button
            >
          </form>
        </div>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { useI18n } from 'vue-i18n'
  import { getAddressConfirm, setAddressConfirm } from '@/api'
  import { hasCallbackUrl } from '@/utils/tool'
  import { validator } from '@/enum/enums'
  import { useRouter } from 'vue-router'

  const { t } = useI18n()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)
  const router = useRouter()

  const state: {
    errorMessage: string
    formJson: any
    inquirySchema: any
  } = reactive({
    errorMessage: '',
    formJson: {},
    inquirySchema: {
      address_confirm_code: {
        component: 'Text',
        label: t('pages.AddressConfirm.form.input'),
        required: true,
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.AddressConfirm.form.input'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.address_confirm_code.regex as RegExp,
            t('validation.address_confirm_code_error')
          )
      }
    }
  })

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await setAddressConfirm(state.formJson)
          loading.value = false
          router.push({
            name: 'Home',
            query: {
              success: 'AddressConfirm'
            }
          })
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          loading.value = false
        }
      }
    }, 0)
  }

  onMounted(async () => {
    const { contents } = await getAddressConfirm()
    if (!contents?.callback_url) return
    hasCallbackUrl(contents?.callback_url)
  })
</script>
