<template>
  <div class="the_modal">
    <transition name="modal">
      <div class="the_modal_content" v-show="show">
        <button
          class="uk-modal-close-default uk-close uk-icon"
          type="button"
          v-if="props.hasClose"
          @click="onClose"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            ratio="1"
          >
            <line
              fill="none"
              stroke="#000"
              stroke-width="1.1"
              x1="1"
              y1="1"
              x2="13"
              y2="13"
            ></line>
            <line
              fill="none"
              stroke="#000"
              stroke-width="1.1"
              x1="13"
              y1="1"
              x2="1"
              y2="13"
            ></line>
          </svg>
        </button>

        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { ref, onMounted } from 'vue'
  const props = defineProps({
    hasClose: {
      type: Boolean,
      default: false
    }
  })

  const show = ref(false)

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const onClose = () => {
    emit('close')
  }

  onMounted(() => {
    show.value = true
  })
</script>

<style lang="scss">
  .the_modal {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    &_content {
      width: 600px;
      max-height: 90vh;
      margin: 0 auto;
      margin-top: 5vh;
      background: rgba(23, 27, 54, 0.9);
      line-height: 1.5;
      border-radius: 8px;
      padding: 30px 30px;
      overflow: auto;
      position: relative;
    }
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: all 0.3s linear;
    opacity: 1;
    transform: translateY(0px);
  }

  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
    transform: translateY(-100px);
  }

  @media (max-width: 630px) {
    .the_modal_content {
      width: 95%;
    }
  }
</style>
