<template>
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh4 fw_m">キャンペーン情報</div>
      <the-loading v-if="!state.article"></the-loading>
      <template v-else>
        <h2 class="info_title boxm">{{ state.article.title }}</h2>

        <nav class="column_nav boxm"></nav>

        <div id="column_body" class="boxm">
          <div class="boxss">
            <figure><img :src="state.article.thumb_url" /></figure>

            <p
              >{{ state.article.thumb_comment }}
              <br />
            </p>
            <div class="camp_box"
              ><span class="cb_title">キャンペーン期間</span
              >{{ formatTime(state.article.start_date) }} ～
              {{ formatTime(state.article.end_date) }}</div
            >
            <div class="top_camp_in">
              <h3
                id="toggle_campaign_trigger"
                class="is-trigger"
                onclick="if (document.getElementById('toggle_campaign_content').style.display === 'none'){document.getElementById('toggle_campaign_content').style.display = 'block';}else{document.getElementById('toggle_campaign_content').style.display = 'none';}"
                >キャンペーン詳細
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAD2klEQVRYR71XS2wbVRQ9N3YyY8fQFApIFKkoNMuuKEIgULugfFIWdJEuYNumElKEonhsT8LiLWjs2BNFKBJCBsQKCRFQWDS0fBYFpLJAwIINIv0KWLSNaQqJPU48vtWzPe4ksT3PVhpvLHvuO+e8O/dLUPyMjo6Ggpo2yEzHAR4A0x6AHwZQJiAH0BIT/mDGF/eFtTNCCFsFmvyMTNN8qOTwJANvEFHIz14+Z+YCAZ8EAzSeTCZvtjrTVIAQIriSt+MATCLqVSHebMPMqwCSkbA+JYQoNcJoKCCRSOwuMc4Q6NlOiLcIAV8IEl5NpVK3Nj/bIsAwjAF0Bb4B6PHtIL+LwVdRdl7MZDKLXtwNAt4aH3+k2+FfCNi7veRVNAb+WQ/Qk+9OTl538esCotFoLwWCPwF04F6QezzxOzulZyzLkvGBugAjbk4BiN1b8jp6OjOVlAFeFTA6MbE3WCpfBKDvkACb2BlIp9N/VwQYscQHIDqxQ+TVeGB8ZKWTJ0gI0bOSt5dVi8x2iZTFKhLW+8gwzEF0YcEPmBnLROjzs6vF+22AdvnalnGUonHzfQJOtTJm4NNiXjulhe3vCPRUa1v+OQA+4oA+JtCxliKYs2TEzPMgHGpmKMmtqeTr8rW9KUQkXLC/blYhGXwhH9Jfek+IlaGhocC+/ifmWopgfE9GLPEniAYaCWDGX5Gw1u+t47IrBno06YkNZVqSO2vFF2ZmZgouloyv1bx9BUSPNrwg8yIZcVMWhHBzD/D8tcuXhubm5hzXpibiKwIdrlY4Pu+sFQe95CoekM1KxsD/BER83usWESMjI5oW7j0nzxXzqy/Pzs4WXQwV8lppXqFoPLFIoP1+Ecvg+UhIP+59HUIIPZfLsZe80sYL9me+AVj13EUZhD+A8LyfgJqrFyIh/bVmvb1G/iWBjqrggfGjUhp6wRjcUETb5NVymFUuRJtF/Hfr32PZbHZd/j88PNx9/+4H5pVv7oLJQlRJlUJRTipNM6FxCuHb28u5iqt39T24AMIRJbfXjOqlWP6Oxs0sASfbAajFxFn5TaBX2j0L5g8z6dTJSjeUk1CPw1d3sh2zs77PsqwbdyeiWCJNREbbN+ngADNnrHSqMvzUBVQWj25dpuTBDjDVjzB+La3bz7lVc8NQOjY2tqcr0P0biB5TR1S3ZMY1dtYOTk9PL7mnmo3l5wDqV4dWsVQYy12Y6mJCZwl4WgXaz0Z2SuXFxAWrjWoyKCc6HddkrgM43fZq5r1RJUVL5Xc6WU7Xgl1ve5eQRp7y3Y7dQ83WcyJiMC+56zkRf96r6wuq6/kd74G7APZRFCMAAAAASUVORK5CYII="
                  alt="arrow"
              /></h3>
              <div
                id="toggle_campaign_content"
                v-html="state.article.contents"
              ></div>
              <p></p>
            </div>
            <p></p>
            <!-- <h3>本キャンペーンに関するお問い合わせ先</h3>
          <p class="m_centers cat_bold">
            <a href="https://www.coinestate.co.jp/inquiry/inquiry/" class="a_link"
              >お問い合わせフォーム</a
            ></p
          >
          <p class="m_centers"
            ><span class="cat_red m_small"
              >サポート受付時間：平日午前9時から午後5時</span
            >
            <br /><span class="m_small">（土日祝祭日・年末年始を除く）</span></p
          >
          <p
            >サポート受付時間外にご連絡いただいたお問い合わせのご返信は、翌サポート受付時間以降となります。</p
          > -->
          </div>
        </div>
      </template>
    </div>
  </article>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { getCampaignDetail } from '@/api/index'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'
  import { formatTime } from '@/utils/tool'

  const state: {
    article: any
    loading: boolean
  } = reactive({
    article: undefined,
    loading: true
  })

  const route = useRoute()
  const store = useMainStore()

  const showCampaignDetail = async () => {
    const { contents } = await getCampaignDetail(route?.params?.id as string)
    state.article = contents

    const breadcrumb: SiteMapType = {
      route: route?.name as string,
      name: contents.title
    }

    store.setBreadcrumb(breadcrumb)
    // eslint-disable-next-line
    document!.title = (state.article?.title as string) + ' | Coin Estate'
    state.loading = false
  }

  onMounted(() => {
    showCampaignDetail()
  })
</script>
