<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fc_y">ひまぽいサービス</div>
      <div class="boxs mt_40 mb_40">
        <div class="bh5 fw_m"
          >2000万人以上が利用する国内最大級のポイント交換サービス内で『ひまぽい』との交換が可能に！！</div
        >
        <div class="btxt">
          『ひまぽいサービス』は、当社が発行する有効期限付きポイントで、1ポイント＝1円換算で当社が運営する暗号資産販売所「Coin Estate」において暗号資産との交換が可能です。<br /><br />
          このたび、株式会社サイバーエージェントが提供するポイントプラットフォーム『<a
            href="https://d-money.jp/"
            target="_blank"
            class="a_link"
            data-v-0eade946=""
            >ドットマネー</a
          >』で、利用先に『ひまぽい』が加わりました。<br />
          これにより、お客様が貯めた同サイト内にある各種ポイントを『ドットマネー』に交換した上で『ひまぽい』に1：1で交換することが可能となりました。
          <br />
        </div>
      </div>
      <div class="bh5 fw_m fc_y"
        >『ドットマネー』と『ひまぽい』との交換概要</div
      >
      <div class="main_contents_text boxs">
        <dl class="dl_table">
          <dt>交換レート</dt>
          <dd>300マネー→300ポイント分</dd>
          <dt>交換予定日</dt>
          <dd>3営業日-5営業日程度（土日・祝日を除く）</dd>
          <dt>交換単位</dt>
          <dd>1pt</dd>
          <dt>単位名</dt>
          <dd>ポイント</dd>
          <dt>最低交換額</dt>
          <dd>300pt</dd>
          <dt>月間交換上限</dt>
          <dd>10,000,000pt</dd>
          <dt>有効期限</dt>
          <dd>6月内（6カ月後の応答日の午前零時を以てポイント消滅します）</dd>
        </dl>
      </div>
      <div class="ta_c mw_40">
        『ドットマネー』および『ドットマネー』ロゴは株式会社サイバーエージェントの商標または登録商標です。
      </div>
    </div>
  </article>
  <!-- article end -->
</template>
