<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.TwoStepStatus.title') }}</h1>
        <form class="w60 mr20p ml20p tbw-w100 tbw-mr0p tbw-ml0p formbox">
          <the-message v-if="state.errorMessage">{{
            state.errorMessage
          }}</the-message>
          <div v-else>
            <the-message v-if="state.successMessage" type="primary">{{
              state.successMessage
            }}</the-message>
          </div>

          <ul class="tbw-pl8" style="margin-top: 0">
            <li v-for="option in options.two_step_setting" :key="option.key">
              <label :for="option.value">
                <span class="id_code_wrap">
                  <input
                    type="checkbox"
                    v-model="two_step_setting"
                    name="transaction_objective"
                    :value="option.key"
                    :id="option.value"
                    class="uk-checkbox"
                    disabled
                  />
                  <span class="code_span"
                    >{{ option.value
                    }}<span
                      v-if="readonlySettings.includes(option.key)"
                      class="required-notice"
                    ></span
                  ></span>
                </span>
              </label>
            </li>
            <li>
              <label class="two_step_auth_way mb20"
                ><span class="font_size">{{
                  $t('pages.TwoStepStatus.form.method')
                }}</span></label
              >
            </li>
            <li v-for="option in options.two_step_method" :key="option.key">
              <label :for="`two_step_auth_flg_${option.key}`">
                <input
                  type="radio"
                  :id="`two_step_auth_flg_${option.key}`"
                  name="two_step_auth_flg"
                  :value="option.key"
                  class="uk-radio"
                  v-model="two_step_auth_flg"
                  readonly
                  disabled
                /><span>{{ option.value }}</span>
              </label>
            </li>

            <ul class="mt20 tbw-pl8">
              <li>&nbsp;</li>
              <li>
                <p class="notice">{{ $t('pages.TwoStepStatus.warning') }}</p>
              </li>
              <li
                >{{ $t('pages.TwoStepStatus.issueDes') }}
                <span v-if="two_step_auth_flg === '3'">{{
                  $t('pages.TwoStepStatus.googleAuthenticator')
                }}</span>
                <span v-else>{{ state.two_step_recipient }}</span>
              </li>
            </ul>

            <div id="div_id_twostep_code">
              <label for="id_twostep_code" class="col-form-label requiredField">
                <span class="font_size">{{
                  $t('pages.TwoStepCheck.form.issueLabel')
                }}</span>
                <button
                  class="uk-button uk-button-primary"
                  style="margin: 0 auto; display: block"
                  v-if="two_step_auth_flg !== '3'"
                  v-debounce="{
                    func: issueCode
                  }"
                  v-loading="{
                    loading: loading,
                    value: $t('pages.TwoStepCheck.form.issue')
                  }"
                  type="button"
                  >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                >
                <div v-else>&nbsp;</div>
              </label>
            </div>
          </ul>
          <SchemaFormWithValidation
            :schema="state.schema"
            :model="state.formJson"
            useCustomFormWrapper
            ref="form"
          />

          <button
            class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.TwoStepStatus.form.submit')
            }"
            type="button"
            >{{ $t('pages.TwoStepStatus.form.submit') }}</button
          >
        </form>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'
  import { useRouter } from 'vue-router'
  import { getTwoStepStatus, twoStepIssue, twoStepVerify } from '@/api'

  const { t } = useI18n()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)
  const router = useRouter()

  const readonlySettings = ['01', '02', '08', '09']
  const two_step_setting = ref<string[]>(readonlySettings)
  const two_step_auth_flg = ref<string[]>('1')

  const state: {
    successMessage: string
    errorMessage: string
    isIssued: boolean
    formJson: any
    schema: any
  } = reactive({
    successMessage: '',
    errorMessage: '',
    isIssued: false,
    formJson: {},
    schema: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    }
  })

  const issueCode = async () => {
    loading.value = true
    await twoStepIssue('two_step_init', null)
    state.isIssued = true
    loading.value = false
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true

        try {
          await twoStepVerify(
            'setting_check',
            state.formJson.passcode,
            two_step_auth_flg.value || ''
          )
          loading.value = false
          router.push({
            name: 'TwoStepSetting'
          })
        } catch (error: any) {
          state.formJson.passcode = ''

          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          loading.value = false
        }
      }
    }, 0)
  }

  onMounted(async () => {
    if (router.currentRoute.value.query?.success) {
      state.successMessage = t('error.success_modify')
    }
    if (router.currentRoute.value.query?.error) {
      state.errorMessage = t(`error.error_twostep_modify`)
    }

    try {
      const { contents } = await getTwoStepStatus()
      two_step_setting.value = contents?.two_step_setting
      two_step_auth_flg.value = contents?.two_step_auth_flg

      if (contents?.two_step_auth_flg === '0') {
        two_step_auth_flg.value = '1'
        state.two_step_recipient = contents?.email
      } else if (contents?.two_step_auth_flg === '1') {
        state.two_step_recipient = contents?.email
      } else if (contents?.two_step_auth_flg === '2') {
        state.two_step_recipient = contents?.mobile
      }
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped>
  [type='checkbox'] {
    -webkit-appearance: unset;
    -moz-appearance: unset;
    appearance: unset;
  }
  [type='radio'] {
    -webkit-appearance: unset;
    -moz-appearance: unset;
    appearance: unset;
  }
</style>
