<template>
  <div
    class="main_contents"
    :class="{
      boxm: props.type === '2'
    }"
  >
    <div class="main_contents_list">
      <div
        :class="{
          column_menu_wrap: props.type === '1',
          column_menu_wrap2: props.type === '2'
        }"
      >
        <ul class="column_menu" v-if="state.links[0]?.children?.length > 0">
          <li v-for="item in state.links[0].children" :key="item.route"
            ><router-link :to="{ name: item.route }"
              ><i class="fas fa-book"></i>&nbsp;{{ item.name }}</router-link
            ></li
          >
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { siteMap } from '@/enum/siteMap'
  import { reactive } from 'vue'

  const state: {
    links: any
  } = reactive({
    links: siteMap.filter((i) => i.route === 'Column')
  })
  const props = defineProps({
    type: {
      type: String,
      default: '1'
    }
  })
</script>
<style lang="scss" scoped>
  .column_menu_wrap2 {
    margin-bottom: 0;
  }
</style>
