<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $t(`coins.${route.params.coin}`) }}</div>

      <div class="chart_box">
        <info-chart :type="(route.params.coin as string)"></info-chart>
      </div>
    </div>

    <div class="fdblockw">
      <div class="boxss">
        <div class="">
          <div class="bh5 fc_y fw_b">{{
            $t('pages.CoinInfo.table.title', {
              coin: $t(`coins.${route.params.coin}`)
            })
          }}</div>
          <div class="rates rates_h">
            <span class="rate2">{{ $t('pages.CoinInfo.table.th1') }}</span>
            <span class="rate3">{{ $t('pages.CoinInfo.table.th2') }}</span>
            <span class="rate4">{{ $t('pages.CoinInfo.table.th3') }}</span>
          </div>
          <div class="rates">
            <span class="rate2">
              <img
                :src="`${s3Url}img/icon_${coin}.png`"
                :alt="route.params.coin.toString()"
              />
              {{ (route.params.coin as string).toUpperCase() }} / JPY</span
            >
            <span class="rate3"
              >￥{{
                (wsRef.crypto &&
                  wsRef.crypto[route.params.coin as string]?.average.toLocaleString()) ||
                coinRateRef[route.params.coin as string]?.average.toLocaleString() ||
                0
              }}</span
            >
            <span class="rate4"
              >{{
                (wsRef.crypto && wsRef.crypto[route.params.coin as string]?.day_before_ratio) ||
                coinRateRef[route.params.coin as string]?.day_before_ratio ||
                0
              }}
              %</span
            >
          </div>
          <div class="rate_lists">
            <div class="rate_list">
              <span class="rate_list1">{{
                $t('pages.CoinInfo.table.th4')
              }}</span>
              <span class="rate_list2"
                >￥{{
                  (wsRef.crypto &&
                    wsRef.crypto[route.params.coin as string]?.daily_max_rate.toLocaleString()) ||
                  coinRateRef[route.params.coin as string]?.daily_max_rate.toLocaleString() ||
                  0
                }}</span
              >
            </div>
            <div class="rate_list">
              <span class="rate_list1">{{
                $t('pages.CoinInfo.table.th5')
              }}</span>
              <span class="rate_list2"
                >￥{{
                  (wsRef.crypto &&
                    wsRef.crypto[route.params.coin as string]?.daily_min_rate.toLocaleString()) ||
                  coinRateRef[route.params.coin as string]?.daily_min_rate.toLocaleString() ||
                  0
                }}</span
              >
            </div>
            <div class="rate_list">
              <span class="rate_list1">{{
                $t('pages.CoinInfo.table.th6')
              }}</span>
              <span class="rate_list2"
                >￥{{
                  (wsRef.crypto?.[route.params.coin as string]?.day_before_ratio ?? 0) >= 0 ||
                  (coinRateRef[route.params.coin as string]?.day_before_ratio ?? 0) >= 0
                    ? '+'
                    : '-'
                }}{{
                  countDayBefore(
                    (wsRef.crypto?.[route.params.coin as string]?.daily_base_rate ?? 0),
                    (wsRef.crypto?.[route.params.coin as string]?.average ?? 0)
                  ).toLocaleString()
                }}</span
              >
            </div>
          </div>
          <div class="rates_txt">
            {{ $t('pages.CoinInfo.table.tips') }}
          </div>
        </div>
      </div>
    </div>

    <div class="wblock pw_40">
      <div class="bh4 fc_y fw_b">{{
        $t(`pages.CoinInfo.description.${route.params.coin}.title`)
      }}</div>
      <div class="boxs lh_175">{{
        $t(`pages.CoinInfo.description.${route.params.coin}.content`)
      }}</div>
    </div>
  </article>
  <!-- article end -->
</template>
<script lang="ts">
  export default {
    data() {
      return {
        coin: this.$route.params.coin
      }
    },
    watch: {
      $route() {
        this.coin = this.$route.params.coin
      }
    }
  }
</script>
<script lang="ts" setup>
  import InfoChart from '@/components/InfoChart.vue'
  import { onMounted, watch, onBeforeMount, Ref } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import type { CoinInfo, WebSocketData } from '@/@types/websocket'

  const store = useMainStore()
  const { s3Url, ws, coinRate, tokenDeploy } = storeToRefs(store)
  const wsRef = ws as Ref<WebSocketData>
  const coinRateRef = coinRate as Ref<{ [key: string]: CoinInfo }>
  const route = useRoute()
  const router = useRouter()
  const { t } = useI18n()

  const countDayBefore = (base: number, average: number) => {
    return Math.abs(base - average)
  }

  const setTitle = () => {
    if (route.params.coin)
      document!.title =
        t(`coins.${route.params.coin}`) + route.meta?.title + ' | Coin Estate'
  }

  onMounted(async () => {
    setTitle()
    store.getCoinRate('all')
  })

  onBeforeMount(() => {
    const coin = route.params.coin as string
    if (
      tokenDeploy.value[coin.toUpperCase()] ===
      'false'
    ) {
      router.replace({ name: '404' })
      return
    }
  })

  watch(
    () => route.params.coin,
    async (newVal, oldVal) => {
      if (!newVal) return
      const coin = newVal as string
      if (
        tokenDeploy.value[coin.toUpperCase()] === 'false'
      ) {
        router.replace({ name: '404' })
        return
      }

      setTitle()
    }
  )
</script>

<style lang="scss" scoped>
  .chart_box {
    max-width: 100%;
  }

  .rates,
  .rates_h {
    width: 100%;
    .rate1,
    .rate2,
    .rate3,
    .rate4 {
      width: 33.33% !important;
      justify-content: center;
      text-align: center !important;
      align-items: center;
    }
    .rate2 {
      display: flex;
      img {
        width: 2rem;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 1024px) {
    .rate_list {
      width: 33.33%;
    }
  }
</style>
