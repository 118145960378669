<template>
  <footer id="footer" v-if="props.meta?.association">
    <div class="footer_w_wrap">
      <div class="boxm">
        <h6>{{ $t('menu.policy.member_association') }}</h6>
        <div class="association_set">
          <a href="https://jvcea.or.jp/" target="_blank">
            <img
              :src="`${s3Url}img/bnr_jvcea_w180_1.ja.png?v=1`"
              class="jvcea_img"
              alt="jvcea_img"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url } = storeToRefs(store)

  const props = defineProps({
    meta: Object
  })
</script>

<style lang="scss" scoped>
  .association_set {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      margin: 5px;
      display: block;
    }
    img {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    .association_set {
      display: block;
    }
  }
</style>
