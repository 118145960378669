<template>
  <button
    class="uk-button uk-button-primary uk-button-small bank-search"
    type="button"
    @click="bankSearch"
    >{{ $t('pages.Corporation.step5.searchButton') }}</button
  >

  <the-modal
    v-if="state.modal"
    id="modal-inquiry"
    hasClose
    @close="state.modal = false"
  >
    <section class="modal-inner" v-if="state.modalStep === '1'">
      <h1>{{ $t('pages.Corporation.step5.search.title1') }}</h1>
      <p>{{ $t('pages.Corporation.step5.search.msg1') }}</p>
      <ul class="bank-list" v-if="state.bankList && state.bankList.length > 0">
        <li
          v-for="bank in state.bankList"
          :key="bank.key"
          @click="onSelectMajorBank(bank)"
          >{{ bank.value }}</li
        >
      </ul>
      <p>{{ $t('pages.Corporation.step5.search.msg2') }}</p>
      <ul class="bank-list2">
        <template v-for="(i, index) in tagList" :key="index">
          <li
            v-if="i.name"
            @click="searchBank(i.name)"
            :class="{ 'is-selected': state.selectedName === i.name }"
            >{{ i.name }}</li
          >
          <br v-else-if="i.name === undefined" />
          <li v-else></li>
        </template>
      </ul>
      <!--
        <p>{{ $t('pages.Corporation.step5.search.msg3') }}</p>
        -->
      <template v-if="state.searchBankList">
        <select
          class="uk-select color-1e valid"
          style="border-radius: 5px; margin-top: 5px; margin-bottom: 10px"
          v-model="state.selectedBank"
          @change="onSelectBank"
          v-if="Object.keys(state.searchBankList).length > 0"
        >
          <option disabled selected value hidden>{{
            $t('pages.Corporation.step5.search.option1')
          }}</option>
          <option
            v-for="(value, key) of state.searchBankList"
            :key="key"
            :value="key"
            >{{ value }}</option
          >
        </select>
        <p v-else>{{ $t('pages.Corporation.step5.search.error1') }}</p>
      </template>

      <p v-if="state.bank"
        >{{ $t('pages.Corporation.step5.search.bank') }} 【
        {{ state.bank.name }} 】</p
      >
      <button
        v-if="state.bank"
        class="uk-button uk-button-primary"
        type="button"
        style="margin-top: 10px"
        @click="confirmBank"
        >{{ $t('pages.Corporation.step5.search.next') }}</button
      >
    </section>

    <section class="modal-inner" v-if="state.modalStep === '2'">
      <h1>{{ $t('pages.Corporation.step5.search.title2') }}</h1>
      <p v-if="state.bank"
        >{{ $t('pages.Corporation.step5.search.bank') }} 【
        {{ state.bank.name }} 】</p
      >
      <p>{{ $t('pages.Corporation.step5.search.msg4') }}</p>
      <ul class="bank-list2">
        <template v-for="(i, index) in tagList" :key="index">
          <li
            v-if="i.name"
            @click="searchBranch(i.name)"
            :class="{ 'is-selected': state.selectedName === i.name }"
            >{{ i.name }}</li
          >
          <br v-else-if="i.name === undefined" />
          <li v-else></li>
        </template>
      </ul>
      <template v-if="state.searchBranchList">
        <select
          class="uk-select color-1e valid"
          style="border-radius: 5px; margin-top: 5px; margin-bottom: 10px"
          v-model="state.selectedBranch"
          @change="onSelectBranch"
          v-if="Object.keys(state.searchBranchList).length > 0"
        >
          <option disabled selected value hidden>{{
            $t('pages.Corporation.step5.search.option2')
          }}</option>
          <option
            v-for="(value, key) of state.searchBranchList"
            :key="key"
            :value="key"
            >{{ value }}</option
          >
        </select>
        <p v-else>{{ $t('pages.Corporation.step5.search.error2') }}</p>
      </template>

      <p v-if="state.branch"
        >{{ $t('pages.Corporation.step5.search.branch') }} 【
        {{ state.branch.name }} 】</p
      >
      <button
        class="uk-button uk-button-primary"
        type="button"
        style="margin-top: 10px; margin-right: 10px"
        @click="rollbackBank"
        >{{ $t('pages.Corporation.step5.search.return') }}</button
      >
      <button
        v-if="state.bank && state.branch"
        class="uk-button uk-button-primary"
        type="button"
        style="margin-top: 10px"
        @click="confirmBranch"
        >{{ $t('pages.Corporation.step5.search.submit') }}</button
      >
    </section>
  </the-modal>
</template>

<script lang="ts" setup>
  import { reactive, onMounted, ref } from 'vue'
  import CDMST from '@/enum/cd_mst.json'
  import { CDMSTType } from '@/@types'
  import TheModal from '@/components/TheModal.vue'
  import { getMajorBankList, getBankByName, getBranchList } from '@/api'

  const TheENUM = CDMST as CDMSTType

  const tagList = [
    { name: 'あ' },
    { name: 'か' },
    { name: 'さ' },
    { name: 'た' },
    { name: 'な' },
    { name: 'は' },
    { name: 'ま' },
    { name: 'や' },
    { name: 'ら' },
    { name: 'わ' },
    { name: undefined },
    { name: 'い' },
    { name: 'き' },
    { name: 'し' },
    { name: 'ち' },
    { name: 'に' },
    { name: 'ひ' },
    { name: 'み' },
    { name: null },
    { name: 'り' },
    { name: undefined },
    { name: 'う' },
    { name: 'く' },
    { name: 'す' },
    { name: 'つ' },
    { name: 'ぬ' },
    { name: 'ふ' },
    { name: 'む' },
    { name: 'ゆ' },
    { name: 'る' },
    { name: undefined },
    { name: 'え' },
    { name: 'け' },
    { name: 'せ' },
    { name: 'て' },
    { name: 'ね' },
    { name: 'へ' },
    { name: 'め' },
    { name: null },
    { name: 'れ' },
    { name: undefined },
    { name: 'お' },
    { name: 'こ' },
    { name: 'そ' },
    { name: 'と' },
    { name: 'の' },
    { name: 'ほ' },
    { name: 'も' },
    { name: 'よ' },
    { name: 'ろ' }
  ]

  const state: {
    init: boolean
    bankList: Record<string, string>[] | undefined
    searchBankList: Record<string, string>[] | undefined
    searchBranchList: Record<string, string>[] | undefined
    selectedBank: string
    bank: { code: string; name: string } | undefined
    selectedBranch: string
    branch: { code: string; name: string } | undefined
    formJson: any
    errorMessage: string
    validationError: {
      bank: boolean
      branch: boolean
    }
    modal: boolean
    modalStep: string
    back_permission: boolean
    selectedName: string
  } = reactive({
    init: false,
    bankList: undefined,
    searchBankList: undefined,
    searchBranchList: undefined,
    selectedBank: '',
    bank: undefined,
    selectedBranch: '',
    branch: undefined,
    formJson: {},
    errorMessage: '',
    validationError: {
      bank: false,
      branch: false
    },
    modal: false,
    modalStep: '1',
    back_permission: false,
    selectedName: ''
  })

  const props = defineProps({
    bank_name: String
  })

  const bankSearch = () => {
    console.log('bankSearch')
    state.modal = true
  }

  const onSelectBank = () => {
    if (state.searchBankList && state.selectedBank) {
      state.bank = {
        code: state.selectedBank,
        name: state.searchBankList[state.selectedBank]
      }
      // clear selected option
      state.selectedName = ''
      state.branch = undefined
      state.selectedBranch = ''
      state.searchBranchList = undefined
    }
  }

  const onSelectMajorBank = (bank: Record<string, string>) => {
    state.searchBankList = undefined
    state.bank = { code: bank.key, name: bank.value }
    // clear selected option
    state.selectedName = ''
    state.branch = undefined
    state.selectedBranch = ''
    state.searchBranchList = undefined
    // go to step 2
    state.modalStep = '2'
  }

  const onSelectBranch = () => {
    if (state.searchBranchList && state.selectedBranch) {
      state.branch = {
        code: state.selectedBranch,
        name: state.searchBranchList[state.selectedBranch]
      }
    }
  }

  const searchBank = async (name: string) => {
    state.selectedName = name
    state.bank = undefined
    state.selectedBank = ''
    state.searchBankList = undefined
    const { contents } = await getBankByName(name)
    state.searchBankList = contents
  }

  const searchBranch = async (name: string) => {
    console.log('searchBranch')
    state.selectedName = name
    state.branch = undefined
    state.selectedBranch = ''
    state.searchBranchList = undefined
    if (state.bank?.code) {
      const { contents } = await getBranchList(state.bank?.code, name)
      state.searchBranchList = contents
    }
  }

  const confirmBank = () => {
    console.log('confirmBank')
    state.modalStep = '2'
  }

  const rollbackBank = () => {
    console.log('rollbackBank')
    state.modalStep = '1'
  }

  const emit = defineEmits({
    bank_name: Object
  })

  const confirmBranch = () => {
    console.log('confirmBranch')
    state.modal = false
    state.modalStep = '1'
    emit('bank_name', state)
  }

  onMounted(async () => {
    console.log(props.bank_name)
    try {
      const { contents } = await getMajorBankList()
      state.bankList = Object.keys(contents).map((key) => {
        return { key: key, value: contents[key] }
      })
    } catch (error) {
      console.log(error)
    }
  })
</script>
<style lang="scss">
  .poa-download {
    display: block;
    width: 50%;
    margin: 0 auto;
  }

  .bank-search {
    margin-left: 10px;
  }

  .bank-list {
    list-style: none;
    padding: 0;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    margin: 15px -5px;
    font-weight: 500;
    li {
      flex: 0 0 auto;
      padding: 5px 10px;
      cursor: pointer;
      background-color: #febd09;
      border-radius: 5px;
      margin: 5px;
    }
  }

  .bank-list2 {
    list-style: none;
    padding: 0;
    margin: 15px -3px;
    color: #fff;
    font-weight: 500;
    li {
      width: 35px;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
      background-color: #febd09;
      border-radius: 5px;
      display: inline-block;
      margin: 3px;
      &.is-selected {
        background-color: orange;
        box-shadow: inset 2px 2px 1px rgba($color: #000000, $alpha: 0.3);
      }
    }
  }

  .search-operation {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    &-left {
      flex: 0 0 auto;
    }

    &-right {
      flex: 1;
      padding-left: 15px;
      font-size: 0.875rem;
    }
  }

  @media (max-width: 640px) {
    .search-operation-right {
      flex: 0 0 100%;
      padding-left: 0;
      padding-top: 5px;
    }
  }
  .bank-list2 li {
    padding: unset !important;
  }
</style>
