import axios from 'axios'
import router from '@/router'
import i18n from '@/locale'
import { useMainStore } from '@/store/pinia'

axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers['Accept'] = 'application/json'

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    const prefix = import.meta.env.VITE_APP_API_RESOURCE || ''
    const actions = () => {
      return new Map([
        [
          /^\/crm.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/rate.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/auth.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/kyc.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/wallet.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/trade.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/history.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/report.*$/,
          () => {
            config.url = prefix + config.url
          }
        ],
        [
          /^\/system.*$/,
          () => {
            config.url = prefix + config.url
          }
        ]
      ])
    }
    const action = [...actions()].filter(([key, value]) =>
      key.test(config.url as string)
    )
    action.forEach(([key, value]) => value.call(this))

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use((res) => {
  if (!res.data.success && res.data.message !== 'success') {
    if (i18n.global.te(`error.${res.data.message}`)) {
      res.data.error_translations = i18n.global.t(`error.${res.data.message}`)
    }

    if (
      res.data.message === 'error_judging_status_check' &&
      res.data.message_detail.includes('judging_status_error_')
    ) {
      const step = res.data.message_detail.substring(
        res.data.message_detail.length - 1
      )
      if (res.data.message_detail.includes('judging_status_error_1_')) {
        router.replace({
          name: `IndividualStep${step}`
        })
      } else {
        let addon = ''
        if (step === '4') {
          addon = 'Select'
        } else if (step !== '8' && step !== '4') {
          addon = 'Input'
        }
        router.replace({
          name: `CorporationStep${step}${addon}`
        })
      }
    } else if (res.data.message === 'error_system_maintenance_all') {
      router.replace({
        name: 'Maintenance'
      })
    } else if (res.data.message === 'account_not_exist') {
      router.replace({
        name: 'Login'
      })
      const store = useMainStore()
      store.setIsLogin(false)
    } else if (res.data.message === 'error_application_permission') {
      router.replace({
        name: '404'
      })
    }
    return Promise.reject(res.data)
  }
  if (res.data?.contents?.detail === 'Not found.') {
    router.push({ name: '404' })
  }
  return res.data
})

export default axios
