<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{
          $t('pages.DepositCrypto.title', {
            crypto: state.crypto.toUpperCase()
          })
        }}</h1>

        <crypto-nav :crypto="state.crypto.toString()">
        </crypto-nav>

        <the-message v-if="state.errorMessage">{{
          state.errorMessage
        }}</the-message>

        <template
          v-if="
            Object.keys(state.formJson).length > 0 &&
            state.formJson.address == null
          "
        >
          <div class="tbl_form_wrap">
            <h3 class="history-title ht-subtitle">{{
              $t('pages.DepositCrypto.create.title', {
                crypto: state.crypto.toUpperCase()
              })
            }}</h3>
            <div class="tbl_text1">
              <span class="tbl_text_in"
                >{{
                  $t('pages.DepositCrypto.create.msg1', {
                    crypto: state.crypto.toUpperCase()
                  })
                }}<br class="sp_br" />{{
                  $t('pages.DepositCrypto.create.msg2')
                }}
              </span>
              <span class="tbl_text_in">{{
                $t('pages.DepositCrypto.create.msg3')
              }}</span>
              <span class="tbl_text_in">{{
                $t('pages.DepositCrypto.create.msg4')
              }}</span>
            </div>

            <button
              class="tbl_btn"
              v-debounce="{ func: submit }"
              v-loading="{
                loading: loading,
                value: $t('pages.DepositCrypto.create.button', {
                  crypto: state.crypto.toUpperCase()
                })
              }"
              id="register"
              type="button"
              >{{
                $t('pages.DepositCrypto.create.button', {
                  crypto: state.crypto.toUpperCase()
                })
              }}</button
            >
          </div>
        </template>

        <template
          v-else-if="
            Object.keys(state.formJson).length > 0 &&
            state.formJson.address != null
          "
        >
          <div class="tbl_border">
            <div class="tbl_form">
              <p class="tbl_title"
                >{{
                  $t('pages.DepositCrypto.crypto_msg1', {
                    crypto: state.crypto.toUpperCase()
                  })
                }}<br class="sp_br_550" />{{
                  $t('pages.DepositCrypto.crypto_msg2')
                }}</p
              >

              <p class="tbl_form_text1">{{
                $t('pages.DepositCrypto.input.address', {
                  crypto: state.crypto.toUpperCase()
                })
              }}</p>
              <input
                type="text"
                name="address"
                class="tbl_name"
                readonly
                :value="state.formJson?.address"
              />

              <template v-if="state.crypto == 'xrp'">
                <p class="tbl_form_text1">{{
                  $t('pages.DepositCrypto.input.tag')
                }}</p>
                <input
                  type="text"
                  name="tag"
                  class="tbl_name"
                  readonly
                  :value="state.formJson?.addrtag"
                />
              </template>

              <template v-if="state.crypto == 'xlm'">
                <p class="tbl_form_text1">{{
                  $t('pages.DepositCrypto.input.memo')
                }}</p>
                <input
                  type="text"
                  name="memo"
                  class="tbl_name"
                  readonly
                  :value="state.formJson?.addrtag"
                />
              </template>
            </div>
            <div class="formbox">
              <div>
                <QRCodeVue3
                  :value="state.formJson?.address"
                  :width="256"
                  :height="256"
                  :qrOptions="{
                    typeNumber: '0',
                    mode: 'Byte',
                    errorCorrectionLevel: 'Q'
                  }"
                  :imageOptions="{
                    hideBackgroundDots: true,
                    imageSize: 0.5
                  }"
                  imgclass="img-qr"
                  :dotsOptions="{ type: 'square', color: '#000' }"
                  :cornersSquareOptions="{ type: 'square', color: '#000' }"
                />
              </div>
              <p class="indent-1"
                >{{
                  $t('pages.DepositCrypto.msg1', {
                    crypto: state.crypto.toUpperCase(),
                    min_unit: state.formJson?.min_unit
                  })
                }}
              </p>
              <p class="indent-1">{{ $t('pages.DepositCrypto.msg2') }}</p>
              <p class="indent-1">{{ $t('pages.DepositCrypto.msg3') }}</p>
            </div>
          </div>

          <h1 class="history-title">{{
            $t('pages.DepositCrypto.title_history1', {
              crypto: state.crypto.toUpperCase()
            })
          }}</h1>

          <history-item
            :detail="state.history1"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history1.total_pages"
            :page="state.page1"
            @onchange="changePage1"
            v-if="state.history1 && state.history1?.results?.length > 0"
          ></pagination>

          <h1 class="history-title">{{
            $t('pages.DepositCrypto.title_history2', {
              crypto: state.crypto.toUpperCase()
            })
          }}</h1>

          <history-item
            :detail="state.history2"
            :title="state.title"
            :code_mst="state.code_mst"
          ></history-item>

          <pagination
            :total="state.history2.total_pages"
            :page="state.page2"
            @onchange="changePage2"
            v-if="state.history2 && state.history2?.results?.length > 0"
          ></pagination>
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import QRCodeVue3 from 'qrcode-vue3'
  import User from '@/components/User.vue'
  import CryptoNav from '@/components/CryptoNav.vue'
  import HistoryItem from '@/components/HistoryItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import {
    getDepositCrypto,
    getWalletCryptoHistory,
    setDepositCrypto
  } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { options } from '@/enum/enums'

  const { t } = useI18n()
  const router = useRouter()
  const loading = ref(false)
  const state: {
    title: any[]
    formJson: any
    code_mst: any[]
    history1:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    history2:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page1: number
    page2: number
    loading: boolean
    crypto: string
    errorMessage: string
  } = reactive({
    title: [],
    formJson: {},
    code_mst: [],
    history1: undefined,
    history2: undefined,
    page1: 1,
    page2: 1,
    loading: true,
    crypto: '',
    errorMessage: ''
  })

  const submit = async () => {
    state.errorMessage = ''

    setTimeout(async () => {
      try {
        loading.value = true
        const { contents } = await setDepositCrypto(state.crypto)
        state.formJson = contents
      } catch (error: any) {
        state.errorMessage = error.error_translations || error.message_detail
        document.documentElement.scrollTop = 0
      } finally {
        loading.value = false
      }
    }, 0)
  }

  const showHistory1 = async () => {
    const { contents } = await getWalletCryptoHistory(
      state.crypto?.toString(),
      state.page1,
      '1',
      'incomplete'
    )
    state.history1 = contents
  }

  const showHistory2 = async () => {
    const { contents } = await getWalletCryptoHistory(
      state.crypto?.toString(),
      state.page2,
      '1',
      'complete'
    )
    state.history2 = contents
  }

  const changePage1 = async (page: number) => {
    state.page1 = page
    state.loading = true
    await showHistory1()
    state.loading = false
  }

  const changePage2 = async (page: number) => {
    state.page2 = page
    state.loading = true
    await showHistory2()
    state.loading = false
  }

  const init = async () => {
    try {
      state.crypto = router.currentRoute.value.params.crypto as string
      const { contents } = await getDepositCrypto(state.crypto)
      state.formJson = contents
    } catch (error: any) {
      const message = t('error.' + error?.message, {
        page_name: t('pages.DepositCrypto.title')
      })
      router.push({ name: 'Error', query: { msg: message } })
    }
  }

  onMounted(async () => {
    init()

    state.title = [
      { key: 'upd_date', name: t(`pages.DepositCrypto.item.title1`) },
      {
        key: 'transfer_type',
        name: t(`pages.DepositCrypto.item.title2`),
        type: 'code'
      },
      {
        key: 'amount',
        name:
          t(`pages.DepositCrypto.item.title3`) +
          '(' +
          state.crypto.toString() +
          ')',
        type: 'number'
      },
      {
        key: 'fee',
        name:
          t(`pages.DepositCrypto.item.title4`) +
          '(' +
          state.crypto.toString() +
          ')',
        type: 'number'
      },
      {
        key: 'balance',
        name:
          t(`pages.DepositCrypto.item.title5`) +
          '(' +
          state.crypto.toString() +
          ')',
        type: 'number'
      },
      {
        key: 'tx',
        name: t(`pages.DepositCrypto.item.title6`),
        type: 'url',
        url_key: 'tx_url'
      }
    ]
    state.code_mst = options.deposit_crypto

    await showHistory1()
    await showHistory2()
  })
</script>
<style lang="scss" scoped></style>
