<template>
  <!-- breadcrumb start -->
  <div
    class="breadcrumb_wrap op_65"
    v-if="
      props.meta?.breadcrumb &&
      (loggedInArr.indexOf(router.currentRoute.value.name?.toString() || '') <
        0 ||
        !(
          isLogin &&
          loggedInArr.indexOf(
            router.currentRoute.value.name?.toString() || ''
          ) >= 0
        ))
    "
  >
    <ol
      class="breadcrumb box"
      itemscope
      itemtype="https://schema.org/BreadcrumbList"
    >
      <li
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
      >
        <router-link itemprop="item" :to="{ name: 'Landing' }"
          ><span itemprop="name">
            {{ $t('menu.home') }}&nbsp;</span
          ></router-link
        >
        <meta itemprop="position" content="1" />
      </li>
      <li
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
        v-for="(item, index) in breadcrumb"
        :key="item.name"
      >
        <router-link
          itemprop="item"
          :to="{ name: item.route }"
          v-if="!item.isText"
          ><span itemprop="name">&nbsp;{{ item.name }}&nbsp;</span></router-link
        >
        <span itemprop="name" v-else>&nbsp;{{ item.name }}&nbsp;</span>
        <meta itemprop="position" :content="(index + 2).toString()" />
      </li>
    </ol>
  </div>
  <!-- breadcrumb end -->
</template>

<script lang="ts" setup>
  import { watch, computed } from 'vue'
  import { siteMap } from '@/enum/siteMap'
  import { useRouter, RouteLocationNormalized } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { SiteMapType } from '@/@types'

  const store = useMainStore()
  const { isLogin, breadcrumb } = storeToRefs(store)
  const router = useRouter()

  const props = defineProps({
    meta: Object
  })

  const loggedInArr: string[] = ['Inquiry', 'InquirySuccess']

  const twoObjIsEqual = (obj1: any, obj2: any) => {
    let result = true
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        result = false
        break
      }
    }
    return result
  }

  const setBreadcrumb = (breadcrumb: SiteMapType, route: any) => {
    if (breadcrumb.params) {
      twoObjIsEqual(breadcrumb.params, route.params) &&
        store.setBreadcrumb(breadcrumb)
    } else {
      store.setBreadcrumb(breadcrumb)
    }
  }

  const resetBreadcrumb = () => {
    store.resetBreadcrumb()
  }

  const findParent = (route: RouteLocationNormalized) => {
    siteMap.forEach((item: SiteMapType) => {
      if (item.route === route.name || item.route === route.meta?.relate) {
        setBreadcrumb(item, route)
      } else {
        item.children?.map((i: SiteMapType) => {
          if (i.route === route.name || i.route === route.meta?.relate) {
            setBreadcrumb(item, route)
            setBreadcrumb(i, route)
          }
        })
      }
    })
  }

  watch(
    () => router.currentRoute.value,
    (route) => {
      if (!route.name) return
      resetBreadcrumb()

      findParent(route)
    },
    { immediate: true, deep: true }
  )
</script>

<style lang="scss" scoped>
  .breadcrumb li span[itemprop='name'] {
    font-size: 0.75rem;
  }
</style>
