<template>
  <div
    class="loader"
    :style="{ border: `2px solid ${props.color}` }"
    style="border-bottom-color: transparent"
  ></div>
</template>

<script lang="ts" setup>
  const props = defineProps({
    color: {
      type: String,
      default: '#fff'
    }
  })
</script>

<style lang="scss">
  .loader {
    width: 18px;
    height: 18px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 10px auto;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
