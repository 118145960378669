<template>
  <div class="corporation-confirm">
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_sei') }}</dt>
      <dd>{{ props.data?.name_sei || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_mei') }}</dt>
      <dd>{{ props.data?.name_mei || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_sei_kana') }}</dt>
      <dd>{{
        props.data?.name_sei_kana || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.name_mei_kana') }}</dt>
      <dd>{{
        props.data?.name_mei_kana || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.birthday') }}</dt>
      <dd>{{ props.data?.birthday || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.sex') }}</dt>
      <dd>{{
        findOption('sex', props.data?.sex) || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.country') }}</dt>
      <dd>{{
        findOption('country', props.data?.country) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.post_no') }}</dt>
      <dd>{{ props.data?.post_no || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.addr1') }}</dt>
      <dd>{{ props.data?.addr1 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.addr2') }}</dt>
      <dd>{{ props.data?.addr2 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.addr3') }}</dt>
      <dd>{{ props.data?.addr3 || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="is-half">
      <dt>{{ $t('pages.Corporation.form.priority_phone') }}</dt>
      <dd>{{
        findOption('priority_phone', props.data?.priority_phone) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.mobile_no') }}</dt>
      <dd>{{ props.data?.mobile_no || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.phone_no') }}</dt>
      <dd>{{ props.data?.phone_no || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.department') }}</dt>
      <dd>{{ props.data?.department || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.email') }}</dt>
      <dd>{{ props.data?.email || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.position') }}</dt>
      <dd>{{ props.data?.position || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.relationship') }}</dt>
      <dd>{{
        props.data?.relationship || $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl>
      <dt>{{ $t('pages.Corporation.form.peps') }}</dt>
      <dd>{{
        findOption('peps', props.data?.peps) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl v-if="props.data?.peps === '2'">
      <dt>{{ $t('pages.Corporation.form.peps_text') }}</dt>
      <dd>{{ props.data?.peps_text || $t('pages.Corporation.form.none') }}</dd>
    </dl>
    <p
      class="sub__title"
      style="margin: 40px 0 20px 0; width: 100%"
      v-if="props.subTitle !== false"
      >取引担当者としての投資経験</p
    >
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_stock') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_stock) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_stock_credit') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_stock_credit) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_public_bonds') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_public_bonds) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_investment_trust') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_investment_trust) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_crypto_spot') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_crypto_spot) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
    <dl class="flex-1-1">
      <dt>{{ $t('pages.Corporation.form.exp_crypto_leverage') }}</dt>
      <dd>{{
        findOption('experience', props.data?.exp_crypto_leverage) ||
        $t('pages.Corporation.form.none')
      }}</dd>
    </dl>
  </div>
</template>

<script setup lang="ts">
  import { findOption } from '@/utils/tool'
  const props = defineProps({
    data: Object,
    subTitle: Boolean
  })
</script>
