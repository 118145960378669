<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <div class="main_contents_text boxs">
          <h1 class="err_head">{{ $t('pages.Error.title') }}</h1>

          <div class="boxss">
            <p>&nbsp;</p>
            <!-- 
            <p class="p_name"><span>{{ $t('pages.Error.subtitle') }}</span></p
            ><p>&nbsp;</p>
             -->
            <p class="p_text"
              >{{ $t('pages.Error.msg1') }}<br /><br />

              <b class="err_title">{{ state.errorMessage }}</b
              ><br /><br />
              {{ $t('pages.Error.msg2') }}</p
            >
            <div class="btn_set">
              <router-link :to="{ name: 'Home' }" class="btn_link"
                ><i class="fas fa-chevron-right"></i>&nbsp;{{
                  $t('pages.Error.toTop')
                }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </article>
  </user>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import User from '@/components/User.vue'

  const router = useRouter()
  const state: {
    errorMessage: string
  } = reactive({
    errorMessage: ''
  })

  onMounted(async () => {
    state.errorMessage = router.currentRoute.value.query?.msg?.toString()
  })
</script>
<style lang="scss" scoped>
  .err_head {
    margin: 4rem 0 0;
    text-align: center;
    font-size: 2.6rem;
    color: #febd09;
  }
  .err_title {
    color: #f0516e;
    margin: 3rem 0 1.25rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .is-center {
    text-align: center;
  }
</style>
