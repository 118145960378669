<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="main_contents_text boxs">
        <div class="main-contents">
          <div class="policy_inner">
            <p class="pt4 pb4">{{
              $t('pages.Policy.AntiSocialElimination.msg1')
            }}</p>
            <ul class="re_po">
              <li>{{ $t('pages.Policy.AntiSocialElimination.msg1_1') }}</li>
              <li>{{ $t('pages.Policy.AntiSocialElimination.msg1_2') }}</li>
              <li>{{ $t('pages.Policy.AntiSocialElimination.msg1_3') }}</li>
              <li>{{ $t('pages.Policy.AntiSocialElimination.msg1_4') }}</li>
              <li>{{ $t('pages.Policy.AntiSocialElimination.msg1_5') }}</li>
            </ul>
            <p class="f-right">{{
              $t('pages.Policy.AntiSocialElimination.msg2')
            }}</p>
            <p style="color: #f44" v-if="language !== 'ja'"
              ><br />{{ $t('language_warning') }}</p
            >
          </div>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
