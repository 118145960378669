<template>
  <the-message-trade :message="state.message"></the-message-trade>

  <div class="tab_content is-table" id="chart-tab2" style="display: block">
    <div class="price_right pc-area">
      <ul class=""
        ><li class="wal_list"
          ><span class="box-left brand_name">{{
            $t('pages.Trade.list.item1')
          }}</span
          ><span class="box-right brand_name">{{
            $t('pages.Trade.list.item2')
          }}</span
          ><span class="box-right">{{ $t('pages.Trade.list.item3') }}</span
          ><span class="box-right order_class">{{
            $t('pages.Trade.list.item5')
          }}</span
          ><span class="box-right">{{ $t('pages.Trade.list.item7') }}</span
          ><span class="box-right">{{ $t('pages.Trade.list.item8') }}</span
          ><span class="box-right">{{ $t('pages.Trade.list.item9') }}</span
          ><span class="box-right transfer_no">{{
            $t('pages.Trade.list.item10')
          }}</span
          ><span class="box-right transfer_no">{{
            $t('pages.Trade.list.item11')
          }}</span></li
        >
        <template v-if="Object.keys(props.data?.order_board || {}).length > 0">
          <li
            class="wal_list"
            v-for="record in props.data?.order_board?.results"
            :key="record"
            ><span class="box-center brand_name">
              <button
                class="btn_update"
                v-on:click="confirm(record)"
                v-loading="{
                  loading: state.loading,
                  value: $t('pages.Trade.list.update')
                }"
              >
                {{ $t('pages.Trade.list.update') }}
              </button>
              <button
                class="btn_delete"
                v-on:click="submit_delete(record)"
                v-loading="{
                  loading: state.loading,
                  value: $t('pages.Trade.list.delete')
                }"
              >
                {{ $t('pages.Trade.list.delete') }}
              </button> </span
            ><span class="box-right brand_name"
              >{{ $t('pages.Trade.order.spot')
              }}{{ record.brand_name.replace('Spot_', '') }}</span
            ><span class="box-right" v-bind:class="record.trade_type">{{
              record.trading
            }}</span
            ><span class="box-right order_class"
              >{{
                record.order_type == 'limit'
                  ? $t('pages.Trade.order_type.limit')
                  : $t('pages.Trade.order_type.stop')
              }}({{
                record.order_class == 'oco'
                  ? 'OCO'
                  : $t('pages.Trade.order.single')
              }})</span
            ><span class="box-right">{{
              Math.abs(record.currency1_amount)
            }}</span
            ><span class="box-right">{{
              parseFloat(record.target_rate).toLocaleString(undefined, {
                maximumFractionDigits: [props.decimal_unit]
              })
            }}</span
            ><span class="box-right">{{ record.state }}</span
            ><span class="box-right transfer_no">{{
              record.order_date.replace('T', ' ').replace('Z', '')
            }}</span
            ><span class="box-right transfer_no">{{
              record.transfer_no
            }}</span></li
          >
        </template>
        <template v-else>
          <the-loading></the-loading>
        </template>
      </ul>
    </div>

    <div class="price_right sp-area">
      <template v-if="Object.keys(props.data?.order_board || {}).length > 0">
        <table class="uk-table sp-table tl-fixed"
          ><tbody class="b-top4">
            <template
              v-for="record in props.data?.order_board?.results"
              :key="record"
            >
              <tr class="b-bot b-bot"
                ><th class="w40 va-top ww-ba">{{
                  $t('pages.Trade.list.item1')
                }}</th
                ><td class="w60 ww-ba"
                  ><button
                    class="btn_update"
                    v-on:click="confirm(record)"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.Trade.list.update')
                    }"
                  >
                    {{ $t('pages.Trade.list.update') }}
                  </button>
                  <button
                    class="btn_delete"
                    v-on:click="submit_delete(record)"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.Trade.list.delete')
                    }"
                  >
                    {{ $t('pages.Trade.list.delete') }}
                  </button></td
                ></tr
              ><tr class="b-bot b-bot"
                ><th class="w40 va-top ww-ba">{{
                  $t('pages.Trade.list.item2')
                }}</th
                ><td class="w60 ww-ba"
                  >{{ $t('pages.Trade.order.spot')
                  }}{{ record.brand_name.replace('Spot_', '') }}</td
                ></tr
              ><tr class="b-bot b-bot"
                ><th class="w40 va-top ww-ba">{{
                  $t('pages.Trade.list.item3')
                }}</th
                ><td class="w60 ww-ba" v-bind:class="record.trade_type">{{
                  record.trading
                }}</td></tr
              ><tr class="b-bot b-bot"
                ><th class="w40 va-top ww-ba"
                  >{{ $t('pages.Trade.list.item5') }}・{{
                    $t('pages.Trade.list.item9')
                  }}</th
                ><td class="w60 ww-ba"
                  >{{
                    record.order_type == 'limit'
                      ? $t('pages.Trade.order_type.limit')
                      : $t('pages.Trade.order_type.stop')
                  }}({{
                    record.order_class == 'oco'
                      ? 'OCO'
                      : $t('pages.Trade.order.single')
                  }})・{{ record.state }}</td
                ></tr
              ><tr class="b-bot b-bot"
                ><th class="w40 va-top ww-ba">{{
                  $t('pages.Trade.list.item7')
                }}</th
                ><td class="w60 ww-ba">{{
                  Math.abs(record.currency1_amount)
                }}</td></tr
              ><tr class="b-bot b-bot"
                ><th class="w40 va-top ww-ba">{{
                  $t('pages.Trade.list.item8')
                }}</th
                ><td class="w60 ww-ba">{{
                  parseFloat(record.target_rate).toLocaleString(undefined, {
                    maximumFractionDigits: [props.decimal_unit]
                  })
                }}</td></tr
              ><tr class="b-bot b-bot"
                ><th class="w40 va-top ww-ba">{{
                  $t('pages.Trade.list.item10')
                }}</th
                ><td class="w60 ww-ba">{{
                  record.order_date.replace('T', ' ').replace('Z', '')
                }}</td></tr
              ><tr class="b-bot b-bot4"
                ><th class="w40 va-top ww-ba">{{
                  $t('pages.Trade.list.item11')
                }}</th
                ><td class="w60 ww-ba">{{ record.transfer_no }}</td></tr
              >
            </template>
          </tbody></table
        >
      </template>
      <template v-else>
        <the-loading></the-loading>
      </template>
    </div>
  </div>

  <the-modal
    v-if="state.modal"
    id="modal-confirm"
    hasClose
    @close="state.modal = false"
  >
    <section class="modal-inner">
      <h1>{{ $t('pages.Trade.list.item1') }}</h1>

      <div class="corporation-confirm">
        <dl class="flex-1-1">
          <dt>{{ $t('pages.Trade.list.item2') }}</dt>
          <dd
            >{{ $t('pages.Trade.order.spot')
            }}{{ props.type?.toUpperCase() }}JPY</dd
          >
        </dl>
        <dl class="flex-1-1">
          <dt>{{ $t('pages.Trade.list.item9') }}</dt>
          <dd>{{ state.selectedData?.state }}</dd>
        </dl>
        <dl class="flex-1-1">
          <dt>{{ $t('pages.Trade.list.item4') }}</dt>
          <dd>{{ state.selectedData?.order_condition }}</dd>
        </dl>
        <dl class="flex-1-1">
          <dt>{{ $t('pages.Trade.list.item3') }}</dt>
          <dd>{{ state.selectedData?.trading }}</dd>
        </dl>
        <dl class="flex-1-1">
          <dt>{{ $t('pages.Trade.list.item10') }}</dt>
          <dd>{{
            state.selectedData?.order_date.replace('T', ' ').replace('Z', '')
          }}</dd>
        </dl>
        <dl class="flex-1-1">
          <dt>{{ $t('pages.Trade.list.item11') }}</dt>
          <dd>{{ state.selectedData?.transfer_no }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('pages.Trade.list.item7') }}</dt>
          <dd>{{ Math.abs(state.selectedData?.currency1_amount) }}</dd>
        </dl>
      </div>

      <SchemaFormWithValidation
        :schema="state.schema"
        :model="state.formJson"
        useCustomFormWrapper
        ref="form"
      />

      <button
        class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: state.loading,
          value: $t('pages.Trade.list.item1')
        }"
        type="button"
        >{{ $t('pages.Trade.list.item1') }}</button
      >
    </section>
  </the-modal>
</template>
<script lang="ts" setup>
  import { reactive } from 'vue'
  import * as yup from 'yup'
  import TheModal from '@/components/TheModal.vue'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { useI18n } from 'vue-i18n'
  import { updateOrder, deleteOrder } from '@/api'
  import { make_massage } from '@/utils/tool'

  const { t } = useI18n()

  const props = defineProps({
    type: String,
    tradetoken: String,
    data: Object,
    decimal_unit: Number
  })

  const emit = defineEmits<{
    (e: 'onchange'): void
  }>()

  const state: {
    selectedData: any
    formJson: any
    schema: any
    loading: boolean
    modal: boolean
    message: any
  } = reactive({
    selectedData: {},
    formJson: {},
    schema: {
      target_rate: {
        component: 'Text',
        label: t('pages.Trade.list.item8'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Trade.list.item8'),
            type: t('validation.type.input')
          })
        )
      }
    },
    loading: false,
    modal: false,
    message: {}
  })

  const confirm = async (record: any) => {
    state.selectedData = record
    state.formJson.target_rate = parseFloat(record?.target_rate)
    state.modal = true
  }

  const submit = async () => {
    try {
      state.loading = true
      const { contents } = await updateOrder(
        (props.type as string)?.toLowerCase(),
        {
          contents: {
            trade_type: state.selectedData?.trade_type,
            transfer_no: state.selectedData?.transfer_no,
            target_rate: state.formJson?.target_rate,
            bgauthtoken: props.tradetoken
          }
        }
      )
      emit('onchange')
      state.message = {
        success: t('pages.Trade.list.success')
      }
    } catch (error: any) {
      state.message = {
        error: make_massage(error?.message, t)
      }
    } finally {
      state.modal = false
      setTimeout(function () {
        state.loading = false
      }, 3000)
    }
  }

  const submit_delete = async (record: any) => {
    try {
      state.loading = true
      const { contents } = await deleteOrder(
        (props.type as string)?.toLowerCase(),
        {
          contents: {
            transfer_no: record.transfer_no,
            bgauthtoken: props.tradetoken
          }
        }
      )
      emit('onchange')
      state.message = {
        success: t('pages.Trade.list.success_delete')
      }
    } catch (error: any) {
      state.message = {
        error: make_massage(error?.message, t)
      }
    } finally {
      state.modal = false
      setTimeout(function () {
        state.loading = false
      }, 3000)
    }
  }
</script>
<style lang="scss" scoped>
  @use '@/static/styles/trade-list.scss';
  .btn_update,
  .btn_delete {
    width: 35px;
    height: 18px;
    text-align: center;
  }

  .tab_content {
    padding: unset !important;
  }

  .buy {
    color: #0eb461;
    font-weight: bold;
  }
  .sell {
    color: #f6434e;
    font-weight: bold;
  }
  h1 {
    font-size: 2rem;
    margin: 2rem auto;
  }
  .corporation-confirm {
    padding-top: unset !important;
  }

  .update_button,
  .brand_name {
    min-width: 110px;
  }
  .order_class {
    min-width: 120px;
  }
  .transfer_no {
    min-width: 150px;
  }
  .pc-area {
    display: block;
  }
  .sp-area {
    display: none;
  }
  @media screen and (max-width: 1260px) {
    .pc-area {
      display: none;
    }
    .sp-area {
      display: block;
    }
    .btn_update,
    .btn_delete {
      width: 50px;
      text-align: center;
    }
    .sp-table {
      border: unset;
      background: unset;
    }
    .sp-table {
      display: table;
      background: #081828;
    }
    .sp-table tr.b-bot4 {
      border-bottom: 1px solid #febd09;
    }
    .sp-table th {
      background: #000;
      text-align: right;
      color: #ccc;
    }
    .sp-table td,
    .sp-table th {
      padding: 0.3rem 0.75rem;
      font-size: 0.9rem;
    }
  }
</style>
<style lang="scss">
  .btn_update .loader,
  .btn_delete .loader {
    width: 8px;
    height: 8px;
    margin: 0px auto !important;
  }
</style>
