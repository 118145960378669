<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.PasswordSetting.title') }}</h1>

        <div class="form-box">
          <the-message v-if="state.errorMessage">{{
            state.errorMessage
          }}</the-message>
          <div v-else>
            <the-message v-if="state.successMessage" type="primary">{{
              state.successMessage
            }}</the-message>
          </div>

          <form>
            <ul class="form-normal">
              <SchemaFormWithValidation
                :schema="state.schema"
                :model="state.formJson"
                useCustomFormWrapper
                ref="form"
                class="corporation-form"
              />

              <div id="div_id_twostep_code" v-if="state.twostep_check != '0'">
                <label
                  for="id_twostep_code"
                  class="col-form-label requiredField"
                >
                  <span class="font_size">{{
                    $t('pages.TwoStepCheck.form.issueLabel')
                  }}</span>
                  <button
                    class="uk-button uk-button-primary"
                    style="margin: 0 auto; display: block"
                    v-if="state.twostep_check !== '3'"
                    v-debounce="{
                      func: issueCode
                    }"
                    v-loading="{
                      loading: loading,
                      value: $t('pages.TwoStepCheck.form.issue')
                    }"
                    type="button"
                    >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                  >
                  <div v-else>&nbsp;</div>
                </label>

                <SchemaFormWithValidation
                  :schema="state.schema2"
                  :model="state.formJson"
                  useCustomFormWrapper
                  ref="form"
                  class="corporation-form"
                />
              </div>
            </ul>

            <button
              class="uk-button uk-button-primary uk-width-1-1"
              v-debounce="{ func: submit }"
              v-loading="{
                loading: loading,
                value: $t('pages.PasswordSetting.button')
              }"
              id="register"
              type="button"
              >{{ $t('pages.PasswordSetting.button') }}</button
            >
          </form>
        </div>
      </div>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'
  import { useRouter } from 'vue-router'
  import { twoStepIssue, getPwModifyAfter, setPwModifyAfter } from '@/api'

  const { t } = useI18n()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)
  const router = useRouter()

  const state: {
    twostep_check: string
    formJson: any
    schema: any
    schema2: any
    successMessage: string
    errorMessage: string
  } = reactive({
    twostep_check: '0',
    formJson: {},
    schema: {
      password: {
        component: 'Text',
        label: t('pages.PwModify.form.password2'),
        required: true,
        type: 'password',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.password'),
              type: t('validation.type.input')
            })
          )
      },
      re_password: {
        component: 'Text',
        label: t('pages.PwModify.form.re_password'),
        required: true,
        type: 'password',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        passwordLevel: true,
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.password'),
              type: t('validation.type.input')
            })
          )
          .matches(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{14,128}/,
            t('validation.password')
          )
      },
      re_password_confirm: {
        component: 'Text',
        label: t('pages.PwModify.form.re_password_confirm'),
        required: true,
        type: 'password',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('validation.item.password'),
              type: t('validation.type.input')
            })
          )
          .matches(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{14,128}/,
            t('validation.password')
          )
      }
    },
    schema2: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    successMessage: '',
    errorMessage: ''
  })

  const issueCode = async () => {
    loading.value = true
    await twoStepIssue('db_check', null)
    loading.value = false
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true

        try {
          const { contents } = await setPwModifyAfter({
            contents: {
              ...state.formJson
            }
          })
          loading.value = false
          state.successMessage = t('error.success_modify')
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          loading.value = false
        } finally {
          state.formJson.password = ''
          state.formJson.re_password = ''
          state.formJson.re_password_confirm = ''
          state.formJson.passcode = ''
        }
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getPwModifyAfter()
      state.twostep_check = contents?.two_step_flg
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped>
  .form-box {
    width: calc(100% - 2rem);
    max-width: 720px !important;
    margin: 0 auto;
  }
</style>
