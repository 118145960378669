<template>
  <router-link
    :to="{ name: 'ColumnArticle', params: { id: props.item?.idx } }"
    class="column_set"
  >
    <figure>
      <img
        v-if="props.item?.thumb_url"
        :src="props.item?.thumb_url"
        @error="showDefaultImage"
        alt="thumb"
      />
      <img v-else :src="DefaultImage" alt="default_thumb" />
    </figure>
    <span class="title">{{ props.item?.title }}</span>
    <span class="navs" v-if="props.item?.reg_date">
      <span class="time"
        ><i class="far fa-clock"></i
        >{{ formatDate(props.item?.reg_date) }}</span
      >
    </span>
  </router-link>
</template>

<script lang="ts" setup>
  import { formatDate } from '@/utils/tool'
  import DefaultImage from '@static/img/glossary_thumb.png'

  const props = defineProps({
    item: Object
  })

  const showDefaultImage = (event: Event | any) => {
    event.target.src = DefaultImage
  }
</script>

<style lang="scss" scoped>
  figure img {
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
</style>
