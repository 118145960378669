<template>
  <the-message v-if="state.errorMessage" class="form-error">{{
    state.errorMessage
  }}</the-message>
  <div class="corporation">
    <the-loading v-if="!state.init" style="margin-top: 100px"></the-loading>

    <div class="corporation-wrapper" v-else>
      <ol class="step mb30">
        <li class="br-tl-6 br-bl-6"></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li class="is-current">７{{ $t('pages.Corporation.title.step7') }}</li>
        <li></li>
        <li class="br-tr-6 br-br-6">{{
          $t('pages.Corporation.title.step9')
        }}</li>
      </ol>

      <form>
        <h1>二段階認証のお願い</h1>
        <p style="text-align: center">
          {{ $t('pages.Corporation.step7.msg1')
          }}<router-link
            :to="{
              name: 'CorporationStep3Input',
              query: { uncheck_flg: '1', callback: 'step7' }
            }"
            class="is-link"
            >{{ $t('pages.Corporation.step7.link') }}</router-link
          >{{ $t('pages.Corporation.step7.msg2') }}
        </p>

        <p style="font-size: 1.5rem; text-align: center; margin: 30px 0">
          {{ $t('pages.Corporation.step7.phone') }}{{ state.phone }}</p
        >

        <button
          class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
          :class="{
            'uk-button-primary': state.phone,
            'uk-button-default': !state.phone
          }"
          type="button"
          :disabled="!state.phone"
          v-debounce="{
            func: issueCode
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.step7.issue')
          }"
          >{{ $t('pages.Corporation.step7.issue') }}
        </button>
        <p v-if="state.isIssued" style="text-align: center">{{
          $t('pages.Corporation.step7.issued')
        }}</p>

        <ul class="form-normal">
          <SchemaFormWithValidation
            :schema="state.schema"
            :model="state.formJson"
            useCustomFormWrapper
            ref="form"
            class="corporation-form"
          />
        </ul>

        <button
          class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
          :class="{
            'uk-button-primary': state.phone,
            'uk-button-default': !state.phone
          }"
          v-debounce="{
            func: submit
          }"
          v-loading="{
            loading: loading,
            value: $t('pages.Corporation.step7.verify')
          }"
          type="button"
          >{{ $t('pages.Corporation.step7.verify') }}</button
        >
      </form>
      <p style="width: 90%; margin: 20px auto">
        {{ $t('pages.Corporation.step7.msg3') }}</p
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import {
    getTradeHead,
    twoStepIssue,
    twoStepVerify,
    setCorporationStatus,
    getTwoStep
  } from '@/api'
  import { validator } from '@/enum/enums'

  const { t } = useI18n()
  const router = useRouter()

  const state: {
    init: boolean
    phone: string
    schema: any
    formJson: any
    errorMessage: string
    isIssued: boolean
  } = reactive({
    init: false,
    phone: '',
    schema: {
      passcode: {
        component: 'Text',
        label: t('pages.Corporation.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.Corporation.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    formJson: {},
    errorMessage: '',
    isIssued: false
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const issueCode = async () => {
    loading.value = true
    await twoStepIssue('corporation', null)
    state.isIssued = true
    loading.value = false
  }

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await twoStepVerify('corporation', state.formJson.passcode, '2')
          try {
            await setCorporationStatus('07')
            router.push({ name: 'CorporationStep8' })
          } catch (error: any) {
            state.errorMessage = error.error_translations || error.message_detail
            document.documentElement.scrollTop = 0
          }
        } catch (error: any) {
          state.errorMessage = t('pages.Corporation.step7.error')
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      await getTwoStep(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      const { contents } = await getTradeHead('uncheck')
      state.phone = contents?.mobile_no
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>

<style lang="scss" scoped>
  .poa-download {
    display: block;
    width: 50%;
    margin: 0 auto;
  }

  .is-link {
    color: #00a8ff;
  }

  .uk-button-default {
    font-weight: bold;
    font-size: 1.1rem;
    border-radius: 3px !important;
  }
</style>
