<template>
  <!-- ryuui start -->
  <div class="ryuui" v-if="props.meta?.note !== false">
    <div class="boxs">
      <p v-html="$t('fsa.msg1')"></p>
      <p>
        {{ $t('fsa.msg2') }}<br />
        {{ $t('fsa.msg3') }}<br />
        {{ $t('fsa.msg4') }}
      </p>
      <p>
        {{ $t('fsa.msg5') }}<br />
        {{ $t('fsa.msg6') }}<br />
        {{ $t('fsa.msg7') }}<br />
        {{ $t('fsa.msg8') }}<br />
        {{ $t('fsa.msg9') }}<br />
        {{ $t('fsa.msg10') }}<br /><br />
        {{ $t('fsa.msg11') }}<br />
        {{ $t('fsa.msg12') }}<br />
        {{ $t('fsa.msg13') }}<br />
        {{ $t('fsa.msg14') }}<br />
        {{ $t('fsa.msg15') }}<br />
        {{ $t('fsa.msg16') }}<br />
        {{ $t('fsa.msg17') }}<br />
        {{ $t('fsa.msg18') }}<br />
        {{ $t('fsa.msg19') }}
      </p>
    </div>
  </div>
  <div class="footer_links" v-if="props.meta?.note !== false">
    <a :href="`${s3Url}resource/bnr_trouble_casebook.pdf`" target="_blank">
      <img
        :src="`${s3Url}img/bnr_trouble_casebook.ja.png?v=1`"
        class="jvcea_img"
        alt="jvcea_img"
      />
    </a>
    <a :href="`${s3Url}resource/bnr_crypto_headsup.pdf`" target="_blank">
      <img
        :src="`${s3Url}img/bnr_crypto_headsup.ja.png?v=1`"
        class="jvcea_img"
        alt="jvcea_img"
      />
    </a>
  </div>
  <!-- ryuui end -->
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url } = storeToRefs(store)

  const props = defineProps({
    meta: Object
  })
</script>

<style lang="scss" scoped>
  .ryuui {
    padding-bottom: 1.5rem;
  }
  .footer_links {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      margin: 5px;
      display: block;
    }
    img {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    .footer_links {
      display: block;
    }
  }
</style>
