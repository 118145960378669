<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="main_contents_text boxs">
        <div class="main_contents_head_sp"></div>
        <div class="policy_inner">
          <div class="pp_set">
            <p class="m_right">{{ $t('pages.Policy.Ethics.company') }}</p
            ><br />
          </div>

          <p>{{ $t('pages.Policy.Ethics.msg') }}</p>

          <h2>1 {{ $t('pages.Policy.Ethics.msg1') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg1_1') }} </p>

          <h2>2 {{ $t('pages.Policy.Ethics.msg2') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg2_1') }}</p>

          <h2>3 {{ $t('pages.Policy.Ethics.msg3') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg3_1') }}</p>

          <h2>4 {{ $t('pages.Policy.Ethics.msg4') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg4_1') }}</p>

          <h2>5 {{ $t('pages.Policy.Ethics.msg5') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg5_1') }}</p>

          <h2>6 {{ $t('pages.Policy.Ethics.msg6') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg6_1') }}</p>

          <h2>7 {{ $t('pages.Policy.Ethics.msg7') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg7_1') }}</p>

          <h2>8 {{ $t('pages.Policy.Ethics.msg8') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg8_1') }}</p>

          <h2>9 {{ $t('pages.Policy.Ethics.msg9') }}</h2>
          <p>{{ $t('pages.Policy.Ethics.msg9_1') }}</p>

          <p style="color: #f44" v-if="language !== 'ja'"
            ><br />{{ $t('language_warning') }}</p
          >

          <div class="pp_set">
            <p class="m_right">{{ $t('pages.Policy.Ethics.end') }}</p>
          </div>

          <p style="text-align: right"
            >{{ $t('pages.Policy.Ethics.history') }}
          </p>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
