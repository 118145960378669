<template>
  <!-- article start -->
  <article id="contents" class="boxs loginart">
    <div class="main_contents boxs">
      <h1 v-if="state.formJson.payment_type == '0'">
        {{ $t('pages.HyperWalletPayment.title0') }}</h1
      >
      <h1 v-if="state.formJson.payment_type == '1'">
        {{ $t('pages.HyperWalletPayment.title1') }}</h1
      >

      <template v-if="Object.keys(state.formJson).length > 0">
        <div class="formbox">
          <div class="tbl_border">
            <p class="tbl_title">{{ state.formJson.hojin_meigi }}</p></div
          >

          <the-message v-if="state.errorMessage">{{
            state.errorMessage
          }}</the-message>
          <div v-else>
            <the-message v-if="state.successMessage" type="primary">{{
              state.successMessage
            }}</the-message>
          </div>

          <ul class="form-normal">
            <SchemaFormWithValidation
              :schema="state.schema"
              :model="state.formJson"
              useCustomFormWrapper
              ref="form"
              class="individual-form"
            />

            <template v-if="state.twostep_flg !== '0'">
              <div id="div_id_twostep_code">
                <label
                  for="id_twostep_code"
                  class="col-form-label requiredField"
                >
                  <span class="font_size">{{
                    $t('pages.TwoStepCheck.form.issueLabel')
                  }}</span>
                  <button
                    class="uk-button uk-button-primary"
                    style="margin: 0 auto; display: block"
                    v-if="state.twostep_flg !== '3'"
                    v-debounce="{
                      func: issueCode
                    }"
                    v-loading="{
                      loading: state.loading,
                      value: $t('pages.TwoStepCheck.form.issue')
                    }"
                    type="button"
                    >{{ $t('pages.TwoStepCheck.form.issue') }}</button
                  >
                  <div v-else>&nbsp;</div>
                </label>
              </div>

              <SchemaFormWithValidation
                :schema="state.schema2"
                :model="state.formJson2"
                useCustomFormWrapper
                ref="form"
              />

              <div>
                <p class="indent-1 text-red">{{
                  $t('pages.HyperWalletPayment.msg')
                }}</p>
              </div>

              <button
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                v-debounce="{
                  func: submit
                }"
                v-loading="{
                  loading: state.loading,
                  value: $t('pages.HyperWalletPayment.button_submit')
                }"
                type="button"
                >{{ $t('pages.HyperWalletPayment.button_submit') }}</button
              >
            </template>

            <template v-if="state.twostep_flg === '0'">
              <router-link
                :to="{ name: 'TwoStepStatus' }"
                class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
                >{{ $t('pages.HyperWalletPayment.button_twostep') }}
              </router-link>
            </template>
          </ul>
        </div>
      </template>
      <template v-else>
        <the-loading></the-loading>
      </template>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import {
    getHyperWalletPayment,
    twoStepIssue,
    setHyperWalletPayment
  } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { validator } from '@/enum/enums'
  import { addFormStyle, hasCallbackUrl } from '@/utils/tool'

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const { t } = useI18n()
  const router = useRouter()
  const state: {
    title: any[]
    code_mst: any[]
    input_min: number
    input_max: number
    formJson: any
    formJson2: any
    formJson3: any
    schema: any
    schema2: any
    loading: boolean
    isIssued: boolean
    twostep_flg: string
    crypto: string
    p: string
    k: string
    successMessage: string
    errorMessage: string
  } = reactive({
    title: [],
    code_mst: [],
    input_min: 0,
    input_max: 0,
    formJson: {},
    formJson2: {},
    formJson3: {},
    schema: {
      transaction_id: {
        component: 'Text',
        label: t('pages.HyperWalletPayment.form.title1'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: ''
      },
      fee: {
        component: 'Text',
        label: t('pages.HyperWalletPayment.form.title2'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: ''
      },
      amount: {
        component: 'Text',
        label: t('pages.HyperWalletPayment.form.title3'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: ''
      },
      balance: {
        component: 'Text',
        label: t('pages.HyperWalletPayment.form.title4'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: ''
      },
      balance_after_paid: {
        component: 'Text',
        label: t('pages.HyperWalletPayment.form.title5'),
        type: 'text',
        required: false,
        readonly: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        placeholder: ''
      }
    },
    schema2: {
      passcode: {
        component: 'Text',
        label: t('pages.TwoStepCheck.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        placeholder: '',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.TwoStepCheck.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    loading: false,
    isIssued: false,
    twostep_flg: '',
    crypto: '',
    p: '',
    k: '',
    successMessage: '',
    errorMessage: ''
  })

  const issueCode = async () => {
    state.loading = true
    await twoStepIssue('db_check', null)
    state.isIssued = true
    state.loading = false
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        state.loading = true
        try {
          const { contents } = await setHyperWalletPayment(state.p, state.k, {
            contents: {
              ...state.formJson2
            }
          })
          window.location.href = contents?.callback_url
        } catch (error: any) {
          if (error?.message == 'no_code_issued') {
            state.errorMessage = t('error.no_code_issued')
          } else if (error?.message == 'the_code_you_entered_is_incorrect') {
            state.errorMessage = t('error.the_code_you_entered_is_incorrect')
          } else {
            router.push({
              name: 'ErrorPublic',
              query: { msg: error?.message_detail }
            })
          }
        } finally {
          document.documentElement.scrollTop = 0
          state.loading = false
          state.formJson2.passcode = ''
        }
      }
    }, 0)
  }

  const init = async () => {
    try {
      state.p = router.currentRoute.value.query?.p?.toString() as string
      state.k = router.currentRoute.value.query?.k?.toString() as string
      const { contents } = await getHyperWalletPayment(state.p, state.k)
      state.formJson = contents
      state.twostep_flg = contents?.twostep_flg
      state.input_min = contents?.min_amount
      state.input_max = contents?.limit_amount
    } catch (error: any) {
      router.push({
        name: 'ErrorPublic',
        query: { msg: error?.message_detail }
      })
    }

    addFormStyle()
  }

  onMounted(async () => {
    init()
  })
</script>
<style lang="scss" scoped>
  .tbl_menu {
    margin-bottom: unset;
  }
  .individual-form {
    padding-top: unset;
  }
  .tbl_border {
    padding: unset !important;
    margin: 1rem auto;
  }
  .tbl_title {
    padding-bottom: unset !important;
  }
  .text-red {
    color: #df4c5b;
  }
</style>
