<template>
  <template v-for="(route, index) in props.list" :key="route.route">
    <li
      v-if="
        (language === 'ja' && !route.ja_hide && route.ja_hide !== null) ||
        (language === 'en' && !route.en_hide && route.en_hide !== null) ||
        (language === 'cn' && !route.cn_hide && route.cn_hide !== null)
      "
    >
      <router-link :to="{ name: route.route }" v-if="!route.link">
        {{ route.name }}
      </router-link>
      <a :href="route.link" v-else>
        {{ route.name }}
      </a>
      <span class="open sp" @click="openMenu(index)"
        ><i class="fas fa-chevron-down"></i
      ></span>
      <transition name="slide-fade">
        <ul
          v-if="route.children?.length > 0"
          v-show="state.menuIndex === index || state.windowWidth > 1024"
        >
          <template v-for="child in route.children" :key="child.route">
            <li
              v-if="
                (language === 'ja' &&
                  !child.ja_hide &&
                  child.ja_hide !== null) ||
                (language === 'en' &&
                  !child.en_hide &&
                  child.en_hide !== null) ||
                (language === 'cn' && !child.cn_hide && child.cn_hide !== null)
              "
            >
              <router-link
                :to="{ name: child.route, params: child.params }"
                v-if="!child.link"
              >
                <i class="fas fa-chevron-right"></i>&nbsp;{{ child.name }}
              </router-link>
              <a :href="child.link" :target="formatTarget(child.link)" v-else>
                <i class="fas fa-chevron-right"></i>&nbsp;{{ child.name }}
              </a>
            </li>
          </template>
        </ul>
      </transition>
    </li>
  </template>
</template>
<script lang="ts">
  export default {
    inheritAttrs: false
  }
</script>
<script lang="ts" setup>
  import { reactive, watch, onMounted, onUnmounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useMainStore } from '@/store/pinia'
  import { storeToRefs } from 'pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
  const props = defineProps({
    list: Object
  })
  const state = reactive({
    menuIndex: '-1',
    windowWidth: 0
  })
  const router = useRouter()

  const openMenu = (index: string) => {
    state.menuIndex = state.menuIndex === index ? '-1' : index
  }

  const getWindowWidth = () => {
    state.windowWidth =
      window.innerWidth || document.documentElement.clientWidth
  }

  const formatTarget = (link: string) => {
    return link.includes('pdf') ? '_blank' : '_self'
  }

  watch(
    () => router.currentRoute.value,
    () => {
      state.menuIndex = '-1'
    }
  )

  onMounted(() => {
    getWindowWidth()
    window.addEventListener('resize', getWindowWidth)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', getWindowWidth)
  })
</script>
<style lang="scss" scoped>
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-5px);
    opacity: 0;
  }
</style>
