<template>
  <div class="tbl_menu">
    <ul class="tbl_menu_ul">
      <li
        class="tbl_menu_li"
        v-for="coin in state.coins"
        :key="coin.id"
        v-bind:class="[coin.id === props.crypto ? 'current' : '']"
      >
        <router-link
          :to="{ name: props.link_name, params: { crypto: coin.id } }"
          >{{ coin.id.toUpperCase() }}</router-link
        >
      </li>
    </ul>
    <hr />
  </div>
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import { coins } from '@/enum/coins'

  const store = useMainStore()
  const { tokenDeploy } = storeToRefs(store)
  const state: {
    coins: {
      id: string
      name: string
    }[]
  } = reactive({
    coins: []
  })

  onMounted(async () => {
    Object.keys(tokenDeploy.value).forEach((key: string) => {
      if (tokenDeploy.value[key] === 'true') {
        state.coins.push(
          coins.find(
            (i) => i.id.toLocaleLowerCase() === key.toLocaleLowerCase()
          ) as {
            id: string
            name: string
          }
        )
      }
    })
  })

  const props = defineProps({
    link_name: String,
    crypto: String
  })
</script>

<style lang="scss">
  .tbl_menu {
    margin-bottom: 5rem;
  }
  .tbl_menu_ul {
    display: flex;
    justify-content: space-around;
    font-weight: bold;
  }
  .tbl_menu_ul .current {
    border-bottom: 3px solid #00bfff;
  }
</style>
