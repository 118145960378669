<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="ablock boxs">
        <div class="bh4 fw_m ta_l"
          ><span class="fc_y">1. </span
          >{{ $t('pages.Beginner.transaction.area1.title') }}</div
        >
        <a class="btn_link boxss" href="#transaction_1"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area1.link1') }}</a
        >
        <!-- 
        <a class="btn_link boxss" href="#transaction_2"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area1.link2') }}</a
        >
         -->
        <a
          class="btn_link boxss"
          href="#transaction_4"
          v-if="state.convenienceFlag === 'true'"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area1.link3') }}</a
        >
        <a class="btn_link boxss" href="#transaction_3"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area1.link4') }}</a
        >
      </div>
      <div class="ablock boxs">
        <div class="bh4 fw_m ta_l"
          ><span class="fc_y">2. </span
          >{{ $t('pages.Beginner.transaction.area2.title') }}</div
        >
        <a class="btn_link boxss" href="#transaction_5"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area2.link1') }}</a
        >
      </div>
      <div class="ablock boxs">
        <div class="bh4 fw_m ta_l"
          ><span class="fc_y">3. </span
          >{{ $t('pages.Beginner.transaction.area3.title') }}</div
        >
        <div class="boxss" :class="{ mb_25: language === 'ja' }">{{
          $t('pages.Beginner.transaction.area3.msg')
        }}</div>
        <a
          v-if="language === 'ja'"
          class="btn_link boxss"
          :href="`${s3Url}resource/trade_manual.pdf?v=3`"
          ><i class="fas fa-chevron-right"></i>
          {{ $t('pages.Beginner.transaction.area3.link1') }}</a
        >
      </div>
      <div class="ablock boxs">
        <div class="bh4 fw_m ta_l"
          ><span class="fc_y">4. </span
          >{{ $t('pages.Beginner.transaction.area4.title') }}</div
        >
        <a class="btn_link boxss" href="#transaction_6"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area4.link1') }}</a
        >
        <a class="btn_link boxss" href="#transaction_7"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area4.link2') }}</a
        >
        <a class="btn_link boxss" href="#transaction_8"
          ><i class="fas fa-chevron-right"></i
          >{{ $t('pages.Beginner.transaction.area4.link3') }}</a
        >
      </div>
    </div>

    <div class="wblock pw_40">
      <div class="wblock">
        <div class="flex_w fdblock mb_30" id="transaction_1">
          <div class="w2s flex_c">
            <div class="whitebox box_area">
              <img
                :src="`${s3Url}img/page/general_jpy_v3.${language}.png?v=2`"
                alt="general_jpy"
              />
            </div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area5.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Beginner.transaction.area5.msg')
            }}</div>
          </div>
        </div>
        <!-- 
        <div class="flex_w fdblock mb_30" id="transaction_2">
          <div class="w2s flex_c">
            <div class="whitebox box_area">
              <img
                :src="`${s3Url}img/page/quick_jpy_v3.${language}.png?v=2`"
                alt="quick_jpy"
              />
            </div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area6.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Beginner.transaction.area6.msg')
            }}</div>
          </div>
        </div>
         -->

        <div
          class="flex_w fdblock mb_30"
          id="transaction_4"
          v-if="state.convenienceFlag === 'true'"
        >
          <div class="w2s flex_c">
            <div class="whitebox box_area"
              ><img
                :src="`${s3Url}img/page/conveni_jpy_v3.${language}.png?v=2`"
                alt="conveni_jpy"
            /></div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area7.title')
            }}</div>
            <div class="btxt ta_c"
              >{{ $t('pages.Beginner.transaction.area7.msg') }}<p></p
              ><p class="cat_red">{{
                $t('pages.Beginner.transaction.area7.warning')
              }}</p></div
            >
          </div>
        </div>

        <div class="flex_w fdblock mb_30" id="transaction_3">
          <div class="w2s flex_c">
            <div class="whitebox box_area"
              ><img
                :src="`${s3Url}img/page/deposit_btc_v2.${language}.png?v=2`"
                alt="deposit_btc"
            /></div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area8.title')
            }}</div>
            <div class="btxt ta_c"
              >{{ $t('pages.Beginner.transaction.area8.msg')
              }}<p class="cat_red">{{
                $t('pages.Beginner.transaction.area8.warning')
              }}</p></div
            >
          </div>
        </div>

        <div class="flex_w fdblock mb_30" id="transaction_5">
          <div class="w2s flex_c">
            <div class="whitebox box_area"
              ><img
                :src="`${s3Url}img/page/transfer_v2.${language}.png?v=2`"
                alt="transfer"
            /></div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area9.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Beginner.transaction.area9.msg')
            }}</div>
          </div>
        </div>

        <div class="flex_w fdblock mb_30" id="transaction_6">
          <div class="w2s flex_c">
            <div class="whitebox box_area"
              ><img
                :src="`${s3Url}img/page/tra_flow_v2.${language}.png?v=2`"
                alt="tra_flow"
            /></div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area10.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Beginner.transaction.area10.msg')
            }}</div>
          </div>
        </div>

        <div class="flex_w fdblock mb_30" id="transaction_7">
          <div class="w2s flex_c">
            <div class="whitebox box_area"
              ><img
                :src="`${s3Url}img/page/withdraw_jpy_v2.${language}.png?v=2`"
                alt="withdraw_jpy"
            /></div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area11.title')
            }}</div>
            <div class="btxt ta_c">{{
              $t('pages.Beginner.transaction.area11.msg')
            }}</div>
          </div>
        </div>

        <div class="flex_w fdblock mb_30" id="transaction_8">
          <div class="w2s flex_c">
            <div class="whitebox box_area"
              ><img
                :src="`${s3Url}img/page/withdraw_ca_v2.${language}.png?v=2`"
                alt="withdraw_ca"
            /></div>
          </div>
          <div class="w2s flex_c">
            <div class="bh4 fc_y fw_m">{{
              $t('pages.Beginner.transaction.area12.title')
            }}</div>
            <div class="btxt ta_c"
              >{{ $t('pages.Beginner.transaction.area12.msg')
              }}<p class="cat_red">{{
                $t('pages.Beginner.transaction.area12.warning')
              }}</p></div
            >
          </div>
        </div>
      </div>
    </div>
    <div class="fdblockw pw_40 bbnone">
      <div class="wblock">
        <div class="main_contents_text boxs">
          <div class="bh3 mw_20">{{
            $t('pages.Beginner.transaction.notes.title')
          }}</div>
          <div class="mb_25 ta_c">{{
            $t('pages.Beginner.transaction.notes.description')
          }}</div>

          <div class="bh4 mb_20 mt_40 fc_y">{{
            $t('pages.Beginner.transaction.notes.part1.title')
          }}</div>
          <div class="box_set">
            <a
              href="https://www.gov-online.go.jp/useful/article/201610/1.html"
              target="_blank"
              class="btn_link"
              ><i class="fas fa-chevron-right"></i
              >{{ $t('pages.Beginner.transaction.notes.part1.link1') }}</a
            >
            <a
              href="https://www.fsa.go.jp/news/30/20180427/20180427.html"
              class="btn_link"
              target="_blank"
              ><i class="fas fa-chevron-right"></i
              >{{ $t('pages.Beginner.transaction.notes.part1.link2') }}</a
            >
          </div>
          <div class="bh4 mb_20 mt_40 fc_y">{{
            $t('pages.Beginner.transaction.notes.part2.title')
          }}</div>
          <div class="mb_25 ta_c">{{
            $t('pages.Beginner.transaction.notes.part2.description')
          }}</div>
          <div class="box_set" v-if="language === 'ja'">
            <a
              href="https://www.npa.go.jp/sosikihanzai/jafic/index.htm"
              class="btn_link"
              target="_blank"
              ><i class="fas fa-chevron-right"></i
              >{{ $t('pages.Beginner.transaction.notes.part2.link') }}</a
            >
          </div>
          <div class="box_set" v-if="language !== 'ja'">
            <a
              href="https://www.npa.go.jp/sosikihanzai/jafic/index_e.htm"
              class="btn_link"
              target="_blank"
              ><i class="fas fa-chevron-right"></i
              >{{ $t('pages.Beginner.transaction.notes.part2.link') }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </article>

  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { s3Url, language } = storeToRefs(store)

  const state = reactive({
    convenienceFlag: import.meta.env.VITE_APP_CONVENI_FLG
  })
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1024px) {
    .box_area {
      margin: 0 auto;
      width: 80%;
      text-align: center;
    }
  }
</style>
