<template>
  <li class="file-send" v-if="show">
    <span
      >{{ label }}
      <label style="color: red">{{ $t('form.file_alert') }}</label>
      <template v-if="required">
        <span v-if="requiredStyle === 'form'" class="required-notice">{{
          $t('form.required')
        }}</span>
        <span v-else class="required">*</span> </template
      ><span v-if="labelAddition" style="color: red">{{
        labelAddition
      }}</span></span
    >
    <p
      style="font-size: 0.9rem; margin-top: 5px"
      v-if="additionalNotesShow && additionalNotesPosition === 'top'"
      v-html="additionalNotes"
    ></p>
    <div>
      <label :for="uuid" class="uk-button-primary">
        <input
          type="file"
          :id="uuid"
          :accept="accept"
          :required="required"
          @input="update($event.target.files)"
          @blur="validate"
          :disabled="disabled"
          :readonly="readonly"
          ref="file"
        />
      </label>
      <button
        id="btn-example-file-reset2"
        type="button"
        :class="`uk-button-default is-${store.language}`"
        @click="update(null)"
      >
        {{ $t('form.initialize') }}
      </button>
      <div class="clear-both"></div>
    </div>

    <template v-if="preview">
      <img :id="uuid" class="" :src="preview" :alt="label" />
      <br />
    </template>

    <label
      :id="uuid"
      class="error"
      :for="uuid"
      v-if="validation?.errorMessage"
      >{{ validation.errorMessage }}</label
    >

    <p
      style="font-size: 0.9rem; margin-top: 5px"
      v-if="additionalNotesShow && additionalNotesPosition === 'bottom'"
      v-html="additionalNotes"
    ></p>
  </li>
</template>

<style lang="scss">
  .file-send img {
    display: block;
  }
</style>

<style lang="scss" scoped>
  .is-en {
    margin-right: 0.25rem;
  }
</style>

<script>
  import { inject, ref, watch } from 'vue'
  import { useMainStore } from '@/store/pinia'

  export default {
    setup(props) {
      const needValidation = ref(inject('needValidation'))
      const store = useMainStore()

      watch(needValidation, () => {
        props.validation.validate()
      })

      return {
        store
      }
    },
    props: {
      modelValue: { required: true },
      required: {
        type: Boolean,
        default: false
      },
      requiredStyle: {
        type: String
      },
      label: {
        type: String,
        required: true
      },
      accept: {
        type: String,
        default: '.jpeg,.jpg,.png,.bmp,.pdf'
      },
      config: {
        type: Object,
        default: () => ({ type: 'text' })
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      uuid: {
        type: Number,
        default: 0
      },
      validation: {
        type: Object,
        default: () => ({})
      },
      type: {
        type: String,
        default: 'text'
      },
      validations: {
        type: Object,
        default: () => ({})
      },
      additionalNotes: {
        type: String,
        default: ''
      },
      additionalNotesShow: {
        type: Boolean,
        default: false
      },
      additionalNotesPosition: {
        type: String,
        default: 'bottom'
      },
      show: {
        type: Boolean,
        default: true
      },
      labelAddition: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        preview: '',
        value: undefined
      }
    },
    methods: {
      update(value) {
        this.$emit('update:modelValue', value)
        // preview
        if (value) {
          const [file] = value
          if (file.type.includes('pdf')) return
          this.preview = URL.createObjectURL(file)
        } else {
          this.preview = ''
          this.$refs.file.value = ''
        }
      },
      validate() {
        this.validation.validate()
      }
    }
  }
</script>
