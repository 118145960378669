<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="main_contents_text boxs">
        <div class="main_contents_head_sp"></div>

        <div class="policy_inner">
          <p>{{ $t('pages.Policy.DebtFulfillment.msg') }}</p>

          <h2 style="text-align: left"
            >1. {{ $t('pages.Policy.DebtFulfillment.msg1') }}</h2
          >
          <p>{{ $t('pages.Policy.DebtFulfillment.msg1_1') }}</p>

          <h2 style="text-align: left"
            >2. {{ $t('pages.Policy.DebtFulfillment.msg2') }}</h2
          >
          <p>{{ $t('pages.Policy.DebtFulfillment.msg2_1') }}</p>

          <h2 style="text-align: left"
            >3. {{ $t('pages.Policy.DebtFulfillment.msg3') }}</h2
          >
          <p>{{ $t('pages.Policy.DebtFulfillment.msg3_1') }}</p>
        </div>
        <p style="color: #f44" v-if="language !== 'ja'"
          ><br />{{ $t('language_warning') }}</p
        >
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
