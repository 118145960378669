<template>
  <!-- article start -->
  <article id="infopage">
    <h1 class="contents_title">{{
      $router.currentRoute?.value?.meta?.title
    }}</h1>

    <the-loading v-if="state.loading"></the-loading>

    <div class="top_info boxm" v-else>
      <div class="info_sets boxs">
        <template
          v-if="state.notification && state.notification?.results?.length > 0"
        >
          <notification-item
            v-for="item in state.notification.results"
            :key="item.notification_id"
            :new="isNotificationNew(item.reg_date)"
            :detail="item"
          ></notification-item>
        </template>
        <p v-else>今のお知らせはありません。</p>
      </div>
    </div>

    <pagination
      :total="state.notification.total_pages"
      :page="state.page"
      @onchange="changePage"
      v-if="state.notification && state.notification?.results?.length > 0"
    ></pagination>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { reactive, onMounted } from 'vue'
  import NotificationItem from '@/components/NotificationItem.vue'
  import Pagination from '@/components/Pagination.vue'
  import { getNotificationList } from '@/api/index'
  import { isNotificationNew } from '@/utils/tool'

  const state: {
    notification:
      | {
          results: any[]
          total_pages: number
        }
      | undefined
    page: number
    loading: boolean
  } = reactive({
    notification: undefined,
    page: 1,
    loading: true
  })

  const showNotification = async () => {
    state.loading = true
    const { contents } = await getNotificationList(state.page)
    state.notification = contents
    state.loading = false
  }

  const changePage = async (page: number) => {
    state.page = page
    await showNotification()
  }

  onMounted(async () => {
    await showNotification()
  })
</script>
