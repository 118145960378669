<template>
  <li :class="props.liClass" v-show="props.show">
    <span v-if="label">
      <label
        >{{ label }}
        <template v-if="props.required">
          <span v-if="props.requiredStyle === 'form'" class="required-notice">{{
            $t('form.required')
          }}</span>
          <span v-else class="required">*</span>
        </template>
      </label>
    </span>
    <input
      type="date"
      :id="props.uuid.toString()"
      :required="props.required"
      :disabled="props.disabled"
      :value="props.modelValue"
      :readonly="props.readonly"
      :class="props.inputClass"
      :placeholder="props.placeholder"
      @input="onInput"
      @blur="validate"
      min="1900-12-31"
    />
    <label
      :id="props.uuid.toString()"
      class="error"
      :for="props.uuid.toString()"
      v-if="validation?.errorMessage"
      >{{ validation.errorMessage }}</label
    >
    <p
      style="font-size: 0.9rem; margin-top: 5px"
      v-if="props?.additionalNotesShow"
      v-html="props?.additionalNotes"
    ></p>
  </li>
</template>

<script lang="ts" setup>
  import { inject, ref, watch } from 'vue'
  const needValidation = ref(inject('needValidation'))
  const emit = defineEmits(['update:modelValue'])

  watch(needValidation, () => {
    props.validation.validate()
  })

  const props = defineProps({
    modelValue: { required: true },
    show: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredStyle: {
      type: String
    },
    label: {
      type: String
    },
    config: {
      type: Object,
      default: () => ({ type: 'text' })
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: Number,
      default: 0
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'text'
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    inputClass: {
      type: String,
      default: ''
    },
    liClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    additionalNotes: {
      type: String,
      default: ''
    },
    additionalNotesShow: {
      type: Boolean,
      default: false
    },
    positive: {
      type: Boolean,
      default: false
    }
  })

  const validate = () => {
    props.validation.validate()
  }

  const onInput = (event: Event) => {
    const target = event.target as HTMLInputElement
    emit('update:modelValue', target.value)
    if (!props.positive) return
    target.value = target.value.replaceAll('-', '')
  }

  defineExpose({
    validate
  })
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0;
  }

  ul,
  li {
    list-style: none;
  }
</style>
