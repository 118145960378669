<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="main_contents_text boxs">
        <div class="main_contents_head_sp"></div>
        <div class="main-contents">
          <div class="policy_inner">
            <p class="pt4 pb4">{{ $t('pages.Policy.AntiSocial.msg1') }}</p>
            <ul class="anti-list">
              <li>{{ $t('pages.Policy.AntiSocial.msg1_1') }}</li>
              <li>{{ $t('pages.Policy.AntiSocial.msg1_2') }}</li>
              <li>{{ $t('pages.Policy.AntiSocial.msg1_3') }}</li>
              <li>{{ $t('pages.Policy.AntiSocial.msg1_4') }}</li>
              <li>{{ $t('pages.Policy.AntiSocial.msg1_5') }}</li>
            </ul>
            <p style="color: #f44" v-if="language !== 'ja'"
              ><br />{{ $t('language_warning') }}</p
            >
            <hr class="mb_35" />
            <div
              style="text-align: right"
              v-html="$t('pages.Policy.AntiSocial.history')"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'

  const store = useMainStore()
  const { language } = storeToRefs(store)
</script>
