<template>
  <div v-if="!state.init">
    <the-loading style="margin-top: 100px; margin-bottom: 100px"></the-loading>
  </div>
  <template v-else>
    <div class="tstep">
      <img
        :src="`${s3Url}img/pc_step4.${language}.png?v=1`"
        class="sp_no"
        alt="step"
      />
      <img
        :src="`${s3Url}img/sp_step4.${language}.png?v=1`"
        class="sp"
        alt="step"
      />
    </div>
    <the-message v-if="state.errorMessage" class="form-error">{{
      state.errorMessage
    }}</the-message>
    <div class="individual">
      <div class="individual-wrapper">
        <ol class="step mb30">
          <li><span style="padding-left: 1em">１</span></li>
          <li><span style="padding-left: 1em">２</span></li>
          <li class="is-current br-tl-6 br-bl-6"
            >３{{ $t('pages.Individual.title.step3') }}</li
          >
          <li class="br-tr-6 br-br-6">{{
            $t('pages.Individual.title.step4')
          }}</li>
        </ol>

        <form>
          <h1>{{ $t('pages.Individual.title.step3') }}</h1>
          <p style="text-align: center">
            {{ $t('pages.Individual.step3.msg1') }}
            <router-link
              :to="{
                name: 'IndividualStep1',
                query: { uncheck_flg: '1' }
              }"
              class="is-link"
              >{{ $t('pages.Individual.step3.link') }}</router-link
            >{{ $t('pages.Individual.step3.msg2') }}
          </p>

          <p style="font-size: 1.5rem; text-align: center; margin: 30px 0">
            {{ $t('pages.Individual.step3.phone') }}{{ state.phone }}</p
          >

          <button
            class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
            :class="{
              'uk-button-primary': state.phone,
              'uk-button-default': !state.phone
            }"
            type="button"
            :disabled="!state.phone"
            v-debounce="{
              func: issueCode
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.Individual.step3.issue')
            }"
            >{{ $t('pages.Individual.step3.issue') }}
          </button>
          <p v-if="state.isIssued" style="text-align: center">{{
            $t('pages.Individual.step3.issued')
          }}</p>

          <ul class="form-normal">
            <SchemaFormWithValidation
              :schema="state.schema"
              :model="state.formJson"
              useCustomFormWrapper
              ref="form"
              class="individual-form"
            />
          </ul>

          <button
            class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction"
            :class="{
              'uk-button-primary': state.phone,
              'uk-button-default': !state.phone
            }"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.Individual.step3.verify')
            }"
            type="button"
            >{{ $t('pages.Individual.step3.verify') }}</button
          >
        </form>
        <p style="width: 90%; max-width: 600px; margin: 20px auto">{{
          $t('pages.Individual.step3.msg3')
        }}</p>
      </div>
    </div>
  </template>
  <!-- ここから Crib Notesユニバーサルタグのコード -->
  <component :is="'script'" v-if="env === 'prod' && state.clib_counter_flg">
    (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/" + a
    + "/atm.js"; e.id = d; e.async = true;
    b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
    "script", "__cribnotesTagMgrCmd", "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
    "https://tag.cribnotes.jp/container_manager");
  </component>
  <!-- ここまで Crib Notesユニバーサルタグのコード -->
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import {
    twoStepIssue,
    twoStepVerify,
    getTwoStep,
    setCorporationStatus
  } from '@/api'
  import { validator } from '@/enum/enums'

  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { s3Url, language, env } = storeToRefs(store)

  const state: {
    clib_counter_flg: boolean
    phone: string
    schema: any
    formJson: any
    errorMessage: string
    isIssued: boolean
    init: boolean
  } = reactive({
    clib_counter_flg: false,
    phone: '',
    schema: {
      passcode: {
        component: 'Text',
        label: t('pages.Individual.form.passcode'),
        type: 'number',
        required: true,
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        validations: yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.passcode'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.pass_code.regex as RegExp,
            t('validation.pass_code_error')
          )
      }
    },
    formJson: {},
    errorMessage: '',
    isIssued: false,
    init: false
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const loading = ref(false)

  const issueCode = async () => {
    loading.value = true
    await twoStepIssue('individual', null)
    state.isIssued = true
    loading.value = false
  }

  const submit = async () => {
    console.log('submit')
    const valid = form.value?.validate()
    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          await twoStepVerify('individual', state.formJson.passcode, '2')
          await setCorporationStatus('03')
          router.push({ name: 'IndividualStep4' })
        } catch (error: any) {
          state.errorMessage = t('pages.Individual.step3.error')
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop = 0
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getTwoStep()
      state.clib_counter_flg = contents?.clib_counter_flg
      state.phone = contents?.mobile_no
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      document.documentElement.scrollTop = 0
    }
  })
</script>

<style lang="scss" scoped>
  .uk-button-default {
    font-weight: bold;
    font-size: 1.1rem;
    border-radius: 3px !important;
  }

  .individual-form {
    max-width: 600px;
    margin: 0 auto;
  }
</style>
