<template>
  <li :class="liClass" v-show="show">
    <span v-if="label">
      <label
        >{{ label }}
        <template v-if="required">
          <span v-if="requiredStyle === 'form'" class="required-notice">{{
            $t('form.required')
          }}</span>
          <span v-else class="required">*</span>
        </template>
      </label>
    </span>
    <div class="checkbox-group" :id="uuid?.toString()">
      <div class="checkbox-item" v-for="option in options" :key="option.key">
        <input
          type="checkbox"
          :id="`${name}_${option.key}`"
          :name="name"
          :value="option.key"
          v-model="checkbox"
          class="uk-checkbox"
          @blur="validate"
        />
        <label :for="`${name}_${option.key}`">{{ option.value }}</label>
      </div>
    </div>
    <label
      :id="uuid?.toString()"
      class="error"
      :for="uuid?.toString()"
      v-if="validation?.errorMessage"
      >{{ validation.errorMessage }}</label
    >
    <p
      style="color: red; font-size: 0.9rem; margin-top: 5px"
      v-if="additionalNotesShow"
      v-html="additionalNotes"
    ></p>
  </li>
</template>

<script lang="ts" setup>
  import { inject, ref, watch, onMounted } from 'vue'
  const needValidation = ref(inject('needValidation'))
  const emit = defineEmits(['update:modelValue'])

  const checkbox = ref<string[]>([])

  interface Props {
    modelValue?: any
    show?: boolean
    required?: boolean
    requiredStyle?: string
    name?: string
    disabled?: boolean
    readonly?: boolean
    uuid?: number
    label?: string
    options?: { key: string; value: string }[]
    placeholders?: string
    validation?: any
    validations?: any
    additionalNotes?: string
    additionalNotesShow?: boolean
    liClass?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    show: true
  })

  watch(
    checkbox,
    (val) => {
      emit('update:modelValue', val.length.toString())
    },
    { deep: true }
  )

  watch(needValidation, () => {
    props.validation.validate()
  })

  const validate = () => {
    props.validation.validate()
  }

  onMounted(() => {
    checkbox.value = props?.modelValue === '1' ? ['1'] : []
  })

  defineExpose({
    validate
  })
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 0;
  }

  ul,
  li {
    list-style: none;
  }

  .is-border .checkbox-group {
    border: solid 1px #7daaff;
    padding: 10px;
  }

  .checkbox-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    width: 100%;

    .checkbox-item {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1.25rem;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      label {
        flex: 1;
        font-weight: 300;
      }

      input[type='checkbox'] {
        margin-top: 0;
        margin-right: 5px;
        flex: 0 0 30px;
      }
    }
  }
</style>
