<template>
  <div v-if="!state.init">
    <the-loading style="margin-top: 100px; margin-bottom: 100px"></the-loading>
  </div>

  <template v-else>
    <div class="tstep">
      <img
        :src="`${s3Url}img/pc_step2.${language}.png?v=1`"
        class="sp_no"
        alt="step"
      />
      <img
        :src="`${s3Url}img/sp_step2.${language}.png?v=1`"
        class="sp"
        alt="step"
      />
    </div>
    <the-message v-if="state.errorMessage" class="form-error">{{
      state.errorMessage
    }}</the-message>
    <div class="individual">
      <div class="individual-wrapper">
        <ol class="step mb30">
          <li class="is-current br-tl-6 br-bl-6"
            >１{{ $t('pages.Individual.title.step1') }}</li
          >
          <li><span style="padding-left: 1em">２</span></li>
          <li><span style="padding-left: 1em">３</span></li>
          <li class="br-tr-6 br-br-6">{{
            $t('pages.Individual.title.step4')
          }}</li>
        </ol>

        <form>
          <h1>{{ $t('pages.Individual.title.step1') }}</h1>
          <p>{{ $t('pages.Individual.step1.msg1') }}</p>

          <ul class="form-normal">
            <SchemaFormWithValidation
              :schema="state.schema"
              :model="state.formJson"
              useCustomFormWrapper
              ref="form"
              class="individual-form"
            />

            <li>
              <span>
                <label
                  >{{ $t('pages.Individual.form.transaction_objective') }}
                  <span class="required-notice">{{ $t('form.required') }}</span>
                </label>
              </span>
              <div class="checkbox-group" id="transaction_objective">
                <div
                  class="checkbox-item"
                  v-for="option in options.individual_transaction_objective"
                  :key="option.key"
                >
                  <input
                    type="checkbox"
                    :id="option.value"
                    name="transaction_objective"
                    :value="option.key"
                    class="uk-checkbox"
                    v-model="transaction_objective"
                  />
                  <label :for="option.value">{{ option.value }}</label>
                </div>
              </div>
              <label
                class="error"
                style="margin-top: 0.5rem"
                v-if="transaction_objective_error"
                >{{ $t('validation.individual.transaction_objective') }}</label
              >
            </li>
            <li class="flex-1-1" v-show="transaction_objective?.includes('99')">
              <span>
                <label class="madd_title"
                  >{{ $t('pages.Individual.form.transaction_objective_other') }}
                  <span class="required-notice">{{ $t('form.required') }}</span>
                </label>
              </span>
              <input
                type="text"
                required
                v-model="transaction_objective_other"
                class="form-control valid"
              />
              <label
                class="error"
                style="margin-top: -0.5rem"
                v-if="transaction_objective_other_error"
                >{{
                  transaction_objective_other.length > 64
                    ? $t('validation.maxLength', {
                        maxLength:
                          validator.transaction_objective_other.maxLength
                      })
                    : $t('validation.required', {
                        content: t(
                          'pages.Individual.form.transaction_objective_other'
                        ),
                        type: t('validation.type.input')
                      })
                }}</label
              >
            </li>

            <SchemaFormWithValidation
              :schema="state.schema_2"
              :model="state.formJson_2"
              useCustomFormWrapper
              ref="form_2"
              class="individual-form"
              style="padding-top: 0"
            />
          </ul>

          <button
            class="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom primaryAction"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.Individual.step1.submit')
            }"
            type="button"
            >{{ $t('pages.Individual.step1.submit') }}</button
          >
        </form>
      </div>
    </div>
  </template>
  <!-- ここから Crib Notesユニバーサルタグのコード -->
  <component :is="'script'" v-if="env === 'prod' && state.clib_counter_flg">
    (function (b, f, d, a, c) { var e = b.createElement(f); e.src = c + "/" + a
    + "/atm.js"; e.id = d; e.async = true;
    b.getElementsByTagName(f)[0].parentElement.appendChild(e) })(document,
    "script", "__cribnotesTagMgrCmd", "4f5ad26c-b405-4a75-8a16-17fc85ce53bd",
    "https://tag.cribnotes.jp/container_manager");
  </component>
  <!-- ここまで Crib Notesユニバーサルタグのコード -->
</template>

<script lang="ts" setup>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'
  import * as yup from 'yup'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import SchemaFormWithValidation from '@/components/SchemaFormWithValidation.vue'
  import {
    addFormStyle,
    checkDate,
    assignForm,
    watchOptions
  } from '@/utils/tool'
  import { validator, options } from '@/enum/enums'
  import { getAddrByPostCode, getIndividual, setIndividual } from '@/api'

  const { t } = useI18n()
  const router = useRouter()
  const store = useMainStore()
  const { s3Url, language, env } = storeToRefs(store)

  const state: {
    clib_counter_flg: boolean
    schema: any
    schema_2: any
    formJson: any
    formJson_2: any
    errorMessage: string
    init: boolean
  } = reactive({
    clib_counter_flg: false,
    schema: {
      name_sei: {
        component: 'Text',
        label: t('pages.Individual.form.name_sei'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.name_sei'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.name_sei'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_kanji.regex as RegExp,
            t('validation.individual.input_error', {
              content: t('pages.Individual.form.name_sei')
            })
          )
      },
      name_mei: {
        component: 'Text',
        label: t('pages.Individual.form.name_mei'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.name_mei'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.name_mei'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_kanji.regex as RegExp,
            t('validation.individual.input_error', {
              content: t('pages.Individual.form.name_mei')
            })
          )
      },
      name_sei_kana: {
        component: 'Text',
        label: t('pages.Individual.form.name_sei_kana'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.name_sei_kana'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        additionalNotesShow: true,
        additionalNotes: t('validation.zen_kana_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.name_sei_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_katakana.regex as RegExp,
            t('validation.individual.input_error', {
              content: t('pages.Individual.form.name_sei_kana')
            })
          )
      },
      name_mei_kana: {
        component: 'Text',
        label: t('pages.Individual.form.name_mei_kana'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.name_mei_kana'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        additionalNotesShow: true,
        additionalNotes: t('validation.zen_kana_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.name.maxLength as number,
            t('validation.maxLength', { maxLength: validator.name.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.name_mei_kana'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.name_katakana.regex as RegExp,
            t('validation.individual.input_error', {
              content: t('pages.Individual.form.name_mei_kana')
            })
          )
      },
      birthday: {
        component: 'Text',
        label: t('pages.Individual.form.birthday'),
        required: true,
        requiredStyle: 'form',
        type: 'date',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.birthday'),
              type: t('validation.type.input')
            })
          )
          .test('is-date', t('validation.date_error'), checkDate)
      },
      post_no: {
        component: 'Text',
        label: t('pages.Individual.form.post_no'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.post_no'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'flex-1-1',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.post_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.post_no.regex as RegExp,
            t('validation.hankaku_number_error', {
              number: validator.post_no.maxLength
            })
          )
      },
      addr1: {
        component: 'Text',
        label: t('pages.Individual.form.addr1'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.addr1'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr1.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr1.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.addr1'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr1.regex as RegExp,
            t('validation.individual.input_error', {
              content: t('pages.Individual.form.addr1')
            })
          )
      },
      addr2: {
        component: 'Text',
        label: t('pages.Individual.form.addr2'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.addr2'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr2.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr2.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.addr2'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr2.regex as RegExp,
            t('validation.individual.input_error', {
              content: t('pages.Individual.form.addr2')
            })
          )
      },
      addr3: {
        component: 'Text',
        label: t('pages.Individual.form.addr3'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.addr3'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        additionalNotesShow: true,
        additionalNotes: t('validation.zen_warning'),
        validations: yup
          .string()
          .trim()
          .max(
            validator.addr3.maxLength as number,
            t('validation.maxLength', { maxLength: validator.addr3.maxLength })
          )
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.addr3'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.addr3.regex as RegExp,
            t('validation.individual.input_error', {
              content: t('pages.Individual.form.addr3')
            })
          )
      },
      country: {
        component: 'Select',
        label: t('pages.Individual.form.country'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.country,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.country'),
            type: t('validation.type.select')
          })
        )
      },
      sex: {
        component: 'Select',
        label: t('pages.Individual.form.sex'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.sex,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.sex'),
            type: t('validation.type.select')
          })
        )
      },
      mobile_no: {
        component: 'Text',
        label: t('pages.Individual.form.mobile_no'),
        required: true,
        requiredStyle: 'form',
        placeholder: t('pages.Individual.placeholder.mobile_no'),
        type: 'text',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'is-half',
        validations: yup
          .string()
          .trim()
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.mobile_no'),
              type: t('validation.type.input')
            })
          )
          .matches(
            validator.mobile_no.regex as RegExp,
            t('validation.hankaku_number_error', {
              number: validator.mobile_no.maxLength
            })
          )
      },
      profession: {
        component: 'Radio',
        name: 'profession',
        label: t('pages.Individual.form.profession'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.profession,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.profession'),
            type: t('validation.type.select')
          })
        )
      },
      profession_other: {
        component: 'Text',
        type: 'text',
        label: t('pages.Individual.form.profession_other'),
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'flex-1-1',
        show: false,
        validations: undefined
      }
    },
    schema_2: {
      motivation_user: {
        component: 'Radio',
        label: t('pages.Individual.form.motivation_user'),
        required: true,
        requiredStyle: 'form',
        name: 'motivation_user',
        placeholders: t('form.select.placeholder'),
        options: options.motivation_user,
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.motivation_user'),
            type: t('validation.type.select')
          })
        )
      },
      motivation_user_other: {
        component: 'Text',
        label: t('pages.Individual.form.motivation_user_other'),
        type: 'text',
        requiredStyle: 'form',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        liClass: 'flex-1-1',
        show: false,
        validations: undefined
      },
      others_1: {
        component: 'Others',
        html: t('pages.Individual.step1.msg2')
      },
      others_2: {
        component: 'Others',
        html: t('pages.Individual.step1.msg3')
      },
      peps_kbn: {
        component: 'Checkbox',
        name: 'peps_kbn',
        label: t('pages.Individual.form.peps_kbn'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        liClass: 'is-border',
        options: options.peps_kbn,
        validations: undefined
      },
      ability_act: {
        component: 'Checkbox',
        name: 'ability_act',
        label: t('pages.Individual.form.ability_act'),
        required: true,
        requiredStyle: 'form',
        liClass: 'is-border',
        placeholders: t('form.select.placeholder'),
        options: options.ability_act,
        validations: undefined
      },
      financial_asset: {
        component: 'Select',
        label: t('pages.Individual.form.financial_asset'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.financial_asset,
        liClass: 'flex-1-1',
        additionalNotesShow: true,
        additionalNotes: t('pages.Individual.form.financial_asset_tip'),
        additionalNotesPosition: 'top',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.financial_asset'),
            type: t('validation.type.select')
          })
        )
      },
      annual_income: {
        component: 'Select',
        label: t('pages.Individual.form.annual_income'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.annual_income,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.annual_income'),
            type: t('validation.type.select')
          })
        )
      },
      exp_cripto_actual: {
        component: 'Select',
        label: t('pages.Individual.form.exp_cripto_actual'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.exp_cripto_actual'),
            type: t('validation.type.select')
          })
        )
      },
      exp_fx: {
        component: 'Select',
        label: t('pages.Individual.form.exp_fx'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.exp_fx'),
            type: t('validation.type.select')
          })
        )
      },
      exp_stock_trading: {
        component: 'Select',
        label: t('pages.Individual.form.exp_stock_trading'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.exp_stock_trading'),
            type: t('validation.type.select')
          })
        )
      },
      exp_stock_credit: {
        component: 'Select',
        label: t('pages.Individual.form.exp_stock_credit'),
        required: true,
        requiredStyle: 'form',
        placeholders: t('form.select.placeholder'),
        options: options.experience,
        liClass: 'flex-1-1',
        validations: yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.exp_stock_credit'),
            type: t('validation.type.select')
          })
        )
      },
      exp_other_checkbox: {
        component: 'Checkbox',
        name: 'exp_other_checkbox',
        options: [
          { key: '1', value: t('pages.Individual.form.exp_other_text') }
        ]
      },
      exp_other_text: {
        component: 'Text',
        type: 'text',
        liClass: 'is-half',
        inputClass: 'form-control valid',
        labelClass: 'madd_title',
        show: false
      },
      exp_other: {
        component: 'Select',
        placeholders: t('form.select.placeholder'),
        options: options.experience.filter((i) => i.key !== '010'),
        liClass: 'is-half',
        show: false
      }
    },
    formJson: {},
    formJson_2: {},
    errorMessage: '',
    init: false
  })

  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const form_2 = ref<(HTMLInputElement & { validate: any }) | null>(null)

  const transaction_objective = ref<string[]>([])
  const transaction_objective_other = ref('')
  const transaction_objective_error = ref(false)
  const transaction_objective_other_error = ref(false)

  const loading = ref(false)

  const submit = async () => {
    console.log('submit')
    state.schema_2.peps_kbn.validations = yup.string().oneOf(
      ['1'],
      t('validation.required', {
        content: t('pages.Individual.form.peps_kbn'),
        type: t('validation.type.select')
      })
    )

    state.schema_2.ability_act.validations = yup.string().oneOf(
      ['1'],
      t('validation.required', {
        content: t('pages.Individual.form.ability_act'),
        type: t('validation.type.select')
      })
    )
    if (
      !transaction_objective.value ||
      transaction_objective.value.length === 0
    )
      transaction_objective_error.value = true

    const valid = form.value?.validate()
    const valid_2 = form_2.value?.validate()
    setTimeout(async () => {
      if (
        (valid?.meta?.valid &&
          valid_2?.meta?.valid &&
          !transaction_objective_error.value &&
          !transaction_objective_other_error.value) ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true
        try {
          const formData = Object.assign(
            {},
            state.formJson,
            state.formJson_2,
            { transaction_objective: transaction_objective.value },
            {
              transaction_objective_other: transaction_objective_other.value
            }
          )
          formData.motivation_user = [formData.motivation_user]
          formData.profession = [formData.profession]

          await setIndividual({
            contents: {
              ...formData
            }
          })
          router.push({ name: 'IndividualStep1Confirm' })
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
        }
        loading.value = false
      } else {
        document.documentElement.scrollTop =
          document.getElementsByTagName('form')[0].offsetTop
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getIndividual(
        router.currentRoute.value.query?.uncheck_flg?.toString() === '1'
          ? 'uncheck'
          : ''
      )
      state.clib_counter_flg = contents?.clib_counter_flg
      state.formJson = Object.assign(
        state.formJson,
        assignForm(state.schema, contents)
      )
      state.formJson_2 = Object.assign(
        state.formJson_2,
        assignForm(state.schema_2, contents)
      )
      state.formJson_2.motivation_user = contents.motivation_user[0]?.toString()
      state.formJson.profession = contents.profession[0]?.toString()
      state.formJson_2.exp_other_checkbox = contents.exp_other ? '1' : ''
      transaction_objective.value = contents?.transaction_objective || []
      transaction_objective_other.value =
        contents?.transaction_objective_other || ''
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      state.init = true
      addFormStyle()
      document.documentElement.scrollTop = 0
    }
  })

  watch(
    () => state.formJson.post_no,
    async (value: string) => {
      if (validator.post_no?.regex?.exec(value)) {
        const { contents } = await getAddrByPostCode(value)
        if (contents?.prefecture) state.formJson.addr1 = contents?.prefecture
        if (contents?.city) state.formJson.addr2 = contents?.city
        if (contents?.town)
          state.formJson.addr2 = state.formJson.addr2 + contents?.town
      }
    }
  )

  watch(
    () => state.formJson_2.peps_kbn,
    async (value: string, oldValue: string) => {
      if (oldValue === undefined) return
      state.schema_2.peps_kbn.validations = yup.string().oneOf(
        ['1'],
        t('validation.required', {
          content: t('pages.Individual.form.peps_kbn'),
          type: t('validation.type.select')
        })
      )
    }
  )

  watch(
    () => state.formJson_2.ability_act,
    async (value: string, oldValue: string) => {
      if (oldValue === undefined) return
      state.schema_2.ability_act.validations = yup.string().oneOf(
        ['1'],
        t('validation.required', {
          content: t('pages.Individual.form.ability_act'),
          type: t('validation.type.select')
        })
      )
    }
  )

  watch(
    () => state.formJson_2.exp_other_checkbox,
    (value, oldValue) => {
      if (value === '1') {
        state.schema_2.exp_other.show = true
        state.schema_2.exp_other_text.show = true
        state.schema_2.exp_other.validations = yup.string().required(
          t('validation.required', {
            content: t('pages.Individual.form.exp_other'),
            type: t('validation.type.select')
          })
        )
        state.schema_2.exp_other_text.validations = yup
          .string()
          .required(
            t('validation.required', {
              content: t('pages.Individual.form.exp_other_text'),
              type: t('validation.type.input')
            })
          )
          .max(
            validator.exp_other_text.maxLength as number,
            t('validation.maxLength', {
              maxLength: validator.exp_other_text.maxLength
            })
          )
      } else {
        state.schema_2.exp_other.show = false
        state.schema_2.exp_other_text.show = false
        state.schema_2.exp_other.validations = undefined
        state.schema_2.exp_other_text.validations = undefined
      }

      if (oldValue === '1' && value !== '1') {
        state.formJson_2.exp_other = undefined
        state.formJson_2.exp_other_text = ''
      }
    },
    { immediate: true }
  )

  watch(
    () => state.formJson.profession,
    async (value: string) => {
      watchOptions(
        value,
        '99',
        'profession_other',
        state.schema,
        state.formJson,
        yup,
        t,
        'Individual'
      )
    },
    { immediate: true }
  )

  watch(
    () => state.formJson_2.motivation_user,
    async (value: string) => {
      watchOptions(
        value,
        '99',
        'motivation_user_other',
        state.schema_2,
        state.formJson_2,
        yup,
        t,
        'Individual'
      )
    },
    { immediate: true }
  )

  watch(
    () => transaction_objective.value,
    async (value: string[], oldValue: string[]) => {
      if (value.length === oldValue.length) return
      if (!value.includes('99')) transaction_objective_other.value = ''

      transaction_objective_error.value = value.length === 0
      transaction_objective_other_error.value =
        (value?.includes('99') && !transaction_objective_other.value) ||
        transaction_objective_other.value.length > 64
    }
  )

  watch(
    () => transaction_objective_other.value,
    async (value: string) => {
      transaction_objective_other_error.value =
        (transaction_objective.value?.includes('99') && !value) ||
        value.length > 64
    }
  )
</script>

<style lang="scss" scoped>
  .checkbox-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    width: 100%;

    .checkbox-item {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1.25rem;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      label {
        flex: 1;
        font-weight: 300;
      }

      input[type='checkbox'] {
        margin-top: 0;
        margin-right: 5px;
        flex: 0 0 30px;
      }
    }
  }
</style>
