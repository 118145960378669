import { PleaseReadType } from '@/@types'
import i18n from '@/locale'

export const pleaseRead: PleaseReadType[] = [
  {
    // 暗号資産現物取引サービス基本約款
    name: i18n.global.t('menu.policy.regulation_basic_contract'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/crypto_assets_spot_trading_service_terms_and_conditions`
  },
  // {
  //   // 暗号資産現物取引サービス基本約款 新旧対照表
  //   name: i18n.global.t('menu.policy.regulation_basic_contract_check'),
  //   pdf: `${import.meta.env.VITE_APP_S3_URL}resource/crypto_assets_spot_trading_service_terms_and_conditions_new_and_old_comparison_table`
  // },
  {
    // 暗号資産概要説明書
    route: 'ExplanatoryDocument',
    name: i18n.global.t('menu.policy.explanatory_document')
  },
  {
    // 反社会的勢力への対応に関する基本方針
    route: 'AntiSocial',
    name: i18n.global.t('menu.policy.antisocial')
    // pdf: `${import.meta.env.VITE_APP_S3_URL}resource/basic_policy_on_responses_to_antisocial_forces`
  },
  {
    // 反社会的勢力の排除について
    // route: 'AntiSocialElimination',
    name: i18n.global.t('menu.policy.antisocial_elimination'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/elimination_of_anti_social_forces`
  },
  {
    // 個人情報保護方針
    name: i18n.global.t('menu.policy.personal_Information'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/personal_information_protection_policy`
    // route: 'PrivacyPolicy',
    // name: i18n.global.t('menu.policy.personal_Information')
    // pdf: `${import.meta.env.VITE_APP_S3_URL}resource/personal_information_protection_policy`
  },
  {
    // 倫理コード
    // route: 'Ethics',
    name: i18n.global.t('menu.policy.ethics'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/code_of_ethics`
  },
  {
    // マネー・ローンダリング等防止のための基本方針
    name: i18n.global.t('menu.policy.money_laundering_policy'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/basic_policy_to_prevent_money_laundering_etc`
  },
  {
    // 情報セキュリティ基本方針
    // route: 'InformationSecurityBasicPolicy',
    name: i18n.global.t('menu.policy.information_security_basic_policy'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/information_security_basic_policy`
  },
  {
    // システムリスク管理基本方針
    name: i18n.global.t('menu.policy.basic_policy_on_system_risk_management'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/basic_policy_on_system_risk_management`
  },
  {
    // 債務の履行に関する方針
    // route: 'DebtFulfillment',
    name: i18n.global.t('menu.policy.debt_fulfillment'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/policies_for_debt_fulfillment`
  },
  {
    // 利益相反管理方針
    // route: 'ConflictInterest',
    name: i18n.global.t('menu.policy.conflict_interest'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/conflict_of_interest_management_policy`
  },
  {
    // ネット犯罪等の不正防止策についての注意喚起
    // route: 'SecurityPolicy',
    name: i18n.global.t('menu.policy.securitypolicy'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/calling_attention_to_fraud_prevention_measures_for_online_crimes`
  },
  {
    // 当社における計画されたハードフォーク及び新コインへの対応指針
    // route: 'ResponsePolicy',
    name: i18n.global.t('menu.policy.response_policy'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/our_guidelines_for_planned_hard_forks_and_new_coins`
  },
  {
    // 当社の苦情処理・紛争解決に係る業務運営体制等について
    // route: 'Claim',
    name: i18n.global.t('menu.policy.claim'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/our_business_operation_system_for_complaint_handling_and_dispute_resolution`
  },
  // 暗号資産現物取引サービス説明書（契約締結前交付書面）
  {
    name: i18n.global.t(
      'menu.policy.contract_pre_conclusion_delivery_documents'
    ),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/service_manual_of_crypto_assets_spot_trading_document_delivered_prior_to_conclusion_of_a_contract`
  },
  // 暗号資産現物取引サービス説明書（契約締結前交付書面）新旧対照表
  // {
  //   name: i18n.global.t(
  //     'menu.policy.contract_pre_conclusion_delivery_documents_check'
  //   ),
  //   pdf: `${import.meta.env.VITE_APP_S3_URL}resource/service_manual_of_crypto_assets_spot_trading_document_delivered_prior_to_conclusion_of_a_contract_new_and_old_comparison_table`
  // },
  // ひまぽいサービス利用規約
  {
    name: i18n.global.t('menu.policy.bp_terms_of_service'),
    pdf: `${import.meta.env.VITE_APP_S3_URL}resource/himapoi_service_terms_of_use`
  },
  // ひまぽいサービス利用規約 新旧対照表
  // {
  //   name: i18n.global.t('menu.policy.bp_terms_of_service_check'),
  //   pdf: `${import.meta.env.VITE_APP_S3_URL}resource/himapoi_service_terms_of_use_new_and_old_comparison_table`
  // },
  // 当社における新規取り扱い暗号資産の審査について
  {
    route: 'JudgingApplicationCryptoAssets',
    name: i18n.global.t('menu.policy.judging_application_crypto_assets')
  }
]
