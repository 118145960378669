<template>
  <the-message-trade :message="state.message"></the-message-trade>

  <div class="business_time_over" v-if="!props.business_time">
    <div class="business_time_over_content">
      <img src="@static/img/lock.svg" />
      <p v-html="t('pages.Trade.order.msg1')"></p>
    </div>
  </div>

  <div class="data bg-dark orderslippage">
    <ul>
      <li
        >{{ $t('pages.Trade.order.qty') }}({{ props.type?.toUpperCase() }})</li
      >
      <li>
        <input
          type="number"
          title=""
          inputmode="decimal"
          class="form-control text-right"
          autocomplete="off"
          v-bind:placeholder="props.input_min"
          v-model="state.qty"
          onkeydown="return event.keyCode !== 69"
        />
      </li>
    </ul>

    <div class="form-select-price">
      <button
        type="button"
        name="market_qty_plus"
        :value="(obj as string)"
        class="plus-btn"
        v-for="obj in props.btn_list"
        :key="(obj as string)"
        v-on:click="state.qty = qty_plus(state.qty, obj as string)"
        >+{{ obj }}</button
      >

      <button
        type="button"
        value="0"
        class="plus-btn"
        v-on:click="state.qty = '0'"
        style="width: auto"
        >✕</button
      >
    </div>
  </div>

  <div class="data bg-dark orderslippage">
    <ul>
      <li class="slippage-switch"
        >{{ $t('pages.Trade.order.slippage') }}
        <input
          id="btn_slippage"
          type="checkbox"
          name="btn_slippage"
          v-model="state.slippage_flg"
        />
        <label
          for="btn_slippage"
          v-bind:class="[state.slippage_flg ? 'btn_update' : 'btn_off']"
          ><span></span
        ></label>
      </li>

      <li id="slippage-parts" style="display: block; height: 52px">
        <div class="jpys">
          <input
            type="number"
            title=""
            inputmode="decimal"
            class="form-control text-right"
            id="input_slippage"
            name="input_slippage"
            maxlength="4"
            v-model="state.slippage"
            v-if="state.slippage_flg"
            v-bind:max="props.other?.slippage"
            v-bind:placeholder="props.other?.slippage"
            onkeydown="return event.keyCode !== 69"
          />
        </div>
      </li>
    </ul>
  </div>

  <div class="market-trade-area">
    <ul class="data-4col">
      <li style="border-bottom: none">
        <label class="checkbox-agreement">
          <input
            type="checkbox"
            class="market-checkbox-input"
            v-on:click="setTradeConfirm($event)"
            v-model="state.order_confirm_flg"
            id="market_trade_agreement"
          />
          <span style="margin-left: 5px">{{
            $t('pages.Trade.order.msg2')
          }}</span></label
        >
      </li>
    </ul>

    <div>
      <div class="bg-dark" style="margin-top: 10px">
        <div class="approx-amount-container">
          <div class="approx-amount-box sell-box">
            <div class="approx-amount-label"
              >{{ $t('pages.Trade.order.bid') }}：{{
                $t('pages.Trade.order.approx_amount')
              }}</div
            >
            <div class="approx-amount-value">{{
              Math.round(state.approx_amount_bid).toLocaleString()
            }}</div>
          </div>
          <div class="approx-amount-box buy-box">
            <div class="approx-amount-label"
              >{{ $t('pages.Trade.order.ask') }}：{{
                $t('pages.Trade.order.approx_amount')
              }}</div
            >
            <div class="approx-amount-value">{{
              Math.round(state.approx_amount_ask).toLocaleString()
            }}</div>
          </div>
        </div>
      </div>
    </div>
    <ul class="data-4col">
      <button
        class="mat-btn uk-button bg-red"
        style="width: 50%"
        v-debounce="{
          func: confirm
        }"
        v-loading="{
          loading: state.loading,
          value:
            $t('pages.Trade.order.sell') +
            ' ' +
            (
              (store.ws.crypto &&
                store.ws.crypto[props.type?.toLocaleLowerCase()]?.bid) ||
              0
            ).toLocaleString()
        }"
        value="sell"
        >{{ $t('pages.Trade.order.sell') }}
        {{
          (
            (store.ws.crypto &&
              store.ws.crypto[props.type?.toLocaleLowerCase()]?.bid) ||
            0
          ).toLocaleString()
        }}</button
      >
      <button
        class="mat-btn uk-button bg-green"
        style="width: 50%"
        v-debounce="{
          func: confirm
        }"
        v-loading="{
          loading: state.loading,
          value:
            $t('pages.Trade.order.buy') +
            ' ' +
            (
              (store.ws.crypto &&
                store.ws.crypto[props.type?.toLocaleLowerCase()]?.ask) ||
              0
            ).toLocaleString()
        }"
        value="buy"
        >{{ $t('pages.Trade.order.buy') }}
        {{
          (
            (store.ws.crypto &&
              store.ws.crypto[props.type?.toLocaleLowerCase()]?.ask) ||
            0
          ).toLocaleString()
        }}</button
      >
    </ul>
  </div>

  <the-modal
    v-if="state.modal"
    id="modal-confirm"
    hasClose
    @close="state.modal = false"
  >
    <section class="modal-inner">
      <h1>{{ $t('pages.Trade.order.order_confirm') }}</h1>

      <div class="corporation-confirm">
        <dl>
          <dt>{{ $t('pages.Trade.order.brand_name') }}</dt>
          <dd
            >{{ $t('pages.Trade.order.spot')
            }}{{ props.type?.toUpperCase() }}JPY</dd
          >
        </dl>
        <dl>
          <dt>{{ $t('pages.Trade.order.qty') }}</dt>
          <dd>{{ state.qty }}</dd>
        </dl>
        <dl>
          <dt>{{ $t('pages.Trade.order.rate') }}</dt>
          <dd>{{ state.target_rate.toLocaleString() }}</dd>
        </dl>
      </div>

      <button
        class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
        v-debounce="{
          func: submit
        }"
        v-loading="{
          loading: state.loading,
          value: [
            state.trade_type == 'buy'
              ? $t('pages.Trade.order.buy')
              : $t('pages.Trade.order.sell')
          ]
        }"
        v-bind:class="[state.trade_type == 'buy' ? 'bg-green' : 'bg-red']"
        type="button"
        >{{
          state.trade_type == 'buy'
            ? $t('pages.Trade.order.buy')
            : $t('pages.Trade.order.sell')
        }}</button
      >
    </section>
  </the-modal>
</template>

<script lang="ts" setup>
  import { reactive, onMounted, watch } from 'vue'
  import { useMainStore } from '@/store/pinia'
  import { qty_plus, make_massage } from '@/utils/tool'
  import { useI18n } from 'vue-i18n'
  import TheModal from '@/components/TheModal.vue'
  import { setTradeConfirmFlg, orderMarket } from '@/api'

  const { t } = useI18n()
  const store = useMainStore()

  const props = defineProps({
    type: String,
    tradetoken: String,
    btn_list: Array,
    input_min: String,
    setting: Object,
    other: Object,
    business_time: Boolean,
    rate_ask: String,
    rate_bid: String
  })
  const emit = defineEmits<{
    (e: 'onchange'): void
  }>()
  const state: {
    qty: string
    slippage: string
    approx_amount: string
    approx_amount_ask: string
    approx_amount_bid: string
    trade_type: string
    target_rate: string
    slippage_flg: boolean
    order_confirm_flg: boolean
    loading: boolean
    modal: boolean
    message: any
  } = reactive({
    qty: '0',
    slippage: '0',
    approx_amount: '0',
    approx_amount_ask: '0',
    approx_amount_bid: '0',
    trade_type: '',
    target_rate: '0',
    slippage_flg: true,
    order_confirm_flg: false,
    loading: false,
    modal: false,
    message: {}
  })

  const setTradeConfirm = async (e: any) => {
    try {
      const { contents } = await setTradeConfirmFlg(
        (props.type as string)?.toLowerCase(),
        {
          contents: {
            bgauthtoken: props.tradetoken as string,
            check: e.target.checked
          }
        }
      )
    } catch (error: any) {
      state.message = {
        error: make_massage(error?.message, t)
      }
    }
  }

  const confirm = async (e: any) => {
    state.trade_type = e.target.value
    const rate_info =
      store.ws.crypto[(props.type as string)?.toLocaleLowerCase()]
    state.target_rate = e.target.value == 'sell' ? rate_info.bid : rate_info.ask

    if (parseFloat(state.qty) <= 0) {
      return
    }

    if (!state.order_confirm_flg) {
      state.modal = true
    } else {
      submit()
    }
  }

  const submit = async () => {
    try {
      state.loading = true
      const { contents } = await orderMarket(
        (props.type as string)?.toLowerCase(),
        {
          contents: {
            qty: state.qty,
            trade_type: state.trade_type,
            slippage: state.slippage_flg ? state.slippage : 0,
            target_rate: state.target_rate,
            bgauthtoken: props.tradetoken
          }
        }
      )
      emit('onchange')
      state.message = {
        success: t('error.success_order_market', {
          trade_type: t('pages.Trade.trade_type.' + state.trade_type)
        })
      }
    } catch (error: any) {
      state.message = {
        error: make_massage(error?.message, t)
      }
    } finally {
      state.modal = false
      setTimeout(function () {
        state.loading = false
      }, 3000)
    }
  }

  onMounted(async () => {
    state.qty = props.input_min || '0'
    state.slippage = props.other?.slippage || '0'
    state.slippage_flg = props.other?.slippage != 0 ? true : false
    state.order_confirm_flg = props.setting?.market_agreement == '1'
  })

  watch(
    () => props.input_min as string,
    async (value: string, oldValue: string) => {
      state.qty = props.input_min || '0'
    }
  )

  watch(
    () => store.ws.crypto,
    async (value: any, oldValue: any) => {
      state.approx_amount_ask = parseFloat(
        state.qty * (value?.[props.type?.toLocaleLowerCase()]?.ask || 0)
      )

      state.approx_amount_bid = parseFloat(
        state.qty * (value?.[props.type?.toLocaleLowerCase()]?.bid || 0)
      )
    }
  )

  watch(
    () => state.qty as string,
    async (value: string, oldValue: string) => {
      let current_ask = 0
      let current_bid = 0
      if (store.ws.crypto) {
        current_ask =
          parseFloat(store.ws.crypto[props.type?.toLocaleLowerCase()]?.ask) || 0
        current_bid =
          parseFloat(store.ws.crypto[props.type?.toLocaleLowerCase()]?.bid) || 0
      } else {
        current_ask = parseFloat(props.rate_ask)
        current_bid = parseFloat(props.rate_bid)
      }

      state.approx_amount_ask = state.qty * current_ask

      state.approx_amount_bid = state.qty * current_bid
    }
  )
</script>

<style lang="scss" scoped>
  @use '@/static/styles/trade-list.scss';
  @use '@/static/styles/trade-order.scss';
  .loader {
    margin: 0px auto !important;
  }
  h1 {
    font-size: 2rem;
    margin: 2rem auto;
  }
  .corporation-confirm {
    padding-top: unset !important;
  }

  .business_time_over {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    z-index: 970;
  }
  .business_time_over_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .business_time_over_content img {
    display: block;
    height: 100px;
    margin-bottom: 1.5rem;
    opacity: 0.8;
  }

  .approx-amount-container {
    display: flex;
    width: 100%;
    gap: 2px;
  }

  .approx-amount-box {
    flex: 1;
    padding: 8px 12px;
    border-radius: 4px;

    .approx-amount-label {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 4px;
      text-align: center;
    }

    .approx-amount-value {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }
  }

  .sell-box {
    background-color: rgba(255, 59, 48, 0.15);
    .approx-amount-value {
      color: #ff3b30;
    }
  }

  .buy-box {
    background-color: rgba(52, 199, 89, 0.15);
    .approx-amount-value {
      color: #34c759;
    }
  }
</style>
