<template>
  <!-- article start -->
  <article id="toppage">
    <div class="wblock pw_40">
      <div class="bh3 fw_m">{{ $router.currentRoute?.value?.meta?.title }}</div>

      <div class="fee_table_wrap">
        <div class="sliderArea">
          <div class="thumb slick-initialized slick-slider">
            <div class="slick-track">
              <div
                class="slick-slide slick-active"
                :class="{ 'slick-current': index === state.carouselIndex }"
                v-for="(item, index) in state.crypto"
                @click="changeCrypto(index)"
                :modelValue="state.currentIndex"
                :key="item"
                ><img
                  :src="`${s3Url}img/icon_${item.toLocaleLowerCase()}.png`"
                  :alt="item.toLocaleLowerCase()"
                />
                {{ item.toLocaleUpperCase() }}
              </div>
            </div>
          </div>
          <carousel
            :items-to-show="1"
            :wrap-around="true"
            ref="myCarousel"
            v-if="state.crypto.length > 0"
          >
            <template v-for="item in state.crypto" :key="item">
              <slide>
                <div class="slide-item">
                  <div class="bh5 fw_m fc_y"
                    >{{ item.toLocaleUpperCase() }}（{{
                      $t(`coins.${item.toLocaleLowerCase()}`)
                    }}）</div
                  >
                  <div class="">
                    <table class="fee_table">
                      <tbody>
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th1_1`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td1_1`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th" colspan="2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.title2`)
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_1`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_1`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_2`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_2`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_3`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_3`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_4`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_4`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_5`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_5`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_6`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_6`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_7`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_7`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_8`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_8`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_9`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_9`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_10`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_10`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_11`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_11`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_12`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_12`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_13`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_13`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_14`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_14`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_15`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_15`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_16`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_16`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_17`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_17`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th2_18`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td2_18`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th" colspan="2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.title3`)
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th3_1`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td3_1`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th3_2`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td3_2`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th3_3`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td3_3`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th3_4`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td3_4`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th3_5`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td3_5`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th" colspan="2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.title4`)
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_1`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_1`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_2`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_2`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_3`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_3`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_4`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_4`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_5`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_5`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_6`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_6`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_7`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_7`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_8`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_8`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_9`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_9`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_10`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_10`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_11`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_11`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th4_12`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td4_12`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th" colspan="2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.title5`)
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th5_1`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td5_1`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th5_2`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td5_2`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th5_3`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td5_3`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th5_4`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td5_4`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th5_5`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td5_5`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th5_6`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td5_6`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th" colspan="2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.title6`)
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th6_1`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td6_1`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th6_2`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td6_2`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th6_3`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td6_3`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th6_4`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td6_4`
                            )
                          }}</td></tr
                        >
                        <tr
                          ><td class="fee_th2">{{
                            $t(`pages.Policy.ExplanatoryDocument.table.th6_5`)
                          }}</td
                          ><td class="fee_td2">{{
                            $t(
                              `pages.Policy.ExplanatoryDocument.${item.toLocaleLowerCase()}.td6_5`
                            )
                          }}</td></tr
                        >
                      </tbody>
                    </table>
                  </div>
                </div>
              </slide>
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </article>
  <!-- article end -->
</template>

<script lang="ts" setup>
  import { ref, reactive, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/pinia'
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide } from 'vue3-carousel'

  const store = useMainStore()
  const { s3Url, tokenDeploy } = storeToRefs(store)
  const myCarousel = ref<any>(null)
  const state: {
    carouselIndex: number
    currentIndex: number
    crypto: string[]
  } = reactive({
    carouselIndex: 0,
    currentIndex: 0,
    crypto: []
  })

  Object.keys(tokenDeploy.value).map((key: string) => {
    if (tokenDeploy.value[key] === 'true') state.crypto.push(key)
  })

  const changeCrypto = (index: number) => {
    state.carouselIndex = index
    myCarousel.value?.slideTo(index)
  }

  watch(
    () => myCarousel.value?.currentSlide,
    (index) => {
      if (typeof index === 'number') {
        state.carouselIndex = index
      }
    },
    { immediate: true }
  )
</script>
<style lang="scss" scoped>
  .slide-item {
    width: 100%;
    .fee_table {
      text-align: left;
    }
  }
</style>
<style lang="scss">
  .carousel__slide {
    align-items: flex-start;
  }
  .fee_td_in {
    margin: 0.25rem 0 0 0.5rem;
    font-size: 0.9rem;
  }
  .fee_td2_in {
    margin: 0.25rem 0 0 0.5rem;
    font-size: 0.9rem;
  }
  .fee_th2 {
    width: 9%;
    padding: 1rem 0.25rem;
    text-align: center;
    background: #111430;
    color: #f1e0b0;
    font-weight: normal;
    vertical-align: middle;
    font-weight: bold;
  }
  .thumb {
    margin: 0 0 3rem;
    background: #070a27;
    .slick-track {
      display: flex;
    }
    .slick-slide {
      text-align: center;
      padding: 0.85rem 0 0.75rem;
      font-size: 0.9rem;
      flex: 1;
      img {
        display: block;
        height: 1.25rem;
        margin: 0 auto 0.25rem;
      }
    }
    .slick-current {
      color: #febd09;
      background: #171a37;
    }
  }

  .ls_min {
    letter-spacing: -0.1rem;
  }
  @media (max-width: 1024px) {
    .fee_th2 {
      font-size: 0.96rem;
    }
    .fee_td_in,
    .fee_td2_in {
      font-size: 0.86rem;
    }
    .thumb {
      margin: 0 0 1.5rem;
      .slick-slide {
        padding: 0.5rem 0 0.4rem;
        font-size: 0.8rem;
        img {
          height: 1rem;
          margin: 0 auto 0.2rem;
        }
      }
    }
  }
</style>
