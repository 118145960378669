<template>
  <router-link
    :to="{
      name: 'NotificationArticle',
      params: { id: props.detail?.notification_id }
    }"
    class="info_set"
  >
    <div class="is_l">
      <span class="is_time">{{ props.detail?.start_date.slice(0, 10) }}</span>
      <span class="is_cat">{{
        store.language === 'en'
          ? TheENUM.contents['50007'].results[props.detail?.category].val2
          : TheENUM.contents['50007'].results[props.detail?.category].val1
      }}</span>
    </div>
    <div class="is_r">
      <span class="is_new" v-if="props.new">NEW!</span>
      <span class="is_title">{{ props.detail?.title }}</span>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
  import CDMST from '@/enum/cd_mst.json'
  import { CDMSTType } from '@/@types'
  import { useMainStore } from '@/store/pinia'

  const TheENUM = CDMST as CDMSTType
  const store = useMainStore()

  const props = defineProps({
    new: Boolean,
    detail: Object
  })
</script>
