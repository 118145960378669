<template>
  <user>
    <!-- article start -->
    <article id="contents" class="boxs loginart">
      <div class="main_contents boxs">
        <h1>{{ $t('pages.NotificationSetting.title') }}</h1>

        <div class="two-factor_center1">
          <form class="w60 mr20p ml20p tbw-w100 tbw-mr0p tbw-ml0p formbox">
            <the-message v-if="state.errorMessage">{{
              state.errorMessage
            }}</the-message>
            <div v-else>
              <the-message v-if="state.successMessage" type="primary">{{
                state.successMessage
              }}</the-message>
            </div>

            <ul class="tbw-pl8" style="margin-top: 0">
              <li>
                <label class="two_step_auth_way mb20"
                  ><span class="font_size">{{
                    $t('pages.NotificationSetting.item.title1')
                  }}</span></label
                >
              </li>
              <li
                v-for="option in options.notification_login"
                :key="option.key"
              >
                <label :for="option.value">
                  <input
                    type="checkbox"
                    v-model="notification_code"
                    name="notification_code"
                    :value="option.key"
                    :id="option.value"
                    class="uk-checkbox"
                  />
                  {{ option.value }}
                </label>
              </li>
            </ul>
            <ul class="tbw-pl8" style="margin-top: 0">
              <li>
                <label class="two_step_auth_way mb20"
                  ><span class="font_size">{{
                    $t('pages.NotificationSetting.item.title2')
                  }}</span></label
                >
              </li>
              <li
                v-for="option in options.notification_trade"
                :key="option.key"
              >
                <label :for="option.value">
                  <input
                    type="checkbox"
                    v-model="notification_code"
                    name="notification_code"
                    :value="option.key"
                    :id="option.value"
                    class="uk-checkbox"
                  />
                  {{ option.value }}
                </label>
              </li>
            </ul>
            <ul class="tbw-pl8" style="margin-top: 0">
              <li>
                <label class="two_step_auth_way mb20"
                  ><span class="font_size">{{
                    $t('pages.NotificationSetting.item.title3')
                  }}</span></label
                >
              </li>
              <li
                v-for="option in options.notification_transfer"
                :key="option.key"
              >
                <label :for="option.value">
                  <input
                    type="checkbox"
                    v-model="notification_code"
                    name="notification_code"
                    :value="option.key"
                    :id="option.value"
                    class="uk-checkbox"
                  />
                  {{ option.value }}
                </label>
              </li>
            </ul>

            <div class="two-factor_left" style="margin-top: 30px">
              <button
                class="uk-button uk-button-primary uk-width-1-1"
                v-debounce="{ func: confirm }"
                v-loading="{
                  loading: loading,
                  value: $t('pages.NotificationSetting.button')
                }"
                id="confirm"
                type="button"
                >{{ $t('pages.NotificationSetting.button') }}</button
              >
            </div>
          </form>
        </div>
      </div>

      <the-modal
        v-if="state.modal"
        id="modal-notification"
        hasClose
        @close="state.modal = false"
      >
        <section class="modal-inner">
          <h1 v-html="$t('pages.NotificationSetting.confirm.title')"></h1>

          <div>{{ $t('pages.NotificationSetting.confirm.msg') }} </div>

          <button
            class="uk-button uk-width-1-1 uk-margin-small-bottom primaryAction uk-button-primary"
            style="margin-top: 30px"
            v-debounce="{
              func: submit
            }"
            v-loading="{
              loading: loading,
              value: $t('pages.NotificationSetting.button')
            }"
            type="button"
            >{{ $t('pages.NotificationSetting.button') }}</button
          >
        </section>
      </the-modal>
    </article>
    <!-- article end -->
  </user>
</template>

<script lang="ts" setup>
  import { reactive, ref, onMounted, watch } from 'vue'
  import User from '@/components/User.vue'
  import * as yup from 'yup'
  import { useI18n } from 'vue-i18n'
  import { validator, options } from '@/enum/enums'
  import { useRouter } from 'vue-router'
  import TheModal from '@/components/TheModal.vue'
  import { getNotificationSetting, setNotificationSetting } from '@/api'

  const { t } = useI18n()
  const form = ref<(HTMLInputElement & { validate: any }) | null>(null)
  const loading = ref(false)
  const router = useRouter()
  const notification_code = ref<string[]>([])

  const state: {
    twostep_check: string
    formJson: any
    modal: boolean
    successMessage: string
    errorMessage: string
  } = reactive({
    twostep_check: '',
    formJson: {},
    modal: false,
    successMessage: '',
    errorMessage: ''
  })

  const confirm = async () => {
    state.modal = true
  }

  const submit = async () => {
    state.errorMessage = ''
    const valid = form.value?.validate()

    setTimeout(async () => {
      if (
        valid?.meta?.valid ||
        document.getElementsByClassName('error').length === 0
      ) {
        loading.value = true

        try {
          const { contents } = await setNotificationSetting({
            contents: {
              notification_code: notification_code.value
            }
          })
          loading.value = false
          state.successMessage = t('error.success_modify')
        } catch (error: any) {
          state.errorMessage = error.error_translations || error.message_detail
          document.documentElement.scrollTop = 0
          loading.value = false
        } finally {
          state.modal = false
        }
      }
    }, 0)
  }

  onMounted(async () => {
    try {
      const { contents } = await getNotificationSetting()
      notification_code.value = contents?.notification_code
    } catch (error: any) {
      state.errorMessage = error.error_translations || error.message_detail
    } finally {
      document.documentElement.scrollTop = 0
    }
  })
</script>
<style lang="scss" scoped></style>
