<template>
  <header
    id="header"
    :class="[router.currentRoute.value.name == 'Trade' ? 'header-trade' : '']"
  >
    <div class="progress" :class="{ show: state.progress }"></div>
    <div
      class="h_container"
      :class="[
        router.currentRoute.value.name == 'Trade' ? 'container-trade' : ''
      ]"
    >
      <div class="logo">
        <template v-if="isLogin !== null && props.meta?.header !== false">
          <template v-if="!isLogin">
            <router-link :to="{ name: 'Landing' }"
              ><img src="@static/img/coinestate_logo.png" alt="Coin Estate1"
            /></router-link>
          </template>
          <template v-else>
            <router-link :to="{ name: 'Home' }"
              ><img
                src="@static/img/coinestate_logo.png"
                alt="Coin Estate2"
                style="max-height: 45px; width: auto"
            /></router-link>
          </template>
        </template>
        <img
          src="@static/img/coinestate_logo.png"
          alt="Coin Estate3"
          style="max-height: 45px; width: auto"
          v-else
        />
      </div>

      <div class="header_nav" v-if="props.meta?.header !== false">
        <div class="hamburger-menu">
          <input type="checkbox" id="menu-btn-check" v-model="state.showMenu" />
          <label for="menu-btn-check" class="menu-btn"><span></span></label>

          <nav id="nav" class="menu-content">
            <template v-if="isLogin">
              <span
                class="menutab1"
                :class="{ menuactv: state.sideMenu === '1' }"
                @click="state.sideMenu = '1'"
                ><i class="fas fa-user"></i
              ></span>
              <span
                class="menutab2"
                :class="{ menuactv: state.sideMenu === '2' }"
                @click="state.sideMenu = '2'"
                ><i class="fas fa-bars"></i
              ></span>
            </template>

            <ul id="menu">
              <home-map
                class="sp menu1"
                v-if="state.sideMenu === '1' && isLogin"
              ></home-map>

              <site-map
                class="menu2"
                :list="state.siteMap"
                v-if="
                  !state.showMenu ||
                  state.sideMenu === '2' ||
                  state.windowWidth > 1024
                "
              ></site-map>
              <template v-if="isLogin !== null">
                <template v-if="!isLogin">
                  <div class="new-hamburger">
                    <template v-if="router.currentRoute.value.name !== 'LP'">
                      <span class="sp">
                        <router-link :to="{ name: 'Login' }">{{
                          $t('header.login')
                        }}</router-link>
                      </span>
                    </template>

                    <span class="sp">
                      <router-link :to="{ name: 'NewAccount' }">{{
                        $t('header.newAccount')
                      }}</router-link>
                    </span>
                  </div>
                </template>
                <template v-else>
                  <li class="sp"
                    ><router-link :to="{ name: 'Home' }">{{
                      $t('header.myPage')
                    }}</router-link></li
                  >
                  <li class="sp"
                    ><a :href="`${state.domain}/logout/`">{{
                      $t('header.logout')
                    }}</a></li
                  >
                </template>
              </template>
            </ul>
          </nav>
        </div>
      </div>

      <nav id="h_nav">
        <template v-if="!isLogin">
          <template v-if="router.currentRoute.value.name !== 'LP'">
            <router-link
              :to="{ name: 'Login' }"
              id="top_login_link"
              class="h_nav_btn"
              >{{ $t('header.login') }}</router-link
            >
          </template>

          <router-link
            :to="{ name: 'NewAccount' }"
            id="top_acnt_reg_link"
            class="h_nav_btn"
            >{{ $t('header.newAccount') }}</router-link
          >
        </template>
        <template v-else>
          <router-link
            :to="{ name: 'Home' }"
            id="top_login_link"
            class="h_nav_btn"
            v-if="props.meta?.header !== false"
            >{{ $t('header.myPage') }}</router-link
          >
          <a
            :href="`${state.domain}/logout/`"
            id="top_logout_link"
            class="h_nav_btn"
            >{{ $t('header.logout') }}</a
          >
        </template>

        <template
          v-if="
            router.currentRoute.value.meta.lang !== false &&
            !(
              router.currentRoute.value.meta.isCorporation &&
              home?.user_type === '2'
            )
          "
        >
          <div id="lang">
            <span
              class="lang_icon"
              @click.stop="state.localeShow = !state.localeShow"
              >{{ languages.find((i) => i.id === language)?.abbreviation
              }}<i class="fas fa-chevron-down"></i
            ></span>
            <div class="lang_sel" v-show="state.localeShow">
              <span class="lang_sels lang_sels_a">{{
                languages.find((i) => i.id === language)?.name
              }}</span>
              <a
                @click="changeLocale(item.id)"
                class="lang_sels"
                v-for="item in languages.filter((i) => i.id !== language)"
                :key="item.id"
                >{{ item.name }}</a
              >
            </div>
          </div>
        </template>
      </nav>
    </div>
  </header>
  <div
    v-if="env !== 'prod'"
    style="color: #fff; text-align: center; font-size: 14px; font-weight: bold"
    :style="{
      backgroundColor: state.colorList[(env as string) as EnvType || 'local']
    }"
  >
    この環境は「 VUE {{ env?.toLocaleUpperCase() }} 」です。
  </div>
</template>

<script lang="ts" setup>
  import { reactive, watch, onMounted, onUnmounted, computed } from 'vue'
  import { storeToRefs } from 'pinia'
  import { languages } from '@/enum/languages'
  import { useMainStore } from '@/store/pinia'
  import { useRouter } from 'vue-router'
  import SiteMap from '@/components/SiteMap.vue'
  import HomeMap from '@/components/HomeMap.vue'
  import { i18nType } from '@/@types'

  interface Props {
    meta?: Record<string, any>
  }

  const props = withDefaults(defineProps<Props>(), {
    meta: () => ({})
  })

  const store = useMainStore()
  const { siteMap, isLogin, language, env, home } = storeToRefs(store)
  const router = useRouter()

  const state = reactive({
    showMenu: false,
    siteMap: computed(() =>
      siteMap.value.filter((i: { route: string }) => i.route != 'Landing')
    ),
    domain: import.meta.env.VITE_APP_DJANGO_DOMAIN as string,
    localeShow: false,
    progress: true,
    sideMenu: '1',
    windowWidth: 0,
    colorList: {
      local: 'red',
      dev: '#0798ff',
      it: '#ffa907',
      stg: '#2ade2a'
    } as const
  })

  type EnvType = keyof typeof state.colorList

  const changeLocale = (id: i18nType) => {
    store.changeLocale(id)
    state.localeShow = false
  }

  const closeLocale = () => {
    state.localeShow = false
  }

  const getWindowWidth = () => {
    state.windowWidth =
      window.innerWidth || document.documentElement.clientWidth
  }

  watch(
    () => router.currentRoute.value,
    () => {
      state.progress = false
      state.showMenu = false
      state.progress = true
      setTimeout(() => {
        state.progress = false
      }, 2200)
    }
  )

  watch(
    isLogin,
    (newIsLogin) => {
      state.sideMenu = newIsLogin ? '1' : '2'
    },
    { immediate: true }
  )

  watch(
    language,
    (lang) => {
      if (lang === 'en') {
        document.getElementsByTagName('body')[0].classList.add('en')
      } else {
        document.getElementsByTagName('body')[0].classList.remove('en')
      }
    },
    { immediate: true }
  )

  onMounted(() => {
    getWindowWidth()
    window.addEventListener('resize', getWindowWidth)
    if (store.userInfo.campaign === '1') store.setSiteMapByCampaign()

    setTimeout(() => {
      state.progress = false
    }, 2200)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', getWindowWidth)
  })

  defineExpose({
    closeLocale
  })
</script>

<style lang="scss" scoped>
  .lang_sel {
    display: block;
  }

  #header {
    position: relative;
  }

  .progress {
    width: 100%;
    height: 2px;
    background-color: #febd09;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: progress 2s ease-in-out;
    display: none;
    &.show {
      display: block;
    }
  }

  @keyframes progress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    .menu1,
    .menu2 {
      display: block;
    }
  }

  .new-hamburger {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    // border-top: 1px solid #FEBD09;

    span {
      width: 49.5%;
      text-align: center;
      padding: 10px 0;
      &:first-of-type {
        border: 1px solid #fff;
        color: #fff;
      }
      &:last-of-type {
        border: 1px solid #febd09;
        color: #febd09;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    #menu {
      border-bottom: none;
    }
  }

  .header-trade,
  .container-trade {
    height: 60px !important;
  }
</style>

<style lang="scss">
  #pmenu,
  #menu {
    .open_1,
    .open_2,
    .open_3,
    .open_4,
    .open_5,
    .open_6,
    .open_7,
    .open_8,
    .open_9,
    .open_10,
    .open_11,
    .open_12,
    .open_13,
    .open_14,
    .open_15,
    .open_16 {
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      line-height: 32px;
      opacity: 0.6;
    }
  }
</style>
